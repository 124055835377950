<template>
  <div
    element-loading-spinner='el-icon-loading'
    element-loading-text='加载中……'
    v-loading='loading'>
    <div class='default-table'>
      <el-table
        :data='tableData'
        :highlight-current-row='true'
        :row-class-name='rowClassName'
        border
        height='80vh'
        ref='refTblLiveDataRecord'
        style='width: 100%'>
        <template v-for='(column,index) in columns'>
          <el-table-column :align="column.align?column.align:'right'"
                           :header-align="column.header_align?column.header_align:'center'"
                           :key='index'
                           :label='`${column.title}`'
                           :min-width='`${column.width}`'
                           :prop='`${column.value}`'
                           v-if="column.component==='el-date-picker'">
            <template slot-scope='{row}' v-if="column.value==='live_time'">
              <el-date-picker
                :placeholder='`选择${column.title}`'
                @change='selectInput(row,column.value)'
                format='yyyy-MM-dd HH:mm:ss'
                size='mini'
                style='width: 98%'
                type='datetime'
                v-model='row[column.value]' value-format='yyyy-MM-dd HH:mm:ss'>
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column :align="column.align?column.align:'right'"
                           :header-align="column.header_align?column.header_align:'center'"
                           :key='index' :label='`${column.title}`'
                             :min-width='220'
                           :prop='`${column.value}`'
                           style="width: 79px;border: 1px solid red;"
                           v-else-if="column.component==='el-input'&&column.value==='account_id'">
            <template slot-scope='{row}'>
              <el-select                     @change='selectInput(row,column.value)'
                                             style="width: 80%;display: block" v-model="row.account_id" placeholder="请选择">
                <el-option
                    v-for="item in artistPlatformCode"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column :align="column.align?column.align:'right'"
                           :header-align="column.header_align?column.header_align:'center'"
                           :key='index' :label='`${column.title}`'
                           :min-width='`${column.width}`'
                           :prop='`${column.value}`'
                           v-else>
            <editable-cell :is-input-number='column.value!="special_venue"' :show-input='true'
                           @change='selectInput(row,column.value)'
                           slot-scope='{row}' v-model='row[column.value]'>
                      <span slot='content'>
                      <span>{{ row[column.value] }}</span>&nbsp;&nbsp;
                        <i class='el-icon-edit float-right' v-if='showEditIcon'></i></span>
            </editable-cell>
          </el-table-column>
        </template>
        <el-table-column align='center' fixed='right' label='操作项' width='120'>
          <template slot-scope='{$index,row}'>
            <el-link :disabled='loadingAdd' @click='saveCurrentRow(row)' type='primary'
                     v-if='tableData.length-1===$index'>
              <span><i class='el-icon-plus'></i>{{ loadingAdd ? '提交中' : '添加' }}</span>
            </el-link>
            <el-link @click='delRow(row,$index)' type='danger' v-else><i class='el-icon-delete'></i>移除
            </el-link>
            <!--                                                <el-link type="danger" @click="copyRow(row,$index)"><i class="el-icon-document-copy"></i>复制</el-link>-->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-row style='margin-top: 10px'>
      <el-col :span='12' style='text-align: left'>
        <Tips :data='tipsList' />
      </el-col>
      <el-col :span='12' style='text-align: right'>
        <el-pagination
          :current-page='currentPage'
          :layout='defaultLayout'
          :page-size='pageSize'
          :page-sizes='pageSizes'
          :total='dataTotal'
          @current-change='handleCurrentChange'
          @size-change='handleSizeChange'
          background>
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
// import { numberFormat } from "@/utils/utilEditableCells";
import Tips from '@/components/Tips'
import {editTable} from '@/components/mixins/editable'

export default {
  name: 'LiveDataRecord',
  components: {
    EditableCell, Tips
  }, mixins: [editTable],
  props: {
    readOnly: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    artistPlatformCode: {
      type: Array,
    },
    searchCondition: {
      type: Object,
      default: () => {
        return {
          group_id: null,
          group_name: '',
          artist_id: null,
          nickname: '',
          platform_code: '',
          platform_name: '',
          time_value: [],
          ym: null
        }
      }
    },
    colSet: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loadingAdd: false,
      tipsList: [
        '排序：默认按直播时间排序',
        '录入：直播时间【必填】，其他列数据必须是【数值】',
        '粘贴：从excel文件中复制一行数据，粘贴（Control + V），要求excel表格列和当前页面列顺序一致',
        '添加：最后一行（黄色底色）为新增数据录入框，需要 点击"添加"按钮，创建一行数据（显示在当前操作行上一行，刷新后按默认排序规则排序）'
      ],
      // ---- 分页相关配置 -------
      apePageSize: 0, // 用于组件切换了每一页数据后的显示
      currentPage: 1,
      tblHeight: 500,
      showEditIcon: true,//是否显示编辑按钮
      loading: false,
      tableData: [],
      tableDataAppend: [{}],
      loadingStatus: false,
      offset: 0,
      activeNames: [],

      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 100
      },
      fields: [],
      currentRow: {},//当前选中的行
      currentColumn: {},//当前选中的列
      currentIndex: {},//当前选中的数据索引
      JsonStr: '',
      columns: []
    }
  },
  computed: {
    // columns() {
    //   let platform_code = this.searchCondition.platform_code
    //   let currentCols = this.colSet[platform_code] ? this.colSet[platform_code] : this.colSet['custom']
    //   return currentCols
    // }
  },

  mounted() {
    this.initColFields()
    // 监听 ctrl+v键盘事件
    document.addEventListener('paste', this.getPasteText, false)

  },
  beforeDestroy() {
    document.removeEventListener('paste', this.getPasteText, false)
  },
  methods: {
    // 获取粘贴文本处理：若有多列数据，则询问确认粘贴开始列
    getPasteText(event) {
      let clipboardData = (event.clipboardData || window.clipboardData)
      const text = clipboardData.getData('text')
      let arrText = text.trim().split(/ [(\r\n)\r\n] /).join().split('\t') // 以转行符切割文本字符串
      if (arrText.length > 1) {
        let fields = this.fields
        let pasteText = arrText.join('\t')
        let confirmMsg = `整行列粘贴：[${pasteText}]，是否确认?`

        // //当前列的字段
        // if (this.currentColumn) {
        //   let field = this.currentColumn.property
        //   let label = this.currentColumn.label || '未知'
        //   let startIndex = this.fields.indexOf(field)
        //
        //   //计算从哪列开始填充
        //   if (startIndex > -1) {
        //     //若选中的是可填充列，则从选中列开始往后填充，否则，填充整列
        //     let len = arrText.length
        //     fields = this.fields.slice(startIndex, len + startIndex)
        //     confirmMsg = `从列【${ label }】开始粘贴：[${ pasteText }]，是否确认?`
        //   }
        // }

        this.$confirm(confirmMsg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.saveRow(fields, arrText)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getTime()
    },
    //列的字段集合（用于复制粘贴）
    async initColFields() {
      let platform_code = this.searchCondition.platform_code
      let currentCols = this.colSet[platform_code] ? this.colSet[platform_code] : this.colSet['custom']
      if (currentCols) {
        this.columns = [...currentCols]
      }

      //列设置
      this.fields = this.columns.map((item) => {
        return item.value
      })
    },
    async initList() {
      this.loading = true
      let pagingInfo = this.getPagingInfo()
      let searchCondition = this.handleSearchCondition()
      let orderSort = { live_time: 'asc', id: 'asc' }
      Object.assign(searchCondition, pagingInfo, { order: orderSort })
      let { list, pages } = await this.$api.getLiveInfoList(searchCondition)
      this.$nextTick(() => {
        this.tableData = list
        this.tableData.push({})
      })
      this.pagingData.total = pages.total
      // this.pagingData.offset = pages.offset

      this.loading = false
    },
    async addInfoData(row) {
      let data = {
        artist_id: this.searchCondition.artist_id,
        platform_code: this.searchCondition.platform_code,
        platform_name: this.searchCondition.platform_name,
        group_id: this.searchCondition.group_id,
        group_name: this.searchCondition.group_name,
        nickname: this.searchCondition.nickname
      }
      Object.assign(data, row)
      let id = await this.$api.addLiveInfo(data)
      if (id) {
        this.$notify.success('添加成功')
        // this.tableData.unshift(row)
        //获取这条数据
        let { info } = await this.$api.getLiveInfo(id)
        // console.log('info', info)
        let len = this.tableData.length
        this.tableData.splice(len - 1, 1)
        // this.tableData.unshift(info)
        this.tableData.push(info)
        this.tableData.push({})
        this.$nextTick(() => {
          this.$refs.refTblLiveDataRecord.doLayout()
        })

      }
    },
    delRow(row, index) {
      this.$confirm(`此操作将永久删除该行数据（ID:${row.id}）, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (row.id) {
          this.$api.delLiveInfo(row.id)
        }
        this.tableData.splice(index, 1)

        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })

    },
    selectInput(row, field) {
      if (row.id) {
        if (row[field] && row[field] !== '' && row[field].length > 0)
          this.saveCell(row, field)
        else {
          if (field !== 'live_time') {
            row[field] = 0
            this.saveCell(row, field)
          }
        }
      }
    },
    //保存单元格数据
    async saveCell(row, field) {
      let data = {}
      if (row.id) {
        data['id'] = row.id
        data['field'] = field
        data['val'] = row[field]
        // data[field] = row[field];
        // data._current_field = field;
      } else {
        this.$notify.error('保存失败！')
        // data = { ...row, ...{ _current_field: field } }
      }

      let id = await this.$api.updateLiveInfo(data)
      if (!id) {
        this.$notify.error('修改失败！')
      }
    },
    //保存单元格数据
    //  async saveCell(row, field) {
    //    let isNull = false
    //    let data = {}
    //    if (row.id) {
    //      data['id'] = row.id
    //      data['field'] = field
    //      data['val'] = row[field]
    //      // data[field] = row[field];
    //      // data._current_field = field;
    //    } else {
    //      this.$notify.error('保存失败！')
    //      // data = { ...row, ...{ _current_field: field } }
    //    }
    //
    //    let regex = /^\d+(\.\d+)?$/
    //    let isNumber = regex.test(row[field])
    //    console.log(row[field])
    //    console.log(isNumber)
    //    if (!isNumber) {
    //      data['id'] = row.id
    //      data['field'] = field
    //      data['val'] = null
    //      isNull = true
    //      this.$message.warning('请输入数字')
    //    }
    //    if (!isNull) {
    //      let id = await this.$api.updateLiveInfo(data)
    //      if (!id) {
    //        this.$notify.error('修改失败！')
    //      }
    //    } else {
    //      this.$message.warning('请输入数字')
    //
    //    }
    //
    //  }
  }
}
</script>

<style scoped>

</style>
<style>
.el-table .add_line {
  background: #f9f6f1;
}

.add_line {
  background-color: #00ff80;
}
</style>
