<template>
  <div class='edit-panel'>
    <div class='edit-title'>
      <span>编辑【{{ widget.title }}】</span>
    </div>
    <div style='margin: 10px;padding: 5px'>
      <div class='flex-left'>
        <div class='widget' :class='[currIndex===index?"active":""]' v-for='(widgetEl,index) in widgets'
             :key='`${widgetEl.title}_${index}`'>
          <div class='cursor-pointer edit-obj ellipsis' @click='handleEdit(widgetEl,index)'> {{ widgetEl.title }}</div>
          <el-link :type='currIndex===index?"primary":"default"' icon='el-icon-circle-close'
                   @click='handleRemove(widgetEl)'
                   style='margin-right:4px;font-size: 16px'></el-link>
        </div>
      </div>
    </div>
    <div class='control-option'>
      <div class='flex-left' style='margin-bottom: 5px;'>
        <el-button type='default' icon='el-icon-plus' @click='handleAdd'>新增</el-button>
        <el-button :disabled='commitLoading' :loading='commitLoading' @click='handleSave' type='primary'>保存
        </el-button>
        <el-button :disabled='commitLoading' :loading='commitLoading' @click='handleReset' type='default'>重置
        </el-button>
      </div>
      <div class='edit-option'><span class='label'>标题</span>
        <div class='edit-val flex-left'>
          <div>
            <el-input v-model='widget.title' placeholder='字段标题' clearable></el-input>
          </div>
          <div>
            <el-input v-model='widget.field' placeholder='字段标识符' clearable></el-input>
          </div>
        </div>
      </div>
      <div class='edit-option'>
        <span class='label'>类型</span>
        <div class='edit-val'>
          <el-select v-model='widget.type' placeholder='类型' clearable>
            <el-option v-for='(typeOption,index) in typeOptions' :key='`type_${index}`' :value='typeOption.value'
                       :label='typeOption.label'>
              {{ typeOption.label }}
            </el-option>
          </el-select>
        </div>
      </div>

      <div class='edit-option' v-if='widget.type'><span class='label'>内容</span>
        <div style='width: 100%'>
          <ape-uploader v-if='widget.type==="image"' @handleUploadSuccess='handleUploadSuccess'
                        :uploadFileList='uploadFileList' :allowSize='allowSize' />
          <ApeEditor :toolbar='toolbar' v-else-if='widget.type==="rich"'
                     :init-html='widget.value'
                     @handleTinymceInput='(e)=>handleRichTxt(e,"value")'></ApeEditor>
          <div v-else>
            <editable-cell :show-input='true'
                           v-model='widget.value' :type='widget.type' placeholder='值编辑'>
              <div slot='content'>
                <div class='flex-sb'>
                  <span>{{ widget.value }}</span>
                  <i class='el-icon-edit default-edit-icon'></i>
                </div>
              </div>
            </editable-cell>
          </div>
        </div>
      </div>
      <div class='edit-option'><span class='label'>图层</span>
        <div class='edit-val'>
          <el-input-number type='number' clearable v-model='widget.z_index' :step='20'>
          </el-input-number>
        </div>
      </div>
      <div class='edit-option'>
        <span class='label'>宽</span>
        <div class='edit-val'>
          <el-input-number type='number' clearable v-model='widget.width' :step='20'>
          </el-input-number>
          <span class='input-val'>(单位：px)</span>
        </div>
        <span class='label'>单元格-宽</span>
        <div class='edit-val'>
          <el-input-number type='number' clearable v-model='widget.cell_width' :step='10'>
          </el-input-number>
          <span class='input-val'>(单位：pt)</span>
        </div>
      </div>
      <div class='edit-option'>
        <span class='label'>高</span>
        <div class='edit-val'>
          <el-input-number type='number' clearable v-model='widget.height' :step='20'></el-input-number>
          <span class='input-val'>(单位：px)</span>
        </div>
        <span class='label'>单元格-高</span>
        <div class='edit-val'>
          <el-input-number type='number' clearable v-model='widget.cell_height' :step='10'></el-input-number>
          <span class='input-val'>(单位：pt)</span>
        </div>
      </div>
      <div class='flex-left'>
        <div class='edit-option'>
          <span class='label'>行区间</span>
          <div class='edit-val'>
            <el-input-number type='number' clearable v-model='widget.start_row' :step='1'></el-input-number>
            <span> ~ </span>
            <el-input-number type='number' clearable v-model='widget.end_row' :step='1'></el-input-number>
          </div>
        </div>
        <div class='edit-option'>
          <span class='label'>列区间</span>
          <div class='edit-val'>
            <el-input type='text' clearable v-model='widget.start_col' placeholder='比如，A'
                      style='width:150px'></el-input>
            <span> ~ </span>
            <el-input type='text' clearable v-model='widget.end_col' placeholder='比如，K' style='width:150px'></el-input>
          </div>
        </div>
      </div>
      <div class='flex-left'>
        <div class='edit-option'>
          <span class='label'>单元格-合并</span>
          <div class='edit-val'>
            <el-input type='text' clearable v-model='widget.h_cell' placeholder='比如，A1' style='width:150px'></el-input>
            <span> ~ </span>
            <el-input type='text' clearable v-model='widget.v_cell' placeholder='比如，F2' style='width:150px'></el-input>
          </div>
        </div>
      </div>
      <div class='flex-left'>
        <div class='edit-option'><span class='label'>相对位置-left</span>
          <div class='edit-val'>
            <el-input-number type='number' clearable v-model='widget.offset_left' :step='20'></el-input-number>
          </div>
        </div>
        <div class='edit-option'><span class='label'>单元格-X轴</span>
          <div class='edit-val'>
            <el-input-number v-model='widget.x_offset' placeholder='X轴偏移量' style='width: 120px'
                             :step='1'></el-input-number>
          </div>
        </div>

      </div>
      <div class='flex-left'>
        <div class='edit-option'><span class='label'>相对位置-top</span>
          <div class='edit-val'>
            <el-input-number type='number' clearable v-model='widget.offset_top' :step='20'></el-input-number>
          </div>
        </div>
        <div class='edit-option'><span class='label'>单元格-Y轴</span>
          <div class='edit-val'>
            <el-input-number v-model='widget.y_offset' placeholder='Y轴偏移量' style='width: 120px'
                             :step='1'></el-input-number>
          </div>
        </div>
      </div>

      <div class='flex-left'>
        <!--        <div class='edit-option'><span class='label'>主题颜色</span>-->
        <!--          <div class='edit-val'>-->
        <!--            <el-input type='color' v-model='widget.theme_color' placeholder='主题色：RGB色值选择' clearable-->
        <!--                      class='color-input' />-->
        <!--            <el-input  class='color-input' v-model='widget.theme_color' placeholder='色值' style='width: 120px'></el-input>-->
        <!--&lt;!&ndash;            <span class='color-val'>{{ widget.theme_color }}</span>&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <div class='edit-option'><span class='label'>背景颜色</span>
          <div class='edit-val'>
            <el-input type='color' v-model='widget.bg_color' placeholder='背景颜色：RGB色值选择' clearable class='color-input' />
            <el-input class='color-input' v-model='widget.bg_color' placeholder='色值' style='width: 120px'></el-input>
            <!--            <span class='color-val' style='text-transform: uppercase;'>{{ widget.bg_color }}</span>-->
          </div>

        </div>
        <div class='edit-option'><span class='label'>字体颜色</span>
          <div class='edit-val'>
            <el-input type='color' v-model='widget.font_color' placeholder='字体大小，纯文本时生效' clearable
                      class='color-input' />
            <el-input class='color-input' v-model='widget.font_color' placeholder='色值' style='width: 120px'></el-input>
            <!--            <span class='color-val' style='text-transform: uppercase;'>{{ widget.font_color }}</span>-->
          </div>
        </div>
        <div class='edit-option'><span class='label'>边框颜色</span>
          <div class='edit-val'>
            <el-input type='color' v-model='widget.border_color' placeholder='字体大小，纯文本时生效' clearable
                      class='color-input' />
            <el-input class='color-input' v-model='widget.border_color' placeholder='色值'
                      style='width: 120px'></el-input>
            <!--            <span class='color-val' style='text-transform: uppercase;'>{{ widget.border_color }}</span>-->
          </div>
        </div>

      </div>

      <div>
        <div class='edit-option'>
          <span class='label'>字体大小</span>
          <div class='edit-val'>
            <el-input-number type='number' v-model='widget.font_size' :step='2' placeholder='字体大小(纯文本时生效)' clearable />
            <!--            <span class='input-val'>{{ widget.font_size }}</span>-->
          </div>
        </div>
        <div class='edit-option'>
          <span class='label'>字体加粗</span>
          <div class='edit-val'>
            <el-select v-model='widget.font_weight' placeholder='类型' clearable>
              <el-option v-for='(option,index) in fontWeightMap' :key='`font_weight_${index}`' :value='option.value'
                         :label='option.label'>
                {{ option.label }}
              </el-option>
            </el-select>
            <!--            <el-input type='text' v-model='widget.font_weight' placeholder='字体加粗(纯文本时生效)' clearable />-->
            <!--            <span class='input-val'>{{ widget.font_weight }}</span>-->
          </div>
        </div>
      </div>
      <div class='edit-option'><span class='label'>超链接</span>
        <div class='edit-val'>
          <el-input type='text' v-model='widget.hyperlink' placeholder='超链接,例如：抖音KOL'
                    clearable />
          <!--          <el-input type='text' v-model='widget.hyperlink' placeholder='超链接,例如：Location#sheet1!A1'-->
          <!--                    @focus='handleFocus("Location#sheet1!A1")' clearable />-->
        </div>
      </div>
      <div style='text-align: center;'>
        <el-button :disabled='commitLoading' :loading='commitLoading' @click='handleSave' type='primary'>保存</el-button>
        <el-button :disabled='commitLoading' :loading='commitLoading' @click='handleReset' type='default'>重置</el-button>
      </div>
      <br />
      <div class='border-effect shadow-effect' style='padding: 10px'>
        <h3>概念</h3>
        <!--        <ul>-->
        <!--          <li><span>1、1磅=1/72英寸（1英寸是2.54厘米，故磅也是绝对大小，1磅就是固定长度）</span></li>-->
        <!--          <li><span>2、1英寸 = 1像素 / Dpi =2.54cm=72磅</span></li>-->
        <!--          <li><span>3、Dpi常用于印刷，表每英寸能打印的点数，如72Dpi=72像素/英寸，我们用大写DPI仅代表Dpi的数字</span></li>-->
        <!--          <li><span>4、px = pt * DPI / 72（即，1像素=1磅*DPI/72）</span></li>-->
        <!--          <li><span>5、显示屏dpi 大多是72 少数96</span></li>-->
        <!--          <li><span style='font-weight: bold'>所以在72dpi屏幕下，1像素=1磅 或是1点</span></li>-->
        <!--        </ul>-->
        <!--        <div>-->
        <!--          在大多数情况下，1px大约等于1.33333pt（这是一个常用的近似值，但具体可能因屏幕分辨率和设置而异，但这里我们采用这个近似值进行计算）。-->
        <!--        </div>-->
        <div>
          <span style='color: #fe346e;font-weight: bold;margin-left: 10px'>100px ≈ 13.89pt</span>
          <el-image :src='tipSrc'></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import ApeUploader from '@/components/ApeUploader'
import ApeEditor from '@/components/ApeEditor'
import { html2json } from 'html2json'
import tipSrc from "./px2pt.png"
//控件元素属性
const widgetStruct = {
  title: '元素1',
  field: 'el_1',
  value: '',
  type: 'text',
  h_cell: 'B2',
  v_cell: 'F2',
  width: 120,
  height: 30,
  cell_height: 16.5,
  cell_width: 13.5,
  start_row: 0,
  end_row: 0,
  start_col: 'A',
  end_col: 'B',
  offset_left: 0,
  offset_top: 0,
  x_offset: 0,
  y_offset: 0,
  bg_color: '',
  font_size: 12,
  font_color: '#000000',
  font_weight: 'normal',
  // theme_color: '#F3E9E9',
  border_color: '#FFFFFF',
  // theme_color: '#C00000',
  col_width: 0,
  row_height: 10,
  hyperlink: '',
  z_index: 8888
}

export default {
  name: 'ControlEditor',
  components: { ApeEditor, ApeUploader, EditableCell },
  data() {
    return {
      tipSrc:tipSrc,
      toolbar: 'undo redo paste',
      allowSize: 1024 * 1024 * 10,
      widget: { ...widgetStruct },
      widgets: [],
      // uploadFileList: [{ url: '' }],
      currIndex: 0,
      key: null,
      commitLoading: false,
      typeOptions: [
        { label: '样式', value: 'style' },
        { label: '文本', value: 'text' },
        { label: '文本域', value: 'textarea' },
        { label: '富文本', value: 'rich' },
        { label: '图片', value: 'image' }
      ],
      //100 - Thin，200 - Extra Light，300 - Light，400 - Regular，500 - Medium，600 - Semi Bold，700 - Bold，800 - Extra Bold，900 - Black
      //并不是所有字体都支持从100到900的所有字重
      fontWeightMap: [
        { label: '正常', value: 'normal', describe: 'normal等价于400' },
        { label: '加粗', value: 'bold', describe: 'bold等价于700' },
        { label: '更粗', value: 'bolder' },
        { label: '更粗更细', value: 'lighter' }
      ],
      DPI: '0'
    }
  },
  computed: {
    uploadFileList() {
      if (this.widget.type === 'image')
        return [{ 'url': this.widget.value }]
      return [{ 'url': '' }]
    }

  },
  methods: {
    updateEl(curr, index) {
      if (index > -1) {
        this.widgets[index] = curr
        this.currIndex = index
        // console.log(curr)
        this.widget = { ...curr }
      }

    },

    reset(fieldMaps) {
      let options = []
      fieldMaps.forEach((fieldMap) => {
        if (fieldMap.field && fieldMap.title)
          options.push({ ...widgetStruct, ...fieldMap })
      })

      this.widgets = options
      this.checkData()
    },
    checkData() {
      if (Object.values(this.widgets) !== this.widgets)
        this.widgets = Object.values(this.widgets)
    },
    checkDataArray(data) {
      if (Object.values(data) !== data) {
        data = Object.values(data)
      }
      return data
    },
    show(data) {
      data = this.checkDataArray(data)
      // console.log(JSON.stringify([...data]))
      this.widgets = [...data]
      if (this.widgets[0]) {
        let first = this.widgets[0]
        this.showCurrField(first.field, first.title)
      }

    },
    //选中元素， 若不存在，则新增
    showCurrField(field, title, offset_left, offset_top) {
      // console.log('field', field, title)
      try {
        if (!this.widgets) {
          this.widgets = []
        }
        if (this.widgets) {
          // console.log('if', this.widgets)
          // console.log('if', Object.values(this.widgets))
          // 若初始值是对象，则转为数组
          let currIndex = this.widgets.findIndex(value => value.field === field)
          if (currIndex === -1) {
            const fieldMap = { field, title, offset_left, offset_top }
            const newWidget = { ...widgetStruct, ...fieldMap }
            // console.log('newWidget', newWidget)

            // 若不存在，则创建
            this.widgets.push(newWidget)
            currIndex = this.widgets.findIndex(value => value.field === field)
          }
          this.handleEdit(this.widgets[currIndex], currIndex)
        }
      } catch (e) {
        console.log('e', e)
      }


    },
    handleRichTxt(val, field) {
      this.widgets[this.currIndex][field] = val
      this.widgets[this.currIndex]['rich_text'] = html2json(val)
    },
    handleUploadSuccess(data) {
      this.widgets[this.currIndex].value = data.full_path || null
      this.uploadFileList = [{ url: data.full_path }]
    },
    handleReset(options) {
      this.widgets = []
      if (options) {
        // let offset_left = 10, offset_top = 0
        let h_cellCol = 'B'
        if (options.length > 0) {
          options.forEach((row, index) => {
            const fieldMap = {
              field: row.field,
              title: row.title,
              type: row.type || 'text',
              value: row.value,
              z_index: row.z_index || 777,
              width: row.width || 120,
              height: row.height || 30,
              cell_height: row.cell_height || 16.5,
              cell_width: row.cell_width || 20,
              start_row: row.start_row || (index + 1),
              end_row: row.end_row || (index + 1),
              start_col: row.start_col || h_cellCol,
              end_col: row.end_col || h_cellCol,
              h_cell: row.h_cell || (h_cellCol + (index + 1)),
              v_cell: row.v_cell || (h_cellCol + (index + 1)),
              offset_left: row.offset_left,
              offset_top: row.offset_top,
              bg_color: row.bg_color || '',
              border_color: row.border_color || '#FFFFF',
              font_color: row.font_color || '',
              font_size: row.font_size || 12,
              font_weight: row.font_weight || 'normal',
              hyperlink: row.hyperlink || ''

            }
            const newWidget = { ...widgetStruct, ...fieldMap }
            this.widgets.push(newWidget)
            // offset_left += 0
            // offset_top += 35
          })
        }
      }

      this.widgets.forEach((item) => {
        // check 富文本 type=rich
        if (item.type === 'rich') {
          item['rich_text'] = item.value ? html2json(item.value) : []
        }
      })

      this.$emit('save', this.widgets)
    },
    handleFocus(val) {
      if (!this.widget.hyperlink)
        this.widget.hyperlink = val
    },
    handleSave() {
      this.commitLoading = true
      this.$emit('save', this.widgets)
      setTimeout(() => {
        this.commitLoading = false
      }, 1000)
    },
    handleEdit(item, index) {
      this.currIndex = index
      //双向绑定
      this.widget = item
      // if (item.type === 'image')
      //   this.uploadFileList = [{ 'url': item.value }]
      // this.$emit('handleCurr', item.field)
    },
    handleRemove(item) {
      this.checkData()

      const index = this.widgets.indexOf(item)
      this.widgets.splice(index, 1)
    },
    handleAdd() {
      this.checkData()
      let item = { ...widgetStruct }
      let index = this.widgets.length + 1
      item.title = `元素${index}`
      item.field = `field_${index}`
      this.widgets.push({ ...item })
    }
  },
  created() {
    let { dpiX, dpiY } = this.$utils.calcDpi()
    this.DPI = `dpiX:${dpiX} , dpiY:${dpiY}`
  }
}
</script>

<style scoped>

.edit-panel {
  border: var(--primary) 1px solid;
  width: 100%;
  /*padding: 0 2px;*/
  /*border-radius: 5px;*/
}

.edit-panel .edit-title {
  background-color: var(--primary);
  color: #f2f2f2;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  width: 100%;
  margin: auto;
}

.edit-panel .widget {
  /*padding: 2px 5px;*/
  min-width: 80px;
  max-width: 160px;
  width: 120px;
  height: 30px;
  /*line-height: 20px;*/
  border: #909399 1px solid;
  text-align: left;
  border-radius: 2px;
  color: #909399;
  vertical-align: middle;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.edit-panel .active {
  border: #fe346e 1px dashed !important;
  color: #fe346e !important;
}

.edit-panel .edit-obj {
  width: 100%;
  /*border: #fe346e 1px dashed;*/
  padding: 4px;
  font-size: 12px;
  /*font-weight: lighter;*/
}

.edit-panel .edit-obj:hover {
  background-color: #fe346e;
  color: #f2f2f2;
}

.edit-panel .control-option {
  padding: 10px;
  border: #f2f2f2 1px dashed;
  margin: 5px;
}

.edit-panel .control-option * {
  /*margin: 0;*/
  /*padding: 2px;*/
  box-sizing: border-box;
}


.edit-option {
  border: #f2f2f2 1px dashed;
  padding: 5px;
  display: flex;
  flex-direction: row;
  line-height: 20px;
  margin-bottom: 5px;
  gap: 10px;
}

.label {
  min-width: 120px;
  /*border: #f2f2f2 1px dashed;*/
  padding: 4px 2px;
  text-align: right;
  font-weight: bold;
  /*background-color: #000c17;*/
  /*color: #f2f2f2;*/
}

.label::after {
  content: "：";
}

.edit-val {
  width: 100%;
  /*border: #f2f2f2 1px dashed;*/
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.edit-val .color-input {
  width: 80px;
}

.edit-val .color-val, .input-val {
  margin-left: 10px;
  padding: 4px 2px;
  text-align: left;
  color: #000c17;
}

.action {
  cursor: pointer;
}
</style>
