<template>
  <div class='border-effect' style='padding: 20px;border-radius: 10px'>

    <el-tabs v-model='activeName'>
      <el-tab-pane label='刊例封面' name='first'>

      </el-tab-pane>
      <el-tab-pane label='KOL提报' name='second'>

      </el-tab-pane>
    </el-tabs>
    <div class='flex-sb' style='width: 100%'>
      <div>&nbsp;</div>
      <el-radio-group v-model='version'>
        <el-radio :label='2024'>2024年版</el-radio>
        <el-radio :label='2025'>2025年版</el-radio>
      </el-radio-group>
      <div style='width: 120px;' class='flex-evenly'>
        <el-link type='primary' @click='showEdit' icon='el-icon-edit'>修改</el-link>
        <PlcExport :search-condition='{year:searchCondition.year,month:searchCondition.month}'/>
      </div>
    </div>
    <div  style='width: 100%;text-align: unset'>
      <div v-if='version===2024'>
        <BaseCover :key='`BaseCover_${baseInfo.id}`' :data='baseInfo' v-if='baseInfo.id' />
      </div>
      <div v-else-if='version===2025'>
        <PlcCover :plc-id='baseInfo.id' v-if='baseInfo.id'/>
        <!--            <BaseCover2025 :key='`BaseCover2025_${baseInfo.id}`' :data='baseInfo' v-if='baseInfo.id' />-->
      </div>
    </div>

    <div class='plc-box'>
      <plc-month-list :key='`plc_month_list_${baseInfo.id}`' :base-info='baseInfo'
                      v-if='baseInfo.id'></plc-month-list>
    </div>
  </div>
</template>

<script>
import BaseCover from '@/pages/plc/detail/fragment/BaseCover'
import BaseCover2025 from '@/pages/plc/detail/fragment/BaseCover2025'
import PlcMonthList from '@/pages/plc/detail/fragment/PlcMonthList'
import ExportBtn from '@/components/export/ExportBtn'
import { mapGetters } from 'vuex'
import PlcExport from '@/pages/plc/export/PlcExport'
import PlcCover from '@/pages/plc/detail/fragment/PlcCover'

export default {
  name: 'ChildPanel',
  components: { PlcCover, PlcExport, ExportBtn, PlcMonthList, BaseCover, BaseCover2025 },
  props: {
    baseInfo: {
      type: Object,
      default() {
        return {
          id: null,
          year: null,
          month: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    searchCondition() {
      return { year: this.baseInfo.year, month: this.baseInfo.month }
    }
  },
  data() {
    return {
      version: 2025,
      activeName: 'first'
    }
  },
  methods: {
    showEdit() {
      if (this.baseInfo.id) {
        let id = this.baseInfo.id
        //一个刊例只打开一个窗口
        const windowTarget = `plc_cover_setting_${id}`
        // const windowTarget = "_blank"
        const windowFeatures = 'left=0,top=0,width=1680,height=1080'
        // 浏览器打开新窗口，若是钉钉微应用，则路由跳转
        this.$utils.safeOpenWindow(this, {
          name: 'plc_cover_setting',
          query: { id: id }
        }, windowTarget, windowFeatures)
      } else {
        this.$notify.warning('封面ID未知')
      }

      // if (this.$refs['refEditBaseCover2025'])
      //   this.$refs['refEditBaseCover2025'].show(this.baseInfo)
    }
  }
}
</script>

<style scoped>
.plc-box{
  padding: 10px;
  border: #f2f2f2 1px dashed;
  border-radius: 10px;
  margin-top: 5px;
}
</style>
