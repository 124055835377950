<template>
  <el-dialog title='添加刊例' :visible.sync='dialogVisible' width='600px'>
    <div class='border-effect' style='padding: 10px'>
      <el-form :model='formData' size='small' label-width='98px' :inline='false'>
        <el-form-item label='平台'>
          <!--        <el-input placeholder='platform_code' v-model='formData.platform_code' />-->
          <el-select v-model='formData.platform_code' @change='handleTitle'>
            <el-option :label='platformOption.name' :value='platformOption.code'
                       v-for='(platformOption,index) in platformOptions' :key='`platformOption${index}`'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='标题'>
          <el-input placeholder='标题' v-model='formData.title' />
        </el-form-item>
        <el-form-item label='同上月'>
          <!--        <el-input placeholder='同上月' v-model='formData.same_notice' />-->
          <el-select v-model='formData.same_notice'>
            <el-option label='是' :value='true'></el-option>
            <el-option label='否' :value='false'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='排序'>
          <el-input-number v-model='formData.order' />
        </el-form-item>
        <el-form-item style='text-align: center'>
          <el-button size='medium' type='primary' @click='handleSave'>保存</el-button>
          <el-button size='medium' type='default' @click='dialogVisible=false'>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddPlcMonth',
  data() {
    return {
      dialogVisible: false,
      platformOptions: [],
      formData: {
        year: null,
        month: null,
        title: null,
        platform_code: null,
        validity_date: null,
        expiry_date: null,
        deadline: null,
        logo_url: null,
        same_notice: true,
        order: 0
      }
    }
  },
  methods: {
    async show(info) {
      let { list } = await this.$api.getJournalList({})
      this.platformOptions = list || []
      this.dialogVisible = true
      this.formData.year = info.year
      this.formData.month = info.month
      this.formData.validity_date = info.validity_date
      this.formData.expiry_date = info.expiry_date
      this.formData.deadline = info.deadline
    },
    handleTitle(val) {
      let obj = this.platformOptions.find(value => value.code === val)
      if (obj) {
        this.formData.title = `${this.formData.year}年${this.formData.month}月缇苏${obj.name}KOL刊例`
      }
    },
    handleSave() {
      this.$confirm('确认添加', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.addPublicationInfo(this.formData)
        this.dialogVisible = false
        this.$emit('saved')
      })
    }
  }
}
</script>

<style scoped>

</style>
