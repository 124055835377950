<template>
<div>
  <el-image :style='`width: ${logoSetting.width}px;height: ${logoSetting.height}px`' fit='contain'
            :src="`${logoSetting.logo_url}`"
            :alt='`平台LOGO`' v-if='logoSetting.logo_url' />
</div>
</template>

<script>
export default {
  name: 'PlcLogo',
  props:{
    logoSetting:{
      type:Object
    }
  }
}
</script>

<style scoped>

</style>
