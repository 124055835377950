<template>
  <div class="router">
    <div class='router-box'>
      <div :key='index' v-for='(item, index) in conditionArr'>
        <div class='col-box'
             :class="index===0?'col-first':index===conditionArr.length-1?'col-final':''">
          <div class='top-cover-line'></div>
          <div class='top-vertical-line'></div>
          <div class='condition-node'>
            <!--              条件显示-->
            <div class='time-route' @click='routeBoxClick(item,index)'>
              <div class='time-title'>
                {{ item.name }}
                <el-icon :key='index' v-if='index<conditionArr.length-1'
                         @click.stop.native='minusBtn(index)' class='el-icon-close time-close'></el-icon>
              </div>
              <div class='tile-content'>
                <!--条件内容显示-->
                <span v-if='index===conditionArr.length-1'>其他情况进入此流程</span>
                <span class="overConditions" v-else> {{ formatValue(item.properties.conditions) }}</span>
              </div>
            </div>
          </div>
          <!--          流程节点-->
          <SettingPanel :data.sync='item.properties' :type="'route'"></SettingPanel>

        </div>
      </div>
    </div>
    <!--    条件配置-->
    <setting-route-drawer :routeTemp.sync='routeTemp' :conditions.sync='conditions' ref='drawerRoute'
                          :drawerRoute.sync='drawerRoute'></setting-route-drawer>
  </div>
</template>

<script>
import UserSelect from '@/pages/commerce/components/UserSelect'
import SettingPanel from '@/pages/oaManage/SettingPanel'
import SettingRouteDrawer from '@/pages/oaManage/SettingRouteDrawer.vue'

export default {
  name: 'SettingRouteBox',
  components: { SettingRouteDrawer, SettingPanel, UserSelect },
  props: {
    conditionNodes: {
      type: Array
    }
  },
  computed: {
    conditionArr: {
      get() {
        return [...this.conditionNodes]
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {
        this.$emit('update:conditionNodes', val)
      }
    }
  },
  data() {
    return {
      member_type_map: {
        custom: '发起人自选',
        supervisor: '直属主管',
        director: '部门主管',
        fix: '指定成员'
      },
      drawerRoute: false,
      routeTemp: {},
      checkedMembers: [],
      drawerTemp: {},
      active: 0,
      conditions: [],
      optMap: {
        lowerBound: '小于',
        upperBound: '大于',
        lowerBoundEqual: '小于等于',
        upperBoundEqual: '大于等于',
        boundEqual: '等于'
      }
    }
  },
  watch: {
    routeTemp(val) {
      this.conditionArr[this.active]['properties']['conditions'] = val['properties']['conditions']
      this.conditionArr[this.active]['name'] = val['name']
      this.$forceUpdate()
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    routeBoxClick(data, index) {
      this.routeTemp = data
      this.active = index
      if (data?.type) {
        let conditions = data.properties ? data.properties.conditions : null
        if (conditions && conditions.length != 0) {
          let newConditions = []
          conditions.forEach((item) => {
            // eslint-disable-next-line no-unused-vars
            let newObbject = {
              checkRoute: [],
              checkedMembers: [],
              checkDepts: [],
              echoData: null
            }
            let nameArr = []
            item.forEach((i) => {
              //有类型
              if (i?.type) {
                newObbject.checkRoute.push(i.type)
                if (i?.type === 'dept_condition') {
                  //echoData
                  if (i?.conds?.length) {
                    i.conds.forEach((j) => {
                      if (j.type === 'user') {
                        newObbject.checkedMembers.push({ name: j.name, userid: j.value })
                        nameArr.push(j.name)
                      } else {
                        newObbject.checkDepts.push({ name: j.name, dpt_id: j.value })
                        nameArr.push(j.name)
                      }
                    })
                  }
                } else if (i?.type === 'range_condition') {
                  let arr = ['lowerBound', 'upperBound', 'lowerBoundEqual', 'upperBoundEqual', 'boundEqual']
                  let mony = arr.map((value) => {
                    return i[String(value)] ? {
                      type: String(value),
                      value: i[String(value)]
                    } : false
                  }).filter(Boolean)
                  mony[mony.length - 1] ? newObbject.rangeValue = mony[mony.length - 1] : ''
                }
              }
            })
            //
            newObbject.echoData = ''
            newObbject.echoData = nameArr.join(',')
            newConditions.push(newObbject)
          })
          this.conditions = newConditions
        } else {
          this.conditions = [
            {
              checkRoute: ['dept_condition'],
              rangeValue: {
                type: '',
                value: ''
              },
              echoData: '',
              checkDepts: [],
              checkedMembers: []
            }
          ]
        }
        this.active = index
        this.drawerRoute = index !== this.conditionArr.length - 1;
        this.callback()
      }
    },
    //删除条件分支
    minusBtn(index) {
      this.conditionArr.splice(index, 1)
      if (this.conditionArr.length === 1) {
        this.conditionArr.splice(0, 1)
      }
      this.callback()
      this.$forceUpdate()
    },
    callback() {
      // console.log('conditions', this.conditions)
      this.$emit('update:conditionNodes', this.conditionArr)
    },
    formatValue(conditions) {
      if (conditions) {
        let allCond = []
        conditions.forEach((j, index) => {
          //这里是2个并且
          let messageArr = []
          j.forEach((b) => {
            if (b?.type) {
              if (b?.type === 'dept_condition') {
                let names = []
                b.conds.forEach((i) => {
                  names.push(i.name)
                })
                messageArr.push(`${b.paramLabel}属于:${names.join('、')}`)
              } else if (b?.type === 'range_condition') {
                let arr = Object.keys(this.optMap)// ['lowerBound', 'upperBound', 'lowerBoundEqual', 'upperBoundEqual', 'boundEqual']
                let bound = arr.map((item) => {
                  return b[item] != '' && b[item] != undefined ? `${this.optMap[item]}:${b[item]}元` : false
                }).filter(Boolean)
                messageArr.push('金额' + String(bound))
              }
            }
          })

          let msgStr = messageArr.join(' 并且 ')
          allCond.push(msgStr)
          //最后一次遍历，不追加“或”
          if (index != conditions.length - 1) {
            allCond.push('或')
          }
        })
        return allCond.join(' ')
      } else return ''
    },
    addRouteType(type, index, item) {
      if (index === 0) {
        item.properties.childNode = {
          member_type: 'custom',
          members: [],
          nodeId: Math.random(),
          tpl_type: type,
          childNode: {}
        }

        // this.formatChildNode(item)
        this.$forceUpdate()
      } else {
        let orgChildNode = item.properties.childNode
        item.properties.childNode = {
          member_type: 'custom',
          members: [],
          nodeId: Math.random(),
          tpl_type: type,
          childNode: orgChildNode
        }
        // let forData = item
        // let newArray = []
        // const fn = function(data) {
        //   newArray.push(data)
        //   forData = forData.childNode
        // }
        // for (let i = 0; i <= length; i++) {
        //   if (forData?.childNode) {
        //     if (i === index) {
        //       forData.childNode = {
        //         member_type: 'custom',
        //         members: [],
        //         nodeId: Math.random(),
        //         tpl_type: type,
        //         childNode: { ...forData.childNode }
        //       }
        //     }
        //     fn(forData.childNode)
        //   }
        // }
        // item = newArray[0]
        // this.formatChildNode(item)
        this.$forceUpdate()
      }
    },


    tagChange(i) {
      this.drawerTemp.members.splice(i, 1)
    },

    handleMLeaderSelect(item) {
      if (!this.drawerTemp.members) {
        this.drawerTemp.members = []
      }
      this.drawerTemp.members.push({ name: item.label, userid: item.value })
    },
    clearUser(item) {
      if (item.member_type != 'fix') {
        item.members = []
      }
    },
    timeBoxClick(i) {
      this.drawerTemp = i
      this.drawer = true
    }


  }
}
</script>

<style lang='scss' scoped>
.router {
  width: 100%;
  display: block;
  overflow: scroll;
}
.router-box {
  width: 100%;
  display: -webkit-box;
  justify-content: center;


  .col-box {
    width: 220px;
    //border: 1px solid red;
    float: left;
    position: relative;

    .col-box:before {
      content: '';
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      margin: auto;
      width: 2px;
      height: 100%;
      background-color: #e4e7ed;
    }

    .top-cover-line {
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: #e4e7ed;
      top: -2px;
      left: -1px;
    }

    .top-vertical-line {
      width: 2px;
      height: 50px;
      background-color: #e4e7ed;
      position: relative;
      left: calc(50% - 2px)

    }

    .condition-node {
      min-height: 140px;
      width: 200px;
      margin: 0 auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-flex: 1;
    }
  }

  .col-first {
    .top-cover-line {
      width: 50%;
      left: calc(50% - 2px);
    }
  }

  .col-final {
    .top-cover-line {
      width: 50%;
    }
  }
}


.time-start {
  margin: 0 auto;
  width: 200px;
  height: 80px;
  //border: 1px solid silver;
  border-top: none;
  display: block;
  clear: both;

  .time-title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    padding-left: 20px;
    color: white;
    background-color: rgb(63, 76, 108);
    z-index: 2;
    position: relative;
  }

  .tile-content {
    padding-left: 20px;
    border: 1px solid silver;
    border-top: none;
    border-radius: 4px;
    margin-top: -4px;
    height: 50px;
    line-height: 50px;
  }
}

.time-approve {
  cursor: pointer;

  .time-title {
    background-color: rgb(245, 154, 35);
  }
}

.time-cc {
  cursor: pointer;

  .time-title {
    background-color: #409eff;
  }
}

.time-close {
  font-size: 18px;
  float: right;
  cursor: pointer;
  margin-right: 8px;
}


.drawer-content {
  padding: 0 20px;
  max-height: 86%;
  overflow: scroll;
}



.time-route {
  border: 1px solid #e4e7ed;
  min-height: 100px;
  max-height: 200px;


  > * {
    padding: 10px;
  }

  .time-title {
    color: #2d815d;
  }


}


.triangle {
  width: 0;
  height: 0;
  border: 15px solid;
  margin: 0 auto;
  border-color: #E4E7ED rgb(0, 0, 0, 0) rgb(0, 0, 0, 0) rgb(0, 0, 0, 0);
}

.overConditions {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>
