<template>
  <div class="emphasis-products_container">
    <previewPPT :id='ppt.product_id' :visible.sync='ppt.show' fullscreen />

    <el-form inline :model="search" class="emphasis-products_container__search">
      <!--  品类  -->
      <el-form-item>
        <el-select v-model='search.category' filterable placeholder='搜索品类' clearable
                   @change='handleSearch'>
          <el-option v-for='item in options.category' :key='`category-${item.value}`' :label='item.label'
                     :value='item.label' />
        </el-select>
      </el-form-item>
      <!--  类目  -->
      <el-form-item>
        <el-input v-model='search.category_sub' placeholder='搜索类目' clearable @change='handleSearch' />
      </el-form-item>
      <el-form-item>
        <el-input v-model="search.product_name" placeholder="搜索产品" clearable @change="handleSearch" />
      </el-form-item>
      <el-form-item>
        <select-sale :value.sync="search.sale_id" placeholder="搜索销售" @change="handleSearch" />
      </el-form-item>
      <el-form-item>
        <select-product-brand :key="search.brand_id||'brand'" :value.sync="search.brand_id" placeholder="搜索品牌"
                              @change="handleSearch" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain round @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-form-item style="float: right;">
        <emphasis-products-store
          v-if='hasAuthority("emphasis_products_manage__save")'
          ref="store"
          @success="callbackSuccess" />
      </el-form-item>
    </el-form>

    <ml-table ref="TABLE" :search="search" :api="emphasisProductList" :expand-row-keys="expandRowKeys" hand-request>
      <template slot="columns">
        <el-table-column v-if='hasAuthority("emphasis_products_sch__list")' type="expand">
          <template v-slot="{row}">
            <emphasis-products-sch-list :key="'sch-list_'+key+'-'+row.id" :emphasis-products-id="row.id" />
          </template>
        </el-table-column>
        <el-table-column label="" width="70" align="center">
          <template v-slot="{row:{id}}">
            <el-button v-if="expandRowKeys.includes(id)" type="text" @click="expandRowKeys=[]">收起</el-button>
            <el-button v-else type="text" @click="expandRowKeys=[id]">查看排期</el-button>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column label="品类类目" min-width="100">
          <template v-slot="{row:{category,category_sub}}">
            {{ category }}{{ category_sub ? `/${category_sub}` : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="brand_name" label="品牌" min-width="100" />
        <el-table-column prop="sale_name" label="销售" min-width="70" />
        <el-table-column prop="product_name" label="商品名称" min-width="300" />
        <el-table-column prop="budget" label="预算" min-width="70" />
        <el-table-column prop="commission" label="佣金" min-width="70" />
        <el-table-column prop="febi" label="费比" min-width="70" />
        <el-table-column label="操作" width="210" align="center">
          <template v-slot="{row}">
            <emphasis-products-sch-save v-if='hasAuthority("emphasis_products_sch__save")' :key="'sch-save-add'+row.id"
                                        :emphasis-products-id="row.id"
                                        @success="callbackSuccessSch" />
            <el-button :disabled="!row.product_id" type="text" @click="handlePPT(row)">PPT</el-button>
            <el-button
              v-if='hasAuthority("emphasis_products_manage__save")&&((onlyEditSelf&&row.sale_id===login_user_id)||!onlyEditSelf)'
              type="text" @click="handleEdit(row)">修改
            </el-button>
            <el-button
              v-if='hasAuthority("emphasis_products_manage__delete")&&((onlyDeleteSelf&&row.sale_id===login_user_id)||!onlyDeleteSelf)'
              type="text" @click="handleDelete(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template slot="footer">
        <el-button v-if='hasAuthority("emphasis_products_stat")' type="text" icon="el-icon-top-right" size="small"
                   @click="handleToStat">重点大全案品看板
        </el-button>
        <el-button v-if='hasAuthority("emphasis_products_category_list")' type="text" icon="el-icon-top-right"
                   size="small"
                   @click="handleToCategory">品类管理
        </el-button>
      </template>
    </ml-table>
  </div>
</template>
<script>

import { defineComponent } from 'vue'
import ArtistSearch from '@/components/artist/ArtistSearch.vue'
import MlTable from '@/components/MlTable/index.vue'
import { emphasisProductList } from '@/api/emphasis-products'
import EmphasisProductsStore from './store.vue'
import { emphasisProductDelete, emphasisProductOptions } from '../../../../api/emphasis-products'
import SelectSale from '../../../../components/select/user.vue'
import SelectProductCategory from '@/components/select/product-category.vue'
import previewPPT from '@/pages/lb/product/PreviewPPT.vue'
import SelectProductBrand from '../../../../components/select/product-brand.vue'
import { mapGetters } from 'vuex'
import EmphasisProductsSchSave from './schedules/save.vue'
import EmphasisProductsSchList from './schedules/list.vue'

const { id: login_user_id } = JSON.parse(localStorage.getItem('user_info'))
const genKey = function() {
  return new Date().getTime()
}

export default defineComponent({
  components: {
    EmphasisProductsSchList,
    EmphasisProductsSchSave,
    SelectProductBrand,
    previewPPT,
    SelectProductCategory,
    SelectSale,
    EmphasisProductsStore,
    MlTable,
    ArtistSearch
  },
  data() {
    return {
      key: genKey(),
      login_user_id,
      expandRowKeys: [],
      search: {
        product_name: null,
        sale_id: null,
        category: null,
        category_sub: null,
        brand_id: null
      },
      ppt: {
        show: false,
        product_id: null
      },
      options: { category: [] }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    onlyEditSelf() {
      return this.userPermissions.indexOf('emphasis_products_manage__save_all') === -1
    },
    onlyDeleteSelf() {
      return this.userPermissions.indexOf('emphasis_products_manage__delete_all') === -1
    }
  },
  created() {
    this.init()
  },
  methods: {
    emphasisProductList,
    init: async function() {
      this.getOptions().then()
      const { query } = this.$route
      if (query) {
        let { brand_id } = query
        brand_id = parseInt(brand_id + '')
        if (brand_id) {
          this.search.brand_id = brand_id
        }
      }
      await this.$nextTick()
      this.$refs.TABLE.handleSearch()
    },
    callbackSuccessSch: function() {
      this.key = genKey()
    },
    getOptions: async function() {
      this.options = await emphasisProductOptions({ fields: ['category'] })
    },
    callbackSuccess: function(is_edit) {
      if (is_edit) {
        this.$refs.TABLE.getTable()
      } else {
        this.$refs.TABLE.handleSearch()
      }
    },
    handleToStat: function() {
      this.$router.push({ name: 'EmphasisProductsStat' })
    },
    handleToCategory: function() {
      const { href } = this.$router.resolve({ name: 'EmphasisProductCategory' })
      window.open(href, '_blank')
    },
    handleSearch: function() {
      this.$refs.TABLE.handleSearch()
    },
    handleEdit: function(row) {
      this.$refs.store.handleEdit(row)
    },
    handlePPT: function({ product_id }) {
      this.ppt = {
        show: true,
        product_id
      }
    },
    handleDelete: async function({ id, product_name }) {
      this.$confirm(`确认删除【${product_name}】吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await emphasisProductDelete({ id })
        this.$message.success('删除成功')
        this.handleSearch()
      }).catch(() => {

      })
    }
  }
})
</script>
<style lang="scss">
.EmphasisProductsList_main {
  margin: 12px !important;
  background: #ffffff;
  border-radius: 20px;
}

.emphasis-products_container {
  height: calc(100vh - 136px);
  padding: 24px;

  &__search {
    border-bottom: 1px solid rgb(242, 242, 242);

    .el-form-item--mini.el-form-item {
      margin-bottom: 12px !important;
    }
  }

  &__main {
    padding: 12px 0;
    height: calc(100% - 50px);
  }

  .ml-table-container {
    height: calc(100% - 52px);
    margin-top: 24px;
  }

  .el-table__expand-icon {
    display: none;
  }
}
</style>
