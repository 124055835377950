import * as request from '@/utils/request'

/**
 * 列表
 * @param data
 * @returns {Promise}
 */
export function emphasisProductList(data) {
  return request.post('/emphasis_product/list', data)
}

/**
 * 品类
 * @param data
 * @returns {*}
 */
export function emphasisProductOptions(data) {
  return request.post('/emphasis_product/options', data)
}

/**
 * 保存
 * @param data
 * @returns {*}
 */
export function emphasisProductSave(data) {
  return request.post('/emphasis_product/store', data)
}

/**
 * 删除
 * @param data
 * @returns {*}
 */
export function emphasisProductDelete(data) {
  return request.post('/emphasis_product/delete', data)
}

/**
 * 汇总
 * @param data
 * @returns {*}
 */
export function emphasisProductStat(data) {
  return request.post('/emphasis_product/stat', data)
}

/**
 *
 * @param data
 * @returns {*}
 */
export function getEmphasisProductsScheduleList(data) {
  return request.post('/emphasis_products_schedule/list', data)
}

/**
 * @description 保存
 * @param {*} data
 * @returns
 */
export function saveEmphasisProductsSchedule(data) {
  return request.post('/emphasis_products_schedule/store', data)
}

/**
 *
 * @param data
 * @returns {*}
 */
export function deleteEmphasisProductsSchedule(data) {
  return request.post('/emphasis_products_schedule/delete', data)
}
