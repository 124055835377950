<template>
  <div class="default-table tableBox"
       v-loading="loadingStatus"
       element-loading-text="数据加载中……"
       element-loading-spinner="el-icon-loading">
    <el-table border :data="tableData" :span-method="objectSpanMethod" height="100%">
      <el-table-column prop="platform" label="#" min-width="50" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.platform_code" class="platform-logo">
            <el-avatar shape="square" :size="30"
                       :src="getLogoUrl(scope.row.platform_code)"
                       @error="true">
              <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
            </el-avatar>
            <br/>
          </div>
          <b>{{ scope.row.platform }}</b>
        </template>
      </el-table-column>
      <el-table-column prop="income_type" label="收入分类" align="center" min-width="100">
        <template slot-scope="scope">
          <b>{{ scope.row.income_type }}</b>
        </template>
      </el-table-column>

      <template v-for="i in 12">
        <el-table-column :prop="`month_${i}`" :key="i" :label="`${i}月`" min-width="100" align="right"
                         header-align="center">
          <template slot-scope="scope">
            <!--      合计部分不能编辑      -->
            <b v-if="tableData.length-3<=scope.$index">
              {{ moneyFormat(scope.row[`month_${ i }`]) }}
            </b>
            <span v-else>{{ scope.row[`month_${ i }`] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>

</template>

<script>
import EditableCell from "@/components/EditableCell.vue";
import { numberFormat } from "@/utils/utils";

const income_types = [
  { id: 1, name: '商务营收' },
  { id: 2, name: '执行商单数' },
  { id: 3, name: '短视频佣金' },
  { id: 4, name: '直播佣金' },
  { id: 5, name: '直播坑位费' },
  { id: 6, name: '授权收入' },
  { id: 7, name: '推广花费' },
];

export default {
  name: "RevenueRecord",
  components: {
    EditableCell
  },
  props: {
    searchCondition: {
      type: Object,
      default: () => {
        return {
          is_all: null,
          group_id: null,
          artist_id: null,
          year: null,
        }
      },
    },
    platforms: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  watch: {
    // 表格数据
    platforms: function () {
      let map = {}
      this.platforms.forEach((platform) => {
        map[platform.code] = platform
      })
      this.platformMap = map
    },
  },
  data() {
    return {
      data: [],
      income_types: income_types,
      loadingStatus: false,
      offset: 0,
      platformMap: {}
    }
  },
  computed: {
    // 表格数据
    tableData() {
      // 增加三行数据
      let revenueRow = { platform: '总营收' };
      // revenueRow['platform'] = '总营收'
      let costRow = { platform: '总推广花费' };
      // costRow['platform'] = '总推广花费'
      let incomeRow = { platform: '当期盈亏' };
      // incomeRow['platform'] = '当期盈亏'
      if (this.data && this.data.length > 0) {
        this.data.forEach((row) => {

          let category = row['category'];
          for (let i = 1; i <= 12; i++) {
            let incomeField = 'month_' + i;
            let val = row[incomeField]
            // 总营收 = 保量收入 + 商务营收 + 短视频佣金 + 直播佣金 + 直播坑位费
            if (['income_8','income_1', 'income_3', 'income_4', 'income_5'].indexOf(category) > -1) {
              if (!revenueRow[incomeField]) {
                revenueRow[incomeField] = 0;
              }
              revenueRow[incomeField] += val * 1;
            }
            // 总推广花费 = 推广花费
            if (['income_7'].indexOf(category) > -1) {
              if (!costRow[incomeField]) {
                costRow[incomeField] = 0;
              }
              costRow[incomeField] += val * 1;
            }
            //当期盈亏 = 当期总营收 - 当期总推广花费
            incomeRow[incomeField] = (revenueRow[incomeField] - costRow[incomeField]);
          }
        })
        let appendRows = [
          revenueRow,
          costRow,
          incomeRow,
        ];
        return [...this.data, ...appendRows]
      } else {
        return []
      }
    },
  },
  created() {
  },
  methods: {
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    async getRevenueDataList() {
      this.loadingStatus = true;
      const searchCondition = this.handleSearchCondition();
      let { list } = await this.$api.getRevenueDataList(searchCondition);
      this.$nextTick(() => {
        this.data = list
      });
      this.loadingStatus = false
    },
    initData(searchCondition) {
      this.data = []
      this.searchCondition = searchCondition
      if (searchCondition.group_id && searchCondition.year && searchCondition.artist_id) {
        this.getRevenueDataList()
      }
    },

    async selectInput(row, field) {
      let data = {}
      if (row.id) {
        data['id'] = row.id;
        data[field] = row[field];
        data._current_field = field;
      } else {

        let appendFields = {
          year: this.searchCondition.year,
          group_id: this.searchCondition.group_id,
          artist_id: this.searchCondition.artist_id,
        }
        data = { ...row, ...appendFields, ...{ _current_field: field } }
      }

      let id = await this.$api.saveRevenueData(data)
      if (id) {
        row.id = id
      } else {
        this.$notify.error('保存失败！')
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {};
      if (this.searchCondition.is_all) {
        condition.is_all = this.searchCondition.is_all
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }

      return condition
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      // eslint-disable-next-line no-unused-vars
      let platformsLength = 5;
      let platformsJson = localStorage.getItem('platforms')
      if (platformsJson) {
        platformsLength = JSON.parse(platformsJson).length
      }
      if (rowIndex < platformsLength * income_types.length) {
        // 平台数组长度 * 收入分类数组长度 = 需要合并平台的行数
        if (columnIndex === 0) {
          if (rowIndex % income_types.length === 0) {
            return {
              rowspan: income_types.length,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      } else {
        // 剩下的为“合计”  横向合并列
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      }
    },
    getLogoUrl(platform_code) {
      return this.platformMap[platform_code] ? this.platformMap[platform_code]['logo_url'] : ''
    }
  }
}
</script>

<style scoped>
.tableBox {
  height: 80vh;
}
</style>
<style>

</style>
