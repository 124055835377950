<template>
  <div class="handle-button" style="display: inline">
    <el-button v-if="isEdit" type="text" @click="handleEdit()">修改</el-button>
    <el-button v-else type="text" @click="handleAdd()">添加排期</el-button>
    <el-dialog
      :title="`${form.id?'修改':'新增'}排期`"
      :visible.sync="show"
      center
      custom-class="emphasis_products_schedules-container"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="排期日期：" prop="date">
          <el-date-picker v-model="form.date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"
                          style="width: 100%" />
        </el-form-item>
        <el-form-item label="红人：" prop="artist_id">
          <select-artist v-if="show" :value.sync="form.artist_id" :label.sync="form.artist_nickname" style="width: 100%" />
        </el-form-item>
        <el-form-item label="平台：" prop="platform_code">
          <select-platform :value.sync="form.platform_code" :label.sync="form.platform_name" style="width: 100%" />
        </el-form-item>
        <el-form-item label="负责人：" prop="charge_id">
          <select-user v-if="show" :value.sync="form.charge_id" :label.sync="form.charge_name"
                       placeholder="负责人"
                       style="width: 100%" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <ml-button text='确 定' @click.native='handleSubmit' />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ArtistSearch from '@/components/artist/ArtistSearch.vue'
import SelectPlatform from '@/components/select/platform.vue'
import SelectUser from '@/components/select/user.vue'
import MlButton from '@/components/MlButton/index.vue'
import { saveEmphasisProductsSchedule } from '../../../../../api/emphasis-products'
import SelectArtist from '../../../../../components/select/artist.vue'

const Model = {
  id: null,
  emphasis_products_id: null,
  date: null,
  platform_code: null,
  platform_name: null,
  artist_id: null,
  artist_nickname: null,
  charge_id: null,
  charge_name: null,
  creator_id: null
}

const rules = {
  date: [{ required: true, message: '请填写排期', trigger: ['blur', 'change'] }],
  platform_code: [{ required: true, message: '请填写平台', trigger: ['blur', 'change'] }],
  artist_id: [{ required: true, message: '请填写红人', trigger: ['blur', 'change'] }],
  charge_id: [{ required: true, message: '请填写负责人', trigger: ['blur', 'change'] }]
}

export default {
  name: 'EmphasisProductsSchSave',
  components: { SelectArtist, MlButton, SelectUser, SelectPlatform, ArtistSearch },
  props: {
    emphasisProductsId: {
      type: Number,
      required: true
    },
    obj: {
      type: [Object, Boolean],
      required: false
    },
    isEdit: Boolean
  },
  data() {
    return {
      rules,
      show: false,
      form: { ...{}, ...Model }
    }
  },
  methods: {
    handleAdd: function() {
      this.form = { ...{}, ...Model }
      this.show = true
    },
    handleEdit: function() {
      this.form = { ...{}, ...this.obj }
      this.show = true
    },
    handleSubmit: function() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.form.emphasis_products_id = this.emphasisProductsId
          await saveEmphasisProductsSchedule(this.form)
          this.show = false
          this.$message.success('操作成功')
          this.$emit('success')
        }
      })
    }
  }
}
</script>
<style lang="scss">
.emphasis_products_schedules-container {
  border-radius: 20px;
  width: 500px;
}
</style>
