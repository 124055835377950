<template>
  <el-cascader v-model="v" :options="options" :props="{label:'display_name',value:'id'}" :placeholder="placeholder"
               clearable />
</template>
<script>
export default {
  name: 'SelectProductCategory',
  props: {
    placeholder: {
      type: String,
      default() {

        return '选择类目'
      }
    },
    value: {
      type: [String, Number, Array],
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      v: null,
      options: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(_) {
        this.v = _
      }
    },
    v(_) {
      this.updateData(_)
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    updateData: function(_) {
      const obj = this.options.find(option => {
        return option.id === _
      })
      this.$emit('update:label', obj ? obj.display_name : null)
      this.$emit('update:value', _)
      this.$emit('change', obj || false)
    },
    getOptions: async function() {
      let { list } = await this.$api.getCategoryListByIdentify(`product-category`)
      this.options = list || []
    }
  }
}

</script>
<style scoped>

</style>
