<template>
  <el-select v-model="v" filterable allow-create remote reserve-keyword :placeholder="placeholder"
             :remote-method="getOptions"
             :loading="loading" clearable>
    <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.title" />
  </el-select>
</template>
<script>
import { optionsProducts } from '../../api/options'

export default {
  name: 'SelectProduct',
  props: {
    placeholder: {
      type: String,
      default() {
        return '输入关键词检索商品'
      }
    },
    productName: {
      type: [String, Number],
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      v: null,
      options: [],
      loading: false
    }
  },
  watch: {
    productName: {
      immediate: true,
      handler(_) {
        this.v = _
      }
    },
    v(_) {
      this.updateData(_)
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: async function() {
      await this.getOptions(this.productName)
      if (this.productName) {
        this.updateData(this.productName)
      }
    },
    updateData: function(_) {
      const obj = this.options.find(option => {
        return option.title === _

      })
      this.$emit('update:product-name', obj ? obj.title : (_ || null))
      this.$emit('update:product-id', obj ? obj.id : null)
      this.$emit('change', obj || false)
    },
    getOptions: async function(keyword) {
      const data = await optionsProducts({ keyword })
      this.options = data || []
    }
  }
}
</script>
<style scoped>

</style>
