<template>
  <div>
    <export-btn size='mini' type='text' ref='refExportLink'
                :can-export="userPermissions.indexOf('plc_export')>-1"
                :lint-title='`导出`' :title='`下载《${searchCondition.year}年${searchCondition.month}月刊例》`'
                @export='exportData(searchCondition)'
                class-name='export-btn'
                style='display: inline' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'PlcExport',
  components: { ExportBtn },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    searchCondition: {
      type: Object,
      default() {

      }
    }
  },
  methods: {
    //导出表格
    // eslint-disable-next-line no-unused-vars
    async exportData(row) {
      try {
        // eslint-disable-next-line no-unused-vars
        let date = new Date()
        //获取数据
        let data = await this.$api.getExportPublicationAllData(row.year, row.month)
        // // 生成excel
        let response = await this.$api.downloadPublicationExcelByData(data)
        let name = `【缇苏】${row.year}年${row.month}月刊例（含税）-${date.getMonth() + 1}.${date.getDate()}`
        this.$refs[`refExportLink`].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    }
  }
}
</script>

<style scoped>

</style>
