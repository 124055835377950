import store from '@/store'
/**
 * 数组交集
 * @param arr1
 * @param arr2
 * @returns {*}
 */
function intersection(arr1, arr2) {
  return arr1.filter(item => arr2.includes(item))
}

export function hasAuthority(val) {
  // console.log( store.getters.userPermissions)
  // console.log(store.state.userPermissions)
  const userPermissions = store.state.userPermissions
  if (Array.isArray(val)) {
    // 数组形式时
    const result = intersection(userPermissions, val)
    return result.length > 0
  } else {
    // 字符串形式时
    return userPermissions.indexOf(val) > -1
  }
}
