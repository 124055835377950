<template>
  <div class="ml-button handle-button" style="display: inline">
    <el-button type="text" size="mini" icon="el-icon-download" @click="handleShow">下载《导入星广汇数据》</el-button>
    <el-drawer
      :visible.sync="show"
      :close-on-press-escape="false"
      title="预览数据明细"
      custom-class="down-ts-products-drawer"
      modal-append-to-body
      append-to-body
    >
      <ml-table ref="TABLE" :search="search" :api="tradeStatsSettleProductList" hand-request>
        <template slot="columns">
          <el-table-column type="index" label="序号" width="55" />
          <el-table-column prop="_month" label="月份" width="65" show-overflow-tooltip />
          <el-table-column prop="platform_name" label="平台" width="55" show-overflow-tooltip />
          <el-table-column prop="artist_account" label="达人抖音号" show-overflow-tooltip />
          <el-table-column prop="ad_nickname" label="达人昵称" show-overflow-tooltip />
          <el-table-column prop="product_title" label="商品名称" show-overflow-tooltip />
          <el-table-column prop="flow_source" label="流量来源" width="80" show-overflow-tooltip />
          <el-table-column prop="shop_name" label="店铺名称" show-overflow-tooltip />
          <el-table-column label="订单数" align="right">
            <template v-slot="{row:{trade_count}}">
              <span class="num-ph">{{ trade_count }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结算金额" align="right">
            <template v-slot="{row:{settle_amount}}">
              ￥<span class="num-ph">{{ settle_amount|numberFormat(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="总佣金收入" align="right">
            <template v-slot="{row:{sci}}">
              ￥<span class="num-ph">{{ sci|numberFormat(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="佣金比例" align="right">
            <template v-slot="{row:{_commission_rate}}">
              <span class="num-ph">{{ _commission_rate }}</span>
            </template>
          </el-table-column>
        </template>
      </ml-table>
      <div class="footer">
        <el-button @click="show = false">取 消</el-button>
        <export-by-page :round="false" icon="" module="TRADE_M_S_ST_P_DY" :search="search" txt="下 载" />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { tradeStatsSettleProductList } from '../../../../api/trade_stat'
import MlTable from '@/components/MlTable/index.vue'
import ExportByPage from '@/components/export/ByPage/index.vue'

export default {
  name: 'DownTSProducts',
  components: { ExportByPage, MlTable },
  props: {
    search: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    tradeStatsSettleProductList,
    handleShow: function() {
      this.show = true
      this.$nextTick(() => {
        this.$refs.TABLE.handleSearch()
      })
    },
    handleSubmit: function() {
      console.log('cbdhjsbchjdsbcd')
    }
  }
}

</script>
<style lang="scss">
.down-ts-products-drawer {
  width: 1200px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  .el-form-item {
    display: block;
    margin-right: 0;
  }

  .el-form-item__label {
    width: 150px;
  }

  .el-form-item__content {
    width: calc(100% - 204px);
    display: inline-block;
  }

  .ml-table-container {
    height: calc(100% - 77px);
  }
}
</style>
