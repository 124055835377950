/**
 * 自动生成 router代码【模块名：】
 * 生成日期：2025/01/09 11:46:04
 * 生成路径: src/router/employee.js
 * 生成人：超级管理员
 * 需要在文件 src/router/router.js 引入当前文件
 * import employee from './employee'
 // 路由详情
 const routes = [
 {
     path: '/',
     component: () => import('@/layouts/BasicLayout'),
     name: 'BasicLayout',
     meta: { title: '首页' },
     redirect: '/myNav',
     children: [
     ...
      employee,//
     ]
 */

export default  {
  path: 'employee/list',
  name: 'employee',
  component: () => import('@/pages/employee/EmployeeList'),
  meta: { title: '员工管理' },
}
