<template xmlns:el-col='http://www.w3.org/1999/html'>
  <div>
    <!--    提报内容-->
    <div>
      <el-link class='fanhui-button'
               type='primary'
               icon='el-icon-d-arrow-left'
               @click='backParentPage'>返回
      </el-link>

      <div class='plc'>
        <div>
          <div style='display: inline-block;'>
            <PlcLogo :logo-setting='setting?.work_sheet?.logoSetting' v-if='setting.work_sheet'/>
          </div>
          <div class='title' style='font-size: 36px'>{{ info.title }}</div>
          <div class='tag'>（<span class='date'>{{
              info.validity_date || '未设置开始日期'
            }} ~ {{ info.expiry_date || '未设置截止日期' }}</span>）
            <i class='el-icon-edit' @click='editBtn' v-if="userPermissions.indexOf('plc_edit')>-1"></i>
          </div>
        </div>

        <div class='notice'>
          <div>
            <span class='notice-title'>合作须知：</span>
            <el-link type='primary' @click='editBtn' v-if="userPermissions.indexOf('plc_edit')>-1"
                     icon='el-icon-edit'></el-link>
          </div>
          <div class='info_box' v-html='info.notice'></div>
        </div>

        <!--          筛选条件-->
        <div :class="['row-opt',fixHeader?'fixed-header':'',isCollapse?'fold':'unfold']">
          <div class='table-search'>
            <el-form size='mini' :inline='true' :model='searchCondition'>
              <el-form-item>
                <el-button type='primary' icon='el-icon-plus'
                           v-if="userPermissions.indexOf('plc_adjust')>-1"
                           @click='addAPlcDetail'>
                  刊例提报
                </el-button>
              </el-form-item>
              <el-form-item>
                <el-radio-group v-model='tabActive'>
                  <el-radio-button label='status' value='status'
                                   v-if="userPermissions.indexOf('plc_save_quote')>-1">
                    待定价
                  </el-radio-button>
                  <el-radio-button label='apply_status' value='apply_status'
                                   v-if="userPermissions.indexOf('plc_save_quote')>-1">
                    改价申请
                  </el-radio-button>
                  <el-radio-button label='my' value='my'>我的</el-radio-button>
                  <el-radio-button label='all' value='all'>全部</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label='' v-if="userPermissions.indexOf('plc_save_quote')>-1">
                <artist-search :name.sync='searchCondition.nickname' @handleSelect='chooseArtist'
                               @clicked='searchForm' />
              </el-form-item>
              <el-form-item>
                <el-button type='primary' icon='el-icon-search' @click='searchForm'>搜索</el-button>
              </el-form-item>
              <el-form-item>
                <el-link v-if="userPermissions.indexOf('platform_setting')>-1" type='primary'
                         icon='el-icon-setting'
                         @click='jumpToSettingPage'></el-link>
              </el-form-item>
              <el-form-item style='float: right'>
                <el-button type='danger' icon='el-icon-delete' @click='delRow(info.id)'
                           v-if="userPermissions.indexOf('plc_delete')>-1" title='删除当前刊例'>删除
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class='plc-items'
             v-loading='loadingStatus'
             element-loading-text='刊例提报数据加载中……'
             element-loading-spinner='el-icon-loading'>
          <div>
            <m-pagination :total.sync='pagingData.total' :page.sync='pagingData.current_page'
                          :limit.sync='pagingData.page_size' @pagination='getPageList'></m-pagination>
          </div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="KOL刊例" name="first">
              <el-table
                v-if='setting.setting'
                lazy
                ref='detailTable'
                :data='dataList'
                @sort-change='changeTableSort'
                :header-row-style='headerStyle'
                cell-class-name='plc-cell'
                row-class-name='plc-row'
                border>
                <el-table-column label='基本信息' width='400' align='center' header-align='center' fixed='left'>
                  <template slot-scope='{row,$index}'>
                    <div>
                      <!--                    基本信息-->
                      <PanelKolInfo :data='row' :index='$index' style='height: 100%;margin-bottom: 8px;'
                                    @editIntro='editVal' :platform-type='setting.type' :setting='setting.setting' />

                      <PanelPlatformInfo :data='row' :setting='setting.setting' />

                      <div style='text-align: center;margin-top: 10px'>
                        <el-link type='primary' @click='jumpToPlcItemDetail(row)'>查看提报详情</el-link>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop='real_quote' label='商业化营销中心终价' min-width='900' header-align='center'
                                 align='left'>
                  <template slot-scope='{row,column,$index}'>
                    <div class='edit-box'>
                      <PriceStructView :quote-obj='row[column.property]' :platform-type='setting.type'
                                       v-if='setting.type' />
                      <div class='edit-btn'>
                        <div class='edit-quote' v-if="userPermissions.indexOf('plc_save_quote')>-1">
                          <el-link :underline='false' type='primary' @click='editRow(row,$index)'>
                            <i class='el-icon-edit-outline edit-btn-size'></i>
                          </el-link>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label='商务案例' prop='bs_case' width='400' header-align='center' align='center'>
                  <template slot-scope='{row,column,$index}'>
                    <div class='edit-box'>
                      <panel-bs-case :can-edit="userPermissions.indexOf('plc_save_quote')>-1" :row='row' :row-id='$index'
                                     @editVal='handleBsCaseEdit'></panel-bs-case>
                    </div>
                    <div class='row-opt'>
                      <div class='sub-title'></div>
                      <div style='display: flex;flex-direction: column'>
                        <div class='status'>
                          <div style='display: flex;flex-direction: row;justify-content: center;gap: 10px'>
                            <div>
                              <el-tag size='small' :type='calcStatus(row.status)'
                                      effect='plain'>
                                {{ row.status_alias }}
                              </el-tag>
                            </div>
                            <div>
                              <el-tag size='small' :type='calcApplyStatus(row.apply_status)'
                                      effect='plain'>{{
                                  row.apply_status_alias
                                }}
                              </el-tag>
                            </div>
                            <div>
                              <el-tag size='small'
                                      :type='calcOverTimeStatus(row.is_overtime)'
                                      effect='plain'>{{
                                  row.is_overtime_alias
                                }}
                              </el-tag>
                            </div>
                          </div>
                        </div>
                        <div class='opt'>
                          <div style='display: flex;flex-direction: row;gap: 10px;justify-content: center'>
                            <div>
                              <el-button type='text' @click='jumpToPlcItemDetail(row)'
                                         icon='el-icon-warning-outline'
                                         v-if="userPermissions.indexOf('plc_save_quote')>-1&&row.apply_status===2"
                                         style='color: #FFBA00'>
                                审核
                              </el-button>
                            </div>
                            <div>
                              <el-button type='text'
                                         @click='editAPlcDetail(row,$index)'
                                         icon='el-icon-edit'
                                         v-if="userPermissions.indexOf('plc_adjust')>-1">
                                修改
                              </el-button>
                            </div>
                            <div v-if="isShowAll||userPermissions.indexOf('plc_save_quote')>-1">
                              <el-button type='text' @click='delPlcItem(row,$index)'
                                         icon='el-icon-delete'
                                         v-if="userPermissions.indexOf('plc_item_delete')>-1">
                                删除
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
<!--            <el-tab-pane label="Excel预览" name="second">-->
<!--              {{setting}}-->
<!--            </el-tab-pane>-->

          </el-tabs>


          <div>
            <m-pagination :total.sync='pagingData.total' :page.sync='pagingData.current_page'
                          :limit.sync='pagingData.page_size' @pagination='getPageList'></m-pagination>
          </div>
        </div>

        <div style='margin-top: 100px;padding: 20px;text-align: right'>
          <el-button type='danger' icon='el-icon-delete' @click='delRow(info.id)' style='display: none'
                     v-if="userPermissions.indexOf('plc_delete')>-1" title='删除当前刊例'>删除当前刊例
          </el-button>
        </div>
      </div>
    </div>

    <!--修改：基础信息-->

    <ModalDialog destroy-on-close v-if='dialogData.visible' :dialogData='dialogData' @dialogClose='dialogClose'>
      <template slot='content'>
        <PublicationEdit v-if='dialogData.visible' ref='refPublicationEdit' :id='id' @saved='dialogClose' />
      </template>
    </ModalDialog>

    <!--修改：商务案例-->
    <BsCaseEditor ref='refBsCaseEditor' @handleSaved='refreshList'></BsCaseEditor>

    <!--定价：商业化营销中心终价-->
    <el-dialog :title='dialogQuoteData.title'
               :visible.sync='dialogQuoteData.visible'
               direction='rtl'
               width='90%'>
      <RealQuoteEdit :platform-type='setting.type' ref='refRealQuoteEdit' :id='formData.id' @saved='savedQuote' />
    </el-dialog>

    <!--    数据录入（添加）-抽屉-->
    <!--      刊例提报-->
    <AdjustCreateEdit
      ref='refCreateEdit'
      :key='`AdjustCreateEdit_${editFormData.id||"0"}`'
      :plc-info='info'
      @saved='refreshList'
      @deleted='refreshList'
      @updated='refreshCurrentRow'
      style='height: 80vh' />
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ModalDialog from '@/components/ModalDialog'
import PublicationEdit from '@/pages/publication/BaseEdit'

import RealQuoteEdit from '@/pages/publication/RealQuoteEdit'
import {mapGetters} from 'vuex'
import ExportLink from '@/components/export/ExportLink'
import PriceGrids from '@/pages/publication/compoents/PriceGrids'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import EditableCell from '@/components/EditableCell'
import ArtistSearch from '@/components/artist/ArtistSearch'
import AdjustCreateEdit from '@/pages/publication/adjust/AdjustCreate'
import ApeDrawer from '@/components/ApeDrawer'
import ExportBtn from '@/components/export/ExportBtn'
import PanelPlatformInfo from '@/pages/publication/compoents/PanelPlatformInfo'
import PanelKolInfo from '@/pages/publication/compoents/PanelKolInfo'
import ApeEditor from '@/components/ApeEditor'
import {html2json} from 'html2json'
import MlPageTable from '@/components/common/MlPageTable'
import PanelView from '@/pages/publication/compoents/PanelView'
import PanelBsCase from '@/pages/publication/compoents/PanelBsCase'
import BsCaseEditor from '@/pages/publication/adjust/BsCaseEditor'
import PriceStructView from '@/pages/publication/compoents/PriceStructView'
import {scrollTo} from '@/utils/scroll-to'
import PlcLogo from '@/pages/plc/detail/item/PlcLogo'

const { deepClone } = require('@/utils')
export default {
  name: 'PlcItemPanel',
  computed: {
    ...mapGetters(['isCollapse', 'userPermissions', 'userInfo']),
    isShowAll() {
      //我的，定价，改价申请
      return ['status', 'apply_status', 'my'].indexOf(this.tabActive) > -1
    }
  },
  components: {
    PlcLogo,
    PriceStructView,
    BsCaseEditor,
    PanelBsCase,
    PanelView,
    MlPageTable,
    PanelKolInfo,
    PanelPlatformInfo,
    ExportBtn,
    ApeDrawer,
    ArtistSearch,
    PriceGrids,
    RealQuoteEdit,
    PublicationEdit,
    ModalDialog,
    ExportLink,
    PageHeaderLayout,
    ArtistSearchCustom,
    EditableCell,
    AdjustCreateEdit,
    ApeEditor
  },
  props: {
    id: {
      type: [Number, String]
    }
  },
  data() {
    return {
      top: 100,
      activeName:'first',
      fixHeader: false,
      searchCondition: {},
      tabActive: 'all',
      // id: null,
      info: { title: '', validity_date: '', expiry_date: '', platform_info: {}, platform_code: null },
      needTip: true,
      dataList: [],
      processBarNumber: 0,
      loadingStatus: false,
      orderSort: { tag: 'desc', fans_total: 'desc', id: 'desc' },//默认排序规则
      offset: 0,
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 10,
        current_page: 1
        // offset: 0,
      },
      dialogData: {
        visible: false,
        title: '刊例基础资料编辑',
        width: '1280px',
        loading: true,
        modal: false,
        hide_footer: true
      },
      dialogPlcData: {
        visible: false,
        title: '刊例提报信息编辑',
        width: '1300px',
        loading: true,
        modal: false,
        hide_footer: false
      },
      formData: {},
      formItemData: { id: null, base_case: null, intro: null, bs_case_rich: null },
      editField: null,//当前编辑的字段
      editRowIndex: null,//当前修改的行号
      dialogQuoteData: {
        visible: false,
        title: '商业化营销中心终价',
        width: '80%',
        loading: true,
        modal: false,
        hide_footer: true
      },
      // quoteMap: {},
      tagMap: {
        'new': 'NEW',
        'benefit': '惠',
        'recommend': '荐'
      },
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '刊例录入',
        width_height: '1200px',
        show_footer: false,
        // mask: false,
        close_name: '关 闭'
        // confirm_name: '打 印',
      },
      editFormData: {},
      //当前刊例对应的设置
      setting: {}
    }
  },
  watch: {
    tabActive(val) {
      switch (val) {
        case 'status':
          this.searchCondition.status = 1
          this.searchCondition.apply_status = null

          break
        case 'apply_status':
          this.searchCondition.user_id = null
          this.searchCondition.status = null
          this.searchCondition.apply_status = 2
          break
        case 'all':
          this.searchCondition.user_id = null
          this.searchCondition.status = null
          this.searchCondition.apply_status = null
          break
        case 'my':
          this.searchCondition.user_id = this.userInfo.id
          this.searchCondition.status = null
          this.searchCondition.apply_status = null

          break
        default:

          break
      }
      this.getPageList()
    }
  },
  methods: {
    async loadSetting(platform_code) {
      let { info } = await this.$api.getPlatformSettingInfoByPlatformCode(platform_code)
      this.setting = info || {}
    },
    //编辑器
    handleTinymceInput(val) {
      this.formItemData.bs_case = val
    },
    addAPlcDetail() {
      this.$nextTick(() => {
        this.$refs['refCreateEdit'].add(this.info)
      })
    },
    editAPlcDetail(row, index) {
      this.$nextTick(() => {
        this.$refs['refCreateEdit'].edit(this.info, row)
      })
      this.editRowIndex = index
    },
    refreshCurrentRow() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      //刷新当前编辑
      this.getItemInfo(this.editRowIndex)//更新数据
    },

    refreshList() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      //添加新记录后，刷新列表
      this.getPageList()
    },

    // row, rowIndex
    headerStyle() {
      return 'headerColor'
    },
    /**
     * 获取配置
     * @returns {Promise<void>}
     */
    // async getSetting() {
    //   let {quote_setting} = await this.$api.getPublicationSetting({quote_type: 'platform_quote'});
    //   this.quoteMap = quote_setting
    // },
    async getInfo() {
      let { info } = await this.$api.getPublicationInfo(this.id)
      if (info && info.platform_code)
        await this.loadSetting(info.platform_code)

      this.$nextTick(() => {
        this.info = info
        if (!this.info.notice && this.needTip) {
          this.editBtn()
          this.needTip = false// 标记不需要载提示
        }
        scrollTo(0, 200)
      })
    },
    editBtn() {
      this.dialogData.visible = true
      this.dialogData.loading = false
      this.$nextTick(() => {
        if (this.$refs['refPublicationEdit'])
          this.$refs['refPublicationEdit'].show(this.id)
      })

    },
    // 处理模态框，确定事件
    handleConfirm() {
      this.dialogData.loading = false
      this.dialogData.visible = false
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.dialogData.loading = false
      this.dialogData.visible = false
      this.getInfo()
    },
    //调整配置
    jumpToSettingPage() {
      let platform_info = this.info.platform_info
      if (!platform_info.id) {
        this.$notify.warning('刊例配置信息异常')
        return false
      }

      this.$confirm(`确定打开【${platform_info.name}】刊例配置页面？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        // this.$router.push({
        //   name: 'platform_plc_set',
        //   params: { platform_id: platform_info.id }
        // })
        const { href } = this.$router.resolve({
          name: 'platform_plc_set',
          params: { platform_id: platform_info.id }
        })
        window.open(href, '_blank')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    // 搜索
    searchForm() {
      // this.offset = 0
      this.pagingData.current_page = 1

      this.getPageList()

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = { pid: this.id }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.user_id) {
        condition.user_id = this.searchCondition.user_id
      }
      if (this.searchCondition.status) {
        condition.status = this.searchCondition.status
      }
      if (this.searchCondition.apply_status) {
        condition.apply_status = this.searchCondition.apply_status
      }

      return condition
    },
    async getPageList() {
      this.loadingStatus = true
      let pagingInfo = { page_size: this.pagingData.page_size, current_page: this.pagingData.current_page }
      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getPublicationItemList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      this.offset = pages.offset
      this.loadingStatus = false
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getPageList()
    },
    editRow(row, index) {
      this.formData = row
      this.dialogQuoteData.loading = false
      this.dialogQuoteData.visible = true
      this.dialogQuoteData.title = `商业化营销中心终价【KOL：${row.nickname || '--'} (${row.year}年${row.month}月)】`
      this.$nextTick(() => {
        this.$refs['refRealQuoteEdit'].init(row)
      })
      this.editRowIndex = index
    },
    async savedQuote(real_quote) {
      this.dialogQuoteData.loading = false
      this.dialogQuoteData.visible = false

      if (real_quote) {
        if (this.dataList[this.editRowIndex]) {
          this.dataList[this.editRowIndex]['real_quote'] = deepClone(real_quote)
          this.dataList[this.editRowIndex]['status'] = 2
          // await this.getItemInfo(this.editRowIndex)//更新数据
          await this.getPageList()
        }
      }
    },

    async delRow(id) {
      this.$confirm(`确定删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        //删除刊例（要求刊例明细已全部删除）
        let info = await this.$api.deletePublicationInfo(id)
        if (info === 'ok') {
          await this.$alert('删除成功', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              if (action === 'confirm') {
                this.backParentPage()
                // window.opener.location.reload(true);
                // window.close()
              }
            }
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async fixRows() {
      //修复案例
      this.dataList.forEach((item) => {
        this.processBarNumber++
        item.bs_case_rich = html2json(item.bs_case)
        let data = {
          id: item.id,
          bs_case_rich: item.bs_case_rich
        }
        let id = this.$api.savePlcItem(data)
        if (id) {
          console.log(`ID:${data.id}(${this.processBarNumber}/${this.dataList.length})`)
        }
      })

    },
    backParentPage() {
      this.$router.push({ name: 'plc_list', params: { year: this.info.year, month: this.info.month } })
    },

    async selectArtist(selectInfo) {

      this.searchCondition.artist_id = selectInfo.artist_id
      // this.searchCondition.dept_id = selectInfo.group_id
      this.searchForm()
    },

    // eslint-disable-next-line no-unused-vars
    handleBsCaseEdit(row, field, index) {
      this.$refs['refBsCaseEditor'].show(row)
    },
    //修改刊例提报信息
    editVal(row, field, index) {
      this.formItemData = { id: row.id }
      this.$set(this.formItemData, field, row[field] || '')

      this.editRowIndex = index
      this.editField = field

      this.dialogPlcData.visible = true
      this.dialogPlcData.loading = false
    },
    dialogPlcClose() {
      this.dialogPlcData.loading = false
      this.dialogPlcData.visible = false
    },
    async handlePlcConfirm() {

      let data = {
        id: this.formItemData.id
      }
      // data[this.editField] = this.formItemData[this.editField]

      data[this.editField] = this.formItemData[this.editField]
      data.bs_case_rich = html2json(this.formItemData[this.editField])
      let id = await this.$api.savePlcItem(data)
      if (id) {
        this.msgSuccess('修改成功')
        this.dataList[this.editRowIndex][this.editField] = this.formItemData[this.editField]
        this.dialogPlcData.loading = false
        this.dialogPlcData.visible = false
        await this.getItemInfo(this.editRowIndex)//更新数据
      }
    },

    delPlcItem(row, index) {
      this.$confirm(`确定删除（KOL：${row.nickname}）刊例吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deletePublicationItem(row.id)
        if (info) {
          this.$notify.success('删除成功')
          this.dataList.splice(index, 1)

        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    calcStatus(status) {
      //`${scope.row.status===2?'success':'warning'
      switch (status) {
        case 1:
          return 'warning'
        case 2:
          return 'success'
      }
    },
    calcOverTimeStatus(status) {
      //`${scope.row.status===2?'success':'warning'
      switch (status) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
      }
    },
    calcApplyStatus(status) {
      //`${scope.row.apply_status===3?'success':'danger'}`
      switch (status) {
        case 1:
          return 'warning'
        case 2:
          return 'success'
        case 3:
          return 'danger'
        default:
          return 'info'
      }
    },
    //刷新一行数据
    async getItemInfo(index) {
      let id = this.dataList[index].id
      let { info } = await this.$api.getPublicationItemInfo(id)
      this.dataList[index] = deepClone(info)
      this.$forceUpdate()
    },
    jumpToPlcItemDetail(row) {
      this.$router.push({
        name: 'plc_item', params: { id: row.id }
      })
    },
    //检索红人
    async chooseArtist(item) {
      if (item) {
        // this.searchCondition.group_id = item.group_id
        // this.searchCondition.group_name = item.group_name
        this.searchCondition.artist_id = item.id
        // this.searchCondition.nickname = item.nickname
      } else {
        this.searchCondition.artist_id = null
      }
      this.searchForm()
    },
    handleClick(){
      //tab切换
    }


  },
  async mounted() {
    await this.getInfo()
    await this.getPageList()

    // setTimeout(()=>{
    //   window.onscroll = () => {
    //     let scrollTop = document.documentElement.scrollTop
    //     this.top = scrollTop
    //     this.fixHeader = scrollTop >= 120
    //     console.log('滚动顶部')
    //   }
    // },100)

  },
  activated() {
    alert('提报页面')
  }
}
</script>

<style scoped>
.notice {
  border: #f2f2f2 1px solid;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}

.row-opt .sub-title::before {
  content: '状态及操作项';
  color: #fe346e;
  font-weight: bold;
  font-size: 14px;
}

.row-opt {
  border: #f2f2f2 1px dashed;
  border-radius: 8px;
  padding: 10px;
  margin-top: 8px;

}

.row-opt:hover {
  border: #fe346e 1px dashed;
}

.plc {
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
  padding: 10px;
}

.title-row {
  /*display: flex;*/
  text-align: center;
}

.title {
  flex: 6;
  font-weight: 700;
  font-size: 2rem;
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
  display: inline-block;
}

.tag {
  font-size: 1rem;
  font-weight: 600;
  color: red;
  display: inline-block;
}

.date {
  flex: 2;
  font-size: 0.9rem;
  font-weight: 600;
  color: red;
  display: inline-block;
}

.date::before {
  content: '有效期：';
  font-size: 1rem;
  font-weight: 700;
}

.notice-title {
  font-weight: 800;
  font-size: 1.2rem;
}

.plc-items {
  border: #f2f2f2 1px solid;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}


.price-div {
  display: flex;
  line-height: 100%;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
  /*border: #f2f2f2 1px solid;*/
  height: 100%;
  width: 100%;
}

.edit-btn {
  flex: 1;
  line-height: 100%;
  /*margin-right: 5px;*/
  vertical-align: middle;
  text-align: center;
  /*border: #0C0C0C 1px dashed;*/
  position: relative;
  margin-top: 5px;
}

.edit-quote {
  position: relative;
  top: 45%;
  vertical-align: middle;
  text-align: center;
  line-height: 100%;
  /*border: #a41818 1px dashed;*/
}

.edit-btn-size {
  font-size: 18px;
}

.edit-box {
  border: #f2f2f2 1px dashed;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 8px;
}

.edit-row {
  padding: 10px;
  border: 1px solid rgba(242, 242, 242, 0.8);
  margin-top: 10px;
  border-radius: 10px;
  /*background-color: rgba(242,242,242,0.5)*/
}

.fanhui-button {
  float: right;
}

.case-panel .sub-title {
  font-size: 18px;
  line-height: 24px;
  padding: 6px;
  border: #fe346e 1px dashed;
  background: rgba(254, 52, 110, 0.8);
  color: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.bs_case::before {
  content: "商务案例";
  font-size: 18px;
  line-height: 24px;
  padding: 6px;
  border: #fe346e 1px dashed;
  background: rgba(254, 52, 110, 0.8);
  color: #f2f2f2;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-30%) translateY(-30%);
}

.bs_case {

}

</style>
<style>
/**
 单元格样式调整
 */
.plc-cell {
  vertical-align: top !important;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  /*left: 160px;*/
  z-index: 9;
  transition: width 0.28s;
  background: #fefefe;
  width: 98%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  /*opacity: 0.9;*/
  /*margin-left: 10px;*/
}

.fold {
  left: 10px !important;
}

.unfold {
  left: 160px !important;
}

.info_box {
  margin: 10px 0;
  max-height: 500px;
  min-height: 200px;
  /*border: 1px solid rgb(0 0 0 / 10%);*/
  border-radius: 5px;
  padding: 8px;

}


.tox {
  z-index: 999999 !important;
  border: 1px solid red
}

.opt {
  border: #f2f2f2 1px dashed;
  padding: 10px;
  margin-top: 10px;
}

</style>
