import * as request from '@/utils/request'

/**
 * 列表
 * @param data
 * @returns {Promise}
 */
export function explosiveProductList(data) {
  return request.post('/explosive_product/list', data)
}

/**
 * 保存
 * @param data
 * @returns {*}
 */
export function explosiveProductSave(data) {
  return request.post('/explosive_product/store', data)
}

/**
 * 删除
 * @param data
 * @returns {*}
 */
export function explosiveProductDelete(data) {
  return request.post('/explosive_product/delete', data)
}
