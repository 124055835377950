<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='flex-container'>
          <div class='left'>
            <el-form :model='formData'>

              <div style='display: flex; flex-direction: row; justify-content: flex-start;gap: 2px'>
                <el-card class='panel' style='width: 50%'>
                  <div slot='header'>
                    <span>刊例表格-工作薄配置</span>
                    <el-button style='float: right; padding: 3px 0' type='text' @click='saveLogoSetting'
                               :disabled='loading'
                               :loading='loading'>保存
                    </el-button>
                  </div>
                  <div>
                    <h3>平台Logo设置</h3>
                    <el-form :model='logoSetting' label-position='right' :inline='false'>

                      <div>
                        <el-form-item label='Logo图片'>
                          <div class='flex-sb'>
                            <div>
                              <img :src='logoSetting.logo_url' alt='刊例Logo图' :style='`width: ${logoSetting.width}px;height:${logoSetting.height}px`'>
                            </div>
                            <el-link type='primary' icon='el-icon-edit' @click='dialogLogoVisible=true'></el-link>
                          </div>

                        </el-form-item>
                        <el-form-item label='单元格'>
                          第
                          <el-input placeholder='Logo图标摆放列，例如：E' v-model='logoSetting.cellCol' clearable
                                    style='width: 80px' />
                          列，
                          第
                          <el-input placeholder='Logo图标摆放行，例如：1' v-model='logoSetting.cellRow' clearable
                                    style='width: 80px' />
                          行
                        </el-form-item>
                      </div>

                      <div>
                        <el-form-item label='偏移'>
                          X轴
                          <el-input placeholder='X轴偏移量' v-model='logoSetting.offsetX' clearable style='width: 80px' />
                          磅，
                          Y轴
                          <el-input placeholder='Y轴偏移量' v-model='logoSetting.offsetY' clearable style='width: 80px' />
                          磅
                        </el-form-item>
                      </div>
                      <div>
                        <el-form-item label='大小'>
                          宽度
                          <el-input placeholder='宽度' v-model='logoSetting.width' clearable style='width: 80px' />
                          磅，
                          高度
                          <el-input placeholder='高度' v-model='logoSetting.height' clearable style='width: 80px' />
                          磅
                        </el-form-item>
                        <el-form-item label=''>

                        </el-form-item>
                      </div>


                    </el-form>
                  </div>
                </el-card>
                <el-card class='panel' style='width: 50%'>
                  <div slot='header'>
                    <span>刊例表格-表头设置</span>
                    <el-button style='float: right; padding: 3px 0' type='text' @click='saveTableHeader'
                               :disabled='loading' :loading='loading'>保存
                    </el-button>
                  </div>
                  <div>
                    <el-form-item label='背景色'>
                      <el-color-picker
                        v-model='formData.bg_color'
                        show-alpha
                        :predefine='predefineColors'>
                      </el-color-picker>
                    </el-form-item>
                    <el-form-item label='字颜色'>
                      <el-color-picker
                        v-model='formData.font_color'
                        show-alpha
                        :predefine='predefineColors'>
                      </el-color-picker>
                    </el-form-item>
                    <el-form-item label='行高'>
                      <el-input type='text' :placeholder='`表格行高(单位默认：pt(points 磅值),推荐：${header_height}`'
                                v-model='formData.header_height' clearable />
                      <el-link @click='setHeaderHeight'>推荐行高：{{ header_height }}</el-link>
                      <p style='font-size: 0.8em;'>
                        Valid units are pt (points 磅值), px (pixels), pc (pica), in (inches), cm (centimeters) and mm
                        (millimeters).
                      </p>
                    </el-form-item>
                  </div>
                </el-card>
              </div>

              <el-card class='panel'>
                <div slot='header'>
                  <span>刊例类型（{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style='float: right; padding: 3px 0' type='text' @click="saveSetting('type')"
                             :disabled='loading'
                             :loading='loading'>保存
                  </el-button>
                </div>
                <div>
                  <el-row>
                    <el-col :span='24'>
                      <el-radio-group v-model='formData.type'>
                        <el-radio :label='item.val' :value='item.val' v-for='(item,index) in typeMap' :key='index'>
                          {{ item.title }}<span>刊例</span>
                        </el-radio>
                      </el-radio-group>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
              <el-card class='panel'>
                <div slot='header' class='flex-sb'>
                  <span>第一部分-常规字段({{ formData.common_fields.length }}/{{ common_cols.length }})</span>
                  <div>
                    <el-link type='primary' @click="saveSetting('common_fields')"
                             :disabled='loading'
                             :loading='loading'>保存
                    </el-link>
                    &nbsp;
                    &nbsp;
                    <el-link type='warning' @click='handleClear("common_fields")'>重置</el-link>
                  </div>

                </div>
                <div>
                  <el-checkbox-group v-model='formData.common_fields'>
                    <el-checkbox @change='forceUpdate(extra_col)' :label='extra_col.field'
                                 :value='extra_col.field'
                                 v-for='(extra_col,index) in common_cols' :key='index'>
                      <span :title='extra_col.placeholder'>{{ index + 1 }}、{{ extra_col.title }}</span>
                      <span v-if='extra_col.is_default' title='默认项'>(<i class='el-icon-check'></i>)</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-card>
            </el-form>

          </div>
          <!--          报价配置-->
          <div class='border-effect edit-view'>
            <PlcQuoteSetting :form-data.sync='formData' :plc-base='platform' @handleSave='handleSaveQuote' />
          </div>
          <!--          导出表格配置-->
          <div class='border-effect edit-view'>
            <PlcColumnsTable ref='refSheetSetting'
                             :form-data.sync='formData'
                             @handleSave='handleSaveSheetSetting'></PlcColumnsTable>
          </div>
        </div>
      </div>
    </PageHeaderLayout>

    <!--    视频报价配置-->
    <el-dialog :visible.sync='dialogPriceVisible' width='600px'>
      <div class='form-card'>
        <el-form :inline='false' :model='formQuote' label-width='90px' label-position='right'>
          <el-form-item label='报价标题'>
            <el-input type='textarea' rows='2' v-model='formQuote.title' clearable></el-input>
          </el-form-item>
          <el-form-item label='报价字段'>
            <el-input v-model='formQuote.field' clearable>
              <span slot='prepend'>quote_</span>
            </el-input>
          </el-form-item>
          <el-form-item label='数据类型'>
            <el-select v-model='formQuote.type' style='width: 100%'>
              <el-option value='number' label='数值'></el-option>
              <el-option value='text' label='文本'></el-option>
              <el-option value='textarea' label='文本域'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='描述'>
            <el-input type='textarea' v-model='formQuote.placeholder' placeholder='描述该字段用途或者举例' clearable></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button @click='addRow' type='primary' v-if='currentIndex===-1'>添加</el-button>
          <el-button @click='saveRow' type='primary' v-else>保存</el-button>
          <el-button @click='resetRow' type='info'>重置</el-button>
        </div>
      </div>
    </el-dialog>



    <PlcFieldDialog :visible.sync='dialogVisible'></PlcFieldDialog>

    <el-dialog :visible.sync='dialogLogoVisible'>
      <ape-uploader @handleUploadSuccess='handleUploadSuccess'
                    :uploadFileList='uploadFileList' :allowSize='allowSize' />
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import EditableCell from '@/components/EditableCell'
import PlcFieldDialog from '@/pages/platform/components/PlcFieldDialog'
import PlcColumnsTable from '@/pages/plc/setting/PlcColumnsTable'
import plcMap from '@/json/plc.json'
import ApeUploader from '@/components/ApeUploader'
import PlcQuoteSetting from '@/pages/plc/setting/PlcQuoteSetting'

const { deepClone } = require('@/utils')
//视频时长报价-表单数据结构
const singlePriceStruct = { title: '', field: '', placeholder: '请输入信息', type: 'text', val: '' }

export default {
  name: 'PlcSet',
  components: {
    PlcQuoteSetting,
    ApeUploader,
    PageHeaderLayout, EditableCell, PlcFieldDialog, PlcColumnsTable
  },
  computed: {
    quoteMulti() {
      return this.quoteInfo.multi[this.formData.type] || []
    },
    quoteMixed() {
      return this.quoteInfo.mixed || {}
    },
    priceShowMap() {
      if (this.formData.type && this.customPriceMap) {
        return this.customPriceMap[this.formData.type] || {
          'multi': true,
          'single': true,
          'mixed': true,
          'showLast': true
        }
      } else
        return {
          'multi': true,
          'single': true,
          'mixed': true,
          'showLast': true
        }
    },
    uploadFileList() {

      return [{ url: this.logoSetting.logo_url }]
    }
  },

  data() {
    return {
      platform_id: null,
      // uploadFileList: [],
      allowSize: 1024 * 1024 * 10,
      platform: { name: '', code: '' },
      loading: false,
      activeName: 'checkbox',
      activePriceName: 'single',
      header_height: '31.5pt',//表格行高
      formData: {
        type: 'ONLINE',
        bg_color: '#000000',
        font_color: '#FFFFFF',
        header_height: '31.5pt',//表格行高
        setting: {
          quote: {
            price_type_map: this.priceTypeMap,
            setting: { multi: this.quoteMulti, single: [], mixed: this.quoteMixed }
          },
          common_fields: [],//常规字段
          platform_quote: [],//平台报价字段
          extra_fields: [],//其他字段
          columns_data: []//列自定义数据
        },//全部配置
        common_fields: [],//常规字段
        platform_quote: [],//平台报价字段
        extra_fields: [],//其他字段
        columns_data: [],//列自定义数据
        work_sheet: {
          logoSetting: {
            cellCol: 'E',
            cellRow: 1,
            width: 50,
            height: 50,
            offsetX: 10,
            offsetY: 10
          }
        }
      },
      excelColsArr: [],
      logoSetting: {
        cellCol: 'E',
        cellRow: 1,
        width: 50,
        height: 50,
        offsetX: 10,
        offsetY: 10,
        logo_url: ''
      },
      defaultLogSetting: {
        cellCol: 'E',
        cellRow: 1,
        width: 50,
        height: 50,
        offsetX: 10,
        offsetY: 10
      },
      typeMap: plcMap.typeMap,
      common_cols: plcMap.common_cols,
      extra_cols: [],
      multiChecked: false,
      singleChecked: false,
      priceTypeMap: {
        'multi': {
          'title': '品类场次报价',
          'checked': true
        },
        'single': {
          'title': '报价类目报价',
          'checked': true
        },
        'mixed': {
          'title': '专（混）场报价',
          'checked': true
        }
      },
      customPriceMap: plcMap.customPriceMap,
      quoteInfo: plcMap.quoteInfo,
      extra_fields: [],
      dateTypeMap: [
        { label: '数值', value: 'number' }
        , { label: '文本', value: 'text' }
        , { label: '文本域名', value: 'textarea' }
        // , { label: '富文本', value: 'richtext' }
      ],
      formQuote: singlePriceStruct,
      currentIndex: -1,
      quoteMap: {},
      predefineColors: [
        'rgba(0,0,0,1)',
        'rgba(255,0,59,1)',
        'rgba(255,182,0,1)',
        'rgba(0,158,239,1)',
        'rgba(255,78,0,1)',
        'rgba(179,0,0,1)',
        'rgba(255,255,250,1)',
        'rgba(255,255,200,1)'
      ],
      columns: [],//表格列表配置
      //excel表格配置
      excelDataList: [],
      dialogVisible: false,
      dialogLogoVisible:false,
      dialogPriceVisible: false
    }
  },
  methods: {
    insertColumn(item, rangeArr) {
      let index = this.formData.columns_data ? this.formData.columns_data.findIndex(value => value.field === item.field) : -1

      // let rowOrg = this.formData.columns_data[index];
      let row = {
        field: item.field,
        title: item.title,
        height: '80.1',
        width: '15',
        horizontal: 'center',
        showTool: true,
        children: item.children
      }
      // Object.assign(row, rowOrg)//合并数据
      if (rangeArr.indexOf(item.field) > -1 && index === -1)
        this.formData.columns_data.push(row)
    },
    calcColumns() {
      this.common_cols.forEach((item) => {
        this.insertColumn(item, this.formData.common_fields)
      })
      //报价字段
      this.formData.platform_quote.forEach((item) => {

        this.insertColumn(item, [item.field])
      })
      //额外字段
      this.extra_cols.forEach((item) => {
        this.insertColumn(item, this.formData.extra_fields)
      })
    },

    // eslint-disable-next-line no-unused-vars
    handleCellEnter(row, column, event) {
// 鼠标悬浮单元格时的处理逻辑
      console.log(`鼠标悬浮在: ${row[column.property]} - ${column.label}`)
    },
    // eslint-disable-next-line no-unused-vars
    handleCellLeave(row, column, event) {
      // 鼠标离开单元格时的处理逻辑
      console.log(`鼠标离开: ${row[column.property]} - ${column.label}`)
    },
    // eslint-disable-next-line no-unused-vars
    handleRowDbClickEvent(row, column, event) {
      let index = this.formData.platform_quote.indexOf(row)
      this.editRow(row, index)
    },
    // eslint-disable-next-line no-unused-vars
    editRow(row, index) {
      this.dialogPriceVisible = true
      this.formQuote = deepClone(row)
      this.currentIndex = index
      // this.calcColumns()
    },

    saveRow() {
      this.formData.platform_quote[this.currentIndex] = {}
      this.formData.platform_quote[this.currentIndex] = { ...this.formQuote }

      this.resetRow()

    },
    addRow() {
      // 报价列字段必须quote_开头
      if (this.formQuote.field.indexOf('quote_') !== 0) {
        this.formQuote.field = 'quote_' + this.formQuote.field
      }

      this.formData.platform_quote.push({ ...this.formQuote })

      this.saveSetting('platform_quote')
      this.dialogPriceVisible = false
    },
    resetRow() {
      this.currentIndex = -1
      // let index = this.formData.platform_quote ? this.formData.platform_quote.length + 1 : 1
      //{ title: '', field: `quote_${index}` }
      this.formQuote = { ...singlePriceStruct }

    },
    //复选框勾选后触发
    forceUpdate() {
      // this.calcColumns()//重新计算表格列
      this.$forceUpdate()
    },
    async savePlcSetting() {
      await this.saveSetting('platform_quote')
    },
    async saveExtra() {
      await this.saveSetting('extra_fields')
    },
    async saveTableHeader() {
      await this.saveSetting(['bg_color', 'font_color', 'header_height'])
    },
    async saveLogoSetting() {
      let data = {
        platform_id: this.platform_id,
        work_sheet: { logoSetting: this.logoSetting }
      }
      this.loading = true
      let info = await this.$api.savePlatformSetting(data)
      if (info) {
        this.$notify.success('保存成功')
      }
      this.loading = false
    },
    //保存配置
    async handleSave() {

      const setting = {
        quote: {
          type_map: this.priceTypeMap,
          setting: { multi: this.quoteMulti, single: this.formData.platform_quote, mixed: this.quoteInfo.mixed }
        },
        common_fields: this.formData.common_fields,
        platform_quote: this.formData.platform_quote,
        extra_fields: this.formData.extra_fields,
        columns_data: this.formData.columns_data,
        work_sheet: { logo: this.logoSetting }
      }
      this.loading = true
      let info = await this.$api.savePlatformSetting({
        platform_id: this.platform_id,
        setting: setting
      })
      if (info) {
        this.$notify.success('保存成功')
      }
      this.loading = false
    },
    //清空
    handleClear(field) {
      this.formData[field] = []
    },
    async saveSetting(field) {
      let data = {
        platform_id: this.platform_id
      }
      if (typeof field === 'string') {
        data[field] = this.formData[field]
      } else if (typeof field === 'object') {
        field.forEach((f) => {
          data[f] = this.formData[f]
        })
      }

      this.loading = true
      let info = await this.$api.savePlatformSetting(data)
      if (info) {
        await this.handleSave()
      }
      this.loading = false
    },
    async getPlcPlatformInfo() {
      let { info } = await this.$api.getPlatformInfo(this.platform_id)
      if (info) {
        this.platform = { name: info.name, code: info.code }
        // console.log('plc platform:', this.platform)
      }
    },
    reset() {
      this.formData = {
        type: 'ONLINE',
        bg_color: '#000000',
        font_color: '#FFFFFF',
        header_height: '31.5pt',//表格行高
        //全部配置
        setting: {
          quote: { price_type_map: this.priceTypeMap, setting: { multi: this.quoteMulti, single: [] } },
          common_fields: [],//常规字段
          platform_quote: [],//平台报价字段
          extra_fields: [],//其他字段
          columns_data: []//列自定义数据
        },
        common_fields: [],//常规字段
        platform_quote: [],//平台报价字段
        extra_fields: [],//其他字段
        columns_data: [],//列自定义数据
        work_sheet: {
          logoSetting: { ...this.defaultLogSetting }
        }
      }
      this.resetRow()
    },
    async getPlatformSettingInfoByPlatformId() {
      this.reset()
      let { info } = await this.$api.getPlatformSettingInfoByPlatformId(this.platform_id)
      if (info && info.id) {
        this.formData = info || {}
        const lastSetting = info.setting || {}
        //数据类型要保证正确，否则可能渲染失败（MAC电脑上）(数据类型不对，确保数据类型正确，手动处理)
        this.formData.platform_quote = info.platform_quote || []
        this.formData.setting = lastSetting
        this.formData.extra_fields = info.extra_fields || []
        this.formData.common_fields = info.common_fields || []
        this.formData.columns_data = info.columns_data || []
        this.formData.work_sheet = info.work_sheet || {}
        this.formData.type = info.type || 'ONLINE'
        if (info.work_sheet)
          this.logoSetting = info.work_sheet.logoSetting || this.defaultLogSetting

        //显示上次的报价配置
        if (lastSetting.quote) {
          if (lastSetting.quote.type_map) {
            this.priceTypeMap.multi.checked = !!(lastSetting.quote.type_map.multi.checked)
            this.priceTypeMap.single.checked = !!(lastSetting.quote.type_map.single.checked)
          }
        }

        //工作簿配置-刊例提报sheet配置
        await this.$nextTick(() => {
          if (this.$refs['refSheetSetting'])
            this.$refs['refSheetSetting'].init(this.formData.setting.columns_data)
        })
      }
    },

    setHeaderHeight() {
      this.formData.header_height = this.header_height
    },
    // eslint-disable-next-line no-unused-vars
    handleDelPlcColsRow(row) {
      const pIndex = this.formData.columns_data.indexOf(row)
      if (pIndex > -1) {
        this.formData.columns_data.splice(pIndex, 1)
      } else {

        this.$notify.warning('未匹配到该数据项，无法移除')
      }
    },
    handleCloseRange(item, posIndex) {
      this.handleAddToExcelCol(item)
      this.excelColsArr.splice(posIndex, 1)
    },
    handleAddToExcelCol(item) {
      let itemChildren = []
      if (item.field === 'multi') {
        // 计算 children
        itemChildren = this.getMultiChildren()
      }
      const cell = {
        field: item.field,
        title: item.title,
        height: '80.1',
        width: '15',
        horizontal: 'center',
        merge_col: 'Y',
        merge_row: 'Y',
        showTool: true,
        children: itemChildren
      }
      this.formData.columns_data.push(cell)
    },
    getMultiChildren() {
      // 平台报价字段(multi)
      return this.quoteMulti.cols
    },
    //保存导出表格【工作簿配置】
    handleSaveSheetSetting(data) {
      this.formData.columns_data = data
      // console.log('d', data)
      this.saveSetting('columns_data')
    },
    handleUploadSuccess(data) {
      this.logoSetting.logo_url = data.full_path
    },
    handleSaveQuote() {
      this.saveSetting('platform_quote')
    }
  },
  created() {
    this.platform_id = this.$route.params ? this.$route.params.platform_id : null
    if (this.platform_id) {
      this.getPlcPlatformInfo()
      this.getPlatformSettingInfoByPlatformId()
    }

  }
}
</script>

<style scoped>
.edit-view {
  width: clamp(400px, 90vw, 1280px);
  border-radius: 10px;
  padding: 20px
}

.panel {
  margin-top: 10px;
}

.form-card {
  border: #DCDFE6 1px solid;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 10px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: auto;
  border: #f2f2f2 1px solid;
  padding: 10px;
}

.left {
  width: clamp(400px, 90vw, 1280px);
}

</style>
<style>
.click-row-over {
  /*color: #fe346e !important;*/
  cursor: pointer;
  background-color: rgb(117, 43, 236);
}
</style>
