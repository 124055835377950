import * as request from '@/utils/request'

export default {
  /**
   * @description 获取列表
   * @param {*} data
   * @returns
   */
  getArtistList(data) {
    return request.post('/artist/list', data)
  },
  /**
   * @description 保存
   * @param {*} data
   * @returns
   */
  saveArtist(data) {
    return request.post('/artist/store', data)
  },
  /**
   * @description 获取详情
   *
   * @param {*} id
   * @returns
   */
  getArtistInfo(id) {
    let data = { id: id }
    return request.post('/artist/get_info', data)
  },
  /**
   * @description 根据id删除单条信息
   * @param {*} data
   * @returns
   */
  deleteArtist(id) {
    let data = { id: id }
    return request.post('/artist/delete', data)
  },
  /**
   * @description 红人名录表头
   * @returns {Promise}
   */
  getColumnsArtist() {
    return request.post('/artist/columns', {})
  },

  /**
   * @description 红人转组
   * @param data
   * @returns {Promise}
   */
  groupTransferArtist(data) {
    return request.post('/artist/group_transfer', data)
  },

  /**
   * @description 红人记录操作日志
   * @returns {Promise}
   * @param data
   */
  getArtistLog(data) {
    return request.post('/artist/log', data)
  },

  /**
   * @description 分组列表
   * @returns {Promise}
   */
  getGroupList() {
    return request.post('/artist/group_list', {})
  },

  /**
   * @description 平台列表
   * @returns {Promise}
   */
  getPlatforms() {
    return request.post('/artist/platforms', {})
  },
  /**
   * @description 昵称是否已占用
   * @returns {Promise}
   */
  isUsedArtistNickname(data) {
    return request.post('/artist/is_used', data)
  },

  /**
   * @description 分组红人
   * @param group_id
   * @param sign_status
   * @returns {Promise}
   */
  getGroupArtists(group_id, sign_status) {
    if (sign_status === undefined || sign_status === 'undefined') {
      sign_status = 'Y'
    }
    let data = { group_id: group_id, sign_status: sign_status }
    return request.post('/artist/group_artists', data)
  },
  /**
   * @description 分组红人
   * @param group_id
   * @param sign_status
   * @param nickname
   * @returns {Promise}
   */
  getGroupArtistsByNickname(group_id, sign_status, nickname) {
    if (sign_status === undefined || sign_status === 'undefined') {
      sign_status = 'Y'
    }
    let data = { group_id: group_id, sign_status: sign_status, nickname: nickname }
    return request.post('/artist/group_artists', data)
  },
  /**
   * @description 分组红人2
   * @param group_id
   * @param sign_status
   * @returns {Promise}
   */
  getGroupKols(group_id, sign_status) {
    if (sign_status === undefined || sign_status === 'undefined') {
      sign_status = 'Y'
    }
    let data = { group_id: group_id, sign_status: sign_status }
    return request.post('/sign_info/sign_group_artist', data)
  },
  /**
   * @description 分组红人
   * @param group_id
   * @param sign_status
   * @returns {Promise}
   */
  getSignGroups() {

    // let data = { group_id: group_id, sign_status: sign_status }
    return request.post('/sign_info/sign_group_list')
  },
  /**
   * 加载组内红人列表
   * @param group_id
   * @returns {Promise}
   */
  loadGroupArtists(group_id) {
    let data = { group_id: group_id }
    return request.post('/artist/group_artists', data)
  },
  /**
   * @description 分组签约状态红人
   * @param group_id
   * @returns {Promise}
   */
  getGroupSignArtists(group_id) {
    let data = { group_id: group_id, sign_status: 'Y' }
    return request.post('/artist/group_artists', data)
  },
  /**
   * @description 搜索红人列表：红人昵称
   * @param {*} data
   * @returns
   */
  searchArtists(data) {
    return request.post('/artist/search', data)
  },
  /**
   * @description 搜索(星广汇)红人列表：红人昵称
   * @param {*} data
   * @returns
   */
  searchAdArtists(data) {
    return request.post('/artist/search_ad', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  getPdCompareList(data) {
    return request.post('/artist/pd_compare_list', data)
  },
  /**
   *对照表
   * @param data
   * @returns {Promise}
   */
  getCompareList(data) {
    return request.post('/artist/compare_list', data)
  },

  /**
   *
   * @param data
   * @returns {Promise}
   */
  saveArtistCompare(data) {
    return request.post('/artist/save_compare', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  delArtistCompare(data) {
    return request.post('/artist/del_compare', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  getAdArtistCompareList(data) {
    return request.post('/artist/ad_compare_list', data)
  },

  /**
   * 保存红人平台详情
   * @param data
   * @returns {Promise}
   */
  saveArtistPlatformInfo(data) {
    if (data.id) {
      return request.post('/artist_journal/edit', data)
    } else {
      return request.post('/artist_journal/add', data)
    }
  },
  delArtistPlatformInfo(data) {
    return request.post('/artist_journal/delete', { id: data })

  },

  /**
   * 获取红人平台详情
   * @param data
   * @returns {Promise}
   */
  getArtistPlatformInfo(data) {
    return request.post('/artist/get_platform_info', data)
  },
  /**
   * 红人（KOL）拥有的账号的平台
   * @returns {Promise}
   * @param id
   */
  getArtistOwnedPlatforms(id) {
    let data = { artist_id: id }
    return request.post('/artist/owned_platforms', data)
  },

  /**
   * 红人各个平台的开通情况及其详情
   * @returns {Promise}
   * @param id
   */
  getArtistPlatforms(id) {
    let data = { artist_id: id }
    return request.post('/artist/get_artist_platforms', data)
  },
  /**
   * @description 获取限额红人列表
   * @param {*} data
   * @returns
   */
  getArtistLimitConfigList(data) {
    return request.post('/artist_limit_config/list', data)
  },
  /**
   * @description 新增红人限额
   * @param {*} data
   * @returns
   */
  setArtistLimitConfig(data) {
    return request.post('/artist_limit_config/add', data)
  },
  /**
   * @description 新增红人限额
   * @param {*} data
   * @returns
   */
  setEditArtistLimitConfig(data) {
    return request.post('/artist_limit_config/edit', data)
  },
  /**
   * @description 新增红人限额
   * @param {*} data
   * @returns
   */
 getArtistLimitInfo(data) {
    return request.post('/artist_limit_config/infoByArtist', data)
  },
// /admin_api/


}
