<template>
  <div class="explosive-products_container">
    <el-form inline :model="search" class="explosive-products_container__search">
      <el-form-item>
        <el-input v-model="search.product_name" placeholder="搜索产品" clearable @change="handleSearch" />
      </el-form-item>
      <el-form-item>
        <select-sale :value.sync="search.sale_id" placeholder="搜索销售" @change="handleSearch" />
      </el-form-item>
      <el-form-item>
        <select-product-brand :key="search.brand_id||'brand'" :value.sync="search.brand_id" placeholder="搜索品牌"
                              @change="handleSearch" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain round @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-form-item style="float: right;">
        <explosive-products-store
          v-if='hasAuthority("explosive_products_manage__save")'
          ref="store"
          @success="callbackSuccess" />
      </el-form-item>
    </el-form>

    <ml-table ref="TABLE" :search="search" :api="explosiveProductList" hand-request>
      <template slot="columns">
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column prop="brand_name" label="品牌" min-width="100" />
        <el-table-column prop="sale_name" label="销售" min-width="70" />
        <el-table-column prop="product_name" label="商品名称" min-width="300" />
        <el-table-column label="GMV" min-width="200">
          <template v-slot="{row:{gmv}}">
            {{ gmv|numberFormat(2) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130" align="center">
          <template v-slot="{row}">
            <el-button
              v-if='hasAuthority("explosive_products_manage__save")&&((onlyEditSelf&&row.sale_id===login_user_id)||!onlyEditSelf)'
              type="text" @click="handleEdit(row)">修改
            </el-button>
            <el-button
              v-if='hasAuthority("explosive_products_manage__delete")&&((onlyDeleteSelf&&row.sale_id===login_user_id)||!onlyDeleteSelf)'
              type="text" @click="handleDelete(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </ml-table>
  </div>
</template>
<script>
import { explosiveProductList, explosiveProductDelete } from '../../../api/explosive-products'
import { defineComponent } from 'vue'
import ArtistSearch from '@/components/artist/ArtistSearch.vue'
import MlTable from '@/components/MlTable/index.vue'
import ExplosiveProductsStore from './store.vue'
import SelectSale from '@/components/select/user.vue'
import SelectProductCategory from '@/components/select/product-category.vue'
import SelectProductBrand from '@/components/select/product-brand.vue'
import { mapGetters } from 'vuex'

const { id: login_user_id } = JSON.parse(localStorage.getItem('user_info'))
export default defineComponent({
  components: {
    SelectProductBrand,
    SelectProductCategory,
    SelectSale,
    ExplosiveProductsStore,
    MlTable,
    ArtistSearch
  },
  data() {
    return {
      login_user_id,
      search: {
        product_name: null,
        sale_id: null,
        brand_id: null
      },
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    onlyEditSelf() {
      return this.userPermissions.indexOf('explosive_products_manage__save_all') === -1
    },
    onlyDeleteSelf() {
      return this.userPermissions.indexOf('explosive_products_manage__delete_all') === -1
    }
  },
  created() {
    this.init()
  },
  methods: {
    explosiveProductList,
    init: async function() {
      const { query } = this.$route
      if (query) {
        let { brand_id } = query
        brand_id = parseInt(brand_id + '')
        if (brand_id) {
          this.search.brand_id = brand_id
        }
      }
      await this.$nextTick()
      this.$refs.TABLE.handleSearch()
    },
    callbackSuccess: function(is_edit) {
      if (is_edit) {
        this.$refs.TABLE.getTable()
      } else {
        this.$refs.TABLE.handleSearch()
      }
    },
    handleSearch: function() {
      this.$refs.TABLE.handleSearch()
    },
    handleEdit: function(row) {
      this.$refs.store.handleEdit(row)
    },
    handleDelete: async function({ id, product_name }) {
      this.$confirm(`确认删除【${product_name}】吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await explosiveProductDelete({ id })
        this.$message.success('删除成功')
        this.handleSearch()
      }).catch(() => {

      })
    }
  }
})
</script>
<style lang="scss">
.ExplosiveProductsManage_main {
  margin: 12px !important;
  background: #ffffff;
  border-radius: 20px;
}

.explosive-products_container {
  height: calc(100vh - 136px);
  padding: 24px;

  &__search {
    border-bottom: 1px solid rgb(242, 242, 242);

    .el-form-item--mini.el-form-item {
      margin-bottom: 12px !important;
    }
  }

  &__main {
    padding: 12px 0;
    height: calc(100% - 50px);
  }

  .ml-table-container {
    height: calc(100% - 52px);
    margin-top: 24px;
  }

}
</style>
