<template>
  <div>
    <div style='display: flex;flex-direction: row;justify-content: space-between;margin-bottom: 8px;'>
      <div style='text-align: center;width: 100%;'>
        <el-link @click='getList' style='text-align: center;font-size: 20px;font-weight: 800'> {{ searchCondition.year
          }}年{{ searchCondition.month }}月提报刊例
        </el-link>
      </div>
      <div style='width: 120px;' class='flex-evenly'>
        <el-link type='primary' icon='el-icon-plus' @click='handleAddPlcMonth'>新增</el-link>
        <export-btn size='mini' type='text' ref='refExportLink'
                    :can-export="userPermissions.indexOf('plc_export')>-1"
                    :lint-title='`导出`' :title='`下载《${searchCondition.year}年${searchCondition.month}月刊例》`'
                    @export='exportData(searchCondition)'
                    class-name='export-btn'
                    style='display: inline' />
      </div>
    </div>
    <el-row>
      <el-col :span='24'>
        <div class='default-table'
             v-loading='loadingStatus'
             element-loading-text='玩命加载中……'
             element-loading-spinner='el-icon-loading'>
          <el-table :data='dataList' class='dragTable' border>
            <el-table-column label='序号' type='index' align='center' width='66'></el-table-column>
            <el-table-column
              width='80'
              align='center'
              label='Drag'>
              <template slot-scope='scope'>
                <el-tooltip effect='dark' content='拖动排序' placement='top-start'>
                <span class='drag-handle' :data-id='scope.row.id' :data-parent_id='scope.row.parent_id'
                      :data-depth='scope.row.depth'><i class='el-icon-rank'></i></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label='刊例类型' header-align='center' align='center' width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.platform_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='刊例标题' header-align='center' align='left' min-width='180'
                             :show-overflow-tooltip='true'>
              <template slot-scope='scope'>
                <el-link type='primary' @click='jumpTo(scope.row)'>{{ scope.row.title || '/'
                  }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label='有效期' align='center' width='160' show-overflow-tooltip>
              <template slot-scope='scope'>
                <span>{{ scope.row.validity_date }}</span>
                ~
                <span>{{ scope.row.expiry_date }}</span>
              </template>
            </el-table-column>
            <el-table-column label='提报数量' prop='items_count' width='88' header-align='center'
                             align='right'></el-table-column>
            <el-table-column label='提报截止时间' prop='deadline' width='120' header-align='center'
                             align='center'></el-table-column>
            <el-table-column label='创建时间' prop='created_at' header-align='center' align='center'>
              <template slot-scope='{row}'>
                <span
                  :title="`${$utils.parseTime(row.updated_at,'{y}-{m}-{d} {h}:{i}')}`">{{ $utils.parseTime(row.created_at, '{y}-{m}-{d} {h}:{i}')
                  }}</span>
              </template>
            </el-table-column>
            <!--            <el-table-column label='修改时间' prop='updated_at' header-align='center' align='right'></el-table-column>-->
            <el-table-column label='操作' width='80' align='center'>
              <template slot-scope='{row,$index}'>
                <el-button size='mini' type='text' icon='el-icon-close'
                           @click='handleDelete(row)' :ref='`refExportLink_${$index}`'
                           v-if="userPermissions.indexOf('plc_delete')>-1">
                </el-button>

              </template>
            </el-table-column>
          </el-table>
          <div v-show='pagingData.total>pagingData.page_size'>
            <m-pagination :hidden='pagingData.total===0' :limit.sync='pagingData.page_size'
                          :page.sync='pagingData.current_page'
                          :total.sync='pagingData.total' @pagination='getList'></m-pagination>
          </div>
        </div>
      </el-col>
    </el-row>

<!--    新增-->
    <AddPlcMonth ref='refAddPlcMonth' @saved='getList'/>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import Sortable from 'sortablejs'
import AddPlcMonth from '@/pages/plc/detail/fragment/AddPlcMonth'

export default {
  name: 'PlcMonthList',
  components: { AddPlcMonth, ExportBtn, ApeTable, PageHeaderLayout },
  props: {
    baseInfo: {
      type: Object,
      default() {
        return {
          id: null,
          year: null,
          month: null
        }
      }
    }
  },
  data() {
    return {
      loadingStatus: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      searchCondition: { year: null, month: null },
      orderSort: { year: 'desc', month: 'desc', id: 'asc' },//默认排序规则
      dataList: [],
      columns: [
        // {title: '提报时间', value: 'date_alias', width: 120},
        // {title: '刊例标题', value: 'title', width: 200},
        { title: '提报数量', value: 'items_count', width: 100 },
        // {title: '状态', value: 'status_alias', width: 100},
        // {title: '有效期', value: 'period_validity', width: 120},
        { title: '提报截止时间', value: 'deadline', width: 120 },
        { title: '创建时间', value: 'created_at', width: 120 },
        { title: '最后修改时间', value: 'updated_at', width: 120 }
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15,
        current_page: 1
        // offset: 0,
      },
      // 分页的offset,序号列使用
      offset: 0,
      offer_info: ''
      ,
      video_info: ''
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    addBtn() {
      this.$router.push({ name: 'plc_create2' })
    },
    switchPaging() {
      this.getList()
    },
    jumpTo(row) {
      this.$utils.safeOpenWindow(this, {
        name: 'plc_item_main',
        query: { id: row.id }
      },'_blank',null)

    },
    // 初始化列表
    async getList() {
      this.loadingStatus = true
      let pagingInfo = { page_size: this.pagingData.page_size, current_page: this.pagingData.current_page }
      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getPublicationList(searchCondition)
      this.dataList = list || []
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false
      this.$nextTick(() => {
        this.dragSort()
      })
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.month) {
        condition.month = this.searchCondition.month
      }

      return condition
    },

    async exportAsExcel(row) {
      try {
        // eslint-disable-next-line no-unused-vars
        let date = new Date()
        // // 生成excel
        let response = await this.$api.exportPublicationAsExcel(row.year, row.month)
        let name = `【缇苏】${row.year}年${row.month}月刊例（含税）-${date.getMonth() + 1}.${date.getDate()}导出`
        this.$refs[`refExportAsExcel`].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    //导出表格
    // eslint-disable-next-line no-unused-vars
    async exportData(row) {
      try {
        // eslint-disable-next-line no-unused-vars
        let date = new Date()
        //获取数据
        let data = await this.$api.getExportPublicationAllData(row.year, row.month)
        // // 生成excel
        let response = await this.$api.downloadPublicationExcelByData(data)
        let name = `【缇苏】${row.year}年${row.month}月刊例（含税）-${date.getMonth() + 1}.${date.getDate()}`
        this.$refs[`refExportLink`].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    backParentPage() {
      this.$router.push({ name: 'plc' })
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该月刊例, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.deletePublicationInfo(row.id)
        await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(orderIds) {
      let t = this
      let info = await this.$api.orderPublication(orderIds)
      if (info == 'ok') {
        t.getList()

      }
      // console.log(orderIds)
      this.$message.success('保存成功!')
    },
    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.dragTable .el-table__body-wrapper > table > tbody')[0]
      // console.log(el)
      this.sortable = Sortable.create(el, {
        handle: '.drag-handle',
        setData: function(dataTransfer) {
          dataTransfer.setData('Text', '')
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
        },
        onEnd: evt => {
          let oldId = evt.item.querySelectorAll('.drag-handle')[0].dataset.id
          let Ids = []
          let orderIds = []
          let newList = []
          let tmp = el.querySelectorAll('.drag-handle')
          for (let i = 0, len = tmp.length; i < len; i++) {
            newList[tmp[i].dataset.id] = { 'parent_id': tmp[i].dataset.parent_id, 'depth': tmp[i].dataset.depth }
            Ids.push(tmp[i].dataset.id)
          }
          // 处理组合实际请求后台数据
          for (let i = 0, len = Ids.length; i < len; i++) {
            if (newList[oldId].parent_id === newList[Ids[i]].parent_id && newList[oldId].depth === newList[Ids[i]].depth) {
              orderIds.push(Ids[i])
            }
          }
          this.dragSortSubmit(orderIds)
        }
      })
    }
    , handleAddPlcMonth() {
      if(this.baseInfo.id){
        //刊例
        this.$refs['refAddPlcMonth'].show(this.baseInfo)

      }else {
        this.$notify.warning("未知刊例，无法新增刊例类型")
      }
    }
  },
  mounted() {
    this.getList()

  },
  created() {
    this.searchCondition.year = this.baseInfo.year
    this.searchCondition.month = this.baseInfo.month


  }
}
</script>

<style scoped lang='scss'>
.export-btn {
  padding: 7px 8px;
  font-size: 12px !important;
  font-stretch: 100%
}

.drag-handle {
  font-size: 24px;
  cursor: pointer;
}

</style>

