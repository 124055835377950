<template>
  <el-select v-model="v" filterable remote reserve-keyword :placeholder="placeholder" :remote-method="getOptions"
             :loading="loading" clearable>
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
  </el-select>
</template>
<script>

import { optionsBrandHubBrands } from '../../api/options'

export default {
  name: 'SelectProductBrand',
  props: {
    placeholder: {
      type: String,
      default() {

        return '输入关键词检索品牌'
      }
    },
    value: {
      type: [String, Number],
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      v: null,
      options: [],
      loading: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(_) {
        this.v = _
      }
    },
    v(_) {
      this.updateData(_)
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: async function() {
      await this.getOptions(this.value)
      if (this.value){
        this.updateData(this.value)
      }
    },
    updateData: function(_) {
      const obj = this.options.find(option => {
        return option.value === _
      })
      this.$emit('update:label', obj ? obj.label : null)
      this.$emit('update:value', _)
      this.$emit('change', obj || false)
    },
    getOptions: async function(keyword) {
      const data = await optionsBrandHubBrands({ keyword })
      this.options = data || []
    }
  }
}
</script>
<style scoped>

</style>
