export default {
  path: 'lb_menu',
  component: () => import('@/pages/lb/Index'),
  meta: { title: '排期选品' },
  children: [
    {
      path: 'lb_stat',
      name: 'LbStat',
      component: () => import('@/pages/lb/stat'),
      meta: { title: '仪表盘' }
    },
    {
      path: 'lb_schedule',
      name: 'LbSchedule',
      component: () => import('@/pages/lb/ScheduleIndex'),
      meta: { title: '直播排期' }
    },
    {
      path: 'lb_kol',
      name: 'LbScheduleKol',
      component: () => import('@/pages/lb/kol/Index'),
      meta: { title: '选品日期', alive: true }
    },
    {
      path: 'lb_live',
      name: 'LbScheduleLive',
      component: () => import('@/pages/lb/live/Index'),
      meta: { title: '直播日期', alive: true }
    },
    {
      path: 'product_library',
      name: 'productLibrary',
      component: () => import('@/pages/lb/product/ProductLibrary'),
      meta: { title: '产品库', alive: true }
    },
    {
      path: 'product_handover',
      name: 'productHandover',
      component: () => import('@/pages/lb/product/handover/index.vue'),
      meta: { title: '产品交接', alive: true }
    },
    {
      path: 'product_cosmetic_audit',
      name: 'productCosmeticAudit',
      component: () => import('@/pages/lb/product/audit/cosmetic/index.vue'),
      meta: { title: '产品库审核', alive: true }
    },
    {
      path: 'product_dress_audit',
      name: 'productDressAudit',
      component: () => import('@/pages/lb/product/audit/dress/index.vue'),
      meta: { title: '服饰库审核', alive: true }
    },
    {
      path: 'product_investment',
      name: 'investment',
      component: () => import('@/pages/lb/product/investment'),
      meta: { title: '招商情况一览', alive: true }
    },
    {
      path: 'opt_view',
      name: 'optView',
      component: () => import('@/pages/lb/dashboard/OptView'),
      meta: { title: '运营情况一览', alive: true }
    },
    {
      path: 'member_statistics',
      name: 'LbMemberStatistics',
      component: () => import('@/pages/lb/product/investment'),
      meta: { title: '成员情况一览', alive: true }
    },
    {
      path: 'detail',
      name: 'lbScheduleDetail',
      component: () => import('@/pages/lb/ScheduleDetail'),
      meta: { title: '排期详情', hideInnerMenu: true }
    },
    {
      path: ':id/live_detail',
      name: 'lbLiveDetail',
      component: () => import('@/pages/lb/live/Detail'),
      meta: { title: '直播定品详情', hideInnerMenu: true }
    },
    {
      path: ':id/kol_detail',
      name: 'lbKolDetail',
      component: () => import('@/pages/lb/kol/Detail'),
      meta: { title: '红人选品会详情', hideInnerMenu: true }
    },
    {
      path: 'product_brand',
      component: () => import('@/pages/lb/brand/index'),
      meta: { title: '品牌库', alive: true },
      children: [
        {
          path: '/',
          name: 'product_brand',
          component: () => import('@/pages/lb/brand/BrandList.vue'),
          meta: { title: '品牌列表' }
        },
        {
          path: 'product_custom',
          name: 'product_custom',
          component: () => import('@/pages/lb/brand/CustomList'),
          meta: { title: '客户列表' }
        },
        {
          path: 'product_contract',
          name: 'product_contract',
          component: () => import('@/pages/lb/brand/ContractList'),
          meta: { title: '合同列表' }
        },
        {
          path: ':id/product_info',
          name: 'product_info',
          component: () => import('@/pages/lb/brand/infoPage'),
          meta: { title: '客户详情' }
        }
      ]
    },
    {
      path: 'product_selection',
      name: 'productSelection',
      component: () => import('@/pages/lb/product_selection/SelectionDrawer'),
      meta: { title: '初选选品', hideInnerMenu: true }
    },
    {
      path: 'product_selection_ack',
      name: 'productSelectionAck',
      component: () => import('@/pages/lb/selection_ack/Index'),
      meta: { title: '确认选品', hideInnerMenu: true }
    },
    {
      path: 'product_dress_brand',
      name: 'productDressBrand',
      component: () => import('@/pages/lb/dress/Index'),
      meta: { title: '服饰产品库', alive: true }
    },
    {
      path: 'product_dress',
      name: 'productDress',
      component: () => import('@/pages/lb/dress/DressLibrary.vue'),
      meta: { title: '服饰产品库', alive: true }
    },
    {
      path: 'product_dress_kol/:id',
      name: 'productDressKol',
      component: () => import('@/pages/lb/dress/dressProduct/index.vue'),
      meta: { title: '服饰选品表', alive: true }
    },
    {
      path: 'product_dress_live/:id',
      name: 'productDressLive',
      component: () => import('@/pages/lb/live/SelectDressProduct'),
      meta: { title: '服饰定品表', alive: true }
    },
    {
      path: 'product_specimen_list',
      name: 'productSpecimenList',
      component: () => import('@/pages/lb/product/SpecimenList'),
      meta: { title: '寄样管理', alive: true }
    },
    {
      path: 'brand_join',
      name: 'brandJoin',
      component: () => import('@/pages/lb/brand/brand_join_record/BrandJoinRecordList'),
      meta: { title: '品牌对接', alive: true }
    },
    {
      path: 'product_sec_kill',
      name: 'productSecKill',
      component: () => import('@/pages/lb/product/seckill/index.vue'),
      meta: { title: '服饰产品库', alive: true }
    },
  ]
}

