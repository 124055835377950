export default {
  path: 'plc',
  name: 'plc',
  component: () => import('@/pages/plc/PlcIndex'),
  meta: {title: 'KOL刊例' , alive: true},
  children: [
    {
      path: 'add',
      name: 'plc_create',
      component: () => import('@/pages/publication/CreateEdit'),
      meta: {title: '新建月刊'},
    },
    {
      path: ':id/edit',
      name: 'plc_edit',
      component: () => import('@/pages/publication/CreateEdit'),
      meta: {title: '修改月刊'},
    },
    {
      path: ':year/:month/list',
      name: 'plc_list',
      component: () => import('@/pages/publication/PlcList'),
      meta: {title: '月刊列表'},
    },
    {
      path: 'plc_item_detail/:id',
      name: 'plc_item_detail',
      component: () => import('@/pages/publication/PlcDetail'),
      meta: {title: '刊例详情'},
    }
  ]
}
