<template>
  <div class='popular-case-save-container'>
    <!--  添加类目  -->
    <save-category ref='Save' @success='callbackAddCate' />
    <save-category-sub ref='SaveSub' @success='getOptionsCategorySub' />

    <el-button type='primary' icon='el-icon-plus' @click='handleShowDrawer(false)'>
      新增
    </el-button>
    <el-drawer :visible.sync='show' :close-on-press-escape='false' :title="`${cmd==='add'?'新增':'编辑'}爆款案例`"
               custom-class='popular-case-save-drawer'>
      <el-form ref='form' :model='form' :rules='rules' label-width='80px' size='medium'>
        <el-form-item label='红人' prop='artist_id'>
          <artist-search :artist-id.sync='form.artist_id' :artist-name.sync='form.artist_name'
                         :name='form.artist_name' style='width: 100%;' />
        </el-form-item>
        <el-form-item label='品牌' prop='brand_name'>
          <el-input v-model='form.brand_name' placeholder='请输入品牌' />
        </el-form-item>
        <el-form-item label='合作形式' prop='coa_mode'>
          <el-select v-model='form.coa_mode' filterable allow-create placeholder='请选择合作形式'>
            <el-option v-for='item in options.coa_mode' :key='item.value' :label='item.label' :value='item.value' />
          </el-select>
        </el-form-item>
        <el-form-item label='品类' prop='category'>
          <el-select v-model='form.category' filterable placeholder='请选择品类' class='select-category'>
            <el-option v-for='item in options.category' :key='item.value' :label='item.label' :value='item.value' />
          </el-select>
          <el-button type='primary' icon='el-icon-plus' circle @click='handleAddCate' />
        </el-form-item>
        <el-form-item label='发布平台' prop='platform'>
          <el-select v-model='form.platform' filterable placeholder='请选择发布平台'>
            <el-option v-for='item in options.platform'
                       :key='item.value' :label='item.label'
                       :value='`${JSON.stringify(item)}`' />
          </el-select>
        </el-form-item>
        <el-form-item label='类目' prop='category_sub'>
          <el-select :key='key' v-model='form.category_sub' filterable placeholder='请选择类目' class='select-category'>
            <el-option v-for='item in options.category_sub' :key='item.value' :label='item.label' :value='item.value' />
          </el-select>
          <el-button type='primary' icon='el-icon-plus' circle @click='handleAddCateSub' />
        </el-form-item>
        <el-form-item label='特别场' prop='special'>
          <el-select v-model='form.special' filterable allow-create placeholder='小专场/日播等' clearable>
            <el-option v-for='item in options.special' :key='item.value' :label='item.label' :value='item.value' />
          </el-select>
        </el-form-item>
        <el-form-item label='功效' prop='efficacy'>
          <el-select v-model='form.efficacy' filterable allow-create placeholder='请选择功效'>
            <el-option v-for='item in options.efficacy' :key='item.value' :label='item.label' :value='item.value' />
          </el-select>
        </el-form-item>
        <el-form-item label='产品' prop='product_name'>
          <el-input v-model='form.product_name' placeholder='请输入' />
        </el-form-item>
        <el-form-item label='是否挂车' prop='is_trailer'>
          <el-radio-group v-model='form.is_trailer'>
            <el-radio-button label='Y'>是</el-radio-button>
            <el-radio-button label='N'>否</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label='合作时间' prop='coa_date'>
          <el-date-picker v-model='form.coa_date' type='date' value-format='yyyy-MM-dd' format='yyyy年MM月dd日' />
        </el-form-item>
        <el-form-item label='客单价' prop='price_per_customer'>
          <ml-input :value.sync='form.price_per_customer' num placeholder='请输入客单价' />
        </el-form-item>
        <el-form-item label='GMV/万' prop='price_gmv'>
          <ml-input :value.sync='form.price_gmv' num placeholder='请输入GMV/万' />
        </el-form-item>
        <el-form-item label='销量/件' prop='num_sales'>
          <ml-input :value.sync='form.num_sales' int placeholder='请输入销量' />
        </el-form-item>
        <el-form-item label='更新日期' prop='update_date'>
          <el-date-picker v-model='form.update_date' type='date' value-format='yyyy-MM-dd' format='yyyy年MM月dd日' />
        </el-form-item>
        <el-form-item label='在线人数' prop='num_live_visitors'>
          <ml-input :value.sync='form.num_live_visitors' int placeholder='请输入在线人数' />
        </el-form-item>
        <el-form-item label='链接' prop='link' class='form-item-all-line'>
          <ml-input :value.sync='form.link' placeholder='请输入链接' />
        </el-form-item>
        <el-form-item label='海报图片' :key='key_upload' class='form-item-image__outer form-item-all-line'>
          <ml-upload-images ref='images' :images-param='form.pic_posters' class='form-item-image'>
            <p class='placeholder'>爆款海报</p>
            <div class='image-upload-btn'>
              <div class='upload-content'>
                <i class='el-icon-upload' />
                <p class='upload-text'>添加上传图片</p>
              </div>
            </div>
          </ml-upload-images>
        </el-form-item>
      </el-form>
      <div style='height: 80px;' />
      <div class='footer'>
        <ml-button size='medium' @click.native='handleSubmit' />
      </div>
    </el-drawer>
  </div>
</template>
<script>

import MlInput from '../../../components/MlInput/index.vue'
import ArtistSearch from '../../../components/artist/ArtistSearch.vue'
import { optionsPopularCases, optionsPopularCasesCategory, savePopularCase } from '../../../api/popular_case'
import MlButton from '../../../components/MlButton/index.vue'
import PageHeaderLayout from '../../../layouts/PageHeaderLayout.vue'
import SaveCategorySub from '@/pages/category_module/save-category-sub.vue'
import SaveCategory from '@/pages/category_module/save-category.vue'
import MlUploadImages from '../../../components/MlUploadImg/multiple.vue'

const rules = {
  coa_mode: [{ required: true, message: '请选择合作形式', trigger: 'change' }],
  platform: [{ required: true, message: '请选择发布平台', trigger: 'change' }],
  artist_id: [{ required: true, message: '请选择红人', trigger: 'change' }],
  category: [{ required: true, message: '请选择品类', trigger: 'change' }],
  category_sub: [{ required: true, message: '请选择类目', trigger: 'change' }],
  coa_date: [{ required: true, message: '请选择合作时间', trigger: 'change' }],
  brand_name: [{ required: true, message: '请输入品牌', trigger: 'blur' }]
}
const ModelForm = {
  artist_id: null,
  artist_name: null,
  coa_mode: null,
  special: null,
  platform: null,
  platform_code: null,
  platform_name: null,
  category: null,
  category_id: null,
  category_sub: null,
  efficacy: null,
  brand_name: null,
  product_name: null,
  is_trailer: 0,
  coa_date: null,
  price_per_customer: null,
  price_gmv: null,
  num_sales: null,
  num_live_visitors: null,
  update_date: null,
  link: null,
  pic_posters: []
}
const getKey = function() {
  return '' + (new Date().getTime())
}
export default {
  name: 'PopularCaseSave',
  components: { MlUploadImages, SaveCategory, SaveCategorySub, PageHeaderLayout, MlButton, ArtistSearch, MlInput },
  data() {
    return {
      rules,
      cmd: 'add',
      key: getKey(),
      key_upload: getKey(),
      loading: false,
      show: false,
      form: { ...{}, ...ModelForm },
      options: { platform: [], category: [], category_sub: [], efficacy: [], coa_mode: [], special: [] }
    }
  },
  watch: {
    'form.category'() {
      this.getOptionsCategorySub()
    }
  },
  methods: {
    getKey,
    callbackAddCate: async function(name) {
      const { category } = await optionsPopularCases({ fields: ['category'] })
      this.options.category = category || []
      this.form.category = name
    },
    handleAddCateSub: function() {
      this.$refs.SaveSub.interfaceAdd(this.form.category_id)
    },
    handleAddCate: function() {
      this.$refs.Save.interfaceAdd()
    },
    /**
     * 编辑
     * 给父组件用
     * @param obj
     */
    interfaceEdit: function(obj) {
      this.handleShowDrawer(obj)
    },
    /**
     * 弹出层
     * @param edit_obj
     */
    handleShowDrawer: async function(edit_obj = false) {
      await this.getOptions()

      // 重置表单
      this.form = { ...{}, ...ModelForm }
      if (typeof edit_obj === 'object') {
        this.getOptionsCategorySub().then()
        edit_obj.platform = JSON.stringify({ label: edit_obj.platform_name, value: edit_obj.platform_code })
        this.cmd = 'edit'
        this.form = { ...this.form, ...edit_obj }
      } else {
        this.form.platform = null
        this.cmd = 'add'
      }
      this.show = true
      this.key_upload = 'key_upload' + getKey()
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.clearValidate()
      })
    },
    getOptions: async function() {
      this.options = await optionsPopularCases()
    },
    getOptionsCategorySub: async function() {
      const obj = this.options.category.find((row) => {
        return row.value === this.form.category
      })
      if (obj) {
        this.form.category_id = obj.id || -1
      }
      if (!this.form.category_id) {
        return
      }

      this.options.category_sub = await optionsPopularCasesCategory({ parent_id: this.form.category_id })
      this.key = getKey()
    },
    handleSubmit: async function() {
      this.$refs.form.validate(async (val) => {
        if (val) {
          const params = this.form
          const platform = params.platform ? JSON.parse(params.platform) : []
          params.platform_code = platform.value
          params.platform_name = platform.label
          params.images = this.$refs.images ? (this.$refs.images.images || []) : []

          const response = await savePopularCase(params)
          if (response) {
            this.$message.success('保存成功')
            this.$emit('success')
            this.show = false
          }
        }
      })
    }
  }
}
</script>

<style lang='scss'>
.popular-case-save-container {
  display: inline;
}

.popular-case-save-drawer {
  width: 1000px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  .el-form {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    grid-column-gap: 20px;

    .form-item-all-line {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .el-radio-group {
      width: 100%;

      .el-radio-button {
        width: 50%;

        span {
          width: 100%;
        }
      }
    }
  }

  .ml-upload-image {
    position: relative;
    width: 140px;
    height: 205px;

    &__outer {
      .el-form-item__content {
        display: flex;
      }
    }

    .placeholder {
      position: absolute;
      width: 100%;
      top: -14px;
      text-align: center;
      line-height: 18px;
      display: block;
      opacity: 1;
      border-radius: 6px 6px 0 0;
      background-color: #ff5a91;
      padding: 4px 0;
      color: #fff;
    }

    .image-upload-btn {
      border: 1px dashed #d7dbe0;
      border-radius: 9px;
      background-color: #f7f8fa;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .upload-content {
      width: 100%;
      cursor: pointer;
      position: absolute;
      transform: translateY(-18px);
      text-align: center;

      i {
        font-size: 50px;
        color: #ccc;
      }
    }

    .upload-text {
      font-size: 12px;
      line-height: 1;
      color: #666;
      text-align: center;
      margin: 12px 0 0;
    }
  }

  .el-select, .el-date-editor, .el-input, .el-autocomplete {
    width: 100%;
  }

  .select-category {
    width: calc(100% - 46px);
    margin-right: 10px;
  }
}
</style>
