export default {
  path: 'plc/detail',
  name: 'plc_item_main',
  component: () => import('@/pages/plc/detail/Index'),
  meta: { title: 'KOL刊例', alive: true },
  children:[
    {
      path: 'item_main',
      name: 'plc_item_main',
      component: () => import('@/pages/plc/detail/ItemDetail'),
      meta: { title: '刊例提报', alive: true },
    },
    {
      path: 'cover_setting',
      name: 'plc_cover_setting',
      component: () => import('@/pages/plc/detail/CoverSetting'),
      meta: { title: '封面设置', alive: true },
    }
  ]
}
