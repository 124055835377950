import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：】
 * 生成日期：2024/12/26 11:46:57
 * 生成路径: src/api/plc_setting.js
 * 生成人：超级管理员
 */

/**
 * @description 获取列表
 * @param {*} data
 * @returns
 */
export function getPlcSettingList(data) {
  return request.post('/plc_setting/list', data)
}
/**
 * @description 保存
 * @param {*} data
 * @returns
 */
export function savePlcSetting(data) {
  return request.post('/plc_setting/store', data)
}

/**
 * @description 获取详情
 * @param id
 * @returns
 */
export function getPlcSettingInfo(id) {
  let data = { id: id }
  return request.post('/plc_setting/get_info', data)
}

/**
 * @description 获取详情
 * @param where
 * @returns
 */
export function queryPlcSettingInfo(where) {
  return request.post('/plc_setting/query', where)
}

/**
 * @description 根据id删除单条信息
 * @param id
 * @returns
 */
export function deletePlcSetting(id) {
  let data = { id: id }
  return request.post('/plc_setting/delete', data)
}

/**
 * 导入
 * @param data
 * @returns {Promise}
 */
export function importPlcSettings(data) {
  return request.post('/plc_setting/import', data)
}

/**
 * 导出
 * @param data
 * @returns {Promise}
 */
export function exportPlcSettings(data) {
  return request.postBlob('/plc_setting/export', data)
}
