<template>
  <div class="trade_stat_settle_container">
    <el-form inline :model="search" class="trade_stat_settle_container__search">
      <el-form-item>
        <el-date-picker v-model="search.month" value-format="yyyy-MM-dd" type="month" :clearable="false" />
      </el-form-item>
      <el-form-item>
        <artist-search :artist-id.sync="search.artist_id" @handleSelect='handleSearch' />
      </el-form-item>
      <el-form-item>
        <el-select v-model="search.live_type" placeholder="类型" @change="handleSearch" clearable>
          <el-option label="达播" value="1" />
          <el-option label="日播" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-form-item class="right-tool">
        <export-by-page v-if="userPermissions.indexOf('bs_trade_stats_settle_export_a')>-1"
                        module="TRADE_M_S_ST_DY"
                        :search="search" :filename="filename" txt="下载《抖音月度结算汇总》" is-text />
        <down-t-s-products v-if="userPermissions.indexOf('bs_trade_stats_settle_export_b')>-1" :search="search" />
        <ml-button icon="el-icon-refresh" type="text" text="初始化数据" @click.native="handleInitStats" />
      </el-form-item>
    </el-form>
    <div v-watermark="filename" :key="key" v-loading="loading" class="trade_stat_settle_container__main">
      <el-table ref="TABLE" :search="search" :data="table"
                show-summary :summary-method="getSummaries" height="100%">
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column label="红人昵称" prop="artist_nickname" show-overflow-tooltip>
          <template v-slot="{row}">
            <artist-nickname :info='{artist_id:row.artist_id,nickname:row.artist_nickname}' />
          </template>
        </el-table-column>
        <el-table-column label="合作时长" prop="duration">
          <template v-slot="{row:{duration}}">
            {{ duration }}个月
          </template>
        </el-table-column>
        <el-table-column label="结算金额" align="right" prop="settle_amount" sortable>
          <template v-slot="{row:{settle_amount}}">
            ￥<span class="num-ph">{{ settle_amount|numberFormat(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="sci_kol" sortable>
          <template slot="header">
            <div>
              <div>结算佣金收入</div>
              <div>达人</div>
            </div>
          </template>
          <template v-slot="{row:{sci_kol}}">
            ￥<span class="num-ph">{{ sci_kol|numberFormat(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="sci_agency" sortable>
          <template slot="header">
            <div>
              <div>结算佣金收入</div>
              <div>机构</div>
            </div>
          </template>
          <template v-slot="{row:{sci_agency}}">
            ￥<span class="num-ph">{{ sci_agency|numberFormat(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="sci" sortable>
          <template slot="header">
            <div>
              <div>结算佣金收入</div>
              <div>含税合计</div>
            </div>
          </template>
          <template v-slot="{row:{sci}}">
            ￥<span class="num-ph">{{ sci|numberFormat(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="sci_no" sortable>
          <template slot="header">
            <div>
              <div>结算佣金收入</div>
              <div>不含税合计</div>
            </div>
          </template>
          <template v-slot="{row:{sci_no}}">
            ￥<span class="num-ph">{{ sci_no|numberFormat(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="达人补贴" align="right" prop="expert_subsidy" sortable>
          <template v-slot="{row:{expert_subsidy}}">
            ￥<span class="num-ph">{{ expert_subsidy|numberFormat(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结算后退款" align="right" prop="custom_return" sortable>
          <template v-slot="{row}">
            <trade-stat-settle-custom-return v-if="userPermissions.indexOf('bs_trade_stats_settle_cr')>-1" :row="row"
                                             @success="getTable()" />
            <template v-else>
              ￥<span class="num-ph">{{ row.custom_return|numberFormat(2) }}</span>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import ArtistSearch from '@/components/artist/ArtistSearch.vue'
import ExportByPage from '@/components/export/ByPage/index.vue'
import MlButton from '@/components/MlButton/index.vue'
import { tradeStatsInit, tradeStatsSettleList } from '../../../api/trade_stat'
import { numberFormat } from '../../../utils/utils'
import { mapGetters } from 'vuex'
import { parseTime } from '../../../utils'
import moment from 'moment/moment'
import DownTSProducts from './component/download-products.vue'
import TradeStatSettleCustomReturn from './component/custom-return.vue'

const genKey = function() {
  return ((new Date().getTime() / 1000) | 0).toString(36)
}
export default {
  components: { TradeStatSettleCustomReturn, DownTSProducts, MlButton, ExportByPage, ArtistSearch },
  data() {
    return {
      loading: false,
      key: genKey(),
      search: {
        month: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        platform_code: 'douyin',
        platform_name: '抖音'
      },
      table: []
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    filename() {
      const month = parseTime(this.search.month, '{y}年{m}月')
      return `${month}${this.search.platform_name}月度结算汇总`
    }
  },
  watch: {
    'search.month'() {
      this.key = genKey()
      this.handleSearch()
    }
  },
  created() {
    this.handleSearch()
  },
  methods: {
    handleConfig: function() {
      this.$refs.DOM_READY && this.$refs.DOM_READY.handleConfig()
    },
    handleInitStats: async function() {
      await tradeStatsInit({
        month: this.search.month,
        platform_code: this.search.platform_code,
        command: 'calc:trade-stats__settle'
      })
      this.$message.success('初始化任务发布，请稍后查看数据。')
    },
    handleSearch: function() {
      this.getTable()
      // this.getListStatus()
    },
    getTable: async function() {
      if (this.loading) {
        return
      }
      this.loading = true
      const { list } = await tradeStatsSettleList(this.getParam())
      setTimeout(() => {
        this.loading = false
        this.$nextTick(() => {
          this.$refs.TABLE.doLayout()
        })
      }, 80)
      this.table = list || []
    },
    getParam: function() {
      const params = { ...{}, ...this.search }
      for (const paramsKey in params) {
        const value = params[paramsKey]
        if (value === '' || value === null) {
          delete params[paramsKey]
        }
      }
      return params
    },
    getSummaries: function(param) {
      const { columns, data } = param
      const sums = []
      const obj = {}
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '汇总'
          return
        }
        const { property } = column
        if (!property || ['artist_nickname', 'duration', 'month'].includes(property)) {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          obj[property] = sums[index]
          sums[index] = '￥' + numberFormat(sums[index], 2)
        } else {
          obj[property] = 0
          sums[index] = ''
        }
      })

      return sums
    }
  }
}
</script>
<style lang="scss">
.trade_stat_settle_main {
  margin: 12px !important;
  //background: #fff !important;
  .page-content {
    min-height: unset !important;
  }

  .main-page-content {
    padding: 0 12px 12px 12px !important;
    min-height: unset !important;
  }

  .el-date-editor {
    width: 110px;
  }

  .trade_stat_settle_container {
    height: calc(100vh - 138px - 24px);
    padding: 12px;

    &__search {
      border-bottom: 1px solid rgb(242, 242, 242);

      .el-form-item--mini.el-form-item {
        margin-bottom: 12px !important;
      }
    }

    &__main {
      padding: 12px 0;
      height: calc(100% - 52px);
    }

    &__tool {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__platforms {
      color: #8a8e99;

      span {
        cursor: pointer;
        padding: 8px 20px;
      }

      span.active {
        color: #fe346e;
        font-weight: 700;
      }

      span:first-child {
        padding-left: 0 !important;
      }
    }

    &__list-desc {
      border-radius: 18px;
      padding: 0.62vw 1.248vw;
      font-size: 12px;
      margin-bottom: 20px;
      color: #606266;
      width: 10vw;
      background: linear-gradient(260deg, #fff3f8, #fffbfd 80%, #fffcfd 179%);

      li {
        list-style: disc !important;
        margin-left: 26px;
      }

      li:first-child {
        margin-top: 12px;
      }
    }
  }

  .el-table, .el-table tr {
    background-color: transparent !important;
  }

  .el-table td.el-table__cell div {
    font-family: "PH Medium";
    font-weight: bold;
  }

  .el-table th.el-table__cell.is-sortable {
    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.2;
    }
  }

  .right-tool {
    float: right;

    .el-form-item__content {
      display: flex;
      align-items: center;
    }

  }
}
</style>

