export default {
  path: 'emp_exp_product',
  name: 'EmphasisProduct',
  component: () => import('@/components/MlRouterView/index.vue'),
  meta: { title: '重点爆款品' },
  children: [
    {
      path: 'emp_category',
      name: 'EmphasisProductCategory',
      component: () => import('@/pages/category_module/index'),
      meta: { title: '重点大全案品分类' }
    },
    {
      path: 'emp_manage',
      name: 'EmphasisProductsList',
      component: () => import('@/pages/emp_exp_product/emphasis-products/list/index.vue'),
      meta: { title: '重点大全案品' }
    },
    {
      path: 'emp_stat',
      name: 'EmphasisProductsStat',
      component: () => import('@/pages/emp_exp_product/emphasis-products/stat/index.vue'),
      meta: { title: '重点大全案品看板' }
    },
    {
      path: 'exp_manage',
      name: 'ExplosiveProductsManage',
      component: () => import('@/pages/emp_exp_product/explosive-product/index.vue'),
      meta: { title: '爆品里程碑' }
    }
  ]
}
