<template>
  <div>
    <el-card class='panel'>
      <div slot='header' class='flex-sb'>
        <div>
          <b>第二部分-平台报价字段</b>
        </div>
        <div>

          <el-button style=' padding: 3px 0;' type='text' @click='handleSave'
                     :disabled='loading'
                     :loading='loading'>保存
          </el-button>
        </div>
      </div>
      <el-tabs v-model='activePriceName' type='card'>
        <el-tab-pane label='视频时长报价' name='single' v-if='priceShowMap.single'>
          <div class='box-shadow' style='padding: 1px 10px;'>
            <div>
              <!--                          <h3>视频时长报价（一般是线上平台的刊例）</h3>-->
              <div style='display: flex;flex-direction: column;gap: 5px;'>
                <div class='border-effect' style='padding: 10px'>
                  <div class='flex-sb'>
                    <b>报价表单设置</b>
                    <div>
                      <el-button type='warning' size='mini' icon='el-icon-setting'
                                 @click='showDefaultDemos'>
                        快捷设置
                      </el-button>
                      &nbsp;
                      &nbsp;
                      <el-button type='primary' size='mini' icon='el-icon-plus' @click='handleAdd'>新增
                      </el-button>
                    </div>
                  </div>
                  <div>
                    <el-table ref='tbl_quote'
                              :data='formData.platform_quote'
                              row-class-name='click-row-over'
                              border
                    >
                      <el-table-column label='报价标题' prop='title' header-align='center' min-width='160'>
                        <editable-cell :show-input='row.editMode' slot-scope='{row,column}'
                                       v-model='row[column.property]' type='textarea' placeholder='报价标题'>
                          <div slot='content'>
                            <div class='flex-sb'>
                              <span>{{ row[column.property] }}</span>
                              <i class='el-icon-edit default-edit-icon'></i>
                            </div>
                          </div>
                        </editable-cell>
                      </el-table-column>
                      <el-table-column label='报价字段' prop='field' header-align='center'
                                       min-width='120'>
                        <editable-cell :show-input='row.editMode' slot-scope='{row,column}'
                                       v-model='row[column.property]' type='text' placeholder='报价字段'>
                          <div slot='content'>
                            <div class='flex-sb'>
                              <span>{{ row[column.property] }}</span>
                              <i class='el-icon-edit default-edit-icon'></i>
                            </div>
                          </div>
                        </editable-cell>
                      </el-table-column>
                      <el-table-column label='数据类型' prop='type' header-align='center'
                                       min-width='100'>
                        <template slot-scope='{row}'>
                          <el-select v-model='row.type' placeholder='数据类型' style='width: 100%' clearable>
                            <el-option value='number' label='数值'></el-option>
                            <el-option value='text' label='文本'></el-option>
                            <el-option value='textarea' label='文本域'></el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column label='描述' prop='placeholder' header-align='center'
                                       min-width='140'>
                        <editable-cell :show-input='row.editMode' slot-scope='{row,column}'
                                       v-model='row[column.property]' type='textarea'
                                       placeholder='描述该字段用途或者举例'>
                          <div slot='content'>
                            <div class='flex-sb'>
                              <span>{{ row[column.property] }}</span>
                              <i class='el-icon-edit default-edit-icon'></i>
                            </div>
                          </div>
                        </editable-cell>
                      </el-table-column>
                      <el-table-column label='操作' align='center' header-align='center' width='80'>
                        <template slot-scope='scope'>
                          <!--                                      <el-button type='text' @click='editRow(scope.row,scope.$index)'><i-->
                          <!--                                        class='el-icon-edit'></i>-->
                          <!--                                      </el-button>-->
                          <el-button type='text' @click='delRow(scope.row,scope.$index)'><i
                            class='el-icon-close'></i>
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </el-tab-pane>
        <el-tab-pane label='品类场次报价' name='multi' v-if='priceShowMap.multi'>
          <div class='box-shadow'>
            <el-table :data='quoteMulti.cols' border>
              <el-table-column label='标题' prop='title' align='center'></el-table-column>
              <el-table-column label='字段' prop='field' align='center'></el-table-column>
              <el-table-column label='说明' prop='placeholder' width='200'></el-table-column>
              <el-table-column label='录入方式' prop='type_alias'></el-table-column>
              <el-table-column label='选项' prop='options'>
                <template slot-scope='{row}'>
                  <div v-if='row.options'>
                            <span v-for='(option) in row.options' :key='option.value'>
                              {{ option.label || '' }}
                            </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label='专（混）场报价' name='mixed' v-if='priceShowMap.mixed'>
          <div v-if='quoteMixed.sri'>
            <h4>{{ quoteMixed.sri.label || '' }}</h4>
            <div>
              编辑方式： {{ quoteMixed.sri.type == 'richTxt' ? '富文本编辑器' : quoteMixed.sri.type }}
            </div>
          </div>
          <div v-if='quoteMixed.mfr'>
            <h4>{{ quoteMixed.mfr.label || '' }}</h4>
            <div>
              编辑方式： {{ quoteMixed.mfr.type == 'richTxt' ? '富文本编辑器' : quoteMixed.mfr.type }}}
            </div>
          </div>
          <div v-if='quoteMixed.pfc'>
            <h4>{{ quoteMixed.pfc.label || '' }}</h4>
            <div>
              编辑方式： {{ quoteMixed.pfc.type == 'table' ? '表格' : quoteMixed.pfc.type }}
            </div>
            <div style='padding: 10px'>
              <el-table :data='quoteMixed.pfc.cols' v-if='quoteMixed.pfc.cols' border>
                <el-table-column label='标题' prop='title' align='center'></el-table-column>
                <el-table-column label='字段' prop='field' align='center'></el-table-column>
                <el-table-column label='说明' prop='placeholder' width='200'></el-table-column>
                <el-table-column label='录入方式' prop='type_alias'></el-table-column>
                <el-table-column label='选项' prop='options'>
                  <template slot-scope='{row}'>
                    <div v-if='row.options'>
                            <span v-for='(option) in row.options' :key='option.value'>
                              {{ option.label || '' }}
                            </span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <!--    报价表单参考-->
    <el-dialog :visible.sync='dialogPriceQuickVisible' :append-to-body='true' width='700px'>
      <div class='demo-cards'>
        <div class='flex-left'>
          <h2>报价表单录入项</h2>
          <span style='margin-left: 10px'>推荐：{{ plcBase.name }}({{ plcBase.code }})</span>
        </div>
        <div v-for='(item,plc_code) in quoteMap' :key='plc_code'
             style='border-radius: 20px;margin-bottom: 20px' class='demo-card'>
          <el-link @click='selectDemo(item.cols)' type='primary' class='demo-select'>
            <span style='font-weight: bold;font-size: 18px'>{{ item.title || '' }}<i
              class='el-icon-connection'></i></span>
          </el-link>
          <el-table :data='item.cols' :key='plc_code' border>
            <el-table-column label='报价字段' prop='field' width='160' align='center'></el-table-column>
            <el-table-column label='报价标题' prop='title'></el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import plcMap from '@/json/plc.json'
import EditableCell from '@/components/EditableCell'
import { deepClone } from '@/utils'

export default {
  name: 'PlcQuoteSetting',
  components: { EditableCell },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    plcBase: {
      type: Object,
      default() {
        return { name: '', code: '' }
      }
    }
  },
  computed: {
    quoteMulti() {
      return this.quoteInfo.multi[this.formData.type] || []
    },
    quoteMixed() {
      return this.quoteInfo.mixed || {}
    },
    priceShowMap() {
      if (this.formData.type && this.customPriceMap) {
        return this.customPriceMap[this.formData.type] || {
          'multi': true,
          'single': true,
          'mixed': true,
          'showLast': true
        }
      } else
        return {
          'multi': true,
          'single': true,
          'mixed': true,
          'showLast': true
        }
    }
  },
  watch: {
    'formData.type': {
      handler(val) {
        let priceMap = this.customPriceMap[val]
        delete priceMap.showLast

        if (!priceMap[this.activePriceName]) {
          //遍历
          Object.keys(priceMap).some((key) => {
            if (priceMap[key]) {
              this.activePriceName = key
              return false
            }
          })
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      activePriceName: 'single',
      customPriceMap: plcMap.customPriceMap,
      quoteInfo: plcMap.quoteInfo,
      dialogPriceQuickVisible: false,
      quoteMap: plcMap.quoteInfo.single
    }
  },
  methods: {
    handleAdd() {
      this.dialogPriceVisible = true

    },
    delRow(row, index) {
      this.formData.platform_quote.splice(index, 1)
      this.handleSave()
    },
    handleSave() {
      this.$emit('handleSave', 'platform_quote')
    },
    /**
     * 获取报价配置
     * @returns {Promise<void>}
     */
    async getQuoteSetting() {
      let { quote_setting } = await this.$api.getPublicationSetting({ quote_type: 'platform_quote' })
      this.quoteMap = quote_setting
      console.log(JSON.stringify(quote_setting))
    },
    async showDefaultDemos() {
      // await this.getQuoteSetting()
      this.dialogPriceQuickVisible = true
    },
    selectDemo(rows) {
      this.$confirm('确认选择，此操作将替换原有的视频时长报价配置？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(async () => {
        //选用案例
        this.formData.platform_quote = deepClone(rows)

        await this.handleSave
        this.dialogPriceQuickVisible = false
      }).catch(() => {
        this.$notify.info('已取消选择')
      })

      // this.calcColumns()
      // scrollTo(0, 800)
    }
  },
  created() {

  }

}
</script>

<style scoped>
.demo-cards {
  padding: 10px 20px;
  /*background-image: linear-gradient(-45deg, var( --warning) 0%, green 20%, blue 40%, var(--primary) 100%);*/
  color: #303133;
  /*mix-blend-mode: difference;*/
  border: #f2f2f2 1px dashed;
}

.demo-select {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 800;
  color: #303133;

}

.demo-card {
  border-radius: 8px;
  border: #f2f2f2 1px dashed;
  padding: 20px 15px;
}
</style>
