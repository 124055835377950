import * as request from '@/utils/request'

export function optionsEmphasisProductsCategory(data) {
  return request.post('/emphasis_products_category/options', data)
}

export function subOptionsEmphasisProductsCategory(data) {
  return request.post('/emphasis_products_category/sub_options', data)
}


/**
 * 分类列表
 * @param data
 * @returns {*}
 */
export function emphasisProductsCategoryList(data) {
  return request.post('/emphasis_products_category/list', data)
}

export function emphasisProductsCategoryOptions(data) {
  return request.post('/emphasis_products_category/options', data)
}


/**
 * 分类保存
 * @param data
 * @returns {*}
 */
export function emphasisProductsCategorySave(data) {
  return request.post('/emphasis_products_category/store', data)
}

/**
 * 分类删除
 * @param data
 * @returns {*}
 */
export function emphasisProductsCategoryDelete(data) {
  return request.post('/emphasis_products_category/delete', data)
}
