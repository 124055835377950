<template>
  <div class='main-page-content'>
    <CoverSettingView ref='refCoverSettingView' v-if='id' />
  </div>
</template>

<script>
import CoverSettingView from '@/pages/plc/detail/fragment/CoverSettingView'

export default {
  name: 'CoverSetting',
  components: { CoverSettingView },
  data() {
    return {
      id: null
    }
  },
  methods: {
    getInfo() {
      this.id = this.$route.query.id
      if (this.id) {

        this.$nextTick(() => {
          if (this.$refs['refCoverSettingView'])
            this.$refs['refCoverSettingView'].show(this.id)

        })

      } else {
        this.$notify.info('初始化')
      }
    }
  },
  async mounted() {
    this.id = this.$route.query.id
    await this.getInfo()
  }
}
</script>

<style scoped>

</style>
