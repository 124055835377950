<template>
  <el-table :data="tableData" border style="width: 80%;margin: 20px auto;">
    <el-table-column type="index" label="序号" width="55" />
    <el-table-column prop="artist_nickname" label="红人" />
    <el-table-column prop="platform_name" label="平台" />
    <el-table-column prop="date" label="排期" />
    <el-table-column prop="charge_name" label="负责人" />
    <el-table-column label="操作">
      <template v-slot="{row}">
        <emphasis-products-sch-save
          v-if='hasAuthority("emphasis_products_sch__save")'
          is-edit
          :key="'sch-save-edit'+emphasisProductsId+'_'+row.id"
          :obj="row"
          :emphasis-products-id="emphasisProductsId"
          @success="getList"
        />
        <el-button v-if='hasAuthority("emphasis_products_sch__delete")' type="text" @click="handleDelete(row)">删除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { deleteEmphasisProductsSchedule, getEmphasisProductsScheduleList } from '../../../../../api/emphasis-products'
import EmphasisProductsSchSave from './save.vue'

export default {
  name: 'EmphasisProductsSchList',
  components: { EmphasisProductsSchSave },
  props: {
    emphasisProductsId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tableData: [{}, {}]
    }
  },
  created() {
    console.log('EmphasisProductsSchList created')
    this.getList()
  },
  methods: {
    getList: async function() {
      const { list } = await getEmphasisProductsScheduleList({ emphasis_products_id: this.emphasisProductsId })
      this.tableData = list || []
    },
    handleDelete: async function({ id }) {
      this.$confirm(`确认排期吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteEmphasisProductsSchedule({ id })
        this.$message.success('删除成功')
        await this.getList()
      }).catch(() => {

      })
    }
  }
}
</script>
<style lang="scss">

</style>
