<template>
  <div>
    <el-drawer
      :title='drawerData.title'
      :visible.sync='drawerData.visible'
      direction='rtl'
      :size='drawerData.width_height'
    >
      <template slot='title'><span style='font-weight: bold;font-size: 1.2em'>{{ drawerData.title }}</span></template>

      <el-form ref='elForm' :model='formData' :rules='rules' label-width='120px' label-suffix='：'>
        <!--      基本信息-->
        <div class='main_content'>
          <div class='top_box'>
            <p class='top_box__title'>基本信息 （{{ formData.year }}年{{ formData.month }}月 {{ platformInfo.name }}刊例）
              <el-link v-if="userPermissions.indexOf('platform_setting')>-1" type='primary'
                       style='float: right;right: 30px' icon='el-icon-setting'
                       @click='jumpToSettingPage(platformInfo)'></el-link>
            </p>
          </div>
          <div v-if="formData.id&&userPermissions.indexOf('plc_item_edit')>-1"
               style='line-height: 30px;display: flex;flex-direction: row;gap: 10px;justify-content: flex-end;border-bottom: #f2f2f2 1px solid;margin-bottom: 10px'>
            <el-link style=' padding: 3px 0' type='primary' v-if='!editBase'
                     @click='editBase = true'><i
              class='el-icon-edit-outline'></i>修改
            </el-link>
            <div v-else>
              <el-link style='padding: 3px 0;margin-right: 5px' type='default' @click='editBase=false'><i
                class='el-icon-close'></i>取消
              </el-link> &nbsp;&nbsp;
              <el-link style=' padding: 3px 0;margin-right: 5px' type='success' @click='saveBaseBtn'><i
                class='el-icon-finished'></i>保存
              </el-link>
            </div>
          </div>
          <el-row :gutter='20'>
            <el-col :span='12'>
              <div class='box-kol'>
                <el-form-item label='红人' prop='nickname' :label-width='label_width'>
                  <div style='display: flex;flex-direction: row;justify-content: space-between;gap: 5px'>
                    <span v-if='!editBase'>{{ formData.nickname }} </span>
                    <div v-else style='width: 100%;display: flex;flex-direction: row;gap: 5px'>
                      <div style='width: 300px'>
                        <ArtistSearch style='width: 90%;' :name.sync='formData.nickname' ref='refArtistSearch'
                                      @handleSelect='selectArtist' @clearSelect='selectArtist'></ArtistSearch>
                      </div>
                      <div>
                        {{ formData.nickname }}
                      </div>
                      <div>
                        <el-link icon='el-icon-close' @click='clearArtist'></el-link>
                      </div>
                    </div>

                  </div>
                </el-form-item>
                <el-form-item label='标签' :label-width='label_width'>
                  <el-radio-group v-model='formData.tag' style='width: 280px'>
                    <el-radio :label='tag.val' v-for='(tag,idx) in tagMap' :key='idx'
                              :disabled='!editBase'>
                      {{ tag.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label='刊例月份' :label-width='label_width' prop='ym'>
                  <template slot='label'>
                    <span style='color: #E6A23C;font-weight: 800'>刊例月份</span>
                  </template>
                  <span>{{ formData.year }} 年 {{ formData.month }} 月</span>
                </el-form-item>

                <el-form-item label='KOL简介' :label-width='label_width' prop='intro'>
                  <div class='form-intro'>{{ formData.intro }}</div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span='12'>
              <div class='box-kol' style='min-height: 350px;' v-if='formData.artist_id'>
                <div style='color: #fe346e;display: flex;flex-direction: row;gap: 5px;justify-content: space-between'>
                  <el-button type='text' icon='el-icon-refresh'
                             @click='getKolPlatformInfo'
                             class='link-btn' :loading='loading' :disabled='loading'
                             :title='`同步红人最新平台信息`' v-if='editBase'>同步
                  </el-button>

                  <div>
                    <i class='el-icon-info'></i> 数据同步自《红人平台信息》
                    <el-button type='text' icon='el-icon-edit-outline' v-if='formData.artist_id'
                               @click='goToArtistDetail(formData.artist_id)' class='link-btn'
                               :title='`编辑红人平台信息`'></el-button>
                  </div>
                </div>
                <el-form-item label='头像' :label-width='label_width'>
                  <el-avatar :size='60' :src='formData.cover_pc_url' alt='头像' v-if='formData.cover_pc_url'></el-avatar>
                </el-form-item>
                <el-form-item label='平台ID' :label-width='label_width' prop='account_id'>
                  {{ formData.account_id }}
                </el-form-item>
                <el-form-item label='所在地区' prop='city' :label-width='label_width'>
                  {{ formData.city }}
                </el-form-item>
                <el-form-item label='经纪人' :label-width='label_width' prop='agent_name'>
                  {{ formData.agent_name }}
                </el-form-item>
                <el-form-item label='主页链接' prop='home_page_link' :label-width='label_width'>
                  {{ formData.home_page_link }}

                  <el-link v-if='!editBase' type='primary' @click='openUrl(formData.home_page_link)'
                           icon='el-icon-link'></el-link>
                </el-form-item>
                <el-form-item label='红人肤质' :label-width='label_width'>
                  {{ formData.skin_type }}
                </el-form-item>
                <el-form-item label='红人标签' :label-width='label_width'>
                  {{ formData.kol_label }}
                </el-form-item>
              </div>
              <div v-else style='min-height: 300px;display: flex;align-items: center;justify-content: center;'>
                请先选择红人
              </div>
            </el-col>
          </el-row>

          <div ref='BaseForm' class='box-info' style='position: relative;'>
            <span v-if='editBase&&multiTypes.indexOf(platformType)>-1' class='sync-last-month'
                  @click='handleSyncLastMonth'>
              <i class='el-icon-refresh' />点击同步上月数据
            </span>
            <!--            刊例设置的表单渲染组件-->
            <div v-if='editBase'>
              <!--【容器组件：支持表单数据修改】-->
              <FormRender v-if='formRenderData && formRenderData.length>0'
                          :form-render-struct='formRenderData'
                          :form-data.sync='formData' :setting='setting'>
                <!--  ============== 自定义插槽：表单的个性化渲染 =========== -->
                <template v-slot:last_release_date>
                  <el-date-picker
                    v-model='formData.last_release_date'
                    type='date'
                    format='yyyy年MM月dd日'
                    value-format='yyyy-MM-dd'
                    placeholder='最近广告排期' :disabled='!editBase' clearable>
                  </el-date-picker>
                  <el-button type='text' icon='el-icon-refresh'
                             @click='getCurrentMonthData("last_release_date")'
                             class='link-btn' :loading='loading' :disabled='loading'
                             :title='`同步红人最近广告排期`'></el-button>
                </template>
                <template v-slot:platform>
                  <span
                    v-if='!editBase'>{{ formData.platform }}</span>
                  <PlatformNameSelect :style='`width: ${input_width}`' :multiple='false' v-else
                                      v-model='formData.platform'
                                      @change='handleChangePlatform' />

                </template>

                <template v-slot:platforms>
                  <PlatformNameSelect :style='`width: ${input_width}`' :multiple='true'
                                      v-model='formData.platforms'></PlatformNameSelect>
                </template>

                <template v-slot:fans_total>
                  <el-input v-if='editBase' v-model='formData.fans_total'
                            placeholder='单位：个 -> 万，例如：”1000000“ -> ”100“' style='width: 45%' clearable>
                    <el-button slot='append' icon='el-icon-refresh' :loading='loading'
                               @click='getFansData'
                               title='更新红人粉丝数'></el-button>
                  </el-input>
                  <span style='color: red;font-size: 0.9em;'>
                  {{ $utils.numberFormat(formData.fans_total / 10000, 2, '.', ',') }} 万</span>
                </template>
                <!--                自定义插槽：表单的个性化渲染-->
                <template v-slot:order_total>
                  <el-input type='number' v-model='formData.order_total'
                            placeholder='录入确定合作的单数' :disabled='!editBase'
                            :style='`width: ${input_width}`'
                            clearable>
                    <el-button slot='append' type='primary' icon='el-icon-refresh'
                               @click='syncFromAdSystem'
                               :loading='loading' :disabled='loading'
                               :title='`录入确定合作的单数`'></el-button>
                  </el-input>
                </template>
                <template v-slot:bs_case>
                  <ApeEditor :init-html='formData.bs_case'
                             @handleTinymceInput='(e)=>handleTinymceInput(e,"bs_case")'></ApeEditor>
                </template>
                <template v-slot:bs_case_two>
                  <ApeEditor :init-html='formData.bs_case_two'
                             @handleTinymceInput='(e)=>handleTinymceInput(e,"bs_case_two")'></ApeEditor>
                </template>
                <!--  ============== 自定义插槽：表单的个性化渲染 【结束】 =========== -->
              </FormRender>

            </div>
            <div v-else>
              <!--【展示组件：仅展示数据】-->
              <FormView v-if='formRenderData && formRenderData.length>0' :form-render-struct='formRenderData'
                        :form-data.sync='formData' :setting='setting' />
            </div>

          </div>
        </div>
        <!--        刊例提报报价信息-->
        <div style='padding: 20px'>
          <el-divider>
            <el-link type='primary' @click='showQuotePrice'>报价信息</el-link>
          </el-divider>
          <QuotePrice ref='quotePrice' :form-data.sync='formData' :platform-type='platformType'
                      @updated='emitUpdate'></QuotePrice>
        </div>

        <!--      提交提报-->
        <div class='submit-btn-row' v-if='!formData.id'>
          <el-button round size='medium' type='primary' @click='handleConfirm' class='submit-btn'
                     :disabled='loadingSb'
                     :loading='loadingSb' v-if="userPermissions.indexOf('plc_adjust')>-1">
            <i class='el-icon-check'></i> &nbsp;&nbsp;保存 - 刊例调整
          </el-button>
        </div>
      </el-form>

      <!--        刊例调整说明组件：：数据来自文章管理-->
      <PlcNotice v-if="userPermissions.indexOf('plc_notice')>-1" />

    </el-drawer>

    <!--    红人匹配   -->
    <ArtistMatch :visible.sync='dialogArtistMatchVisible' :id='formData.artist_id' />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import ArtistMatch from '@/pages/setting/artist/ArtistMatch'
import PlcNotice from '../PlcNotice'
import AdjustPricePanel from '@/pages/publication/adjust/AdjustPricePanel'
import ApeEditor from '@/components/ApeEditor'
import { html2json } from 'html2json'
import plcMap from '@/json/plc.json'
import PlatformNameSelect from '@/pages/publication/adjust/PlatformNameSelect'
import ArtistSearch from '@/components/artist/ArtistSearch'
import QuotePrice from '@/pages/publication/adjust/QuotePrice'
import FormRender from '@/pages/publication/compoents/FormRender'
import FormView from '@/pages/publication/compoents/FormView'

// 报价信息-数据结构
const quoteStruct = plcMap.form.quoteStruct

const formData = {
  year: null,
  month: null,
  nickname: '',
  artist_id: '',
  platform_code: null,
  platform_name: null,
  dept_id: '',
  dept_name: '',
  platform: '',
  extra_platform_code: '',
  account_id: '',
  home_page_link: '',
  agent_name: '',
  city: '',
  intro: '',
  bs_case: '',
  fans_total: null,//当月粉丝数
  order_total: null,//当月订单数
  next_order_total: null,//当月订单数
  last_release_date: null,//最近的广告排期
  pre_fans_total: null,//预计粉丝量
  current_quote: { ...quoteStruct },//当月报价（已定的报价）
  next_quote: { ...quoteStruct },//预估报价
  zc_total: null,//赞藏数（万）
  lb_time: null,//直播时间
  lb_case: null,//直播案例
  female_fans_ratio: null,//女粉比例
  avg_online: null,//平均在线
  cover: null,
  opened_star: 'N',
  opened_window: 'N',
  is_partner: 'N',
  opened_cart: 'N',
  cover_pc_url: '',
  fans_state: [
    {
      'fans_count': null,
      'platform_code': 'douyin',
      'platform_name': '抖音粉丝数'
    }, {
      'fans_count': null,
      'platform_code': 'xiaohongshu',
      'platform_name': '小红书粉丝数'
    }, { 'fans_count': null, 'platform_code': 'kuaishou', 'platform_name': '快手粉丝数' }, {
      'fans_count': null,
      'platform_code': 'weibo',
      'platform_name': '微博粉丝数'
    }, { 'fans_count': null, 'platform_code': 'bilibili', 'platform_name': 'B站粉丝数' }, {
      'fans_count': null,
      'platform_code': 'weixin_vedio',
      'platform_name': '视频号粉丝数'
    }
  ],//各个平台粉数数
  //案例富文本
  bs_case_rich: '',
  //红人标签
  kol_label: '',
  //直播频次
  lf: '',
  //百应ID/UID
  by_uid: '',
  //直播业绩
  lsp: '',
  //红人肤质
  skin_type: ''
}

export default {
  name: 'AdjustCreateEdit',
  components: {
    FormView,
    FormRender,
    QuotePrice,
    ArtistSearch,
    PlatformNameSelect,
    AdjustPricePanel,
    ArtistMatch,
    ArtistSearchCustom,
    PlcNotice,
    ApeEditor
  },
  computed: {
    ...mapGetters(['userPermissions']),
    pid() {
      return this.plcInfo.id || null
    },
    platformInfo() {
      return this.plcInfo.platform_info || {}
    },
    year() {
      return this.plcInfo.year || null
    },
    month() {
      return this.plcInfo.month || null
    },
    platformCode() {
      return this.plcInfo.platform_code || null
    },
    platformType() {
      return this.setting.type || 'ONLINE'
    },
    //可查看的字段范围：不同平台配置不同的字段显示
    fieldRange() {
      let common_fields = this.setting.common_fields || []
      // let extra_fields = this.setting.extra_fields || []
      return [...common_fields]
    },
    typeMap() {
      let map = {}
      plcMap.typeMap.forEach((item) => {
        map[item.val] = item.title
      })
      return map
    },
    showPriceBox() {
      //直播和日播不显示 报价信息（展示仅需展示关联平台的报价即可）
      return true
    },
    //表单内容
    formRenderData() {
      let cols = []

      plcMap.common_cols.forEach((f) => {
        //&& this.fieldRange[f.field]
        if (f && this.fieldRange.indexOf(f.field) > -1) {
          cols.push(f)
        }
      })
      return cols
    },
    tagMap() {
      let result = [
        { label: '无', val: '' },
        { label: 'New', val: 'new' }
      ]
      if (this.userPermissions.indexOf('plc_save_quote') > -1) {
        result = [
          { label: '无', val: '' },
          { label: 'NEW', val: 'new' },
          { label: '惠', val: 'benefit' },
          { label: '荐', val: 'recommend' }
        ]
      }

      return result
    }
  },
  data() {
    return {
      title: '刊例提报',
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '刊例录入',
        width_height: '80%',
        show_footer: false,
        // mask: false,
        close_name: '关 闭'
        // confirm_name: '打 印',
      },
      showPlcSetting: false,
      id: null,//编辑对象（提报信息）
      loadingSb: false,
      loadingForm: false,
      loadingOrderTotal: false,
      // setting: {},//配置信息
      label_width: '150px',
      input_width: '360px',
      formData: { ...{}, ...formData },
      rules: {
        nickname: [{ required: true, message: '红人必选', trigger: 'change' }],
        account_id: [{ required: true, message: '平台ID必填', trigger: 'change' }],
        home_page_link: [{ required: true, message: '主页链接必填', trigger: 'change' }],
        agent_name: [{ required: true, message: '负责经纪人必填', trigger: 'change' }],
        city: [{ required: true, message: '所在地区必填', trigger: 'change' }],
        // platform_code: [{required: true, message: '平台必选', trigger: 'blur'}],
        zc_total: [{ required: true, message: '请输入赞藏数必填', trigger: 'change' }],
        fans_total: [{ required: true, message: '请输入当月粉丝数', trigger: 'change' }],
        order_total: [{ required: true, message: '请输入当月订单数', trigger: 'change' }],
        next_order_total: [{ required: true, message: '请输入下月订单数', trigger: 'change' }],
        last_release_date: [{ required: true, message: '最近的广告排期', trigger: 'change' }],
        pre_fans_total: [{ required: true, message: '下月底预估粉丝量', trigger: 'change' }],
        intro: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        platform: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        platforms: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        lb_case: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        female_fans_ratio: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        avg_online: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        lsp: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        lf: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        by_uid: [{ required: true, message: '必填', trigger: ['change', 'blur'] }],
        current_quote: [{ required: true, message: '当月价格', trigger: 'blur' }]
      },
      searchCondition: {},
      platformRange: [],//可选平台
      platforms: [],//平台信息
      loading: false,
      multiTypes: ['DAILY', 'LIVE'],//多平台类型,目前 日播和直播KOL可选多平台
      quoteMap: {},
      dialogArtistMatchVisible: false,//红人匹配
      editBase: false,//修改基本
      //平台视频时长报价（直播刊例，来自所选平台的视频报价结构）
      platformQuoteArr: [],
      plcInfo: {},//刊例基本信息
      setting: {}//刊例配置
    }
  },
  methods: {
    // 刊例配置
    async loadSetting() {
      if (this.platformCode) {
        let { info } = await this.$api.getPlatformSettingInfoByPlatformCode(this.platformCode)
        this.setting = info || {}
        this.$notify.info('刊例配置已加载')
      }
    },
    //编辑器
    handleTinymceInput(val, field) {
      this.formData[field] = val
    },
    onClose() {
      this.drawerData.visible = false
    },
    async add(plcInfo) {
      // 显示弹窗（抽屉）
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `刊例提报-录入`
      this.drawerData.visible = true
      this.drawerData.loading = false
      this.plcInfo = { ...plcInfo }
      this.editBase = true
      this.id = null

      // 加载平台可选项
      await this.getPlatforms()
      // 加载刊例配置
      await this.getPlcSetting(true)
      // 初始化表单
      await this.initForm(plcInfo)

      //组件渲染后，初始化
      // 等待 Vue 完成 DOM 更新
      await this.$nextTick()

      // 清空红人选项
      if (this.$refs['refArtistSearch'])
        this.$refs['refArtistSearch'].clearData()

      //  显示报价信息
      this.showQuotePrice()
      // 默认加一行报价信息
      if (this.$refs['quotePrice'])
        await this.$refs['quotePrice'].showMultiFirst()
      else {
        this.$notify.info('未初始化报价')
      }
    },
    async edit(plcInfo, row) {
      this.drawerData.visible = true
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `刊例提报-修改`
      this.drawerData.loading = false
      this.editBase = false
      this.plcInfo = { ...plcInfo }
      await this.$nextTick()
      // 初始化表单
      await this.getInfo(row.id)

      // 加载平台可选项
      await this.getPlatforms()
      // 加载刊例配置
      await this.getPlcSetting(true)
      //  显示报价信息
      await this.$nextTick()
      //  显示报价信息
      this.showQuotePrice()
    },
    initForm(plcInfo) {
      this.formData = { ...{}, ...formData }
      this.formData['pid'] = this.pid
      this.formData.year = plcInfo.year
      this.formData.month = plcInfo.month
      this.formData.platform_code = plcInfo.platform_code
      this.formData.platform_name = plcInfo.platform_info.name
      if (this.formData.id) {
        this.$notify.warning('表单结构异常，已临时纠正，请联系管理员检查！！！')
        delete this.formData.id
      }
    },
    clearArtist() {
      if (this.$refs['refArtistSearch'])
        this.$refs['refArtistSearch'].clearSelect()
    },

    // 获取红人该平台的基本信息（）
    async getKolPlatformInfo() {
      if (!this.formData.artist_id) {
        this.$notify.warning('红人未知')
      }
      if (!this.platformInfo.code) {
        this.$notify.warning('平台未选')
      }
      // this.$notify.info(`${this.platformInfo.code} - ${this.formData.artist_id}`)
      if (this.platformInfo.code && this.formData.artist_id) {
        this.formData.cover = null
        this.formData.cover_pc_url = null
        this.formData.home_page_link = null
        this.formData.account_id = null
        this.formData.agent_name = null
        this.formData.city = null
        this.loading = true
        let { info } = await this.$api.getArtistPlatformInfo({
          code: this.platformInfo.code,
          artist_id: this.formData.artist_id
        })

        this.formData.home_page_link = info.home_page_link
        this.formData.account_id = info.account_id
        this.formData.agent_name = info.agent_name
        this.formData.city = info.city
        this.formData.intro = info.intro
        this.formData.cover = info.cover
        this.formData.kol_label = info.kol_label
        this.formData.skin_type = info.skin_type
        this.formData.cover_pc_url = info.cover_pc_url
        this.loading = false
      }
    },

    async selectArtist(selectInfo) {
      if (selectInfo) {
        this.formData.nickname = selectInfo.nickname
        this.formData.artist_id = selectInfo.id
        this.formData.dept_id = selectInfo.group_id
        this.formData.dept_name = selectInfo.group_name
        //切换红人-期基本信息清空
        this.formData.home_page_link = ''
        this.formData.account_id = ''
        this.formData.agent_name = ''
        this.formData.city = ''
        this.formData.intro = ''
        this.formData.cover = 0
        this.formData.cover_pc_url = null
        //红人平台信息
        await this.getKolPlatformInfo()
        //日播达播不需要加载上月报价
        await this.copyLastInfo()
      } else {
        this.formData.nickname = null
        this.formData.artist_id = null
      }
    },

    //获取平台选择范围
    async getPlatformRange() {
      if (this.formData.artist_id) {
        let { info } = await this.$api.getArtistInfo(this.formData.artist_id)
        this.platformRange = []
        if (info.platforms) {
          let objs = info.platforms
          let codes = Object.keys(objs)
          for (let p in codes) {
            let code = codes[p]
            let val = objs[code]
            if (val === 'Y')
              this.platformRange.push(code)
          }
        }
      }
    },

    async handleChangePlatform(val) {
      //若需要 platform_code 和 platform_name 替换成平台
      if (val) {
        await this.copyLastInfo()//同步上月信息
      } else {
        this.formData.extra_platform_code = ''
      }
    },
    //获取平台配置信息
    async getPlcSetting(needCalc) {
      await this.loadSetting(this.platformType)

      //平台的报价信息
      if (needCalc) {
        //添加刊例的时候直接组装报价录入表格
        this.calcPlatformQuote()
      }
    },
    async initFansState() {
      if (this.platformType === 'OFFLINE') {
        let fansState = []
        this.platforms.forEach((item) => {
          fansState.push({ platform_name: `${item.name}粉丝数`, platform_code: item.code, fans_count: null })
        })
        this.formData.fans_state = fansState
      }
    },
    calcPlatformQuote() {
      // 直播和日播不需要
      let platformQuoteArr = this.platformQuoteArr
      if (platformQuoteArr.length > 0) {
        //组装
        this.formData.current_quote = {
          single: [],
          multi: []
        }

        this.formData.next_quote = {
          single: [],
          multi: []
        }

        if (platformQuoteArr && platformQuoteArr.length > 0) {
          platformQuoteArr.forEach((item) => {
            this.formData.current_quote.single.push(Object.assign({}, item, { remark: '' }))
            this.formData.next_quote.single.push(Object.assign({}, item, { remark: '' }))
          })
        }
      }
    },

    openUrl(href) {
      window.open(href, '_blank')
    },
    // 响应编辑按钮
    goToArtistDetail(id) {
      const { href } = this.$router.resolve({
        name: 'artist-edit',
        params: { artist_id: id }
      })
      // this.$router.push(this.$route.path + '/' + id + '/edit')
      window.open(href, '_blank')
    },
    //调整到品配置
    jumpToSettingPage(row) {
      let id = row.id
      const { href } = this.$router.resolve({
        name: 'platform_plc_set',
        params: { platform_id: id }
      })
      // this.$router.push(this.$route.path + '/' + id + '/edit')
      window.open(href, '_blank')
    },
    handleConfirm() {
      // console.log(this.formData)
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        this.onSubmit()
      })
    },
    async onSubmit() {
      this.loadingSb = true
      setTimeout(() => {
        this.loadingSb = false
      }, 1000)

      let { current_quote, next_quote } = this.$refs['quotePrice'].getCurrentForm()
      this.formData.current_quote = current_quote
      this.formData.next_quote = next_quote
      // 富文本处理
      this.formData.bs_case_rich = this.formData.bs_case ? html2json(this.formData.bs_case) : ''

      let extra_json = { ...this.formData }
      delete extra_json.extra_info
      delete extra_json.current_quote
      delete extra_json.next_quote
      delete extra_json.real_quote
      delete extra_json.bs_case
      delete extra_json.bs_case_rich

      extra_json['type'] = this.platformType
      if (this.formData.bs_case_two) {
        extra_json.bs_case_two_rich = html2json(this.formData.bs_case_two)
      }
      let id = await this.$api.savePublicationItem(Object.assign(this.formData, { extra_info: extra_json }))
      if (id) {
        this.$notify.success('保存成功')
        localStorage.removeItem('plc-form')
        this.$emit('saved')
        this.loadingSb = false
        this.onClose()
      }
    },
    async getInfo(id) {
      // this.$notify.info(`ID:${id}`)
      this.loadingForm = true
      let { info } = await this.$api.getPublicationItemInfo(id)
      if (info) {
        // console.log('info', info)
        //表单数据
        this.formData = info || {}
        //获取平台选择范围
        this.loadingForm = false
        //平台报价配置信息和额外字段
        // await this.getPlcSetting(false)
      }
    },
    async syncFromAdSystem() {
      this.loadingOrderTotal = true
      if (this.formData.platform_code && this.formData.artist_id && this.formData.year && this.formData.month) {
        let param = {
          artist_id: this.formData.artist_id,
          platform_code: this.formData.platform_code,
          year: this.formData.year,
          month: this.formData.month
        }

        let { total, code } = await this.$api.getPublicationOrderTotal(param)
        this.loadingOrderTotal = false
        if (code === 200) {
          if (total != undefined) {
            //同步自广告系统
            this.$confirm(`红人(KOL)【${this.formData.nickname}】的广告订单数为 ${total} (数据来自星广汇系统), 是否同步?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$nextTick(() => {
                this.formData.order_total = total
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消同步'
              })
            })
          }
        } else if (code === 404) {
          this.$confirm(`红人(KOL)【${this.formData.nickname}】未与星广汇系统匹配， 是否前往匹配?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.dialogArtistMatchVisible = true
            // this.goToArtistDetail(this.formData.artist_id)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        }
      } else {
        this.$notify.warning('请先选择红人、平台、月份！！！')
        this.loadingOrderTotal = false
      }
    },
    delRow() {
      this.$confirm(`确定删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deletePublicationItem(this.formData.id)
        if (info) {
          this.$notify.success('删除成功')
          this.$emit('deleted')
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    async copyLastInfo() {
      //添加提报的时候同步上月信息：商务案例、当月价格（同步自上月的定价）
      if (this.platformType === 'LIVE' && !this.formData.extra_platform_code) {
        // 直播不同步
        return
      }
      if (!this.formData.id && this.formData.artist_id && this.platformInfo.code) {

        const { info } = await this.$api.getLastPublicationItem({
          artist_id: this.formData.artist_id,
          year: this.formData.year,
          month: this.formData.month,
          platform_code: this.platformInfo.code,
          extra_platform_code: this.formData.extra_platform_code
        })
        console.log('last info', info)
      }
    },
    showQuotePrice() {
      this.$nextTick(() => {
        if (this.$refs['quotePrice']) {
          this.$refs['quotePrice'].show(this.setting, this.formData)
        } else
          this.$notify.warning('报价信息展示异常')
      })
    },
    // eslint-disable-next-line no-unused-vars
    async getCurrentMonthData(fields) {
      this.$notify.info('同步红人粉丝数和最近排期')
      this.formData.fans_total = null
      this.formData.last_release_date = null
      if (this.formData.platform_code && this.formData.artist_id && this.formData.year && this.formData.month) {
        let { fans_total, last_release_date } = await this.$api.getPublicationCurrentData({
          artist_id: this.formData.artist_id,
          platform_code: this.formData.platform_code,
          year: this.formData.year,
          month: this.formData.month
        })
        this.$nextTick(() => {
          if (fields.indexOf('fans_total') > -1)
            this.formData.fans_total = fans_total

          if (fields.indexOf('last_release_date') > -1)
            this.formData.last_release_date = last_release_date
        })
      } else {
        this.$notify.warning('红人平台未知，无法同步，请手动录入！！！')
      }
    },
    //各个平台粉数数量获取
    async getFansData() {
      this.loading = true
      if (this.formData.artist_id && this.formData.year && this.formData.month) {
        let { data_fans } = await this.$api.getFansData({
          artist_id: this.formData.artist_id,
          year: this.formData.year,
          month: this.formData.month
        })
        let fans_total = 0
        if (data_fans && data_fans.length > 0) {
          data_fans.forEach((item) => {
            let index = this.formData.fans_state.findIndex(value => value.platform_code === item.platform_code)
            if (index > -1) {
              // console.log(`${ index } item`, item)
              this.formData.fans_state[index]['fans_count'] = item.fans_count || 0
              fans_total += item.fans_count * 1
            }
          })
          this.formData.fans_total = fans_total
        } else {
          this.$notify.warning('平台粉丝信息为空')
        }
      }

      this.loading = false
    },
    //申请改价
    // async applyEditPrice() {
    //   let { info } = await this.$api.applyEditPricePublicationItem({ id: this.formData.id })
    //   if (info)
    //     this.msgSuccess('已申请改价')
    // },

    //修改保存-刊例基本信息
    async saveBaseBtn() {
      this.editBase = false
      let extra_json = { ...this.formData }
      delete extra_json.extra_info
      delete extra_json.current_quote
      delete extra_json.next_quote
      delete extra_json.real_quote
      delete extra_json.bs_case
      delete extra_json.bs_case_rich
      delete extra_json.id

      extra_json.type = this.platformType
      //修改基本信息
      let data = Object.assign({}, this.formData, { extra_info: extra_json })
      delete data.current_quote
      delete data.next_quote
      delete data.real_quote
      let info = await this.$api.saveBasePublicationItem(data)
      if (info)
        this.msgSuccess('保存成功')

      this.$emit('updated')
      this.onClose()
    },
    async getPlatforms() {
      let { list } = await this.$api.getPlatforms()
      this.platforms = list || []
    },
    handleSelectPlatform(val) {
      if (val) {
        this.formData.platform_code = val.code
        this.formData.platform_name = val.name
        this.getKolPlatformInfo()
      }

    },
    // eslint-disable-next-line
    calcColName(field, title) {
      let columns = this.setting.columns_data
      let obj = columns.find(value => value.field === field)
      return obj ? obj.title : title
    },
    async copyQuote() {
      const _this = this
      const dom = _this.$refs.BaseForm
      const nodes = dom.querySelectorAll('.el-form-item__label')
      const fields = []
      for (let i = 0; i < nodes.length; i++) {
        const tmp = nodes[i]
        const field = tmp.getAttribute('for') || false
        if (field) {
          fields.push(field)
        }
      }

      const { info } = await this.$api.getLastPublicationItem({
        artist_id: this.formData.artist_id,
        year: this.formData.year,
        month: this.formData.month,
        platform_code: this.platformInfo.code,
        extra_platform_code: this.formData.extra_platform_code
      })
      if (!info) {
        // 不同步
        this.$notify.warning('上月未提报！')
        return
      }
      // // 上个月的定价
      // if (info.real_quote) {
      //   // 同步上月定价为本月价格
      //   this.handleQuote(info.real_quote || {})
      //   // deepClone(info.real_quote)
      //   this.$notify.success('报价已同步')
      // } else {
      //   this.$notify.warning('上月未定价！')
      // }

      // 同步数据
      fields.map(field => {
        _this.formData[field] = info ? info[field] : ''
      })
      _this.$message.success('已同步')
    },
    handleSyncLastMonth: async function() {
      const _this = this
      if (!this.formData.artist_id) {
        _this.$message.warning('请先选择红人')
        return
      }
      _this.$confirm(`确认同步上月数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await _this.copyQuote()
      }).catch(() => {

      })
    },
    emitUpdate() {
      this.$notify.success('提交修改')
      // this.formData[field] = val
      this.$emit('updated')
    }
  }
}
</script>

<style scoped lang='scss'>
.box-panel {
  padding: 10px;
}

.box-panel .search-box {
  padding: 10px;
  border-radius: 8px;
  border: #f2f2f2 1px solid;
  margin-bottom: 8px;
}

//
.box-panel .base-box, .price-box {
  border: #f2f2f2 1px solid;
  padding: 10px;
  border-radius: 4px;
}

.flex-start {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start
}

.border-effect {
  border: #f2f2f2 1px solid;
  padding: 10px 0;
  margin-bottom: 5px;
}

.sub-title {
  width: 200px;
  padding: 5px 10px;
  border: #f2f2f2 1px solid;
  //color: #f2f2f2;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 5px;
  display: none;
}

.service-status::before {
  content: "服务开通情况";
  line-height: 20px;
  padding: 5px 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  border: #f2f2f2 1px solid;
  border-radius: 5px;
}

/*.price-box {*/
/*  border: #f2f2f2 1px solid;*/
/*  padding: 10px;*/
/*  border-radius: 4px;*/
/*}*/

.submit-btn-row {
  text-align: center;
  margin-top: 10px;
  border-top: #f2f2f2 1px solid;
  padding: 10px;
}

.link-btn {
  /*color: #ff3176 !important;*/
  font-size: 16px !important;
  /*background-color: #ff3176!important;*/
  /*border: 1px solid #ff3176!important;*/
}

.is-label {
  display: inline-block;
  font-weight: 600;
  text-align: right;
  margin-right: 5px;
  font-size: 0.8rem;
}

.intro, .bs_case {
  /**
  首行缩进
   */
  text-indent: 2em;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fl-row * {
  box-sizing: border-box;
}

.fl-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  //border: #f2f2f2 1px solid;
}

.fl-row .fl-col {
  width: 50%;
  //border: #f2f2f2 1px solid;
}

.box-kol, .box-info {
  border: #f2f2f2 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.sync-last-month {
  position: absolute;
  right: 24px;
  top: -10px;
  background: #fff;
  padding: 0 10px;
  cursor: pointer;
  color: #ff3176;

  i {
    margin-right: 4px;
  }
}

.main_content {
  border: 1px solid #DCDFE6;
  padding: 10px;
  width: 94%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.main {
  background: #ffffff;
  border-radius: 20px;
  color: rgba(0, 0, 0, .85);
  padding: 10px 16px;
  margin-bottom: 10px;
  margin-top: 0;

  &__title {
    color: black;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    padding-left: 12px;
    //margin-bottom: 18px;
  }

  &__title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #DB4D83;
    border-radius: 4px;
  }
}

.top_box {
  background: #ffffff;
  border-radius: 20px;

  &__title {
    color: black;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
  }

  &__title::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 8px;
    height: 8px;
    border: 4px solid #3590CC;
    background-color: white;
    border-radius: 50%;
  }
}


.form-intro {
  box-sizing: border-box;
  height: 200px;
  width: 96%;
  padding: 1px 5px;
  border: #f2f2f2 1px solid;
}
</style>
