// eslint-disable-next-line no-unused-vars
function getDropNode(node) {
  while (node) {
    if (node.dataset.drop) {
      return node
    }
    node = node.parentNode
  }

}

// eslint-disable-next-line no-unused-vars
function clearDropStyle() {
  const dropNodes = document.querySelectorAll('.drop-over')
  dropNodes.forEach((node) => {
    node.classList.remove('drop-over')
  })
}



/**
 * 拖拽区域容器事件绑定
 * @param el  document.querySelector('.drag-container')
 */
export function dragContainerBind(el, callback) {
  let draggedObj = null
  let container = document.querySelector('.drag-container')
//开始
  container.ondragstart = (e) => {
    // e.preventDefault()
    e.dataTransfer.effectAllowed = e.target.dataset.effect
    e.dataTransfer.dropEffect = "move"
    draggedObj = e.target
  }

// over
  container.ondragover = (e) => {
    e.preventDefault()
  }

// 进入
  // eslint-disable-next-line no-unused-vars
  container.ondragenter = (e) => {
    e.preventDefault()
    // clearDropStyle()
    // const dropNode = getDropNode(e.target)
    // if (!dropNode) {
    //   return
    // }
    // if (e.dataTransfer.effectAllowed === dropNode.dataset.drop) {
    //   dropNode.classList.add('drop-over')
    // }
  }

  // 落下
  container.ondrop = (e) => {
    e.preventDefault()
    // console.log('drop', e.target.dataset)
    // console.log('被拖拽对象：', draggedObj)
    if (typeof callback === 'function')
      callback(draggedObj)
  }
}
