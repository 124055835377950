<template>
  <el-dialog :title="`${cmd==='add'?'新增':'编辑'}类目`" :visible.sync='visible' width='500px' center
             custom-class='save-category-sub-dialog' append-to-body modal-append-to-body>
    <el-form ref='form' :model='form' :rules='rules' label-width='60px' size='medium'>
      <el-form-item label='品类' prop='category_id'>
        <el-select v-model='form.category_id' :disabled="cmd==='edit'" placeholder='请选择品类' filterable
                   style='width: 100%;'>
          <el-option v-for='option in options' :key='option.id' :label='option.label' :value='option.id' />
        </el-select>
      </el-form-item>
      <el-form-item v-for='(child,child_i) in form.children'
                    :key='child_i' :label="child_i===0?'类目':''" :prop='`children[${child_i}].display_name`'
                    :rules="{ required: true, message: '请输入类目名称', trigger: 'blur' }">
        <el-input v-model='child.display_name' placeholder='请输入类目名称' maxlength='8' show-word-limit
                  style='width: calc(100% - 46px)' />
        <template v-if="cmd==='add'">
          <el-button v-if='child_i===(form.children.length-1)' type='primary' icon='el-icon-plus' circle
                     style='margin-left: 10px' @click='handleAddChild' />
          <el-button v-else icon='el-icon-minus' circle
                     style='margin-left: 10px' @click='form.children.splice(child_i,1)' />
        </template>
      </el-form-item>
    </el-form>
    <div slot='footer' class='dialog-footer'>
      <el-button @click='visible = false' size='medium'>取 消</el-button>
      <el-button type='primary' @click='handleSubmit' size='medium'>确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>

import {
  optionsPopularCasesCategory,
  popularCasesCategorySave
} from '@/api/popular_case'
import { emphasisProductsCategorySave, optionsEmphasisProductsCategory } from '../../api/emphasis_products_category'

const ModelForm = {
  category_id: null,
  category_name: null,
  children: [{ display_name: null }]
}
const rules = {
  category_id: [{ required: true, message: '请选择品类', trigger: 'change' }]
}
export default {
  name: 'SaveCategorySub',
  props: {
    tbl: {
      type: String,
      default() {
        return 'popular_cases_category'
      }
    }
  },
  data() {
    return {
      rules,
      api: {
        options: optionsPopularCasesCategory,
        save: popularCasesCategorySave
      },
      visible: false,
      options: [],
      cmd: 'add',// add|edit
      form: { ...{}, ...ModelForm }
    }
  },
  watch: {
    'tbl': {
      immediate: true,
      handler(_) {
        if (_ === 'popular_cases_category') {
          // 爆款案例
          this.api = {
            options: optionsPopularCasesCategory,
            save: popularCasesCategorySave
          }
        } else {
          // 重点大全案品
          this.api = {
            options: optionsEmphasisProductsCategory,
            save: emphasisProductsCategorySave
          }
        }
        this.getCategories()
      }
    }
  },
  methods: {
    /**
     * 新增
     * 给父组件用
     * @param category_id
     */
    interfaceAdd: async function(category_id = null) {
      await this.getCategories()
      this.cmd = 'add'
      this.form = { ...{}, ...ModelForm }
      this.form.category_id = category_id
      this.form.children = [{ display_name: null }]
      this.visible = true
    },
    /**
     * 编辑
     * 给父组件用
     * @param category_id
     * @param display_name
     */
    interfaceEdit: function(category_id = null, display_name, id) {
      this.cmd = 'edit'
      this.form = { ...{}, ...ModelForm }
      this.form.category_id = category_id
      this.form.children = [{ display_name, id }]
      this.visible = true
    },
    getCategories: async function() {
      const response = await this.api.options({ parent_id: 0 })
      this.options = Array.isArray(response) ? response : []
    },
    handleAddChild: function() {
      this.form.children.push({ display_name: null })
    },
    handleSubmit: function() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await this.api.save(this.form)
          this.$message.success('操作成功')
          this.visible = false
          this.$emit('success')
        }
      })
    }
  }
}
</script>
<style lang='scss'>
.save-category-sub-dialog {
  border-radius: 20px;
}
</style>
