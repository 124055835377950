<template>
  <div style='display: flex;flex-direction: row;justify-content: flex-start;gap: 5px;align-items: flex-start'>
    <div>
      <div
        style='padding:2px;border: #f2f2f2 1px dashed;margin-bottom: 8px;display: flex;flex-direction: row;justify-content: flex-start;gap: 5px;flex-wrap: wrap;'>
        <div>
          <el-button @click='handleReset'>初始化</el-button>
        </div>
        <div class='edit-val'>
          <span class='label'>宽</span>
          <el-input-number v-model='panel.width' :step='20'></el-input-number>
        </div>
        <div class='edit-val'>
          <span class='label'>高</span>
          <el-input-number v-model='panel.height' :step='20'></el-input-number>
        </div>
        <div class='edit-val'>
          <span class='label cursor-pointer' @click='panel.bg_color="#F3E9E9"'>背景色</span>
          <el-input type='color' v-model='panel.bg_color' placeholder='背景色：RGB色值选择' clearable class='color-input' />
          <!--        <el-button type='text' size='mini' @click='panel.bg_color="#F3E9E9"'>默认</el-button>-->
        </div>
        <div class='edit-val'>
          <span class='label'>显示网格</span>
          <el-switch
            v-model='showGrid'
            active-color='#13ce66'
            inactive-color='#ff4949'>
          </el-switch>
        </div>
        <!--        <div class='edit-val'>-->
        <!--          <span class='label'>显示含义</span>-->
        <!--          <el-switch-->
        <!--            v-model='showTitle'-->
        <!--            active-color='#13ce66'-->
        <!--            inactive-color='#ff4949'>-->
        <!--          </el-switch>-->
        <!--        </div>-->
        <div class='edit-val'>
          <el-button @click='showEditorView'>{{ showEditorPanel ? '显示编辑器' : '隐藏编辑器' }}</el-button>
        </div>
      </div>

      <div class='flex-panel' style='position: static'>
        <div :class='[showGrid?"bg-grid1":""]'>
          <!--          默认静态定位-->
          <div>
            <!--            可拖拽区域：内部元素都是相对定位-->
            <div id='panel' class='panel-cover'
                 :style='`position: relative;opacity: ${showGrid?"80%":"100%"};width: ${panel.width}px;height: ${panel.height}px;background-color: ${panel.bg_color}`'>
              <template v-for='(item,index) in widgetSettings'>
                <div :key='`${item.field}_${index}`'
                     class='widget editable draggable'
                     draggable='true'
                     data-drop='true'
                     data-effect='move'
                     @click='handleEditCell'
                     :data-field='item.field'
                     :data-title='item.title'
                     style='opacity: 0.75;cursor: move;position: absolute;'
                     :style='`z-index: ${item.z_index||6666};font-size:${item.font_size||12}px;font-weight: ${item.font_weight||"normal"};width:${item.width}px;height:${item.height}px;left: ${item.offset_left}px;top: ${item.offset_top}px;background-color: ${item.bg_color||"unset"}`'
                     :title='`${item.title}`'
                >
                  <!--                  <div class='widget-title ellipsis' v-show='showTitle'>{{ item.title }}</div>-->
                  <div v-if='item.type==="image"' style='width:100%;height:100%;'>
                    <img :src='item.value' :alt='item.title' object-fit='contain'
                         style='width:100%;height:100%;display: inline-block;' />
                  </div>
                  <div v-else-if='["rich","textarea"].indexOf(item.type)>-1'>
                    <div v-html='item.value'></div>
                  </div>
                  <div v-else>
                    <div :title='item.title'>
                      {{ item.value }}
                    </div>
                  </div>
                  <div class='resizer' title='缩放' v-show='showGrid'></div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class='flex-center' style='border: #f2f2f2 1px dashed;margin-top: 50px'>
        <div>
          <PlcExport :search-condition='{year:formData.year,month:formData.month}' />
        </div>
        <div>
          <el-link type='primary' icon='el-icon-setting' @click='showStructData'>数据结构</el-link>
        </div>
      </div>
    </div>

    <!--      编辑器-->
    <div class='panel-edit' v-show='showEditorPanel'
         :style='`top:${boxPanel.top}px;left:${boxPanel.left}px;width: ${boxPanel.width}px;height: ${boxPanel.height}px;`'
         style='margin-top: 40px'>

      <div class='flex-sb' style='margin-bottom: 8px'>
        <div draggable='true' data-effect='move' data-drop='true'>
          <span class='el-icon-rank' style='font-size: 20px;text-align: left'></span>
        </div>
        <div class='flex-left'>
          <div class='edit-val'>
            <span class='label'>宽度</span>
            <el-input-number v-model='boxPanel.width' placeholder='宽度' style='width: 100px'
                             :step='20'></el-input-number>
          </div>
          <div class='edit-val'>
            <span class='label'>高度</span>
            <el-input-number v-model='boxPanel.height' placeholder='高度' style='width: 100px'
                             :step='20'></el-input-number>
          </div>
        </div>
        <div>
          <el-link type='danger' class='el-icon-close' @click='showEditorPanel=false'></el-link>
        </div>
      </div>
      <div>
        <ControlEditor ref='refControlEditor' @save='handleSave' @handleCurr='handleCurr' />
      </div>
    </div>

    <!--    查看【数据结构】-->
    <el-dialog title='数据结构' :visible.sync='dialogPreVisible'>
      <pre style='height: 900px;overflow-y: scroll;overflow-x: scroll'>
        {{ widgetSettings }}
      </pre>
    </el-dialog>
  </div>
</template>

<script>

import hot from '@/assets/icons/hot.png'
import titleImg from '@/pages/plc/detail/images/cover2025.png'
import logoImg from '@/pages/plc/detail/images/tisu_logo.png'
import { html2json } from 'html2json'
import ControlEditor from '@/pages/plc/detail/fragment/ControlEditor'
import { queryPlcSettingInfo, savePlcSetting } from '@/api/plc_setting'
import PlcExport from '@/pages/plc/export/PlcExport'
import plcMap from '@/json/plc.json'

function debounce(func, wait) {
  let timeout
  return function(...args) {
    const later = () => {
      clearTimeout(timeout)
      func.apply(this, args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export default {
  name: 'CoverSettingView',
  components: { PlcExport, ControlEditor },
  data() {
    return {
      dialogVisible: false,
      dialogPreVisible: false,
      plc_id: null,
      formData: {},
      activeField: null,
      hot: hot,
      ImgW: '40px',
      ImgH: '40px',
      titleImg: titleImg,
      logoImg: logoImg,
      showGrid: false,
      showTitle: false,
      panel: {
        width: 920,
        height: 1080,
        bg_color: '#FFFFFF'
      },
      showEditorPanel: true,
      dragMode: false,
      boxPanel: { width: 920, height: 1080, top: 60, right: 10, left: 930 },
      //拖拽元素
      draggedElement: null,
      initialMousePos: { x: 0, y: 0 },
      initialElementPos: { left: 0, top: 0 },
      debouncedMouseMove: null,
      widgetSettings: []//控件配置信息，用于渲染
      , defaultCoverSetting: plcMap.export.cover
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async show(id) {
      this.plc_id = id
      // let formData = { plc_id: this.plc_id }
      let { info } = await this.$api.getPublicationMonthDetail(this.plc_id)
      if (info) {
        this.formData = { ...info }
      }

      const { cover_setting } = await queryPlcSettingInfo({ plc_id: this.plc_id })
      this.widgetSettings = this.checkDataArray(JSON.parse(cover_setting))

      this.dialogVisible = true

      this.$nextTick(() => {
        this.handleShowEditor()
        // 拖拽
        this.dragEvent()
      })
    },
    initWidgetSettings() {
      this.widgetSettings = []
    },
    // ==================== 元素移动事件绑定 ===================
    dragEvent() {
      //【注册防抖事件】减少 move 行为的触发
      this.debouncedMouseMove = debounce(this.onMouseMove, 10)

      //===============draggable 相对定位=============
      const draggables = document.querySelectorAll('.draggable')
      const _this = this
      // 为每个可拖拽的元素添加鼠标事件监听器
      draggables.forEach(element => {
        const resizer = element.querySelector('.resizer')
        let isDragging = false
        let isResizing = false
        let startX, startY, initialWidth, initialHeight, offsetX, offsetY

        function onMouseDownDrag(e) {
          isDragging = true
          const style = window.getComputedStyle(element)
          // const style = element.getBoundingClientRect()
          startX = e.clientX - (parseInt(style.left) || 0)
          startY = e.clientY - (parseInt(style.top) || 0)

          document.addEventListener('mousemove', (moveEvent) => onMouseMoveDrag(moveEvent, element))
          document.addEventListener('mouseup', onMouseUpDrag)
        }

        function onMouseMoveDrag(e, el) {
          if (!isDragging) return
          el.style.left = `${e.clientX - startX}px`
          el.style.top = `${e.clientY - startY}px`
        }

        function onMouseUpDrag() {
          isDragging = false
          document.removeEventListener('mousemove', onMouseMoveDrag)
          document.removeEventListener('mouseup', onMouseUpDrag)
          handleUpdateCurrControl(element)
        }

        function onMouseDownResize(e) {
          isResizing = true
          const rect = element.getBoundingClientRect()
          // const rect = window.getComputedStyle(element)
          initialWidth = rect.width
          initialHeight = rect.height
          offsetX = e.clientX - rect.left - initialWidth
          offsetY = e.clientY - rect.top - initialHeight

          document.addEventListener('mousemove', (moveEvent) => onMouseMoveResize(moveEvent, element))
          document.addEventListener('mouseup', onMouseUpResize)
        }

        function onMouseMoveResize(e, el) {
          if (!isResizing) return
          const newWidth = e.clientX - el.getBoundingClientRect().left - offsetX
          const newHeight = e.clientY - el.getBoundingClientRect().top - offsetY
          el.style.width = `${newWidth}px`
          el.style.height = `${newHeight}px`
          // 可选：调整把手大小以保持比例（这里简化了处理）

        }

        function onMouseUpResize() {
          isResizing = false
          document.removeEventListener('mousemove', onMouseMoveResize)
          document.removeEventListener('mouseup', onMouseUpResize)
          handleUpdateCurrControl(element)
        }

        //同步元素信息到控件中
        function handleUpdateCurrControl(draggedElement) {
          //获取这个元素的计算后样式
          if (draggedElement) {
            const style = window.getComputedStyle(draggedElement)
            // console.log(style.left,style.top,style.height,style.width)
            // console.log(_this.widgetSettings)
            const field = draggedElement.dataset.field
            let index = _this.widgetSettings.findIndex(value => value.field === field)
            let curr = _this.widgetSettings[index]
            curr.offset_top = draggedElement.style.top.split('px')[0]
            curr.offset_left = draggedElement.style.left.split('px')[0]
            curr.width = parseFloat(style.width.split('px')[0])
            curr.height = parseFloat(style.height.split('px')[0])

            _this.$nextTick(() => {
              if (_this.$refs['refControlEditor'])
                _this.$refs['refControlEditor'].updateEl(curr, index)
            })
          }
        }

        element.addEventListener('mousedown', (e) => {
          if (e.target === resizer) {
            onMouseDownResize(e)
          } else {
            onMouseDownDrag(e)
          }
        })

      })

    },
    unDragEvent() {
      const draggables = document.querySelectorAll('.draggable')
      // 为每个可拖拽的元素添加鼠标事件监听器
      draggables.forEach(draggable => {
        draggable.removeEventListener('mousedown', this.onMouseDown)
      })
    },
    onMouseDownDrag(event) {
      this.draggedElement = event.currentTarget

      this.draggedElement.style.position = 'absolute'
      // this.initialMousePos.x = event.clientX - this.draggedElement.getBoundingClientRect().left;
      // this.initialMousePos.y = event.clientY - this.draggedElement.getBoundingClientRect().top;

      // this.draggedElement.style.position = 'relative'
      // //获取元素当前的样式属性（注意：这些值可能是字符串，需要转换为数字）
      const style = window.getComputedStyle(this.draggedElement)
      this.initialElementPos.left = parseInt(style.left, 10) || 0 // 如果left是auto，则默认为0
      this.initialElementPos.top = parseInt(style.top, 10) || 0   // 如果top是auto，则默认为0
      // 记录鼠标按下时的位置
      this.initialMousePos.x = event.clientX
      this.initialMousePos.y = event.clientY

      this.draggedElement.style.cursor = 'move' // 更改光标样式
    },
    onMouseMove(event) {
      if (this.draggedElement) {
        // 计算鼠标移动的距离
        const dx = event.clientX - this.initialMousePos.x
        const dy = event.clientY - this.initialMousePos.y
        // 更新元素的位置（使用相对定位，所以直接修改left和top）
        this.draggedElement.style.left = `${this.initialElementPos.left + dx}px`
        this.draggedElement.style.top = `${this.initialElementPos.top + dy}px`
      }
    },
    onMouseUp() {
      // document.removeEventListener("mousemove",this.debouncedMouseMove)
      // this.draggedElement.style.cursor = 'pointer'
      // console.log('拖拽结束', this.draggedElement)
      this.handleUpdateCurrControl(this.draggedElement)
      // this.draggedElement.removeEventListener('mousedown', this.onMouseDown)
      this.draggedElement = null // 重置拖拽元素
      // 可以在这里添加逻辑来处理拖拽结束后的操作（如果需要）
    },
    //同步元素信息到控件中
    handleUpdateCurrControl(draggedElement) {
      //获取这个元素的计算后样式
      if (draggedElement) {
        const style = window.getComputedStyle(draggedElement)
        console.log(style.left, style.top, style.height, style.width)
        const field = draggedElement.dataset.field
        let index = this.widgetSettings.findIndex(value => value.field === field)
        let curr = this.widgetSettings[index]
        curr.offset_top = draggedElement.style.top.split('px')[0]
        curr.offset_left = draggedElement.style.left.split('px')[0]

        this.$nextTick(() => {
          if (this.$refs['refControlEditor'])
            this.$refs['refControlEditor'].updateEl(curr, index)
        })
      }


    },
    // ==================== 元素移动事件绑定 ===================
    checkDataArray(data) {
      if (Object.values(data) !== data) {
        data = Object.values(data)
      }
      return data
    },

    //保存封面信息及其配置
    async saveCoverData() {
      let postData = {
        year: this.formData.year,
        month: this.formData.month,
        logo_url: this.formData.logo_url,
        company_info: this.formData.company_info,
        quote_info: this.formData.quote_info,
        video_info: this.formData.video_info,
        extra_info: this.formData.extra_info,
        resource_dir: this.formData.resource_dir,
        cover_info_rich: {
          company_info: html2json(this.formData.company_info || ''),
          extra_info: html2json(this.formData.extra_info || ''),
          quote_info: html2json(this.formData.quote_info || ''),
          video_info: html2json(this.formData.video_info || '')
        }
      }
      let id = await this.$api.savePublicationCoverInfo(postData)
      if (id) {
        this.msgSuccess('保存成功')
      }
    },
    // ========== 控件编辑器 ===========
    getDropNode(node) {
      while (node) {
        if (node.dataset.drop) {
          return node
        }
        node = node.parentNode
      }
    },
    getWidgetNode(node) {
      while (node) {
        if (node.dataset.drop) {
          return node
        }
        node = node.parentNode
      }
    },
// eslint-disable-next-line no-unused-vars
    handleEditCell(event) {
      event.preventDefault()
      let editNode = this.getWidgetNode(event.target)
      if (!editNode) {
        return
      }
      if (editNode.className.indexOf('editable') > -1) {
        // 可编辑区域
        // 父节点是布局容器
        // const parentNode = event.target.parentNode
        const offsetLeft = editNode.offsetLeft
        const offsetTop = editNode.offsetTop
        const field = editNode.dataset.field
        const title = editNode.dataset.title
        if (field && title) {
          this.showEditorPanel = true
          if (this.$refs['refControlEditor'])
            this.$refs['refControlEditor'].showCurrField(field, title, offsetLeft, offsetTop)
        } else {
          this.$notify.info('编辑区数据结构异常')
        }
      }
    },
    handleShowEditor() {
      //计算编辑器默认 top left
      this.boxPanel.top = 100
      this.boxPanel.left = 940
      this.boxPanel.height = 1080

      this.$nextTick(() => {
        if (this.$refs['refControlEditor'])
          this.$refs['refControlEditor'].show(this.widgetSettings)
      })

    },
    async handleSave(widgets) {

      let cacheKey = `widgets_${this.formData.id}`
      const setting = JSON.stringify(widgets)
      localStorage.setItem(cacheKey, setting)
      this.widgetSettings = widgets
      //数据持久化
      // resource_dir
      let resource_dir = []
      widgets.forEach((widget) => {
        // console.log(widget.field)
        if (widget.field.indexOf('p_logo_') > -1) {
          //[{ title: '', sheet_title: '' }]
          resource_dir.push({ title: widget.title, sheet_title: widget.hyperlink })
        }
      })

      // console.log(resource_dir)
      let data = await savePlcSetting({ plc_id: this.plc_id, cover_setting: setting, resource_dir: resource_dir })
      if (data) {
        await this.$alert('保存成功', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            this.$message({
              type: 'success',
              // message: `action: ${action}`
              message: `刷新页面`
            })
            //保存成功，清空缓存
            localStorage.removeItem(cacheKey)
            window.location.reload()
          }
        })
      }


    },
    handleReset() {
      this.defaultCoverSetting.forEach((item) => {
        const f = item.field
        if (this.formData[f] && !item.value) {
          let val = this.formData[f]
          // console.log('f', f, val, item.value)
          item.value = val
        }
      })
      this.$nextTick(() => {
        if (this.$refs['refControlEditor'])
          this.$refs['refControlEditor'].handleReset(this.defaultCoverSetting)
      })
    },
    handleCurr(field) {
      this.activeField = field
    },
    showEditorView() {
      this.showEditorPanel = !this.showEditorPanel
    },
    showStructData() {
      //查看数据结构
      this.dialogPreVisible = !this.dialogPreVisible
    },
    changeDrag() {
      if (this.dragMode) {
        this.dragEvent()
      } else {
        this.unDragEvent()
      }
      // this.dragMode = !this.dragMode
    }
  },
  created() {

  }
}
</script>

<style scoped>
/**
支持缩放
 */
.element {
  resize: both;
  overflow: hidden;
}

div {
  box-sizing: border-box;
}

.flex-panel {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /*padding: 10px;*/
  border: #f2f2f2 1px solid;
}

.opacity75 {
  opacity: 0.7;
}

/**
 * 新封面样式
 */
.panel-cover {
  /*border: rgba(230, 162, 60, 0.92) 1px solid;*/
  /*padding: 20px;*/
  /*min-width: 900px;*/
  /*min-height: 1080px;*/
  /*margin: 10px auto;*/
  /*background-color: rgb(243, 233, 233);*/

}

.panel-cover * {
  box-sizing: border-box;
}

/*网格背景*/
.bg-grid {
  position: relative;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: -1;
  border-right: 1px #adaeaf dashed;
  border-bottom: 1px #adaeaf dashed;
}

/* 虚线部分 */
.bg-grid::before,
.bg-grid::after {
  opacity: .5;
  content: '';
  background-repeat: repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.bg-grid::before {
  /* 从左往右 */
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 1px, transparent 1px),
  linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 1px, transparent 1px);
  background-size: 10px 30px;
}

.bg-grid::after {
  /* 从上往下 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 1px, transparent 1px),
  linear-gradient(to right, rgba(0, 0, 0, 0.6) 1px, transparent 1px);
  background-size: 30px 10px;
}

/* 实线条部分 */
.bg-grid1 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #5f5f5f 1px, transparent 1px),
  linear-gradient(to bottom, #5f5f5f 1px, transparent 1px) repeat;
  background-size: 20px 20px;
  border-right: 1px #5f5f5f solid;
  border-bottom: 1px #5f5f5f solid;
}

/*
/*————————————————*/

/*版权声明：本文为博主原创文章，遵循 CC 4.0 BY-SA 版权协议，转载请附上原文出处链接和本声明。*/

/*                         原文链接：https://blog.csdn.net/weixin_44875693/article/details/129841949*/

.panel-cover .title-img {
  width: 574px;
  height: 200px;
}

.panel-cover .title-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.panel-cover .company-logo {
  width: 130px;
  height: auto;
  padding: 10px;
}

.panel-cover .company-logo img {
  width: 99%;
  height: auto;
  object-fit: cover;
}

.panel-cover .extra_info {
  border: #f2f2f2 1px solid;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  line-height: 10px;
  margin-bottom: 20px;
}

.panel-cover .part-bottom {
  background-color: #ffffff;
}

.panel-cover .company_info {

  padding-left: 20px;
  width: 100%;

}

.panel-cover .company_info div {
  text-align: center;
  display: inline-block;
}

.panel-cover .resource {
  padding: 10px 5px;
  gap: 10px !important;
  text-align: center;
}

.panel-cover .sheet-enter {
  min-width: 81px;
  width: 120px;
  line-height: 35px;
  height: 35px;
  border-radius: 35px;
  border: rgb(243, 233, 233) 2px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  color: #000c17;
  font-weight: bold;
  font-size: 14px;
}

.panel-cover .title-note, .title-quote {
  font-weight: 800;
  font-size: 20px;

}

.panel-cover .video_info, .quote_info {
  padding: 20px;
}

.panel-edit {
  /*border: rgb(243, 233, 233) 2px solid;*/
  height: auto;
  min-width: 600px;
  width: 700px;
  max-width: 900px;
  /*position: relative;*/
  /*right: 1%;*/
  /*top: 5%;*/
  /*z-index: 9999;*/
  background-color: #ffffff;
  border-radius: 5px;
  border: #f2f2f2 1px dashed;
  padding: 10px;
  overflow-y: scroll
}

.panel-edit * {
  box-sizing: border-box;
}

.editable {
  cursor: pointer;
  display: inline-block;
  /*z-index: 8888;*/
  border: #f2f2f2 1px dashed;
}

.editable:hover {
  /*border: rgba(48, 49, 51, 0.2) 1px dashed !important;*/
  border: #409EFF 2px dashed !important;
}

/**
 选中样式
 */
.active {
  border: var(--primary) 1px dashed;
  border-radius: 10px;
}

.drag {
  height: 40px;
  line-height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  border: #fe346e 1px solid;
  background-color: #f2f2f2;
  position: relative;
  left: 10px;
  top: 10px;
}

.draggable {
  /*position: absolute !important;*/
  /*position: relative;*/
  opacity: 1;
}

.draggable:hover {
  /*z-index: 8889;*/
  /*opacity: 0.5 !important;*/
}

.draggable .resizer {
  width: 10px;
  height: 10px;
  background-color: #be2929;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.widget .widget-title {
  opacity: 0.7;
  text-align: center;
  font-size: 12px;
  width: clamp(100px, 50%, 400px);
  position: absolute;
  top: 0;
  left: 0;
  border: #fe346e 1px dashed;
  color: #fe346e;
  background-color: #ffffff;
  border-radius: 10px;
  display: inline-block;
  padding: 5px;
  line-height: 20px;
  transform: translate(50%, -50%);
}

.drop-curr {
  background-color: #fe346e;
}

.edit-option {
  border: #f2f2f2 1px dashed;
  padding: 5px;
  display: flex;
  flex-direction: row;
  line-height: 20px;
  margin-bottom: 5px;
  gap: 10px;
}

.edit-val {
  min-width: 120px;
  /*border: #f2f2f2 1px dashed;*/
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.edit-val .color-input {
  width: 80px;
}

.label {
  /*width: 120px;*/
  line-height: 20px;
  vertical-align: middle;
  /*border: #f2f2f2 1px dashed;*/
  padding: 4px 4px;
  text-align: right;
  font-weight: bold;
  /*background-color: #000c17;*/
  /*color: #f2f2f2;*/
}

.label::after {
  content: "：";
}

.color-input {
  width: 80px;
}
</style>
