<template>
  <div v-loading="loading" class="lb-stat_container">
    <h2>
      直播数据盘
      <div v-if="tab!=='CATEGORY'" class="tool">
        <ml-button icon="el-icon-refresh" type="text" text="初始化数据" @click.native="handleInitStats" />
      </div>
      <ul>
        <li v-for="(label,value) in tabs" :key="label" :class="{active:value===tab}" @click="tab=value">
          {{ label }}
        </li>
      </ul>
    </h2>
    <div class="lb-stat_container__main">
      <lb-stat-tab1 ref="CHOOSE_PRODUCT" v-if="tab==='CHOOSE_PRODUCT'" />
      <lb-stat-tab2 ref="UP_PRODUCT" v-else-if="tab==='UP_PRODUCT'" />
      <lb-stat-tab3 ref="CATEGORY" v-else-if="tab==='CATEGORY'" />
    </div>
  </div>
</template>
<script>
import LbStatTab1 from './tabs/tab-1.vue'
import LbStatTab2 from './tabs/tab-2.vue'
import LbStatTab3 from './tabs/tab-3.vue'
import LbStatSearch from './components/search.vue'
import MlButton from '../../../components/MlButton/index.vue'
import { lbStatsInit } from '../../../api/lb_stat'

const tabs = {
  CHOOSE_PRODUCT: '选品统计',
  UP_PRODUCT: '上播统计',
  CATEGORY: '类目统计'
}
export default {
  components: { MlButton, LbStatSearch, LbStatTab1, LbStatTab2, LbStatTab3 },
  data() {
    return {
      tabs,
      tab: 'CHOOSE_PRODUCT',
      collection: [],
      loading: false
    }
  },
  methods: {
    handleInitStats: async function() {
      await lbStatsInit({
        months: this.$refs[this.tab].search.months
      })
      this.$message.success('初始化任务发布，请稍后查看数据。')
    }
  }
}
</script>
<style lang="scss">
$--color-primary: #8b0000;

.LbStat_main {
  margin: 12px !important;
  background: #ffffff;
  border-radius: 20px;
}


.lb-stat_container {
  height: calc(100vh - 100px);
  font-family: 'PH Medium';
  padding: 0 12px 12px 12px !important;

  &__main {
    padding: 12px 24px;
    height: calc(100% - 144px);
    overflow-y: auto;
  }

  h2 {
    text-align: center;
    padding: 32px 0 64px 0;
    margin: 0;
    position: relative;
    background-image: url("~@/assets/imgs/emphasis-products-bg.png");
    background-size: 100% auto;

    .tool {
      position: absolute;
      right: 12px;
      top: 36px;
    }

    ul {
      position: absolute;
      bottom: 0;
      width: 100%;
      justify-content: center;
      display: flex;
      font-size: 14px;

      li {
        color: #666;
        padding: 12px 20px;
        cursor: pointer;
      }

      li.active {
        background: #fff;
        border-radius: 12px 12px 0 0;
        color: #1a1a1a;
      }
    }
  }


  .lb-stat_container__main {
    .ml-table-container {
      height: calc(100% - 37px);
    }
  }
}
</style>
