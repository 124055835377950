<template>
  <page-header-layout class='layout'>
    <save-category :tbl="tbl" ref='Save' @success='getList' />
    <save-category-sub :tbl="tbl" ref='SaveSub' @success='getChildren' />
    <div class='category-add' @click='handleAdd' v-if='hasAuthority(["popular_cases_category_create","emphasis_products_category_create"])'>
      <i class='el-icon-plus' style='font-weight: 700;' />&nbsp;新增品类
    </div>
    <el-collapse v-model='active_category_id' accordion>
      <el-collapse-item v-for='(item,item_index) in category' :key='item_index' :name='item.id'>
        <div slot='title' class='el-collapse-title'>
          <div style='display: inline;'>
            <span>品类 {{ item_index + 1 }}：</span>
            <b>{{ item.display_name }}</b>
            <div v-if='active_category_id!==item.id' style='position: absolute;display: inline;right: 70px;'>
              <el-button type='primary' icon='el-icon-edit' circle @click.stop='handleEdi(item)'
                         v-if='hasAuthority(["popular_cases_category_edit","emphasis_products_category_edit"])' />
              <el-button type='danger' icon='el-icon-delete' circle @click.stop='handleDelSub(item)'
                         v-if='hasAuthority(["popular_cases_category_delete","emphasis_products_category_delete"])' />
            </div>
          </div>
        </div>
        <div :key='active_category_id' class='category-sub' v-loading='loading_sub'>
          <div v-for='(child,child_i) in category_sub' :key='child_i' class='category-sub__item'>
            {{ child_i + 1 }}.&nbsp;{{ child.display_name }}
            <div class='category-sub__item-mark'>
              <span @click='handleEdiSub(child)'
                    v-if='hasAuthority(["popular_cases_category_edit","emphasis_products_category_edit"])'>修改</span>
              <span @click='handleDelSub(child)'
                    v-if='hasAuthority(["popular_cases_category_delete","emphasis_products_category_delete"])'>删除</span>
            </div>
          </div>
          <div class='category-sub__item category-sub-add' @click='handleAddSub'
               v-if='hasAuthority(["popular_cases_category_create","emphasis_products_category_create"])'>
            <i class='el-icon-plus' style='font-weight: 700;' />&nbsp;新增类目
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </page-header-layout>
</template>
<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import { popularCasesCategoryDelete, popularCasesCategoryList } from '@/api/popular_case'
import SaveCategorySub from './save-category-sub.vue'
import SaveCategory from './save-category.vue'
import { emphasisProductsCategoryList, emphasisProductsCategoryDelete } from '../../api/emphasis_products_category'

export default {
  name: 'PopularCasesCategory',
  components: { SaveCategory, SaveCategorySub, PageHeaderLayout },
  data() {
    return {
      api: {
        list: popularCasesCategoryList,
        del: popularCasesCategoryDelete
      },
      show: false,
      category: [],
      category_sub: [],
      active_category_id: 0,
      loading_sub: false
    }
  },
  computed: {
    tbl() {
      return this.$route.name === 'EmphasisProductCategory' ? 'emphasis_products_category' : 'popular_cases_category'
    }
  },
  watch: {
    active_category_id: function() {
      this.getChildren()
    },
    '$route.name': {
      immediate: true,
      handler(route_name) {
        if (route_name === 'EmphasisProductCategory') {
          // 重点大全案品
          this.api = {
            list: emphasisProductsCategoryList,
            del: emphasisProductsCategoryDelete
          }
        } else {
          // 爆款案例
          this.api = {
            list: popularCasesCategoryList,
            del: popularCasesCategoryDelete
          }
        }
        this.getList()
      }
    }
  },
  methods: {
    handleAddSub: function() {
      this.$refs.SaveSub.interfaceAdd(this.active_category_id)
    },
    handleEdiSub: function(obj) {
      this.$refs.SaveSub.interfaceEdit(this.active_category_id, obj.display_name, obj.id)
    },
    handleAdd: function() {
      this.$refs.Save.interfaceAdd()
    },
    handleEdi: function(obj) {
      this.$refs.Save.interfaceEdit(obj.display_name, obj.id)
    },
    handleDelSub: function(obj) {
      this.$confirm(`确认删除【${obj.display_name}】吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.api.del({ id: obj.id })
        this.$message.success('删除成功')
        if (obj.parent_id === 0) {
          this.getList()
        } else {
          this.getChildren()
        }
      }).catch(() => {
      })
    },
    getList: async function() {
      const response = await this.api.list({ parent_id: 0 })
      this.category = Array.isArray(response) ? response : []
      if (this.category.length > 0) {
        this.active_category_id = this.category[0].id
      }
    },
    getChildren: async function() {
      if (!this.active_category_id) {
        return
      }
      this.loading_sub = true
      const response = await this.api.list({ parent_id: this.active_category_id })
      this.category_sub = Array.isArray(response) ? response : []
      this.loading_sub = false
    }
  }
}
</script>
<style lang='scss'>

.EmphasisProductCategory_main, .KoLPopularCaseCategory_main {
  margin: 12px !important;

  .page-content {
    min-height: unset !important;
  }

  .main-page-content {
    padding: 0 12px 12px 12px !important;
    min-height: unset !important;
  }
}

.KoLPopularCaseCategory_main-inner, .EmphasisProductCategory_main-inner {
  height: 100%;

  .layout {
    height: 100%;
  }

  .page-content {
    min-height: unset;
    height: calc(100% - 96px);
    background: #fff;
    padding: 48px 24px;

    .main-page-content {
      min-height: unset;
      height: calc(100% - 40px);
    }
  }

  .el-collapse {
    .el-collapse-item__content {
      padding-bottom: unset !important;
    }

    .el-collapse-title {
      width: 100%;
    }

    .is-active {
      .el-collapse-title {
        //text-align: center;

        b {
          color: rgb(255, 49, 118);
        }
      }
    }

    .el-collapse-item__wrap {
      border: 1px solid #f2f2f2;
      border-radius: 10px;
      background: #ffeef3;
    }
  }

  .category-sub {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 10px;
    padding: 20px;
    max-height: 350px;
    overflow-y: auto;

    &__item:hover {
      .category-sub__item-mark {
        opacity: .9 !important;
      }
    }

    &__item {
      background-color: hsla(0, 0%, 100%, .8);
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      padding: 4px 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      cursor: pointer;
      position: relative;

      &-mark {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        opacity: 0;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;

        span {
          color: #FFFFFF;
          cursor: pointer;
        }

        span + span {
          margin-left: 10px;
        }
      }
    }
  }

  .category-add {
    text-align: center;
    background: rgb(255, 49, 118);
    color: rgb(255, 255, 255);
    width: 10%;
    margin: 0 auto 20px auto;
    padding: 8px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
  }

  .category-sub-add {
    text-align: center;
    background: rgb(255, 49, 118);
    color: #fff;
  }
}
</style>
