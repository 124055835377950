<template>
  <el-select
    v-model="v"
    filterable
    default-first-option
    remote
    placeholder="选择平台"
    :size="size"
    clearable
  >
    <el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code" />
  </el-select>
</template>

<script>
let platforms = localStorage.getItem('platforms')
platforms = platforms ? JSON.parse(platforms) : []
export default {
  name: 'SelectPlatform',
  // eslint-disable-next-line vue/require-prop-types
  props: ['value', 'size'],
  data() {
    return {
      v: null,
      options: platforms
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(_) {
        this.v = _
      }
    },
    v(_) {
      const obj = this.options.find(option => {
        return option.code === _

      })
      this.$emit('update:label', obj ? obj.name : null)
      this.$emit('update:value', _)
      this.$nextTick(() => {
        this.$emit('change')
      })
    }
  }
}
</script>

<style scoped>

</style>
