<template>
  <div style='padding: 10px'>
    <div v-if='dataList'>
      <el-table :data='dataList' :show-header='dataList.length>0' empty-text='无嵌套表格' border>
        <el-table-column label='#' :align='`center`' width='66'>
          <template slot-scope='{$index}'>
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <!--        <el-table-column label='字段' prop='field' :align='`center`' width='120'>-->
        <!--        </el-table-column>-->
        <el-table-column label='列名' prop='title' :align='`center`' width='160'>
          <editable-cell
            slot-scope='{row,column}'
            :show-input='row.editMode'
            v-model='row[column.property]'
            type='textarea'>
            <div slot='content'>
                <span :title='`${row.field}`' data-effect='copy'
                      data-drop='copy'>{{ row[column.property] }}</span>
              <el-button type='text' icon='el-icon-edit' class='edit-btn'></el-button>
            </div>
          </editable-cell>
        </el-table-column>
        <el-table-column label='描述' prop='placeholder' :align='`center`' min-width='160'>
          <editable-cell
            slot-scope='{row,column}'
            :show-input='row.editMode'
            v-model='row[column.property]'
            type='textarea'>
            <div slot='content'>
              <span>{{ row[column.property] }}</span>
              <el-button type='text' icon='el-icon-edit' class='edit-btn'></el-button>
            </div>
          </editable-cell>
        </el-table-column>
        <el-table-column label='行高(pt)' prop='height' :align='`center`' width='100'>
          <editable-cell
            slot-scope='{row,column}'
            :show-input='row.editMode'
            v-model='row[column.property]'
            :is-input-number='true'>
            <div slot='content'>
              <span>{{ row[column.property] }}</span>
              <el-button type='text' icon='el-icon-edit' class='edit-btn'></el-button>
            </div>
          </editable-cell>
        </el-table-column>
        <el-table-column label='列宽(pt)' prop='width' :align='`center`' width='120'>
          <editable-cell
            slot-scope='{row,column}'
            :show-input='row.editMode'
            v-model='row[column.property]'
            :is-input-number='true'>
            <div slot='content'>
              <span>{{ row[column.property] }}</span>
              <el-button type='text' icon='el-icon-edit' class='edit-btn'></el-button>
            </div>
          </editable-cell>
        </el-table-column>
        <el-table-column label='对齐方式' prop='horizontal' align='center' width='120'>
          <template slot-scope='{row,column}'>
            <el-select v-model='row[column.property]'>
              <el-option label='左对齐' value='left'>左对齐</el-option>
              <el-option label='居中' value='center'>居中</el-option>
              <el-option label='右对齐' value='right'>右对齐</el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label='合并列名' prop='merge_col' align='center' width='120'>
          <template slot-scope='{row,column}'>
            <el-select v-model='row[column.property]'>
              <el-option label='是' value='Y'>是</el-option>
              <el-option label='否' value='N'>否</el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label='合并行' prop='merge_row' align='center' width='120'>
          <template slot-scope='{row,column}'>
            <el-select v-model='row[column.property]'>
              <el-option label='是' value='Y'>是</el-option>
              <el-option label='否' value='N'>否</el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
export default {
  name: 'PlcChildColumns',
  components: { EditableCell },
  props: {
    dataList: {
      type: [Array, Object]

    }
  },
  // data() {
  //   return {
  //     dataList: []
  //   }
  // },
  // methods: {
  //   show(data) {
  //     this.dataList = [...data]
  //   }
  // }
}
</script>

<style scoped>

</style>
