// https://developer.mozilla.org/zh-CN/docs/Web/API/Navigator
// Navigator 接口代表了用户代理的状态和身份，它允许脚本对其进行查询并注册自身以便执行某些活动。
// 可以通过 window.navigator 只读属性获取 Navigator 对象。
// 浏览器兼容性： https://developer.mozilla.org/zh-CN/docs/Web/API/Navigator/connection
//  以mac电脑的钉钉微应用不支持，因为 Safari 不兼容（更多不兼容的浏览看上面的链接 ，比如 Safari on iOS、Firefox、WebView on iOS、Node.js）

// 模块化:立即执行函数表达式（IIFE：Immediately - Invoked Function Expression）为模块提供私有化空间
// 前面的 分号“;”：可以防止在压缩时候，前面的代码没有写结束的分号导致报错
(function() {

  let docNetwork = null

  function getNetwork() {
    let info = {}
    try {
      if (window.navigator) {
        const navigator = window.navigator

        if (navigator.onLine) {
          if (navigator.connection) {
            info = {
              // 返回连接的有效类型（意思是“slow-2g”、“2g”、“3g”或“4g”中的一个）。此值是使用最近观察到的往返时间和下行链路值的组合来确定的。
              type: navigator.connection.effectiveType,
              // 返回当前连接的有效往返时间估计，四舍五入到最接近的 25 毫秒的倍数。
              rtt: navigator.connection.rtt,
              // 返回以兆比特每秒为单位的有效带宽估计，四舍五入到最接近的 25 千比特每秒的倍数。
              downlink: navigator.connection.downlink
            }
          } else {
            info = {
              type: 'unKnown',
              rtt: '-',
              downlink: '-'
            }
          }

        } else {
          info = {
            type: 'offline'
          }
        }
      } else {
        info = {
          type: 'Not Supported',
          agent: window.navigator.userAgent
        }
      }

    } catch (e) {
      // console.log('err', e)
      info = {
        type: 'error',
        agent: window.navigator.userAgent
      }
      //上报到服务端 @todo
    }

    return info
  }

  function showNetworkStatus() {
    //是否关闭检测网络状态
    let isClose = sessionStorage.getItem('network-view-close')
    if (isClose !== 'yes') {
      const info = getNetwork()
      if (!docNetwork) {
        docNetwork = document.createElement('div')
        docNetwork.id = 'monitor_network'
        docNetwork.classList.add('network-view')
        document.body.appendChild(docNetwork)
      }

      let infoHtml = '<div class="network-status">'
      if (info.type === 'offline') {
        infoHtml += '<div>离线</div>'
      } else {
        infoHtml += '<div><span style="font-size: 10px">状态：</span>' + info.type + '</div>'
        infoHtml += '<div><span style="font-size: 10px">延迟：</span>' + info.rtt + 'ms</div>'
        infoHtml += '<div><span style="font-size: 10px">带宽：</span>' + info.downlink + 'Mb/s</div>'
      }
      infoHtml += '</div><div class="btn-close" >关闭</div>'
      docNetwork.innerHTML = infoHtml
      changeColor(docNetwork, info)
      bindCloseEvent()
      return info
    }
  }

  function bindCloseEvent() {
    let closeBtn = document.querySelector('.btn-close')
    closeBtn.addEventListener('click', function() {
      // alert('确认关闭')
      handleHide()
    })
  }

  function handleHide() {
    // docNetwork.classList.add('hide')
    docNetwork.style.display = 'none'
    sessionStorage.setItem('network-view-close', 'yes')
  }

  /**
   * 修改样式
   * 网络延迟的状态可以分为以下几个等级：
   1~30ms：极快，几乎察觉不出有延迟，适合进行任何类型的网络活动，包括高要求的在线游戏
   31~50ms：良好，可以正常进行游戏和其他网络活动，没有明显的延迟情况
   51~100ms：普通，对抗类游戏在一定水平以上能感觉出延迟，偶尔感觉到停顿
   100ms~200ms：较差，无法正常游玩对抗类游戏，有明显卡顿，偶尔出现丢包和掉线现象
   200ms~500ms：很差，访问网页有明显的延迟和卡顿，经常出现丢包或无法访问
   大于500ms：极差，难以接受的延迟和丢包，甚至无法访问网页
   大于1000ms：基本无法访问
   * @param docNetwork
   * @param info
   */
  function changeColor(docNetwork, info) {
    if (docNetwork.children && docNetwork.children.length > 0) {
      let childNode = docNetwork.children[0]
      childNode.classList.remove('green')
      childNode.classList.remove('yellow')
      childNode.classList.remove('red')
      childNode.classList.remove('off')
      if (info.type === 'offline') {
        childNode.classList.add('off')
      } else {
        if (info.rtt <= 50) {
          childNode.classList.add('green')
        } else if (info.rtt < 200) {
          childNode.classList.add('yellow')
        } else {
          childNode.classList.add('red')

        }
      }
    }
  }

  function registerNetworkEvent(eId) {
    docNetwork = document.getElementById(eId)

    showNetworkStatus()
    //navigator.connection:  https://developer.mozilla.org/zh-CN/docs/Web/API/NetworkInformation
    // [Navigator：onLine 属性] https://developer.mozilla.org/zh-CN/docs/Web/API/Navigator/onLine
    window.addEventListener('offline', showNetworkStatus)
    window.addEventListener('online', showNetworkStatus)
    // [在连接信息发生变化时触发的事件]https://developer.mozilla.org/zh-CN/docs/Web/API/NetworkInformation
    if (window.navigator && window.navigator.connection) {
      window.navigator.connection.addEventListener('change', showNetworkStatus)
    }
  }

  //延迟执行
  setTimeout(() => {
    registerNetworkEvent('monitor_network')
  }, 100)


  //扩展用途：网络状态差的时候先缓存数据到本地，等网络状态恢复的时候再上传（扫码等场景）
})()


