<!--/**-->
<!--* 自动生成 vue导入组件代码【模块名：】-->
<!--* 生成日期：2025/01/09 11:46:04-->
<!--* 生成路径: src/pages/employee/ImportEmployee.vue-->
<!--* 生成人：管理员-->
<!--*/-->
<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               width='80%'>

      <el-row :gutter='15'>
        <el-col :span='12'>
          <el-upload
            class='upload-demo'
            :on-change='handleChange'
            :on-success='importSuccess'
            :headers='uploadHeaders'
            :data='postData'
            name='file'
            :show-file-list='false'
            :file-list='file'
            :before-upload='beforeUpload'
            drag
            action='/admin_api/employee/import'
            multiple>
            <i class='el-icon-upload'></i>
            <div class='el-upload__text'>将文件拖到此处，或<em>点击上传</em></div>
            <div class='el-upload__tip' slot='tip'></div>
          </el-upload>
        </el-col>
        <el-col :span='12'>
          <div>
            <export-btn :can-export='true' ref='refExportLink' type='primary' lintTitle='下载导入模板'
                        @export='downloadTpl'></export-btn>
          </div>
          <div>
            <tips :data='tipList'></tips>
          </div>
        </el-col>
      </el-row>
      <el-row v-if='importList.length>0'>
        <el-col :span='24'>
          <div class='default-table'>
            <el-divider>导入数据预览</el-divider>
            <ImportTable :showIndex='true'
                         :tableData='importList'
                         :columns='columns'
                         border
                         :row-class-name='rowClassName'
            ></ImportTable>
          </div>
          <div style='text-align: center;padding: 10px' v-if='showCommitBtn'>
            <el-button type='primary' :disabled='loadingCommit' :loading='loadingCommit' @click='onsubmit()'>提交
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import { getToken } from '@/utils/auth'
import ImportTable from '@/components/common/ImportTable'
import { exportEmployees,saveEmployee } from '@/api/employee'

export default {
  name: 'ImportEmployee',
  components: { ImportTable, ExportBtn },
  data() {
    return {
      dialogTitle: '导入',
      file: [],
      uploadLoading: false,
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      postData: { },
      maxSize: 20,//文件最大的 10 M
      tipList: [
        '第1步，下载模板',
        '第2步，按照模板规范填好数据后，根据提示上传文件',
        '注意：1）二次导入，将会追加数据 2）表格空行会过滤'
        // '第3步，预览导入的数据，确认无误后，点击“提交”按钮'
      ],
      offset: 0,
      height: 800,
      columns: [],
      importList: [],
      loadingCommit: false,
      len: 0,
      importedIndexList: [],
      showCommitBtn: true
    }
  },
  methods: {
    onOpen() {
      this.importList = []
      this.len = 0
      this.importedIndexList = []
      this.showCommitBtn = true
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },

    async downloadTpl() {
      try {
        let name = `员工表-导入模板`
        this.$notify.info(name)
        let queryParams = {
          is_tpl: true
        }
        let response = await exportEmployees(queryParams)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    // ======================= 导入相关 =====================
    handleChange(file) {
      this.file = [file]
    },
    beforeUpload(file) {
      //身份凭据
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()


      this.uploadLoading = true
      const isLt2M = file.size / 1024 / 1024 < this.maxSize
      if (!isLt2M) {
        this.$message.error(`导入文件大小不能超过 ${this.maxSize}MB!`)
        this.uploadLoading = false
      }
      return isLt2M
    },
    importSuccess(response) {
      this.showCommitBtn = true
      this.importList = []
      this.len = 0
      this.importedIndexList = []
      if (response.code === 200) {
        let data = response.data ? response.data : []
        this.columns = data.cols || []
        this.$nextTick(() => {
          this.importList = data.list || []
        })

        // this.$emit('imported')
        // setTimeout(() => {
        //   this.close()
        // }, 500)
      } else {
        this.$message.warning(response.message)
      }
    },
    onsubmit() {
      this.len = 0
      this.importedIndexList = []
      this.loadingCommit = true
      if (this.importList.length > 0) {
        this.saveRow(0)
      }


    },
    async saveRow(index) {
      // console.log('index', index)
      // 有效表格数据索引
      if (index < this.importList.length) {
        if (this.importedIndexList.indexOf(index) === -1) {
          this.importedIndexList.push(index)
          const row = this.importList[index]
          await saveEmployee(row)

          setTimeout(() => {
            index++
            this.saveRow(index)
          }, 100)
        }
      }else {
        setTimeout(() => {
          this.loadingCommit = false
          this.showCommitBtn = false
        }, 500)
        this.$emit('imported')
      }
    },
    // eslint-disable-next-line no-unused-vars
    rowClassName({ row, rowIndex }) {
      if (this.importedIndexList.indexOf(rowIndex) === -1) {
        return 'wait-upload'
      } else {
        return 'uploaded'
      }
    }
  }
}
</script>

<style>
.wait-upload {
  color: #409EFF;
}

.uploaded {
  color: #67C23A;
}

</style>
