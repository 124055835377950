<template>
  <div>
    <div class="default-table">
      <el-table ref="multipleTable"
                :data="kolData"
                border
                stripe
                style="width: 100%">
        <el-table-column
            align="center"
            header-align="center"
            label="序号"
            type="index"
            width="80">
        </el-table-column>
        <el-table-column
            align="left"
            header-align="center"
            label="红人"
            min-width="120"
            prop="nicknames"
            :filters="[{text:'全部',value:0},{text:'在约',value:1}]"
            :filtered-value="[1]"
            :filter-method="filterHandler"
        >
          <template slot-scope="{row}">
            <el-tag v-if="row.is_assign" type="danger">定向</el-tag>
            {{ row.nicknames.text }}
          </template>
        </el-table-column>
        <el-table-column
            align="left"
            header-align="center"
            label="合作意向"
            width="300"
        >
          <template slot-scope="{row}">
            <el-checkbox-group v-model="row.radio" :max="1" @change="selectChange">
              <el-checkbox :label="1">试用</el-checkbox>
              <el-checkbox :label="2">可接</el-checkbox>
              <el-checkbox v-if="row.is_assign" :label="3">拒接</el-checkbox>
            </el-checkbox-group>
            <el-input v-if="row.radio.indexOf(3)>-1" v-model="row.intention_note" placeholder="填写原因"
                      @blur="selectChange">
              填写拒接原因
            </el-input>
            <el-input v-if="row.radio!==3&&row.radio.length>0" v-model="row.intention_note" :maxlength="50"
                      placeholder="可给多个备选档期" show-word-limit @blur="selectChange">
            </el-input>
            <!--              <el-date-picker-->
            <!--                  type="date"-->
            <!--                  v-model="row.intention_note"-->
            <!--                  value-format="yyyy-MM-dd"-->
            <!--                  v-if="row.radio.includes(2)&&row.radio.length>0"-->
            <!--                  @blur="selectChange"-->
            <!--                  placeholder="选择合作档期">-->
            <!--              </el-date-picker>-->
          </template>
        </el-table-column>

        <el-table-column
            align="left"
            header-align="center"
            label="平台"
            min-width="300">
          <template slot-scope="{row}">
            <el-checkbox-group v-model="row.checkList" @change="selectChange">
              <el-checkbox
                  v-for="(item,index) in infoPlatforms"
                  :key="index"
                  :disabled="!(row?.platforms[item.value]!=='N')||row.selectedPlatforms.indexOf(item.value)>-1"
                  :label="item.value">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column
            align="left"
            header-align="center"
            label="备注"
            width="300"
        >
          <template slot-scope="{row}">
            <el-input v-if="row.radio!=3&&row.radio.length>0" v-model="row.note" :maxlength="200"
                      type="textarea" autosize :placeholder="infoStatus.note" show-word-limit
                      @blur="selectChange">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            label="报名结果"
            prop="status"
            width="120"
        >
          <template slot-scope="{row}">
            {{ row.status ? '已报' : '可报' }}
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-button
          :disabled="!(checkData?.length>0&&String(checkData[0]?.platforms)!=[])"
          :type="checkData?.length>0&&String(checkData[0]?.platforms)!=[]?'success':'info'"
          size="big"
          @click="handleSuccess">
        预览数据
      </el-button>

    </div>
    <br>
    <el-drawer
        :before-close="closeDrawer"
        :size="'90%'"
        :visible.sync="drawer"
        :with-header="false"
        title="报名详情">
      <div v-if="drawer" class="content_drawer">
        <el-tabs v-model="tabActive" @tab-click="changeTab">
          <el-tab-pane v-for="(item,index) in tabTranslate" :key="index" :label="item.name"
                       :name="item.value">
            <el-table :data="tableInfoData"
                      border stripe
                      style="width: 100%">
              <el-table-column
                  label="序号"
                  type="index"
                  width="80">
              </el-table-column>
              <el-table-column label="合作意向" width="80">
                <template slot-scope="{row}">
                  <!--                    {{ row }}-->
                  <span v-if="row.intention===1" style="color: blue">试用</span>
                  <span v-if="row.intention===2" style="color: green">可接</span>
                  <span v-if="row.intention===3" style="color: red">拒接</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="意向说明"
                  prop="intention_note">
              </el-table-column>
              <el-table-column
                  label="备注"
                  prop="note">
              </el-table-column>
              <el-table-column align="center" header-align="center" label="基本信息"
                               width="270">
                <template slot-scope="scope">
                                           <span v-if="scope.row.is_assign===1&&scope.row?.avatar"
                                                 class="isassign">定向</span>
                  <PanelKolInfo v-if="scope.row?.avatar" :data="scope.row"
                                :index="scope.$index" style="height: 100%"/>
                  <span v-else>&nbsp;
                                            <el-tag v-if="scope.row.is_assign===1&&!scope.row.newDetails"
                                                    type="danger">定向</el-tag>&nbsp;{{
                      scope.row.nickname
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column header-align="center" label="其他信息" width="300">
                <template slot-scope="scope">
                  <PanelPlatformInfo v-if="scope.row?.real_quote" :data="scope.row"/>
                  <span v-else>暂无数据</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="当月价格" min-width="280"
                               prop="real_quote">
                <template slot-scope="{row,column}">
                  <div v-if="row?.real_quote" class="price-div">
                    <div class="edit-btn">
                      <div class="edit-quote">
                        <el-link v-if="row?.quote_source===column.property" :underline="false"
                                 type="primary">
                          <i class="el-icon-success edit-btn-size"></i>
                        </el-link>

                      </div>
                    </div>
                    <div class="edit-box">
                      <PriceGrids :rows="row[column.property]"/>
                    </div>
                  </div>
                  <span v-else>暂无数据</span>

                </template>
              </el-table-column>
              <el-table-column
                  label="商务案例"
                  prop="bs_case"
              >
                <template slot-scope="{row}">
                  <el-card v-if="row?.bs_case" :body-style="{ height: '200px',overflow:'scroll' }"
                           shadow="hover">
                    <div v-html="row?.bs_case"></div>
                  </el-card>
                  <span v-else>暂无数据</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <br>
        <el-button size="big" type="success" @click="handleEnroll">确定报名</el-button>

      </div>
    </el-drawer>

  </div>


</template>

<script>
import PriceGrids from '@/pages/publication/compoents/PriceGrids'
import { mapGetters } from 'vuex'
import PanelPlatformInfo from '@/pages/inquiries/components/PanelPlatformInfo'
import PanelKolInfo from '@/pages/inquiries/components/PanelKolInfo'

export default {
  name: 'InfoDetail',
  components: {
    PanelKolInfo,
    PanelPlatformInfo,
    PriceGrids
  },
  data() {
    return {
      infoData: {},
      projectId: '',
      kolData: [],
      multipleSelection: [],
      drawer: false,
      checkData: [],
      tabActive: '',
      tabPlatforms: [],
      combined: [],
      tableInfoData: []

    }
  },
  props: {
    infoPlatforms: {
      type: Array
    },
    infoStatus: {
      type: Object
    },
    refresh: {
      type: Boolean
    }
  }, computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    tabTranslate() {
      let newCheckList = []
      this.infoPlatforms.forEach((val) => {
        this.tabPlatforms.forEach((item) => {
          if (item == val.value) {
            newCheckList.push(val)
          }
        })
      })
      return newCheckList
    }
  },
  watch: {
    'refresh'(val) {
      if (val) {
        this.getInfo()
      }
    }
  },

  methods: {
    selectChange() {
      let newKolData = []
      this.checkData = []
      this.kolData.forEach((item) => {
        // item.radio && item.checkList && item.radio[0] != undefined && item.radio[0] != null && item.checkList.length > 0 ? newKolData.push(item) : ''
        (item.radio?.[0] ?? '') !== '' && item.checkList?.length > 0 ? newKolData.push(item) : ''
      })
      let checkData = {}
      if (newKolData?.length !== 0) {
        newKolData.forEach((item) => {
              item.selectedPlatforms.forEach((s) => {
                item.checkList = item.checkList.filter(i => i !== s)
              })
              // let reason = String(item.radio[0]) === '3' && item.radio[0] != undefined ? item.reason : ''
              checkData = {
                id: item.id,
                intention: item.radio[0],
                platforms: item.checkList,
                nickname: item.nickname,
                is_assign: item.is_assign,
                intention_note: item.intention_note,
                note: item.note
              }
              this.checkData.push(checkData)
            }
        )
      }
    },
    closeDrawer() {
      this.drawer = false
      this.tabPlatforms = []
      this.tableInfoData = []
    },
    async getInfo() {
      let data = await this.$api.InquiryKolList({ project_id: this.projectId })
      data.forEach((item) => {
        if (item['selectedPlatforms']?.length > 0) {
          item['checkList'] = item['selectedPlatforms']
        } else
          item['checkList'] = []
        item['kolPlatforms'] = []
        item['radio'] = []
        // item['nickname'].indices(' (解约)')
        if (!item['nickname'].includes(' (解约)')) {
          item['nicknames'] = { text: item.nickname, value: 1 }
        } else {
          item['nicknames'] = { text: item.nickname, value: 0 }
        }
      })
      data.forEach((item) => {
        let kolPlatforms = {}
        let newData = {}
        newData = item.platforms
        for (let i in newData) {
          if (newData[i] === 'Y') {
            kolPlatforms = i
            item.kolPlatforms.push(kolPlatforms)
          }
        }
      })
      this.kolData = data
      this.$emit('update:refresh', false)

    },
    async handleEnroll() {
      this.$confirm(`确认给${this.checkData.length}位红人报名?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let kols = []
        let enrollData = {}
        this.checkData.forEach((item) => {
          let newCheckList = []
          item.platforms.forEach((i) => {
            this.infoPlatforms.forEach((val) => {
              if (i == val.value) {
                newCheckList.push({ platform_code: i, platform_name: val.name })
              }
            })
          })
          // let intention = item.intention.includes('试用') ? 1 : item.intention.includes('可接') ? 2 : 3
          // let value = item.intention.substring(index)
          enrollData = {
            artist_id: item.id,
            intention: item.intention,
            platforms: newCheckList,
            is_assign: item.is_assign ? 1 : 2,
            nickname: item.nickname,
            intention_note: item.intention_note || '',
            note: item.note || ''
          }
          kols.push(enrollData)
        })
        let id = await this.$api.InquiryEnroll({ kols, 'project_id': this.projectId })
        if (id) {
          this.$notify.success('报名成功')
          this.drawer = false
          this.$emit('update:refresh', true)
        }
      }).catch(() => {
        this.$notify.info('已取消')
      })
    },

    async handleSuccess() {
      this.drawer = true
      let data = await this.$api.checkInquiry({ kols: this.checkData })
      let newData = []
      for (let i in data) {
        data[i].forEach((item) => {
          newData.push(item)
        })
      }
      let newCheckData = []
      this.checkData.forEach((item) => {
        if (item?.platforms.length > 0) {
          item.platforms.forEach((i) => {
            let platform = { platform: i, ...item }
            newCheckData.push(platform)
          })
        }
      })
      if (newData && newCheckData) {
        const combined = newCheckData.reduce((acc, cur) => {
          const target = acc.find(e => cur.id === e.artist_id && cur.platform === e.platform_code)
          if (target) {
            Object.assign(target, cur)
          } else {
            acc.push(cur)
          }
          return acc
        }, newData)
        this.combined = combined
        this.tabPlatforms = []
        this.tabPlatforms = combined.map((item) => {
          return item.platform_code || item.platform
        })
        this.tabPlatforms = [...new Set(this.tabPlatforms)]
        this.tabActive = this.tabPlatforms?.[0]
        this.changeTab()
      }
    },
    changeTab() {
      this.tableInfoData = []
      this.tableInfoData = this.combined.filter(item => (item.platform_code || item.platform) === this.tabActive)
    },
    filterHandler(value, row) {
      if (value === 0) {
        return row.nickname
      } else
        return row.nicknames.value === value
    }
  },
  mounted() {
    this.projectId = this.$route.params.id
    this.getInfo()

  }
}
</script>
<style>
.content_drawer {
  margin: 20px;
}
</style>
