<template>
  <div class='panel-cover'>
    <div class='flex-sb'>
      <div class='title-img' title='图片尺寸：574px*200px'><img :src='titleImg' alt='封面'></div>
      <div class='company-logo' title='图片尺寸：130px*200px'>
        <!--        <img :src='formData.logo_url' alt='封面'>-->
        <img :src='logoImg' alt='封面'>
      </div>
    </div>
    <div class='extra_info'>
      <!--      KOL说明-->
      <div v-html='formData.extra_info'></div>
    </div>
    <div class='part-bottom'>
      <div class='company_info'>
        <!--      公司说明-->
        <div v-html='formData.company_info'></div>
      </div>
      <div class='resource flex-center' style='gap: 0;' v-if='formData.resource_dir'>
        <template v-for='(item,index) in formData.resource_dir'>
          <div class='sheet-enter ellipsis' :key='`resource_dir_${index}`'>
            <span :title='`${item.sheet_title}`'>{{ item.title || '/' }}</span>
            <img :src='hot' alt='火爆' v-if='item.is_hot' style='width:13px;max-height: 13px;margin-left: 5px' />
          </div>
        </template>
      </div>
      <div style='padding: 20px'>
        <div>
          <div><span class='title-quote'>报价说明</span></div>
          <div class='quote_info'>
            <div v-html='formData.quote_info'></div>
          </div>
        </div>

        <div>
          <div><span class='title-note'>合作说明</span></div>
          <div class='video_info'>
            <div v-html='formData.video_info'></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import hot from '@/assets/icons/hot.png'
import titleImg from '@/pages/plc/detail/images/cover2025.png'
import logoImg from '@/pages/plc/detail/images/tisu_logo.png'

export default {
  name: 'BaseCover2025',
  props: {
    data: {
      type: Object,
      default() {
        return {
          // title: '',
          // logo_url: '',
          // company_info: '',
          // resource_dir: []
        }
      }
    }
  },
  computed: {
    formData() {
      let data = { ...this.data }

      if (data.resource_dir) {
        try {
          if (data.resource_dir && typeof data.resource_dir == 'string')
            data.resource_dir = JSON.parse(data.resource_dir)
        } catch (e) {
          console.log('e', e)
          data.resource_dir = []
        }
      }
      return data
    }
  },
  data() {
    return {
      hot: hot,
      ImgW: '40px',
      ImgH: '40px',
      titleImg: titleImg,
      logoImg: logoImg
    }
  }
}
</script>

<style scoped>
/**
 * 新封面样式
 */
.panel-cover {
  border: #f2f2f2 1px solid;
  /*padding: 20px 20px 30px;*/
  width: 900px;
  margin: 10px auto;
  background-color: #F3E9E9;
}

.panel-cover * {
  box-sizing: border-box;
}

.panel-cover .title-img {
  width: 574px;
  height: 200px;
}

.panel-cover .title-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.panel-cover .company-logo {
  width: 130px;
  height: 200px;
  padding: 10px;
}

.panel-cover .company-logo img {
  width: 90%;
  height: auto;
  object-fit: cover;
}

.panel-cover .extra_info {
  border: #f2f2f2 1px solid;
  padding-left: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  line-height: 10px;
  margin-bottom: 20px;
}

.panel-cover .part-bottom {
  background-color: #ffffff;
}

.panel-cover .company_info {
  padding-left: 20px;
  width: 100%;

}

.panel-cover .company_info div {
  text-align: center;
  display: inline-block;
}

.panel-cover .resource {
  padding: 10px;
  gap: 10px !important;
  text-align: center;
}

.panel-cover .sheet-enter {
  min-width: 81px;
  width: 100px;
  line-height: 35px;
  height: 35px;
  border-radius: 35px;
  border: rgb(243, 233, 233) 2px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  color: #000c17;
  font-weight: bold;
  font-size: 14px;
}

.panel-cover .title-note, .title-quote {
  font-weight: 800;
  font-size: 20px;

}

.panel-cover .video_info, .quote_info {
  padding: 20px;
}
</style>
