<template>
  <div>
    <template v-for='(f,index) in formRenderStruct'>
      <el-form-item :label='calcColName(f.field,f.title)' v-if='!f.is_default' :label-width='label_width'
                    :prop='f.field' :key='`${f.field}_${index}`' >

        <div >
          <div class='form-input-readonly' v-if="f.type==='select'" :style='`width: ${f.width||"100%"}`'>
            {{ Array(formData[f.field]).join(',') || '' }}
          </div>
          <div class='form-input-readonly'  v-else-if='f.type==="switch"' :style='`width: ${f.width||"100%"}`'>
            {{f?.switchMap[formData[f.field]]?.label}}
          </div>
          <div class='form-input-readonly' v-else-if="f.type==='rich_txt'" v-html='formData[f.field]' :style='`width: ${f.width||"98%"}`'>  </div>
          <div class='form-input-readonly' v-else :style='`width: ${f.width||"100%"}`'>{{ formData[f.field] || '/' }}</div>
        </div>
      </el-form-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FormView',
  props: {
    formData: {
      type: Object
    },
    formRenderStruct: {
      type: Array,
      default() {
        return [
          {
            'title': '粉丝（万）',
            'field': 'fans_total',
            'type': 'number',
            'width': '50%',
            'placeholder': '粉丝数导出时格式化，单位：万个',
            'is_auto': true,
            'slotName': 'fans',
            'showSlot': true
          },
          {
            'title': '预估粉丝数',
            'field': 'pre_fans_total',
            'placeholder': '下月底预估粉丝量，必填（单位：个）',
            'type': 'number',
            'is_auto': true
          },
          {
            'title': '开通星图',
            'field': 'opened_star',
            'type': 'switch',
            'is_auto': true,
            'switchMap': {
              'Y': {
                'label': '开通',
                'value': 'Y'
              },
              'N': {
                'label': '未开通',
                'value': 'N'
              }
            }
          }
        ]
      }
    },
    setting: {
      type: Object,
      default() {
        return {
          columns_data: []
        }
      }
    }
  },
  data() {
    return {
      label_width: '150px',
      input_width: '360px'
    }
  },
  methods: {
    // eslint-disable-next-line
    calcColName(field, title) {
      let columns = this.setting.columns_data
      let obj = columns.find(value => value.field === field)
      return obj ? obj.title : title
    }
  }
}
</script>

<style scoped>
.fl-row * {
  box-sizing: border-box;
}

.fl-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

}

.fl-row .fl-col {
  width: 49%;
}

.form-input-readonly {
  border: #f2f2f2 1px solid;
  padding: 0 10px;
  border-radius: 4px;
  width: auto;
}
</style>
