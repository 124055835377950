<template>
  <div>
    <!--    <router-view v-show="$route.matched.length==3"></router-view>-->
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-row class='searchCondition'>
          <el-col :span='22'>
            <el-form size='mini' :inline='true'>
              <el-form-item label='全平台'>
                <el-radio-group v-model='searchCondition.is_all' @change='changePlatFormRange'>
                  <el-radio-button label='Y'>是</el-radio-button>
                  <el-radio-button label='N'>否</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label='年份：'>
                <el-date-picker v-model='searchCondition.year' type='year' placeholder='选择年' size='mini'
                                :clearable='true'
                                @change='showRecords'
                                value-format='yyyy'
                                format='yyyy年'
                                style='width: 120px' />
              </el-form-item>
              <el-form-item label='分组：' prop='group_id' v-if='groups.length>8'>
                <el-select v-model='searchCondition.group_id' placeholder='请选择所属分组' filterable
                           style='width: 90%' clearable @change='showGroups'>
                  <el-option :label='`${item.display_name}(${item.artists_count || 0})`' :value='item.name'
                             v-for='(item,idx) in groups'
                             :key='idx'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label='分组：' v-else>
                <div>
                  <el-radio-group v-model='searchCondition.group_id' @change='showGroups' v-if='groups.length>0'
                                  size='mini'>
                    <el-radio-button :label='group.name' v-for='(group,index) in groups' :key='index'>
                      {{ group.display_name }}（{{ group.artists_count || 0 }}）
                    </el-radio-button>
                  </el-radio-group>
                  <span v-else><i style='color: #909399;font-size: 0.8rem'>暂无：可联系管理员绑定分组</i></span>
                </div>
              </el-form-item>
              <el-form-item label='红人：'>
                <el-radio-group v-model='searchCondition.artist_id' @change='showRecords' v-if='artists.length>0'
                                size='mini'>
                  <el-radio-button :label='artist.id' v-for='(artist,idx) in artists' :key='idx'>{{
                      artist.nickname
                    }}
                  </el-radio-button>
                </el-radio-group>
                <span v-else><i style='color: #909399;font-size: 0.8rem'>--</i></span>
              </el-form-item>

            </el-form>
          </el-col>
          <el-col :span='2' style='text-align: right'>
            <export-link class-name='export-excel' :lint-title='`导出表格`' ref='refExportLink' @export='exportExcel'
                         :can-export="userPermissions.indexOf('revenue_export') !== -1"></export-link>
          </el-col>
        </el-row>

        <el-row :gutter='1'>
          <!--   table列表     -->
          <RevenueRecord ref='RevenueRecord' :platforms='platforms' />
          <br />
          <el-row>
            <el-col>
              <el-link type='warning' :underline='false'> 注意：本数据也用于本组绩效核算，请务必仔细核对后再进行提交</el-link>
            </el-col>
          </el-row>
          <br />

          <RevenueTips/>

          <!--    日志      -->
          <RevenueDataLog :searchCondition="searchCondition"></RevenueDataLog>

        </el-row>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import RevenueRecord from '@/pages/revenue/data/RevenueRecord'
import RevenueDataLog from '@/pages/revenue/RevenueDataLog'
import ExportLink from '@/components/export/ExportLink'
import RevenueTips from '@/pages/revenue/RevenueTips'

export default {
  name: 'RevenueDataList',
  components: {
    RevenueTips,
    ExportLink,
    RevenueDataLog,
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    RevenueRecord
  },
  data() {
    return {
      groups: [],//当前页面展示的分组列表
      artists: [],//可选红人列表
      revenueRecordKey: 'revenueRecordKey',
      loadingStatus: false,
      platforms: [],
      searchCondition: {
        is_all: 'N',
        year: null,
        group_id: null,
        artist_id: null
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  created() {
    this.getPageSettings()
  },
  mounted() {
  },
  watch: {
    '$route.matched': function(n) {
      if (n.length === 2) {
        // this.initList()
      }
    }
  },
  methods: {
    async exportExcel() {
      try {
        let searchCondition = this.searchCondition
        if (searchCondition.year && searchCondition.artist_id) {
          let artist = this.artists.find((value) => value.id === searchCondition.artist_id)
          let group = this.groups.find((value) => value.name === searchCondition.group_id)

          let response = await this.$api.exportRevenueDataList(searchCondition)

          let name = `营收数据[${searchCondition.year}_${artist.nickname}(${group.display_name})]`
          if (this.$refs['refExportLink'])
            this.$refs['refExportLink'].exportSaveXlsx(response, name)
        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    getCurrentYear() {
      let currentDate = new Date()
      this.searchCondition.year = currentDate.getFullYear() + ''
    },
    async showGroups() {
      await this.getGroupArtists()
      this.showRecords()
    },
    changePlatFormRange() {
      this.showRecords()
    },
    showRecords() {
      //检索分组
      // let group = this.groups.find((value) => value.name === this.searchCondition.group_id)
      // this.searchCondition.group_name = group ? group.display_name : ''
      // 筛选
      this.$nextTick(() => {
        if (this.$refs['RevenueRecord'])
          this.$refs['RevenueRecord'].initData(this.searchCondition)
      })
    },
    async getPageSettings() {
      //页面配置-依次加载 我的分组-》红人-》平台-》当前月份
      await this.getMyGroup()
      await this.getPlatforms()
      await this.getCurrentYear()
      this.showRecords()
    },
    async getMyGroup() {
      let { groups } = await this.$api.getMyUserMcnGroup('revenue_data')
      this.groups = groups
      if (this.groups.length > 0) {
        //有所属分组，默认第一个分组数据展示
        this.searchCondition.group_id = this.groups[0]['name']
        await this.getGroupArtists()
      }
    },
    async getGroupArtists() {
      this.searchCondition.artist_id = null
      let groupId = this.searchCondition.group_id
      let { list } = await this.$api.getGroupArtists(groupId)
      this.artists = list
      if (list.length > 0) {
        this.searchCondition.artist_id = list[0].id
      }
    },
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platforms = list
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      return condition
    }
  }
}
</script>

<style scoped>
.searchCondition {
  margin-bottom: 10px;
}
</style>
<style>

.platforms-tab .el-tabs__item {
  display: block;
  margin: 1px 1px;
  line-height: 25px;
  font-size: 1rem;
  /*word-wrap: break-word;*/
  writing-mode: horizontal-tb;
  height: 80px;
  padding-left: 10px;
  padding-top: 10px;
}

.export-excel {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: 600;
}

.el-select > .el-input {
  width: 200px !important;
}
</style>
