<template>
  <div>
    <Tips :data='tipsList' />
  </div>
</template>

<script>
export default {
  name: 'RevenueTips',
  data() {
    return {
      tipsList: [
        '总营收 = 保量收入 + 商务营收 + 短视频佣金 + 直播佣金 + 直播坑位费 ',
        '当期盈亏 = 当期总营收 - 当期总推广花费',
        '平均单价 = 商务营收 / 执行商单数'
      ]
    }
  }
}
</script>

<style scoped>

</style>
