<template>
  <div>
    <div>
      <PageHeaderLayout>
        <div class='main-page-content'>
          <br />
          <div>
            <el-link @click='getList' icon='el-icon-refresh'>刷新</el-link>
          </div>
          <br />
          <el-row :gutter='15'>
            <el-col :span='6'>
              <div class='default-table'>
                <m-table :table-data='dataList' highlight-current-row border>
                  <el-table-column label='序号' type='index' align='center' width='50'></el-table-column>
                  <el-table-column label='模板名称' align='center' prop='description'>
                    <template slot-scope='{row}'>
                      <el-link @click='showSettingMembers(row)'>{{ row.description }}</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column label='模板流程设置' align='center'>
                    <template slot-scope='{ row }'>
                      <el-button type='text' @click='getTempData(row)'>设置<i
                        class='el-icon-edit-outline'></i>
                      </el-button>
                      <el-button v-if='row.setting' type='text' @click='delSetting(row)'>重置</el-button>
                      <el-button v-if='row.setting' type='text' @click='copySetting(row)'>复制</el-button>
                    </template>
                  </el-table-column>
                </m-table>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col v-if='tempData.tpl_id'>
              <div class='setting'>
                <el-card class='box-card' shadow='never'>
                  <div slot='header' class='clearfix'>
                    <span>{{ template.description }}</span>
                    <el-button @click='saveBtn' :loading='loadingCommit' style='float: right; padding: 3px 0;'
                               type='text'>保存
                    </el-button>
                  </div>
                  <div class='time-line'>
                    <div class='time-start'>
                      <div class='time-title'>发起人</div>
                      <div class='tile-content'>发起人固定</div>
                    </div>
                    <div class='time-arrow'>
                      <div class='time-add'>
                        <el-popover placement='right' width='250' trigger='click'>
                          <el-icon slot='reference' class='el-icon-plus'></el-icon>
                          <el-button @click="addTypes('Approve')">审批人</el-button>
                          <el-button @click="addTypes('Cc')">抄送人</el-button>
                          <el-button @click="addRouteType('route')">条件分支</el-button>
                        </el-popover>
                      </div>
                      <div class='time-arrow-line'></div>
                    </div>
                  </div>
                  <div>
                    <!-- 默认条件-->
                    <SettingPanel ref='panel' v-if='showStart' :data.sync='tempData.settings'></SettingPanel>
                    <!-- 条件分支的代码-->
                    <SettingRouteBox v-else
                                     :conditionNodes.sync='tempData.settings.childNode.conditionNodes'></SettingRouteBox>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
          <br />
          <el-link @click='showData = !showData' type='primary'>显示数据</el-link>
          <div v-if='showData'>
            <div>
              <el-input type='textarea' rows='30' :value='JSON.stringify(tempData, null, 4)'></el-input>
            </div>
            <el-row>
              <el-col :span='12'>
                <div style='margin: 10px'>
                  <h3>提交的配置信息</h3>
                  <el-input type='textarea' rows='30' :value='JSON.stringify(tempData, null, 4)'></el-input>
                </div>
              </el-col>
              <el-col :span='12'>
                <div style='margin: 10px'>
                  <h3>解析后的配置信息（H5使用）</h3>
                  <el-input type='textarea' rows='30' :value='JSON.stringify(settingMembers, null, 4)'></el-input>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-dialog
              title="请选择要复制的模版"
              :visible.sync="dialogVisible"
              width="30%"
              destroy-on-close
          >
            <el-select v-model="copyId" placeholder="请选择">
              <el-option
                  v-for="(item) in dataList"
                  :key="item.id"
                  :label="item.description"
                  :value="item.setting_id">
              </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible=false">取 消</el-button>
    <el-button type="primary" @click="handleCopy">确 定</el-button>
  </span>
          </el-dialog>
        </div>
      </PageHeaderLayout>
    </div>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import UserSelect from '@/pages/commerce/components/UserSelect'
import SettingRouteBox from '@/pages/oaManage/SettingRouteBox'
import SettingPanel from '@/pages/oaManage/SettingPanel'

export default {
  name: 'Setting',
  components: { SettingPanel, PageHeaderLayout, UserSelect, SettingRouteBox },
  data() {
    return {
      dataList: [],
      //选择的模板
      template: {},
      member_type_map: {
        custom: '发起人自选',
        supervisor: '直属主管',
        director: '部门主管',
        fix: '指定成员'
      },
      drawer: false,
      loadingCommit: false,
      //流程设置表单
      tempData: { id: null, tpl_id: null, settings: null },
      showData: false,
      settings: {},
      settingMembers: {},
      showStart: true,
      childNode: null,
      dialogVisible: false,
      copyValue: {},
      copyId: null
    }
  },
  // computed: {
  //   isMulti() {
  //     //是否多流程
  //     if (this.tempData.settings) {
  //       if (this.tempData.settings.childNode) {
  //         if (this.tempData.settings.childNode.conditionNodes.length > 0) {
  //           return true
  //         }
  //       }
  //     }
  //     return false
  //   }
  // },
  watch: {
    'tempData.settings.childNode': {
      handler(val) {
        if (!val) {
          this.showStart = true
        } else if (val?.conditionNodes && val?.conditionNodes.length > 0) {
          this.showStart = false
        }
      },
      deep: true
    }
  },
  methods: {

    formatString(value) {
      let returnData = ''
      switch (value) {
        case 'lowerBound' :
          returnData = '小于'
          break
        case 'upperBound' :
          returnData = '大于'
          break
        case 'lowerBoundEqual' :
          returnData = '小于等于'
          break
        case 'upperBoundEqual' :
          returnData = '大于等于'
          break
        case 'boundEqual' :
          returnData = '等于'
          break
      }
      return returnData
    },
    async saveData() {
      // this.tempData.settings = this.settings
      let id = await this.$api.oaTemplateSaveInfo(this.tempData)
      if (id) {
        if (this.tempData.id) {
          this.$message.success('修改成功')
        } else {
          this.tempData.id = id
          this.$message.success('新增成功')
        }
        await this.getList()
        this.loadingCommit = false
      }
    },

    saveBtn() {
      // this.loadingCommit = true
      let start = this.showStart
      let childNode = this.tempData.settings?.childNode
      if (!childNode&&!this.showStart) {
        this.$notify.error('保存至少需要一个分支')
      }
      if (!start) {
        //这里是条件分支流程验证
        let conditionNodes = this.tempData.settings?.childNode?.conditionNodes
        let data = conditionNodes.map((item, index) => {
          if (index != conditionNodes.length - 1) {
            let conditions = item.properties?.conditions
            if (conditions?.length === 0) {
              this.$notify.error(`${item.name}条件请添加条件`)
              return false
            }
          }
          let childNode = item.properties?.childNode
          if (!childNode && !childNode?.length) {
            this.$notify.error(`${item.name}条件分支下请添加审批人`)
            return false
          } else {
            return true
          }
        })
        if (!data.includes(false)) {
          this.saveData()
        }
      } else {
        this.saveData()
      }
    },
    async getList() {
      let { list } = await this.$api.oaTemplateList()
      this.dataList = list
    },
    showSettingMembers(row) {
      this.getTempData(row)
      if (this.showData)
        this.getProcessSetting(row.name, '98894360', 1000)
    },
    hasChildren(item) {
      return !!item?.childNode
    },

    async getProcessSetting(tpl_name, dpt_id, money) {
      this.settingMembers = await this.$api.oaTemplateProcessSetting({
        tpl_name: tpl_name,
        dpt_id: dpt_id,
        money: money
      })
    },
    async getTempData(row) {
      this.template = row
      this.tempData = { id: null, tpl_id: null, settings: null }
      this.tempData.tpl_id = row.id
      let id = row.setting ? row.setting.id : null
      if (id) {
        let { info } = await this.$api.oaTemplateGetInfo({ id: id })
        this.tempData.id = info.id
        this.tempData.settings = { ...info.settings }
      }
    },
    async delSetting(row) {
      this.$confirm(`是否重置 ${row.description} 模板?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (row.setting) await this.$api.oaTemplatedelSetting(row.setting.id)
        await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },

    addTypes(type) {
      this.showStart = true
      let childNode = this.tempData.settings?.childNode
      if (childNode) {
        if (childNode?.conditionNodes) {
          childNode = null
        }
        this.$set(this.tempData, 'settings', {
          childNode: {
            member_type: 'custom',
            members: [],
            nodeId: Math.random(),
            tpl_type: type,
            childNode: childNode
          }
        })
      } else {
        this.$set(this.tempData, 'settings', {
          childNode: {
            member_type: 'custom',
            members: [],
            nodeId: Math.random(),
            tpl_type: type
          }
        })
      }

      // if (this.$refs.panel)
      //   this.$refs.panel.addType(type, -1)
    },
    addRouteType() {
      this.showStart = false
      // let data = {}
      if (this.tempData.settings?.childNode?.conditionNodes) {
        let conditionNodes = this.tempData.settings?.childNode?.conditionNodes
        // console.log(conditionNodes)
        let index = conditionNodes.length
        let node = {
          name: `条件${index}`,
          type: 'condition',
          properties: {
            conditions: [],
            childNode: null
          }
        }
        //数组前面添加一个节点
        this.tempData.settings.childNode.conditionNodes.splice(index - 1, 0, node)
      } else {
        this.tempData.settings = {
          name: '发起人',
          type: 'route',
          nodeId: Math.random(),
          childNode: {
            nodeId: Math.random(),
            conditionNodes: [{
              name: '条件1',
              type: 'condition',
              properties: {
                conditions: [],
                childNode: null
              }
            },
              {
                name: '其他条件',
                type: 'condition',
                properties: {
                  conditions: [],
                  childNode: null
                }
              }]
          }
        }
      }
    },
    copySetting(row) {
      this.copyValue = {
        id: row.setting_id,
        tpl_id: row.id
      }
      this.dialogVisible = true
    },
    async handleCopy() {
      if (this.copyId) {
        let {info} = await this.$api.oaTemplateGetInfo({id: this.copyId})
        let settings = {...info.settings}
        this.tempData = {
          ...this.copyValue,
          settings
        }
        await this.saveData()
        this.dialogVisible = false
        this.copyId = null

      }
    }
  }
  ,
  mounted() {
    this.getList()
  }
}
</script>

<style lang='scss' scoped>

.router-box {
  width: 100%;
  display: flex;
  justify-content: center;


  .col-box {
    width: 220px;
    //border: 1px solid red;
    float: left;
    position: relative;

    .col-box:before {
      content: '';
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      margin: auto;
      width: 2px;
      height: 100%;
      background-color: #e4e7ed;
    }

    .top-cover-line {
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: #e4e7ed;
      top: -2px;
      left: -1px;
    }

    .top-vertical-line {
      width: 2px;
      height: 50px;
      background-color: #e4e7ed;
      position: relative;
      left: calc(50% - 2px)

    }

    .condition-node {
      min-height: 140px;
      width: 200px;
      margin: 0 auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-flex: 1;
    }
  }

  .col-first {
    .top-cover-line {
      width: 50%;
      left: calc(50% - 2px);
    }
  }

  .col-final {
    .top-cover-line {
      width: 50%;
    }
  }
}


.time-start {
  margin: 0 auto;
  width: 200px;
  height: 80px;
  //border: 1px solid silver;
  border-top: none;
  display: block;
  clear: both;

  .time-title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    padding-left: 20px;
    color: white;
    background-color: rgb(63, 76, 108);
    z-index: 2;
    position: relative;
  }

  .tile-content {
    padding-left: 20px;
    border: 1px solid silver;
    border-top: none;
    border-radius: 4px;
    margin-top: -4px;
    height: 50px;
    line-height: 50px;
  }
}

.time-approve {
  cursor: pointer;

  .time-title {
    background-color: rgb(245, 154, 35);
  }
}

.time-cc {
  cursor: pointer;

  .time-title {
    background-color: #409eff;
  }
}

.time-close {
  font-size: 18px;
  float: right;
  cursor: pointer;
  margin-right: 8px;
}


.drawer-content {
  padding: 0 20px;
}

.box-card {
  overflow: scroll;
}

.time-arrow {
  position: relative;
  height: auto;

  .time-arrow-line {
    height: 80px;
    border-left: 2px solid #e4e7ed;
    width: 2px;
    margin: 0 auto;
  }

  .triangle {
    width: 0;
    height: 0;
    border: 15px solid;
    margin: 0 auto;
    border-color: #e4e7ed rgb(0, 0, 0, 0) rgb(0, 0, 0, 0) rgb(0, 0, 0, 0);
  }

  .routerRight {
    border-right: 1px solid #e4e7ed;
    float: right;
  }

  .time-add {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #409eff;
    color: white;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    left: calc(50% - 15px);
    top: 24px;
    cursor: pointer;
    z-index: 99;
  }

  .time-add:hover {
    box-shadow: 1px 1px #c0c4cc;
    -webkit-transition-duration: 0.4s;
    transform: perspective(1px) translateZ(0);
  }
}


.time-arrow-end-text {
  text-align: center;
  margin-top: 10px;
  color: #909399;
}


.drawer-content {
  padding: 0 20px;
}

.time-route {
  border: 1px solid #e4e7ed;
  min-height: 100px;
  max-height: 200px;


  > * {
    padding: 10px;
  }

  .time-title {
    color: #2d815d;
  }


}

.time-arrow {
  position: relative;
  height: auto;

  .time-arrow-line {
    height: 80px;
    border-left: 2px solid #e4e7ed;
    width: 2px;
    margin: 0 auto;
  }

  .triangle {
    width: 0;
    height: 0;
    border: 15px solid;
    margin: 0 auto;
    border-color: #e4e7ed rgb(0, 0, 0, 0) rgb(0, 0, 0, 0) rgb(0, 0, 0, 0);
  }

  .routerRight {
    border-right: 1px solid #e4e7ed;
    float: right;
  }

  .time-add {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #409eff;
    color: white;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    left: calc(50% - 15px);
    top: 24px;
    cursor: pointer;
    z-index: 99;
  }

  .time-add:hover {
    box-shadow: 1px 1px #c0c4cc;
    -webkit-transition-duration: 0.4s;
    transform: perspective(1px) translateZ(0);
  }
}

.time-arrow-end {
  .triangle {
    width: 11px;
    height: 11px;
    margin: 0 auto;
    border-radius: 50%;
    border: 0 solid #e4e7ed;
    background-color: #e4e7ed;
  }

  .time-arrow-end-text {
    text-align: center;
    margin-top: 10px;
    color: #909399;
  }
}

.triangle {
  width: 0;
  height: 0;
  border: 15px solid;
  margin: 0 auto;
  border-color: #E4E7ED rgb(0, 0, 0, 0) rgb(0, 0, 0, 0) rgb(0, 0, 0, 0);
}
</style>
