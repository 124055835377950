<template>
  <div class="handle-button">
    <el-button :type="buttonType" size="mini" @click="handleAdd" round>添加</el-button>
    <el-dialog :title="`${form.id?'修改':'新增'}爆品里程碑`" :visible.sync="show" center append-to-body
               custom-class="explosive-products-store-container">
      <el-form v-loading="loading" ref="form" :model="form" :rules="rules" size="medium"
               label-position="right" label-width="70px">
        <el-form-item label="商品：" prop="product_name">
          <select-product :key="key.SelectProduct" :product-id.sync="form.product_id"
                          :product-name.sync="form.product_name"
                          @change="eventChangeProduct" />
        </el-form-item>
        <el-form-item label="品牌：" prop="brand_id">
          <select-product-brand :key="key.SelectProductBrand" :value.sync="form.brand_id"
                                :label.sync="form.brand_name" />
        </el-form-item>
        <el-form-item label="GMV：" prop="gmv">
          <ml-input :value.sync="form.gmv" num placeholder="请填写GMV" />
        </el-form-item>
        <el-form-item label="销售：" prop="sale_id">
          <span v-if="onlySelf">{{ form.sale_name }}</span>
          <select-sale v-else :key="key.SelectProductSale" :value.sync="form.sale_id" :label.sync="form.sale_name" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <ml-button :disabled="loading" text='确 定' @click.native='handleSubmit' />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SelectProductCategory from '@/components/select/product-category.vue'
import SelectProductBrand from '@/components/select/product-brand.vue'
import SelectProduct from '@/components/select/product.vue'
import SelectSale from '@/components/select/user.vue'
import MlButton from '@/components/MlButton/index.vue'
import SaveCategorySub from '@/pages/category_module/save-category-sub.vue'
import SaveCategory from '@/pages/category_module/save-category.vue'
import { mapGetters } from 'vuex'
import { explosiveProductSave } from '../../../api/explosive-products'
import MlInput from '../../../components/MlInput/index.vue'

const { id: sale_id, name: login_name, nickname: login_nickname } = JSON.parse(localStorage.getItem('user_info'))
const sale_name = login_name || login_nickname

const genKey = function() {
  return (new Date()).getTime()
}
const initKey = function() {
  return {
    SelectProduct: genKey(),
    SelectProductBrand: genKey(),
    SelectCategorySub: genKey(),
    SelectProductSale: genKey()
  }
}
const Model = {
  id: null,
  product_id: '',
  product_name: null,
  brand_id: null,
  brand_name: null,
  gmv: null,
  sale_id: null,
  sale_name: null
}
const rules = {
  product_name: [
    { required: true, message: '请填写商品', trigger: ['blur', 'change'] },
    { min: 1, max: 64, message: '长度在 1 到 64 个字符', trigger: ['blur', 'change'] }
  ],
  gmv: [{ required: true, message: '请填写GMV', trigger: ['blur', 'change'] }],
  brand_id: [{ required: true, message: '请填写品牌', trigger: ['blur', 'change'] }],
  sale_id: [{ required: true, message: '请填写销售', trigger: ['blur', 'change'] }]
}


export default {
  name: 'EmphasisProductsStore',
  components: {
    MlInput,
    SaveCategorySub,
    SaveCategory, MlButton, SelectSale, SelectProductBrand, SelectProductCategory, SelectProduct
  },
  props: {
    buttonType: {
      type: String,
      default() {
        return 'primary'
      }
    }
  },
  data() {
    return {
      rules,
      show: false,
      loading: false,
      is_just_edit: false,
      key: initKey(),
      form: { ...{}, ...Model }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    onlySelf() {
      return this.userPermissions.indexOf('explosive_products_manage__save_all') === -1
    }
  },
  watch: {
    'form.category'() {
      this.getOptionsCategorySub()
    },
    form: {
      deep: true,
      handler() {
        const { product_name, brand_name, sale_name } = this.form
        if (product_name && brand_name && sale_name) {
          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      }
    }
  },
  methods: {
    eventChangeProduct: function(product) {
      if (this.is_just_edit) {
        this.is_just_edit = false
        return
      }
      if (product) {
        const { brand_id } = product
        this.form.brand_id = brand_id || null
        this.key.SelectProductBrand = genKey()
        this.key.SelectProductSale = genKey()
      }
    },
    handleAdd: async function() {
      this.is_just_edit = false
      this.form = { ...{}, ...Model }
      if (this.onlySelf) {
        this.form.sale_id = sale_id
        this.form.sale_name = sale_name
      }
      this.show = true
      await this.$nextTick()
      this.$refs.form && this.$refs.form.clearValidate()
    },
    handleEdit: async function(obj) {
      this.show = true
      this.is_just_edit = true
      this.loading = true
      this.form = { ...{}, ...obj }
      this.key = initKey()
    },
    handleSubmit: function() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await explosiveProductSave(this.form)
          this.show = false
          this.$message.success('操作成功')
          this.$emit('success', !!this.form.id)
        } else {
          return false
        }
      })
    }
  }
}

</script>
<style lang="scss">
.explosive-products-store-container {
  border-radius: 20px;
  width: 800px;

  .el-form {
    width: 80%;
    margin: 0 auto;
  }

  .el-select, .el-cascader {
    width: 100%;
  }

  .select-category {
    width: calc(100% - 46px);
    margin-right: 10px;
  }
}
</style>
