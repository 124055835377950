<template>
  <el-form inline :model="form" class="lb-stat-search">
    <el-form-item>
      <div class="ml-tabs-box">
        <div :class="`ml-tab ${form.by==='year'?'ml-tab_active':''}`" @click="form.by='year'">
          按年
        </div>
        <div :class="`ml-tab ${form.by==='month'?'ml-tab_active':''}`" @click="form.by='month'">
          按月
        </div>
        <div class="ml-tab_active-bg" :style="`${form.by==='year'?'left: 2px;':'right: 2px;'}`" />
      </div>
      <el-date-picker
        :key="form.by" v-model="form.date" :type="form.by" value-format="yyyy-MM" size="mini"
        style="width: 128px;margin-left: 10px;"
        :clearable="false"
        @change='onSubmit'
      />
    </el-form-item>
    <el-form-item>
      <el-input v-model="form.brand_name" placeholder="搜索品牌" @change='onSubmit' />
    </el-form-item>
    <el-form-item>
      <el-input v-model="form.product_name" placeholder="搜索产品名称" @change='onSubmit' />
    </el-form-item>
    <el-form-item>
      <el-select v-model='form.leader_id' filterable placeholder='搜索负责人' @change='onSubmit' clearable>
        <el-option :label='item.label' :value='item.value' v-for='(item,index) in searchLeaderOptions' :key='index' />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-select v-model='form.product_type' filterable placeholder='搜索美妆/服饰' @change='onSubmit' clearable>
        <el-option label='美妆' :value='1' />
        <el-option label='服饰' :value='2' />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" round @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import moment from 'moment'

/**
 * 计算月份
 * @param by
 * @param date
 * @returns {*[]}
 */
const calcMonths = function(by, date) {
  let months = []
  if (by === 'year') {
    const year = moment(date).year()
    for (let i = 1; i <= 12; i++) {
      const month = moment().year(year).month(i - 1) // Moment.js中月份是从0开始的
      months.push(month.format('YYYY-MM-01')) // 以"年-月"格式保存
    }
  } else {
    months = [moment(date).format('YYYY-MM-01')]
  }
  return months
}

export default {
  name: 'LbStatSearch',
  props: {
    tab: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      form: {
        by: 'year',
        date: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        leader_id: null,
        brand_name: null,
        product_name: null,
        product_type: null,
        orderBy: {},
        only_choose_up: 0
      },
      searchLeaderOptions: []
    }
  },
  watch: {
    'form.by'() {
      this.onSubmit()
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: function() {
      this.getLbLeaderLdOptions()
      if (this.tab === 'CHOOSE_PRODUCT') {
        this.form.orderBy = { num_choose: 'desc' }
        this.form.type = 1
      } else if (this.tab === 'UP_PRODUCT') {
        this.form.orderBy = { num_choose: 'desc' }
        this.form.type = 2
      } else {
        this.form.type = null
      }
      this.onSubmit()
    },
    async getLbLeaderLdOptions() {
      let { list } = await this.$api.productLeaderOptions('product')
      this.searchLeaderOptions = list
    },
    onSubmit: function() {
      // 处理月份
      this.form.months = calcMonths(this.form.by, this.form.date)
      this.$emit('search', this.form)
    }
  }
}
</script>
<style lang="scss">
.lb-stat-search {
  margin: 10px 0;

  .ml-tabs-box {
    display: inline-block;
    line-height: 0;
    position: relative;
    vertical-align: middle;
    min-width: 64px;
    --mx-tabs-box-shadow-gap: 2px;
    --input-h-gap: var(--btn-h-gap, 8px);
    --input-min-width: var(--btn-min-width, 48px);
    --input-font-size: var(--btn-font-size, 12px);
    border-radius: 500px;
    background-color: #f8f9fa;
    overflow: visible;

    .ml-tab.ml-tab_active {
      background-color: transparent;
      color: #ff3176;
    }

    .ml-tab:first-child {
      border-bottom-left-radius: var(--border-radius);
      border-top-left-radius: var(--border-radius);
    }

    .ml-tab {
      min-width: 68px;
      align-items: center;
      background-color: transparent;
      color: var(--mx-tabs-box-color, #666);
      cursor: pointer;
      display: inline-flex;
      font-size: 12px;
      justify-content: center;
      margin: 2px;
      padding: 0 var(--input-h-gap, 12px);
      position: relative;
      z-index: 3;
      height: 26px;
      transition: all 0.2s;
    }

    .ml-tab:hover {
      background-color: #fff4f8;
      border-radius: 500px;
      color: #333;
    }

    .ml-tab_active-bg {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .08);
      background-color: #fff;
      border-radius: 500px;
      pointer-events: none;
      position: absolute;
      z-index: 2;
      height: calc(100% - 4px);
      top: 2px;
      width: 84px;
    }
  }
}
</style>
