export default {
  path: 'inquiries',
  name: 'inquiries',
  component: () => import('@/pages/inquiries/index.vue'),
  meta: { title: '询单' },
  children: [
    {
      path: 'list',
      name: 'inquiriesList',
      component: () => import('@/pages/inquiries/inquiryList'),
      meta: { title: '普通询单' }
    },
    {
      path: 'YearFrame',
      name: 'YearFrame',
      component: () => import('@/pages/inquiries/yearInquiry.vue'),
      meta: { title: '年框大全案' }
    },
    {
      path: ':id/inquiriesDetail',
      name: 'inquiriesDetail',
      component: () => import('@/pages/inquiries/InfoDetail'),
      meta: { title: '项目详情' }
    }
  ]
}
