<template>
  <el-dialog :title="`${cmd==='add'?'新增':'编辑'}品类`" :visible.sync='visible' width='500px' center
             custom-class='save-category-sub-dialog' append-to-body modal-append-to-body>
    <el-form ref='form' :model='form' label-width='60px' size='medium'>
      <el-form-item v-for='(child,child_i) in form.children'
                    :key='child_i' :prop='`children[${child_i}].display_name`'
                    :rules="{ required: true, message: '请输入品类名称', trigger: 'blur' }">
        <el-input v-model='child.display_name' placeholder='请输入品类名称' maxlength='8' show-word-limit
                  style='width: calc(100% - 46px)' />
      </el-form-item>
    </el-form>
    <div slot='footer' class='dialog-footer'>
      <el-button @click='visible = false' size='medium'>取 消</el-button>
      <el-button type='primary' @click='handleSubmit' size='medium'>确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>

import { popularCasesCategorySave } from '@/api/popular_case'
import { emphasisProductsCategorySave } from '../../api/emphasis_products_category'

const ModelForm = {
  children: [{ display_name: null }]
}
export default {
  name: 'SaveCategory',
  props: {
    tbl: {
      type: String,
      default() {
        return 'popular_cases_category'
      }
    }
  },
  data() {
    return {
      api: {
        save: popularCasesCategorySave
      },
      visible: false,
      options: [],
      cmd: 'add',// add|edit
      form: { ...{}, ...ModelForm }
    }
  },
  watch: {
    'tbl': {
      immediate: true,
      handler(_) {
        if (_ === 'popular_cases_category') {
          // 爆款案例
          this.api = {
            save: popularCasesCategorySave
          }
        } else {
          // 重点大全案品
          this.api = {
            save: emphasisProductsCategorySave
          }
        }
      }
    }
  },
  methods: {
    /**
     * 新增
     * 给父组件用
     */
    interfaceAdd: function() {
      this.cmd = 'add'
      this.form = { ...{}, ...ModelForm }
      this.form.children = [{ display_name: null }]
      this.visible = true
    },
    /**
     * 编辑
     * 给父组件用
     * @param display_name
     * @param id
     */
    interfaceEdit: function(display_name, id) {
      this.cmd = 'edit'
      this.form = { ...{}, ...ModelForm }
      this.form.children = [{ display_name, id }]
      this.visible = true
    },
    handleSubmit: function() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await this.api.save(this.form)
          this.$message.success('操作成功')
          this.visible = false
          this.$emit('success', this.form.children[0].display_name)
        }
      })
    }
  }
}
</script>
<style lang='scss'>
.save-category-sub-dialog {
  border-radius: 20px;
}
</style>
