<template>
  <div class="tss-custom-return">
    <div class="tss-custom-return_money" @click="handleShow">
      <span class="num-ph">￥{{ row.custom_return|numberFormat(2) }}</span>
      <i class="el-icon-edit" />
    </div>

    <el-dialog
      title="设置结算后退款"
      :visible.sync="show"
      center
      custom-class="tss-custom-return-container"
      append-to-body
    >
      <el-form ref="form">
        <el-form-item label="结算月份：">{{ row._month || '-' }}</el-form-item>
        <el-form-item label="红人昵称：">{{ row.artist_nickname || '-' }}</el-form-item>
        <el-form-item label="结算金额：">
          ￥<span class="num-ph">{{ row.settle_amount|numberFormat(2) }}</span>
        </el-form-item>
        <el-form-item label="结算后退款：">
          <ml-input :value.sync="custom_return" num placeholder="请填写结算后退款金额" style="width: 200px" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MlInput from '../../../../components/MlInput/index.vue'
import { tradeStatsSettleSetCR } from '../../../../api/trade_stat'

export default {
  name: 'TradeStatSettleCustomReturn',
  components: { MlInput },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      custom_return: null
    }
  },
  created() {
    this.custom_return = this.row.custom_return || 0
  },
  methods: {
    handleShow: function() {
      this.show = true
    },
    handleSubmit: async function() {
      await tradeStatsSettleSetCR({ id: this.row.id, custom_return: this.custom_return })
      this.show = false
      this.$message.success('操作成功')
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss">
.tss-custom-return {
  &_money {
    cursor: pointer;

    i {
      margin-left: 8px;
      color: #ff3176;
      opacity: 0;
    }
  }

  &_money:hover {
    i {
      opacity: 1;
    }
  }

}

.tss-custom-return-container {
  width: 400px;
}
</style>
