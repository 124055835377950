<template>
  <div class="handle-button" style="display: inline">
    <span v-if="text" class="span-btn" @click="handleShow">点击配置</span>
    <el-button v-else round type="primary" @click="handleShow">规则配置</el-button>
    <el-drawer
      :visible.sync="show"
      :close-on-press-escape="false"
      :title="`${month_txt}规则配置`"
      custom-class="trade-stat-config-drawer"
      modal-append-to-body
      append-to-body
    >
      <el-form v-loading="loading" ref="form" :model="form" label-width="80">
        <el-tabs v-model="tab">
          <div class="tip">
            <div>配置规则说明：</div>
            <ul>
              <li>订单状态：支持多选，选择的，不合计到总GMV</li>
              <li v-if="tab==='douyin'">流量来源：支持多选，选择的，不合计到总GMV</li>
              <li>店铺名称：支持多选，选择的，不合计到总GMV</li>
              <li>用户实际支付金额，小于等于填写金额值的，不合计到总GMV</li>
            </ul>
          </div>
          <el-tab-pane v-for="(item,platform_code) in MAP_CONFIG" :label="item.platform_name" :name="platform_code"
                       :key="platform_code">
            <el-form-item v-has="{platform_code,field:'order_status__not_in'}" label="订单状态"
                          prop="order_status__not_in">
              <el-select v-model="form[platform_code].order_status__not_in" placeholder="选择要排除的订单状态" multiple
                         style="width: 100%;">
                <el-option v-for="option in options.order_status" :key="option.value" :value="option.value"
                           :label="option.label" />
              </el-select>
            </el-form-item>
            <el-form-item v-has="{platform_code,field:'flow_source__not_in'}" label="流量来源"
                          prop="flow_source__not_in">
              <el-select v-model="form[platform_code].flow_source__not_in" placeholder="选择要排除的流量来源" multiple
                         style="width: 100%;">
                <el-option v-for="option in options.flow_source" :key="option.value" :value="option.value"
                           :label="option.label" />
              </el-select>
            </el-form-item>
            <el-form-item v-has="{platform_code,field:'shop_id__not_in'}" label="店铺名称" prop="shop_id__not_in">
              <el-select v-model="form[platform_code].shop_id__not_in" placeholder="选择要排除的店铺" multiple
                         filterable
                         style="width: 100%;">
                <el-option v-for="option in options.shop" :key="option.value" :value="option.value"
                           :label="option.label" />
              </el-select>
            </el-form-item>
            <el-form-item v-has="{platform_code,field:'amount__max'}" label="成交金额">
              小于等于
              <ml-input :value.sync="form[platform_code].amount__max" num append-button="元" style="width: 120px;" />
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <div class="footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button v-loading="loading" type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import MlInput from '@/components/MlInput/index.vue'
import { tradeStatsGetConfig, tradeStatsSetConfig } from '@/api/trade_stat'
import { optionsTrade } from '@/api/trade'
import MAP_CONFIG from './index'
import { parseTime } from '@/utils'

const checkHasField = function(el, binding) {
  const { value } = binding
  const { platform_code, field } = value
  const has = Object.values(MAP_CONFIG[platform_code].fields).includes(field)

  if (!has) {
    el.parentNode && el.parentNode.removeChild(el)
  }
}

const Model = {
  douyin: {
    order_status__not_in: null,
    flow_source__not_in: null,
    shop_id__not_in: null,
    amount__max: 0
  },
  xiaohongshu: {
    order_status__not_in: null,
    shop_id__not_in: null,
    amount__max: 0
  },
  kuaishou: {
    order_status__not_in: null,
    shop_id__not_in: null,
    amount__max: 0
  }
}

export default {
  name: 'TradeStatConfig',
  components: { MlInput },
  props: {
    month: {
      type: String,
      required: true
    },
    text: Boolean
  },
  data() {
    return {
      MAP_CONFIG,
      show: false,
      loading: false,
      tab: 'douyin',
      form: { ...{}, ...Model },
      options: {
        shop: [],
        flow_source: [],
        order_status: []
      }
    }
  },
  computed: {
    month_txt() {
      return parseTime(this.month, '{y}年{m}月')
    }
  },
  watch: {
    tab: {
      immediate: true,
      handler() {
        this.getOptions()
      }
    },
    month() {
      this.getOptions()
    }
  },
  directives: {
    'has': {
      inserted(el, binding) {
        checkHasField(el, binding)
      },
      update(el, binding) {
        checkHasField(el, binding)
      }
    }
  },
  created() {
  },
  methods: {
    getOptions: async function() {
      this.loading = true
      const response = await optionsTrade({ fields: Object.keys(this.options), platform_code: this.tab })
      setTimeout(() => {
        this.loading = false
      }, 10)
      this.options = response || {}
    },
    handleShow: async function() {
      const { douyin, xiaohongshu, kuaishou } = await tradeStatsGetConfig({ month: this.month })
      this.form = {
        douyin: douyin ? douyin : { ...{}, ...Model.douyin },
        xiaohongshu: xiaohongshu ? xiaohongshu : { ...{}, ...Model.xiaohongshu },
        kuaishou: kuaishou ? kuaishou : { ...{}, ...Model.kuaishou }
      }
      this.show = true
    },
    handleSubmit: function() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // const shop_names = this.form
          await tradeStatsSetConfig({ month: this.month, rules: this.form })
          this.show = false
          this.$message.success('操作成功')
          this.$emit('success')
        } else {
          return false
        }
      })
    }
  }
}

</script>
<style lang="scss">
.trade-stat-config-drawer {
  width: 600px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  .el-form-item {
    display: block;
    margin-right: 0;
  }

  .el-form-item__label {
    width: 150px;
  }

  .el-form-item__content {
    width: calc(100% - 204px);
    display: inline-block;
  }

  .tip {
    border-radius: 18px;
    padding: 12px 24px;
    font-size: 12px;
    margin-bottom: 20px;
    color: #606266;
    background: linear-gradient(260deg, #fff3f8, #fffbfd 80%, #fffcfd 179%);

    li {
      list-style: disc !important;
      margin-left: 26px;
    }

    li:first-child {
      margin-top: 12px;
    }
  }
}
</style>
