<template xmlns:el-col='http://www.w3.org/1999/html'>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <PlcItemPanel :id='id'/>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import PlcItemPanel from '@/pages/plc/detail/item/PlcItemPanel'

export default {
  name: 'PlcDetail',
  components: {
    PlcItemPanel,
    PageHeaderLayout,
  },
  data() {
    return {
      id: null,
    }
  },
  methods: {},
  created() {
    window.onscroll = () => {
      let scrollTop = document.documentElement.scrollTop
      this.top = scrollTop
      this.fixHeader = scrollTop >= 120
    }
    this.id = this.$route.params && this.$route.params.id
    // this.getSetting()

  },
  mounted() {
    // this.getInfo()
    // this.$nextTick(() => {
    //   this.getPageList()
    // })
  }
}
</script>

<style scoped>
.notice {
  border: #f2f2f2 1px solid;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}

.row-opt .sub-title::before {
  content: '状态及操作项';
  color: #fe346e;
  font-weight: bold;
  font-size: 14px;
}

.row-opt {
  border: #f2f2f2 1px dashed;
  border-radius: 8px;
  padding: 10px;
  margin-top: 8px;

}

.row-opt:hover {
  border: #fe346e 1px dashed;
}

.plc {
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
  padding: 10px;
}

.title-row {
  /*display: flex;*/
  text-align: center;
}

.title {
  flex: 6;
  font-weight: 700;
  font-size: 2rem;
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
  display: inline-block;
}

.tag {
  font-size: 1rem;
  font-weight: 600;
  color: red;
  display: inline-block;
}

.date {
  flex: 2;
  font-size: 0.9rem;
  font-weight: 600;
  color: red;
  display: inline-block;
}

.date::before {
  content: '有效期：';
  font-size: 1rem;
  font-weight: 700;
}

.notice-title {
  font-weight: 800;
  font-size: 1.2rem;
}

.plc-items {
  border: #f2f2f2 1px solid;
  border-radius: 10px;
  padding: 10px;
}


.price-div {
  display: flex;
  line-height: 100%;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
  /*border: #f2f2f2 1px solid;*/
  height: 100%;
  width: 100%;
}

.edit-btn {
  flex: 1;
  line-height: 100%;
  /*margin-right: 5px;*/
  vertical-align: middle;
  text-align: center;
  /*border: #0C0C0C 1px dashed;*/
  position: relative;
  margin-top: 5px;
}

.edit-quote {
  position: relative;
  top: 45%;
  vertical-align: middle;
  text-align: center;
  line-height: 100%;
  /*border: #a41818 1px dashed;*/
}

.edit-btn-size {
  font-size: 18px;
}

.edit-box {
  border: #f2f2f2 1px dashed;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 8px;
}

.edit-row {
  padding: 10px;
  border: 1px solid rgba(242, 242, 242, 0.8);
  margin-top: 10px;
  border-radius: 10px;
  /*background-color: rgba(242,242,242,0.5)*/
}

.fanhui-button {
  float: right;
}

.case-panel .sub-title {
  font-size: 18px;
  line-height: 24px;
  padding: 6px;
  border: #fe346e 1px dashed;
  background: rgba(254, 52, 110, 0.8);
  color: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.bs_case::before {
  content: "商务案例";
  font-size: 18px;
  line-height: 24px;
  padding: 6px;
  border: #fe346e 1px dashed;
  background: rgba(254, 52, 110, 0.8);
  color: #f2f2f2;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-30%) translateY(-30%);
}

.bs_case {

}

</style>
<style>
/**
 单元格样式调整
 */
.plc-cell {
  vertical-align: top !important;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  /*left: 160px;*/
  z-index: 9;
  transition: width 0.28s;
  background: #fefefe;
  width: 98%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  /*opacity: 0.9;*/
  /*margin-left: 10px;*/
}

.fold {
  left: 10px !important;
}

.unfold {
  left: 160px !important;
}

.info_box {
  margin: 10px 0;
  max-height: 500px;
  min-height: 200px;
  /*border: 1px solid rgb(0 0 0 / 10%);*/
  border-radius: 5px;
  padding: 8px;

}


.tox {
  z-index: 999999 !important;
  border: 1px solid red
}

.opt {
  border: #f2f2f2 1px dashed;
  padding: 10px;
  margin-top: 10px;
}

</style>
