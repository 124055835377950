<!--suppress ALL -->
<template>
  <div>
    <el-drawer
        :before-close='beforeClose'
        :destroy-on-close='true'
        :show-close='true'
        :size="'60%'"
        :visible.sync='drawerVisible'
        :wrapperClosable='false'
        :with-header='true'
    >
      <template slot='title'>
        <div class='clearfix' slot='header'>
          <span class='title'>普通询单项目{{ textBtn }}</span>
        </div>
      </template>
      <el-form ref='form' :model='form' :rules="rules" label-width='140px' size="mini">
        <div class="main_content">
          <div class="main">
            <span class="main__title">基本信息</span>
          </div>
          <el-form-item prop="name" label='项目名称'>
            <el-input maxlength="20" type="text" v-model='form.name' clearable></el-input>
          </el-form-item>
          <el-form-item label='意向达人'>
            <el-row>
              <el-col :span='3'>
                <el-switch
                    v-model='is_assign'
                    :active-value='1'
                    :inactive-value='0'
                    active-color='#13ce66'
                    active-text='是'
                    inactive-color='#ff4949'
                    inactive-text='否'>
                </el-switch>
              </el-col>
              <el-col :span='21'>
                <ArtistSearch v-if='is_assign===1' ref='ArtistSearch' style='width: 100%'
                              @handleSelect='chooseAdArtist'/>
              </el-col>
            </el-row>
            <el-row v-if='is_assign===1'>
              <el-tag
                  v-for='(tag,index) in intentions'
                  :key='tag.id'
                  :type='tag.id'
                  closable
                  style='margin-left: 3px'
                  @close='tagClose(index)'>
                {{ tag.nickname }}
              </el-tag>
            </el-row>
          </el-form-item>
          <el-row>
            <el-col :span='12'>
              <el-form-item label='品牌' prop="brand">
                <el-select v-model='form.brand' allow-create clearable filterable
                           placeholder='请选择' style='width: 100%' @change="handleChangeBrand">
                  <el-option
                      v-for='item in brandOptions'
                      :key='item.id'
                      :label='item.name'
                      :value='item.id'>
                  </el-option>
                </el-select>

                <!--            <i-->
                <!--                    @click="clickBtn"-->
                <!--                    class="el-icon-search"-->
                <!--                    slot="prefix">-->
                <!--            </i>-->
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label='所属集团' prop="group_id">
                <el-select v-model='form.group_id' clearable filterable placeholder='请选择' style='width: 100%'>
                  <el-option
                      v-for='item in belongGroup'
                      :key='item.id'
                      :label='item.display_name'
                      :value='item.id'>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label='品牌背书' prop="brand_intro">
                <el-input v-model='form.brand_intro' :autosize='{ minRows: 5, maxRows: 10}' clearable maxlength='200'
                          placeholder='请输入品牌背书'
                          show-word-limit type='textarea'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="合作平台" prop="platforms">
            <el-checkbox-group v-model='form.platforms'>
              <el-checkbox v-for='(item,index) in platforms' :key="index+'category'" :label='item.name'>
                {{ item.display_name }}
              </el-checkbox>
            </el-checkbox-group>
            <el-radio-group v-if="form.platforms?.includes('package')" v-model='form.package'>
              <el-radio v-for='(item,index) in packageData' :key="index+'package'"
                        :label='item?.name'>
                {{ item.display_name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='合作形式' prop="coop_id">
            <el-radio-group v-model='form.coop_id'>
              <el-radio v-for='(item,index) in category' :key="index+'category'"
                        :label='item.id'>
                {{ item.display_name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row>
            <el-col :span='12'>
              <el-form-item label='是否平台下单'>
                <el-switch
                    v-model='form.is_platform_pay' :active-value="1" :inactive-value="2"
                    active-color='#13ce66'
                    active-text='是'
                    inactive-color='#ff4949'
                    inactive-text='否'>
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label='是否寄回'>
                <el-switch v-model='form.is_send_back'
                           :active-value="1"
                           :inactive-value="2"
                           active-color='#13ce66' active-text='是' inactive-color='#ff4949'
                           inactive-text='否'></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <div class='row-switch'>
            <el-form-item label='有无特别激励政策'>
              <el-switch
                  v-model='form.is_policy' :active-value='1' :inactive-value='2'
                  active-color='#13ce66' active-text='是' inactive-color='#ff4949'
                  inactive-text='否'>
              </el-switch>
            </el-form-item>
            <el-form-item :rules="form.is_policy===1?rules.policy:[]" v-if='form.is_policy===1' label='激励政策'
                          prop="policy" style='width: 100%'>
              <el-input v-model='form.policy' clearable maxlength='200' show-word-limit
                        size='mini'></el-input>
            </el-form-item>
          </div>
          <el-form-item prop="is_auth_price" label='授权平台'>
            <el-row>
              <el-col :span='3'>
                <el-switch
                    v-model='form.is_auth_price'
                    :active-value="1"
                    :inactive-value="2"
                    active-color='#13ce66' active-text='是' inactive-color='#ff4949'
                    inactive-text='否'>
                </el-switch>
              </el-col>
              <el-col :span='21'>
                <el-form-item :rules="form.is_auth_price===1?rules.auth_platform:[]" prop="auth_platform">
                  <el-select v-if='form.is_auth_price===1' v-model='form.auth_platform' multiple placeholder='请选择'
                             style='width: 100%'>
                    <el-option
                        v-for='item in authPlatform'
                        :key='item.id'
                        :label='item.display_name'
                        :value='item.id'>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item label='期望档期' required>
            <el-date-picker
                v-model='expect_time'
                :picker-options='pickerOptions'
                align='right'
                end-placeholder='结束日期'
                range-separator='至'
                start-placeholder='开始日期'
                type='daterange'
                value-format='yyyy-MM-dd'>
            </el-date-picker>
          </el-form-item>
          <!--          :required='form.coop_id===11052?true:false'-->
          <el-form-item label='直播明细'
                        :rules="form.coop_id===11052?rules.live_detail:[]"
                        prop="live_detail" style='width: 830px'>
            <el-input v-model='form.live_detail' :autosize='{ minRows: 8, maxRows: 10}' clearable maxlength='1000'
                      placeholder='是否走平台/排竞时长/视频保留时长/产品露出时间/是否上脸使用/是否寄送等'
                      show-word-limit type='textarea'></el-input>
          </el-form-item>
          <el-form-item label='其他要求' style='width: 830px'>
            <el-input v-model='form.other_desc' :autosize='{ minRows: 8, maxRows: 10}' clearable maxlength='1000'
                      placeholder='是否走平台/排竞时长/视频保留时长/产品露出时间/是否上脸使用/是否寄送等'
                      show-word-limit type='textarea'></el-input>
          </el-form-item>
          <el-form-item label='报名时间' required>
            <el-date-picker
                v-model='apply_time'
                :picker-options='pickerOptions'
                align='right'
                end-placeholder='结束日期'
                range-separator='至'
                start-placeholder='开始日期'
                type='datetimerange'
                value-format='yyyy-MM-dd HH:mm:ss'>
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="userPermissions.indexOf('inquiry_update_all') !== -1" label='创建人'>
            <el-select v-model='form.created_by' allow-create clearable
                       filterable placeholder='请选择创建人' style='width: 42%'>
              <el-option
                  v-for='item in userOptions'
                  :key='item.id'
                  :label='item.name'
                  :value='item.id'>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="main_content">
          <div class="main">
            <span class="main__title">产品信息</span>
          </div>
          <div v-for="(item,index) in form.product_list" :key="index">
            <div style="overflow: hidden">
              <el-link style="float: right;right: 30px;bottom: 2px" type="danger" @click.native="delProduct(index)">
                删除产品 {{
                  index + 1
                }}
              </el-link>
            </div>
            <div class="main_content">
              <el-row>
                <el-col :span="6">
                  <el-form-item label='产品图片' prop="cover_url">
                    <ApeUploader :limit='1' :upload-file-list='item.cover_url?item.cover_url:null'
                                 :key="'component'+index"
                                 uploadTip='请上传图片，最多上传1份。大小限制2M文件'
                                 @handleUploadRemove='handleUploadRemove($event,index)'
                                 @handleUploadSuccess='handleUploadSuccess($event,index)'></ApeUploader>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-row>
                    <el-form-item label='产品名称' :rules="rules.product"
                                  :prop="'product_list.'+index+'.product'">
                      <productSearch v-if="showEditProduct" :name.sync='product'
                                     style='display: inline'
                                     @handleBlur="handleBlur($event,index)"
                                     @handleSelect='selectProduct($event,index)'/>
                      <span v-else style="cursor: pointer" title="修改产品信息" @click="editProduct">
                      {{ item.product }}
                      <i class="el-icon-edit"></i>
                    </span>
                    </el-form-item>
                    <el-row>
                      <el-form-item label='附件上传'>
                        <el-upload
                            :before-upload='beforeUpload'
                            :file-list='item.inquiry_project_attachment'
                            :headers='uploadHeaders'
                            :on-change='handleChange'
                            :on-success='importSuccess'
                            :show-file-list='false'
                            action='/admin_api/upload/file'
                            class='upload-demo'
                            multiple
                        >
                          <el-button icon='el-icon-upload' type='primary' @click="getImageTypeIndex(index)">点击上传
                          </el-button>
                        </el-upload>
                        <template v-if='item.inquiry_project_attachment?.length>0||false'>
                                <span v-for='(item,i) in item.inquiry_project_attachment' :key='item.id'>
                                   <a :href='item.full_path' target='_blank'>{{ item.original || '附件' }} </a>
                                  <i class='el-icon-delete' style='color: red;margin: 0 5px;cursor: pointer'
                                     @click='deleteFile(item?.id,i,index)'></i>
                                </span>
                        </template>
                      </el-form-item>
                    </el-row>
                  </el-row>
                </el-col>
              </el-row>
              <el-row>
                <el-form-item label='产品卖点' :rules="rules.intro"
                              :prop="'product_list.'+index+'.intro'">
                  <el-input v-model='item.intro' :autosize='{ minRows:4, maxRows: 10}' clearable
                            maxlength='1000' placeholder='品牌背景/产品售卖链接等,字数限制：1000' show-word-limit
                            type='textarea'></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label='是否保量' prop="is_quantity">
                    <el-switch
                        v-model='item.is_quantity' :active-value='1' :inactive-value='2'
                        active-color='#13ce66' active-text='是' inactive-color='#ff4949'
                        inactive-text='否'>
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <div class='row-switch'>
                    <el-form-item :rules="item.is_quantity===1?rules.product:[]" v-if='item.is_quantity===1'
                                  :prop="'product_list.'+index+'.qty_policy'"
                                  label='费比' style='width: 50%'>
                      <el-input v-model='item.qty_policy' clearable maxlength='7' placeholder="例：保2+20%"
                                show-word-limit
                                size='mini'></el-input>
                    </el-form-item>
                    <el-form-item :rules="item.is_quantity===1?rules.live_cmn:[]" v-if='item.is_quantity===1'
                                  :prop="'product_list.'+index+'.live_cmn'"
                                  label='直播佣金' style='width: 50%'>
                      <el-input v-model='item.live_cmn' clearable maxlength='7' placeholder="佣金"
                                show-word-limit
                                size='mini'></el-input>
                    </el-form-item>
                  </div>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label='挂车佣金'>
                    <el-switch
                        v-model='item.is_cart'
                        :active-value="1"
                        :inactive-value="2"
                        active-color='#13ce66' active-text='是' inactive-color='#ff4949'
                        inactive-text='否'>
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span='20' v-if='item.is_cart===1'>
                  <el-form-item :rules="item.is_cart===1?rules.cart_rate:[]" label='佣金'
                                style='width: 40%;display: inline-block' :prop="'product_list.'+index+'.cart_rate'">
                    <el-input v-model='item.cart_rate'
                              style="width: 100%" oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                              type='number'>
                      <template slot='append'>%</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item :rules="item.is_cart===1?rules.cart_mechanism:[]" label='挂车机制'
                                style='width: 60%;display: inline-block'
                                :prop="'product_list.'+index+'.cart_mechanism'">
                    <el-input v-model='item.cart_mechanism' clearable
                              maxlength='20'
                              placeholder='挂车机制'
                              show-word-limit type='text'></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-row>
                  <el-col :span="5">
                    <el-form-item label='是否全网统一机制'>
                      <el-switch
                          v-model='item.unify' :active-value="1" :inactive-value="2"
                          active-color='#13ce66'
                          active-text='是'
                          inactive-color='#ff4949'
                          inactive-text='否'>
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="19">
                    <el-form-item :rules="item.unify===2?rules.special_remark:[]"
                                  :prop="'product_list.'+index+'.special_remark'"
                                  v-if="item.unify===2" label='特殊机制说明'>
                      <el-input v-model='item.special_remark' clearable
                                maxlength='30'
                                placeholder='特殊机制说明'
                                show-word-limit type='text'></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>


                  <el-col :span="5">
                    <el-form-item label='是否有签约独家'>
                      <el-switch
                          v-model='item.exclusive' :active-value="1" :inactive-value="2"
                          active-color='#13ce66'
                          active-text='是'
                          inactive-color='#ff4949'
                          inactive-text='否'>
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col :span="19">
                    <el-form-item :rules="item.exclusive===1?rules.other_activity:[]"
                                  :prop="'product_list.'+index+'.other_activity'" v-if="item.exclusive===1"
                                  label='独家机制'
                                  style='width: 100%'>
                      <el-input v-model='item.other_activity' clearable
                                maxlength='30'
                                placeholder='独家机制说明'
                                show-word-limit type='text'></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-row>
              <el-row>
                <el-form-item label='可否签保价一个月'>
                  <el-switch
                      v-model='item.support_value' :active-value="1" :inactive-value="2"
                      active-color='#13ce66'
                      active-text='是'
                      inactive-color='#ff4949'
                      inactive-text='否'>
                  </el-switch>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label='直播机制' :prop="'product_list.'+index+'.live_mec'" :rules="form.coop_id===11052?rules.live_mec:[]">
                  <el-input v-model='item.live_mec' :autosize='{ minRows: 5, maxRows: 10}' clearable
                            maxlength='100'
                            placeholder='直播机制'
                            show-word-limit type='textarea'></el-input>
                </el-form-item>
              </el-row>
            </div>
          </div>
          <div style="width: 100%;text-align: right">
            <el-button class="el-icon-plus" type="primary" @click="handleAdd">添加产品</el-button>
          </div>
        </div>
      </el-form>
      <div>
        <div style="text-align: center">
          <el-button style="margin:0 10px;display: inline-block" size="medium" type='primary' @click='onSubmit'>
            {{ textBtn }}
          </el-button>
          <span v-if="this.rowId !== 'add'&&form.is_apply!==2">
            <el-button style="margin:0 10px;display: inline-block" size="medium" v-if='form.is_apply!==1'
                       @click='handleVoid(1)'
                       type='success'>开始报名</el-button>
            <el-button style="margin:0 10px;display: inline-block" size="medium" v-if='form.is_apply===1'
                       @click='handleVoid(3)' type='warning'>结束报名</el-button>
          </span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import productSearch from './components/productSearch.vue'
import ArtistSearch from '@/components/artist/ArtistSearch'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import ApeUploader from './components/ApeUploader.vue'

const shortcuts = [{
  text: '最近一周',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
    picker.$emit('pick', [end, start])
  }
}, {
  text: '最近一个月',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
    picker.$emit('pick', [end, start])
  }
}, {
  text: '最近三个月',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() + 3600 * 1000 * 24 * 90)
    picker.$emit('pick', [end, start])
  }
}
]
const itemForm = {
  type: 1,
  name: '',
  brand: '',
  platforms: [],
  is_platform_pay: false,
  coop_id: '',
  is_send_back: false,
  cart_rate: 0,
  is_auth_price: false,
  auth_platform: [],
  expect_start: '',
  except_end: '',
  apply_end: '',
  apply_start: '',
  is_assign: 0,
  original_name: '',
  other_desc: '',
  package: '',
  live_detail: '',
  is_policy: null,
  policy: null,
  product_list: []
}
const productItem = {
  product: null,
  product_id: null,
  cover_url: '',
  is_quantity: null,
  qty_policy: null,
  live_cmn: null,
  intro: null,
  is_cart: null,
  cart_rate: null,
  cart_mechanism: null,
  live_mec: null,
  support_value: null,
  unify: 2,
  special_remark: null,
  exclusive: null,
  other_activity: null,
  inquiry_project_attachment: []
}
const rules = {
  name: { required: true, message: '请输入项目名称', trigger: 'blur' },
  brand: { required: true, message: '请选择/输入品牌', trigger: 'change' },
  brand_intro: { required: true, message: '请输入品牌背书', trigger: 'blur' },
  platforms: { required: true, message: '请选择合作平台', trigger: 'change' },
  coop_id: { required: true, message: '请选择合作形式', trigger: 'change' },
  live_detail: [{ required: true, trigger: ['blur', 'change'], message: '合作形式为全案时,直播明细必填' }],
  cart_mechanism: [{ required: true, trigger: ['blur', 'change'], message: '机制必填' }],
  policy: { required: true, trigger: ['blur', 'change'], message: '激励机制必填' },
  auth_platform: [{ required: true, trigger: ['blur', 'change'], message: '授权平台必填' }],
  product: { required: true, message: '请选择/输入产品', trigger: 'blur' },
  qty_policy: { required: true, message: '请输入费比', trigger: 'blur' },
  live_cmn: { required: true, message: '请输入佣金', trigger: 'blur' },
  cart_rate: { required: true, message: '请输入佣金', trigger: 'blur' },
  special_remark: { required: true, message: '特殊机制必填', trigger: 'blur' },
  other_activity: { required: true, message: '其他机制必填', trigger: 'blur' },
  intro: { required: true, message: '请输入产品卖点', trigger: 'blur' },
  live_mec: { required: true, message: '请输入直播机制', trigger: 'blur' }
}

export default {
  name: 'CreateEdit',
  data() {
    return {
      form: { ...itemForm },
      expect_time: [],//期望日期
      apply_time: [],//报名时间
      platforms: [],//开通平台列表
      category: [],//合作形式
      packageData: [],//打包平台
      belongGroup: [],//所属集团
      authPlatform: [],//授权平台
      textBtn: '创建',
      intentions: [],
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      is_assign: 0,
      pickerOptions: { shortcuts },
      userOptions: [],
      brandOptions: [],
      brand: null,
      productOptions: [],
      product: null,
      showEditProduct: true,
      fileIndex: null,
      rules: rules
    }
  },
  components: { ApeUploader, productSearch, ArtistSearch },
  props: {
    rowId: {
      type: [Number, String],
      default() {
        return 'add'
      }
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    drawerVisible: {
      deep: true,
      handler() {
        this.infoData()
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    getImageTypeIndex(index) {
      this.fileIndex = index
    },
    delProduct(item) {
      if (this.form.product_list.length <= 1) {
        this.$message.error('必须有一个产品')
      } else
        this.form.product_list.splice(item, 1)
    },
    async deleteFile(val, i, index) {
      if (this.rowId !== 'add') {
        this.$confirm(`确定是否删除附件`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.form.product_list[index].inquiry_project_attachment.splice(i, 1)
          let id = await this.$api.deleteInquiryFile({ id: val })
          if (id) {
            this.$notify.success('删除成功')
          }
        })
      } else {
        this.form.product_list[index].inquiry_project_attachment.splice(i, 1)
      }
    },
    beforeUpload() {
      this.uploadLoading = true
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
    },
    //下载上传模块
    handleChange(file) {
      this.file = [file]
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('上传成功')
        this.form.product_list[this.fileIndex].inquiry_project_attachment.push({
          full_path: response?.data[0]?.full_path,
          original: response.data[0].original,
          attachment_id: response.data[0].id
        })
      } else {
        this.$notify.warning(response.msg)
      }
    },
    async handleVoid(status) {
      // "status": 2 //1:进行中 2:已完结 3:已作废
      //is_apply 报名状态 //1：报名中 2:不可报名，3.结束报名
      let statusData = {
        id: this.rowId,
        status: 1,
        is_apply: status
      }
      let id = await this.$api.switchInquiryStatus(statusData)
      if (id) {
        this.$notify.success(`${status === 1 ? '【开始报名切换】' : '【结束报名切换】'}成功`)
        this.$emit('update:drawerVisible', false)
        await this.infoData()
      }
    },
    chooseAdArtist(item) {
      if (item && !(JSON.stringify(item) === '{}')) {
        let includes = this.intentions.find((i) => {
          return i.id === item.id
        })
        if (!includes) {
          this.intentions.push({ nickname: item.nickname, id: item.id })
        }
      }
      this.$refs['ArtistSearch'].clearData()
    },
    tagClose(val) {
      this.intentions.splice(val)
    },
    beforeClose() {
      this.form = { ...itemForm }
      this.$emit('update:drawerVisible', false)
    },
    async getInquiryCoop() {
      let { list } = await this.$api.getCategoryListByIdentify(`inquiry-coop`)
      this.category = list
    },
    async infoData() {
      //合作平台
      //所属集团
      let group = await this.$api.getCategoryListByIdentify(`belong-group`)
      this.belongGroup = group.list
      let authPlatform = await this.$api.getCategoryListByIdentify(`auth-platform`)
      this.authPlatform = authPlatform.list
      let { list } = await this.$api.getCategoryListByIdentify(`inquiry-platforms`)
      list.forEach((item) => {
        if (item.display_name === '打包') {
          this.packageData = item.children
        }
      })
      this.platforms = list
      if (this.rowId !== 'add') {
        //这里是修改的逻辑
        let { info } = await this.$api.infoInquiryProject({ id: this.rowId })
        this.product = info.product
        info.group_id === 0 ? info.group_id = null : ''
        // this.brand = info.brand_id || info.brand
        this.showEditProduct = false
        this.$nextTick(() => {
          this.expect_time = [info['expect_start'], info['except_end']]
          this.apply_time = [info['apply_start'], info['apply_end']]
          this.intentions = info['intentions']
          this.is_assign = info['is_assign']

          // this.attachments = info['attachments']
          // info['auth_platform'] = info['auth_platform']?.id
          // info['is_send_back'] = info['is_send_back'] === 1
          this.form = info
          this.form.platforms.forEach((item, index) => {
            if (item === this.form.package) {
              // delete item
              this.form.platforms.splice(index, 1)
            }
          })
        })
        this.textBtn = '修改'
      } else {
        //报名时间/期望档期/意向达人/附件 - 初始化
        this.expect_time = []
        this.apply_time = []
        this.is_assign = 0
        this.intentions = []
        // this.attachments = []
        this.form = {
          ...itemForm
        }
        this.form.product_list = [{ ...productItem }]
        this.textBtn = '创建'
      }
    },
    editProduct() {
      this.product = this.form.product
      this.showEditProduct = true
    },
    formDataFilter() {
      let returnData = true
      //在新增的时候转一下 因为默认是true/false
      let newValue = { ...this.form }

      // returnData = false
      //期望档期
      if (this.expect_time?.length > 0) {
        [newValue.expect_start, newValue.except_end] = this.expect_time
      } else {
        this.$notify.warning('请选择期望档期')
        returnData = false
      }
      if (this.apply_time?.length > 0) {
        [newValue.apply_start, newValue.apply_end] = this.apply_time
      } else {
        this.$notify.warning('请选择报名时间')
        returnData = false
      }
      if (this.form.is_quantity == 2) {
        newValue.qty_policy = null
        newValue.live_cmn = null
      }
      //初始化内容
      !(newValue['is_cart']) || newValue['is_cart'] === 1 ? '' : newValue['cart_rate'] = 0
      let nickIds = []
      //意向达人
      newValue.is_assign = this.is_assign
      if (newValue.is_assign === 1) {
        if (this.intentions?.length > 0) {
          this.intentions.forEach((item) => {
            nickIds.push(item.id)
          })
          newValue.intentions = nickIds
        } else {
          this.$notify({
            title: '警告',
            message: '请选择意向达人',
            type: 'warning'
          })
          returnData = false
        }
      } else if (newValue.is_assign === 0) {
        newValue.intentions = []
      }
      //附件上传
      // this.attachments?.length > 0 ? newValue.attachments = this.attachments : []
      newValue.platforms.includes('package') ? newValue.platforms.push(newValue?.package) : ''
      // if (this.rowId !== 'add') {
      //   this.form.attachments = []
      //   newValue['attachments'].forEach((item) => {
      //     this.form.attachments.push({
      //       full_path: item?.full_path,
      //       original: item.original,
      //       attachment_id: item.attachment_id
      //     })
      //   })
      //   delete this.form.group
      // }
      this.form['is_auth_price'] === 2 ? delete this.form['auth_platform'] : this.form['auth_platform']
      returnData ? this.form = { ...this.form, ...newValue } : ''
      return returnData
    },
    async onSubmit() {
      if (this.formDataFilter()) {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.type = 1
            // this.form.type = this.isNormalInquiry ? 1 : 2
            if (this.rowId === 'add') {
              let id = await this.$api.saveInquiryProject(this.form)
              if (id) {
                this.$notify.success('添加成功')
                this.$emit('update:drawerVisible', false)
              }
            } else {
              this.form.id = this.rowId
              let id = await this.$api.updateInquiryProject(this.form)
              if (id) {
                this.$notify.success('修改成功')
                this.$emit('update:drawerVisible', false)
              }
            }
          }
        })
      }
    },
    async getBrand() {
      if (this.brandOptions.length === 0) {
        // let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
        let { options } = await this.$api.getBrandOptions()
        this.brandOptions = options
      }
    },
    async getUser() {
      if (this.userOptions.length === 0) {
        let { list } = await this.$api.getUserOption()
        this.userOptions = list
      }
    },
    handleChangeBrand() {
      const data = this.brandOptions.map((item) => {
        return this.form.brand === item.id ? item : false
      }).filter(Boolean)
      if (data.length > 0) {
        this.form.brand = data[0].name
        this.form.brand_id = data[0].id
      } else {
        this.form.brand_id = 0
      }
    },
    handleBlur(item, index) {
      if (item && !(JSON.stringify(item) === '{}')) {
        this.form.product_list[index].product_id = 0
        this.form.product_list[index].product = item
      }
    },
    async selectProduct(item, index) {
      if (item && !(JSON.stringify(item) === '{}')) {
        this.form.product_list[index].product_id = item.id
        this.form.product_list[index].product = item.text
        this.form.product_list[index].cover_url = item.cover_url
        // this.uploadFileList = [{ url: item.cover_url }]
        // this.form.product_list[index].cover_url = [{ url: item.cover_url }]
        this.form.product_list[index].brand_intro = item.intro
        this.form.product_list[index].support_value = item.support_value
        this.form.product_list[index].unify = item.unify || 2
        this.form.product_list[index].special_remark = item.special_remark
        this.form.product_list[index].exclusive = item.exclusive
        this.form.product_list[index].other_activity = item.other_activity
      } else {
        this.form.product_list[index].product_id = 0
        this.form.product_list[index].product = ''
      }
    },
    // 图片删除回调
    handleUploadRemove(event, index) {
      this.form.product_list[index].cover_url = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
    },
    handleUploadSuccess(file, index) {
      this.form.product_list[index].cover_url = file.full_path

    },
    handleAdd() {
      let data = { ...productItem }
      data.inquiry_project_attachment = []
      this.form.product_list.push(data)
    }
  },
  created() {
    this.getInquiryCoop()
    this.infoData()
    this.getBrand()
    this.getUser()
  }
}
</script>
<style lang='scss' scoped>
.title {
  font-size: 18px;
  font-weight: 800;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield; /* 此处写不写都可以 */
}

.row-switch {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start
}

.main_content {
  border: 1px solid #DCDFE6;
  padding: 10px;
  width: 94%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.main {
  background: #ffffff;
  border-radius: 20px;
  color: rgba(0, 0, 0, .85);
  padding: 10px 16px;
  margin-bottom: 10px;
  margin-top: 0;

  &__title {
    color: black;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    padding-left: 12px;
    //margin-bottom: 18px;
  }

  &__title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #92CC68;
    border-radius: 4px;
  }
}

.drawer-main {
  position: relative;
  height: 100%;
  overflow: hidden;

}

::v-deep .el-radio__original {
  display: none !important; /* 隐藏原生 radio 输入，但仍然允许交互 */
}

::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}

</style>
