<template>
  <div class='import-box'>
    <el-form size='small' :inline='false' :model='formData' label-width='100px' label-suffix='：'
             label-position='right'>
      <el-form-item label='上传文件' required>
        <file-chunk-upload @uploaded='handleUploaded' />
      </el-form-item>
      <el-form-item label='归属月份' required>
        <el-date-picker
          v-model='formData.month'
          type='month'
          value-format='yyyy-MM'
          format='yyyy年MM月'
          placeholder='选择月'>
        </el-date-picker>
      </el-form-item>
      <el-form-item label='归属方式' required>
        <el-radio-group v-model="formData.month_type" class="trade-import-radio">
          <el-radio-button label="pay_time">按支付时间</el-radio-button>
          <el-radio-button label="settle_time">按结算时间</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label='归属平台'>
        <PlatformSingleSelect placeholder='选择归属平台'
                              ref='refPlatformSingleSelect'
                              v-model='formData.platform_code'
                              style='width: 220px' @change='changePlatformCode'>
        </PlatformSingleSelect>
        &nbsp;
        &nbsp;
        <export-link ref='refExportTpl' :can-export='true' @export='downloadTpl' class-name='export-btn'>
          【{{ formData.platform_name || '--' }}】模板
        </export-link>
      </el-form-item>
      <el-form-item>
        <el-button type='default' @click='handleClose'>取消</el-button>
        <el-button :disabled='loadingCommit' type='primary' @click.native.stop='handleCommit'>保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import PlatformSingleSelect from '@/components/Platform/PlatformSingleSelect'
import FileChunkUpload from '@/components/import/chunk/FileChunkUpload'
import { exportTradeImportedTpl, saveTradeImported } from '@/api/trade_import'
import ExportLink from '@/components/export/ExportLink'

export default {
  name: 'ImportAndCreate',
  components: { ExportLink, FileChunkUpload, PlatformSingleSelect },
  data() {
    return {
      loadingCommit: false,
      formData: { file_id: null, platform_code: null, platform_name: null, month: null, month_type: null }
    }
  },
  methods: {
    reset() {
      this.$refs['refPlatformSingleSelect'].reset()
    },
    handleClose() {
      this.formData = {}
      this.$emit('close')
    },
    async handleCommit() {
      if (!this.formData.month) {
        this.$message.warning('归属月份必选')
        return false
      }
      if (!this.formData.month_type) {
        this.$message.warning('归属方式必选')
        return false
      }
      if (!this.formData.platform_code) {
        this.$message.warning('归属平台必选')
        return false
      }

      const platform = this.$refs['refPlatformSingleSelect'].getSelectedNode(this.formData.platform_code)
      //预览数据
      let msg = `【${this.formData.month || '--'} ${platform.name || '未知'}】平台数据`
      this.$confirm(`确认提交 ${msg}？`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loadingCommit = true
        setTimeout(() => {
          this.loadingCommit = false
        })
        // ['file_id', 'month', 'platform_code']
        let id = await saveTradeImported(this.formData)
        if (id) {
          this.$notify.success('保存成功')
          this.handleClose()
        }
        this.$emit('saved', this.formData)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤回'
        })
      })


    },
    handleUploaded(fileInfo) {
      // console.log('file_info', fileInfo)
      this.formData.file_id = fileInfo.file_id || null
    },
    async downloadTpl() {
      if (!this.formData.platform_code) {
        this.$notify.warning('请选平台')
        this.$refs['refExportTpl'].hideLoading()
        return false
      }
      try {
        let name = `【${this.formData.platform_name}】平台数据`

        let response = await exportTradeImportedTpl(this.formData)
        if (this.$refs['refExportTpl'])
          this.$refs['refExportTpl'].exportSaveXlsx(response, name)
      } catch (e) {
        this.$refs['refExportTpl'].hideLoading()
        this.$message.warning('导出异常，请联系管理员')
      }


    },
    changePlatformCode(val) {
      const platform = this.$refs['refPlatformSingleSelect'].getSelectedNode(val)
      this.formData.platform_name = platform.name
      console.log(this.formData)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.import-box {
  border: #f2f2f2 1px dashed;
  border-radius: 10px;
  /*text-align: center;*/
  padding: 20px;
  margin: auto;
  width: 500px;
}
</style>
<style lang="scss">
.trade-import-radio {
  width: 220px;

  .el-radio-button {
    width: 50%;

    span {
      width: 100%;
    }
  }
}
</style>
