export default {
  path: 'kol',
  name: 'kol',
  component: () => import('@/pages/kol/index'),
  meta: { title: '红人资源库' },
  children: [
    {
      path: 'list',
      name: 'kol_list',
      component: () => import('@/pages/kol/KolList'),
      meta: { title: 'KOL信息' }
    },
    {
      path: 'XhsResources',
      name: 'xhs_database',
      component: () => import('@/pages/kol/XhsResources'),
      meta: { title: '小红书资料库' }
    },
    {
      path: 'TiktokResources',
      name: 'dy_database',
      component: () => import('@/pages/kol/TiktokResources'),
      meta: { title: '抖音资料库' }
    },
    {
      path: 'project',
      name: 'kol_project',
      component: () => import('@/pages/kol/ProjectList'),
      meta: { title: '项目列表' }
    },
    {
      path: ':id/detail',
      name: 'kol_detail',
      component: () => import('@/pages/kol/ProjectDetail'),
      meta: { title: '项目详情' }
    },
    {
      path: ':id/:type/createEdit',
      name: 'kol_add',
      component: () => import('@/pages/kol/CreateEdit'),
      meta: { title: '红人资源库' }
    },
    {
      path: ':id/ArtistIntro',
      name: 'kol_ArtistIntro',
      component: () => import('@/pages/kol/KolIntro/index'),
      meta: { title: '红人详情' }
    },
    {
      path: 'KoLArchiveList',
      name: 'KoLArchiveList',
      component: () => import('@/pages/kol/archives/KoLArchiveList'),
      meta: { title: '归档记录' }
    },
    {
      path: 'KoLPopularCase',
      name: 'KoLPopularCase',
      component: () => import('@/pages/kol/PopularCase/index.vue'),
      meta: { title: '爆款案例' }
    },
    {
      path: 'KoLPopularCaseCategory',
      name: 'KoLPopularCaseCategory',
      component: () => import('@/pages/category_module/index'),
      meta: { title: '爆款案例分类' }
    },
    {
      path: 'KoLPopularCaseCategoryBoard',
      name: 'KoLPopularCaseCategoryBoard',
      component: () => import('@/pages/kol/PopularCase/Board/index'),
      meta: { title: '爆款案例品牌品类看板' }
    }
  ]
}
