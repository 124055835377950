<template>
  <div v-loading="loading" class="emphasis-products-stat_container">
    <h2>
      年度重点大全案品
      <div class="tool">
        <el-button type="text" icon="el-icon-refresh" size="small" @click="getData">刷新</el-button>
        <el-button v-if='hasAuthority("emphasis_products_category_list")' type="text" icon="el-icon-top-right"
                   size="small" @click="handleToCategory">品类管理
        </el-button>
        <el-button v-if='hasAuthority("emphasis_products_manage__list")' type="text" icon="el-icon-top-right"
                   size="small" @click="handleToList">重点大全案品管理
        </el-button>
      </div>
      <ul>
        <li v-for="category in categories" :key="category.label" :class="{active:category.value===search.category}"
            @click="search.category=category.value">
          {{ category.label }}
        </li>
      </ul>
    </h2>
    <div class="search">
      <el-radio-group v-model="search.category_sub" size="mini">
        <el-radio v-for="c_category in c_categories" :key="c_category.value" :label="c_category.value">
          {{ c_category.label }}
        </el-radio>
      </el-radio-group>
      <div class="search_line" />
    </div>
    <el-empty v-if="!collection||collection.length<1">
      <el-button type="text" icon="el-icon-top-right" size="medium" @click="handleToList(false)">
        点击前往重点大全案品管理添加数据
      </el-button>
    </el-empty>
    <div v-else class="emphasis-products-stat_container__main">
      <div v-for="(brand,brand_i) in collection" :key="brand_i" class="brand" @click="handleToList(brand.brand_id)">
        <span>{{ brand_i + 1 }}. {{ brand.brand_name }}</span>
        <i class="el-icon-right" />
      </div>
    </div>
  </div>
</template>
<script>
import { emphasisProductStat } from '../../../../api/emphasis-products'
import {
  optionsEmphasisProductsCategory,
  subOptionsEmphasisProductsCategory
} from '../../../../api/emphasis_products_category'

export default {
  data() {
    return {
      collection: [],
      categories: [],
      c_categories: [{ value: 0, label: '全部' }],
      search: {
        category: 0,
        category_sub: 0
      },
      loading: false
    }
  },
  watch: {
    search: {
      deep: true,
      handler() {
        this.getData()
      }
    },
    'search.category'() {
      this.getSubCategories()
      // const tmp = this.categories.find(category => {
      //   return category.id === _
      // })
      // let c_categories = tmp.children || []
      // c_categories = JSON.parse(JSON.stringify(c_categories))
      // c_categories.unshift({ id: 0, display_name: '全部' })
      // this.c_categories = c_categories || []
      // this.search.category_cid = 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: function() {
      this.getCategories()
      this.getData()
    },
    getCategories: async function() {
      this.categories = await optionsEmphasisProductsCategory({ parent_id: 0 })
      this.categories.unshift({ value: 0, label: '全品类', children: [] })
    },
    getSubCategories: async function() {
      this.c_categories = await subOptionsEmphasisProductsCategory({ parent_display_name: this.search.category })
      this.c_categories.unshift({ value: 0, label: '全部' })
      this.search.category_sub = 0
    },
    getData: async function() {
      this.loading = true
      const response = await emphasisProductStat(this.search)
      this.collection = response || []
      setTimeout(() => {
        this.loading = false
      }, 200)
    },
    handleToList: function(brand_id) {
      let query = {}
      if (brand_id) {
        query = this.search
        query.brand_id = brand_id
      }

      const { href } = this.$router.resolve({ name: 'EmphasisProductsList', query })
      window.open(href, '_blank')
    },
    handleToCategory: function() {
      const { href } = this.$router.resolve({ name: 'EmphasisProductCategory' })
      window.open(href, '_blank')
    }
  }
}
</script>
<style lang="scss">
$--color-primary: #8b0000;

.EmphasisProductsStat_main {
  margin: 12px !important;
  background: #ffffff;
  border-radius: 20px;
}


.emphasis-products-stat_container {
  height: calc(100vh - 100px);
  font-family: 'PH Medium';
  padding: 0 12px 12px 12px !important;

  &__main {
    padding: 12px 24px;
    max-height: calc(100% - 268px);
    overflow-y: auto;
  }

  h2 {
    text-align: center;
    padding: 32px 0 64px 0;
    margin: 0;
    position: relative;
    background-image: url("~@/assets/imgs/emphasis-products-bg.png");
    background-size: 100% auto;

    .tool {
      position: absolute;
      right: 12px;
      top: 36px;
    }

    ul {
      position: absolute;
      bottom: 0;
      width: 100%;
      justify-content: center;
      display: flex;
      font-size: 14px;

      li {
        color: #666;
        padding: 12px 20px;
        cursor: pointer;
      }

      li.active {
        background: #fff;
        border-radius: 12px 12px 0 0;
        color: #1a1a1a;
      }
    }
  }

  .search {
    //display: flex;
    //justify-content: end;
    padding: 24px 12px;
    margin-bottom: 48px;
    position: relative;

    .search_line {
      position: absolute;
      bottom: 0;
      left: 35%;
      width: 30%;
      border-bottom: 1px solid #f9f9f9;
    }
  }

  .emphasis-products-stat_container__main {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 12px;

    .brand {
      border: 1px dashed #8b000030;
      background: linear-gradient(-270deg, hsla(0,0%,100%,.6) -17%, hsla(0, 0%, 100%, .7) 31%, hsla(0, 0%, 100%, .14) 95%);
      padding: 8px 12px;
      border-radius: 100px;
      font-size: 12px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      i {
        font-size: 21px;
        top: 5px;
        position: absolute;
        right: 12px;
        transition: transform 0.3s;
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        transform: translate3d(-40px, 1.5em, 0);
      }
    }

    .brand:hover {
      background: #ff3176;
      border-color: #ff3176;
      color: #fff;

      i {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .el-radio__label {
    font-size: 12px;
  }
}
</style>
