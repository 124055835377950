import * as dd from 'dingtalk-jsapi'

export function getDingCode(corpId, callback) {
  if (dd.env.platform !== 'notInDingTalk') {
    dd.ready(() => {
      //使用SDK 获取免登授权码
      dd.runtime.permission.requestAuthCode({
        corpId: corpId,
        onSuccess: (info) => {
          // 根据钉钉提供的api 获得code后,再次调用这个callback方法
          // 由于是钉钉获取code是异步操作,不知道什么时候执行完毕
          // callback 函数会等他执行完毕后在自己调用自己
          callback(info.code)
        },
        onFail: (err) => {
          this.$notify.warning('钉钉免登授权失败')
          console.log(JSON.stringify(err))
        }
      })
    })
  }
}

export function checkUserAgentIsDingTalk() {
  const keyword = 'DingTalk'
  // const userAgent = window.navigator.userAgent

  return window.navigator.userAgent.indexOf(keyword) > -1
}

//https://blog.csdn.net/qq_39454432/article/details/138267516
export function isAndroid() {
  const userAgent = window.navigator.userAgent
  return userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1
}

export function isIOS() {
  const userAgent = window.navigator.userAgent
  return userAgent.indexOf('iphone') > -1 ||
    userAgent.indexOf('ios') > -1 ||
    userAgent.indexOf('ipad') > -1 ||
    (userAgent.indexOf('mac os x') > -1 && 'ontouchend' in document)
}

// 浏览器判断
export function isChrome() {
  const userAgent = window.navigator.userAgent
  return userAgent.indexOf('Chrome') > -1 &&
    userAgent.indexOf('Safari') > -1 &&
    userAgent.indexOf('Edge') === -1 &&
    userAgent.indexOf('OPR') === -1
}

export function isSafari() {
  const userAgent = window.navigator.userAgent
  return userAgent.indexOf('Safari') > -1 &&
    userAgent.indexOf('Chrome') === -1 &&
    userAgent.indexOf('Edge') === -1 &&
    userAgent.indexOf('OPR') === -1
}
