<template>
  <div style='width: 1265px;margin: auto'>
    <el-row class='mb8'>
      <div class='plc_border'>
        <el-row>
          <el-col>
            <img :src='formData.logo_url' alt='logo' style='width: 500px;height: 90px'>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div v-html='formData.extra_info'></div>
          </el-col>
        </el-row>
        <el-row class='Plc_text'>
          <el-col>
            <div v-html='formData.company_info'></div>
          </el-col>
        </el-row>
        <el-row class='plc_title'>
          <el-col>
            资源目录
          </el-col>
        </el-row>
        <div class='resource-dir' v-if='formData.resource_dir'>
          <div :key='`resource_dir_${index}`' class='sheet-panel' v-for='(item,index) in formData.resource_dir'>
            <div class='flex-sb' style='gap: 0'>
              <div style='width: 80%;text-align: center'>
                <img :src='hot' v-if='item.is_hot' style='max-height: 13px;margin-right: 10px' />
                <span :title='`${item.sheet_title}`'>{{ item.title || '/' }}</span>
              </div>
            </div>
          </div>
        </div>

        <el-row class='plc_title'>
          <el-col :span='15'>报价说明
          </el-col>
          <el-col :span='9'>视频合作说明</el-col>
        </el-row>
        <el-row style='text-align: left;line-height: 20px'>
          <el-col :span='15'>
            <div v-html='formData.quote_info'></div>
          </el-col>
          <el-col :span='9'>
            <div v-html='formData.video_info'></div>
          </el-col>
        </el-row>
      </div>

    </el-row>
  </div>
</template>

<script>

import hot from '@/assets/icons/hot.png'

export default {
  name: 'BaseCover',
  props: {
    data: {
      type: Object,
      default() {
        return {
          // title: '',
          // logo_url: '',
          // company_info: '',
          // resource_dir: []
        }
      }
    }
  },
  computed: {
    formData() {
      let data = { ...this.data }

      if (data.resource_dir) {
        try {
          if (data.resource_dir && typeof data.resource_dir == 'string')
            data.resource_dir = JSON.parse(this.data.resource_dir)
        } catch (e) {
          console.log('e', e)
        }
      }
      return data
    }
  },
  data() {
    return {
      hot: hot,
      ImgW: '40px',
      ImgH: '40px'
    }
  }
}
</script>

<style lang='scss' scoped>
$border_color: rgb(0 0 0 / 10%);

.platform_name {
  font-weight: 800;
  font-size: 16px;
  /*border: #00feff dashed 1px;*/
  line-height: 40px;

}

.plc_border {
  //margin-top: 30px;
  border: 1px solid $border_color;
  width: 96%;
  //margin: 10px auto;

  > .el-row {
    cursor: pointer;

    > .el-col {
      padding: 10px;
      min-height: 37px;
    }

    text-align: center;
    border-bottom: 1px solid $border_color;

    .el-col-lg-4-8:not(:last-child) {
      border-right: 1px solid $border_color;
      border-bottom: 1px solid $border_color;

      > a {
        color: black;
      }
    }

    @media only screen and (min-width: 768px) {
      .el-col-sm-4-8 {
        width: 20%;
      }
      .el-col-sm-offset-4-8 {
        margin-left: 20%;
      }
      .el-col-sm-pull-4-8 {
        position: relative;
        right: 20%;
      }
      .el-col-sm-push-4-8 {
        position: relative;
        left: 20%;
      }
    }

    @media only screen and (min-width: 992px) {
      .el-col-md-4-8 {
        width: 20%;
      }
      .el-col-md-offset-4-8 {
        margin-left: 20%;
      }
      .el-col-md-pull-4-8 {
        position: relative;
        right: 20%;
      }
      .el-col-md-push-4-8 {
        position: relative;
        left: 20%;
      }
    }

    @media only screen and (min-width: 1200px) {
      .el-col-lg-4-8 {
        width: 20%;
      }
      .el-col-lg-offset-4-8 {
        margin-left: 20%;
      }
      .el-col-lg-pull-4-8 {
        position: relative;
        right: 20%;
      }
      .el-col-lg-push-4-8 {
        position: relative;
        left: 20%;
      }
    }

    @media only screen and (min-width: 1920px) {
      .el-col-xl-4-8 {
        width: 20%;
      }
      .el-col-xl-offset-4-8 {
        margin-left: 20%;
      }
      .el-col-xl-pull-4-8 {
        position: relative;
        right: 20%;
      }
      .el-col-xl-push-4-8 {
        position: relative;
        left: 20%;
      }
    }

    .el-col-15 {
      width: 60%;
      border-right: 1px solid $border_color;
    }

    .el-col-9 {
      width: 40%;
    }
  }

  .Plc_text {
    font-size: 18px;
    /*color: rgb(192, 0, 0);*/
    /*text-decoration: underline;*/
    /*text-align: center;*/
  }

  .plc_title {
    background-color: rgb(192, 0, 0);

    > * {
      color: white;
      text-decoration: underline;
      font-size: 18px;
    }
  }

  > .el-col-md-4-8 {
    border: 1px solid red;
  }
}

.tox {
  z-index: 999999 !important;
  border: 1px solid red
}

.resource-dir {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.resource-dir * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.resource-dir .sheet-panel {
  width: 20%;
  line-height: 30px;
  border: #f2f2f2 1px solid;
  cursor: pointer;
}

</style>
