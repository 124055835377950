<template>
  <div class='flex-panel' style='position: static'>
      <!--          默认静态定位-->
      <div>
        <!--            可拖拽区域：内部元素都是相对定位-->
        <div id='panel' class='panel-cover'
             :style='`position: relative;width: ${panel.width}px;height: ${panel.height}px;background-color: ${panel.bg_color}`'>
          <template v-for='(item,index) in widgetSettings'>
            <div :key='`${item.field}_${index}`'
                 class='widget editable draggable'
                 style='position: absolute;'
                 :style='`z-index: ${item.z_index||6666};font-size:${item.font_size||12}px;font-weight: ${item.font_weight||"normal"};width:${item.width}px;height:${item.height}px;left: ${item.offset_left}px;top: ${item.offset_top}px;background-color: ${item.bg_color||"unset"}`'
                 :title='`${item.title}`'
            >
              <!--                  <div class='widget-title ellipsis' v-show='showTitle'>{{ item.title }}</div>-->
              <div v-if='item.type==="image"' style='width:100%;height:100%;padding: 1px'>
                <img :src='item.value' :alt='item.title' object-fit='contain' style='width:100%;height:100%;display: inline-block;' />
              </div>
              <div v-else-if='["rich","textarea"].indexOf(item.type)>-1'>
                <div v-html='item.value'></div>
              </div>
              <div v-else>
                <div :title='item.title'>
                  {{ item.value }}
                </div>
              </div>

            </div>
          </template>
        </div>
      </div>
  </div>
</template>

<script>
import { queryPlcSettingInfo } from '@/api/plc_setting'

export default {
  name: 'PlcCover',
  props:{
    plcId:{
      type:[Number,String]
    }
  },
  data(){
    return {
      panel: {
        width: 920,
        height: 1080,
        bg_color: '#FFFFFF'
      },
      boxPanel: { width: 920, height: 1080, top: 60, right: 10, left: 930 },
      widgetSettings: []//控件配置信息，用于渲染
    }
  },
  methods:{
    // eslint-disable-next-line no-unused-vars
    async show() {
      const { cover_setting } = await queryPlcSettingInfo({ plc_id: this.plcId })
      this.widgetSettings = this.checkDataArray(JSON.parse(cover_setting))
    },
    checkDataArray(data) {
      if (Object.values(data) !== data) {
        data = Object.values(data)
      }
      return data
    },
  },
  created() {
    this.show()
  }
}
</script>

<style scoped>

</style>
