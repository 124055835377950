<template>
  <el-select v-model="v" filterable remote reserve-keyword :placeholder="placeholder" :remote-method="getOptions"
             :loading="loading" clearable>
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      <span style="float: left">{{ item.label }}</span>
      <span v-if="item.mobile" style="float: right; color: #8492a6; font-size: 13px">
        {{ item.mobile.slice(0, 3) }}***{{ item.mobile.substr(-4) }}
      </span>
    </el-option>
  </el-select>
</template>
<script>

import { optionsUsers } from '../../api/options'

export default {
  name: 'SelectUser',
  props: {
    placeholder: {
      type: String,
      default() {
        return '选择销售'
      }
    },
    value: {
      type: [String, Number],
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      v: null,
      options: [],
      loading: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(_) {
        this.v = _

      }
    },
    v(_) {
      this.updateData(_)
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: async function() {
      await this.getOptions(this.value)
      if (this.value){
        this.updateData(this.value)
      }
    },
    updateData: function(_) {
      const obj = this.options.find(option => {
        return option.value === _
      })
      this.$emit('update:label', obj ? obj.label : null)
      this.$emit('update:value', _)
      this.$emit('change', obj || false)
    },
    getOptions: async function(keyword) {
      const data = await optionsUsers({ keyword })
      this.options = data || []
    }
  }
}
</script>
<style scoped>

</style>
