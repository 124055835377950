<template>
  <div class="handle-button">
    <!--  添加类目  -->
    <save-category ref='Save' tbl="emphasis_products_category" @success='callbackAddCate' />
    <save-category-sub ref='SaveSub' tbl="emphasis_products_category" @success='getOptionsCategorySub' />

    <el-button :type="buttonType" size="mini" @click="handleAdd" round>添加</el-button>
    <el-dialog :title="`${form.id?'修改':'新增'}重点大全案品`" :visible.sync="show" center append-to-body
               custom-class="emphasis-products-store-container">
      <el-form v-loading="loading" ref="form" :model="form" :rules="rules" size="medium"
               label-position="right" label-width="64px">
        <el-form-item label="商品：" prop="product_name">
          <select-product :key="key.SelectProduct" :product-id.sync="form.product_id"
                          :product-name.sync="form.product_name"
                          @change="eventChangeProduct" />
        </el-form-item>
        <el-form-item label='品类：' prop='category'>
          <el-select clearable v-model='form.category' filterable placeholder='请选择品类' class='select-category'>
            <el-option v-for='item in options.category' :key='item.value' :label='item.label' :value='item.value' />
          </el-select>
          <el-button type='primary' icon='el-icon-plus' circle @click='handleAddCate' />
        </el-form-item>
        <el-form-item label='类目：' prop='category_sub'>
          <el-select clearable :key='key.SelectCategorySub' v-model='form.category_sub' filterable
                     placeholder='请选择类目'
                     class='select-category'>
            <el-option v-for='item in options.category_sub' :key='item.value' :label='item.label' :value='item.value' />
          </el-select>
          <el-button type='primary' icon='el-icon-plus' circle @click='handleAddCateSub' />
        </el-form-item>
        <el-form-item label="品牌：" prop="brand_id">
          <select-product-brand :key="key.SelectProductBrand" :value.sync="form.brand_id"
                                :label.sync="form.brand_name" />
        </el-form-item>
        <el-form-item label="销售：" prop="sale_id">
          <span v-if="onlySelf">{{ form.sale_name }}</span>
          <select-sale v-else :key="key.SelectProductSale" :value.sync="form.sale_id" :label.sync="form.sale_name" />
        </el-form-item>
        <el-form-item label="预算：" prop="budget">
          <el-input v-model="form.budget" show-word-limit :max="100" placeholder="请填写预算" />
        </el-form-item>
        <el-form-item label="佣金：" prop="commission">
          <el-input v-model="form.commission" show-word-limit :max="100" placeholder="请填写佣金" />
        </el-form-item>
        <el-form-item label="费比：" prop="febi">
          <el-input v-model="form.febi" show-word-limit :max="100" placeholder="请填写费比" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <ml-button :disabled="loading" text='确 定' @click.native='handleSubmit' />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SelectProductCategory from '@/components/select/product-category.vue'
import SelectProductBrand from '../../../../components/select/product-brand.vue'
import SelectProduct from '@/components/select/product.vue'
import SelectSale from '../../../../components/select/user.vue'
import { emphasisProductOptions, emphasisProductSave } from '../../../../api/emphasis-products'
import MlButton from '@/components/MlButton/index.vue'
import { emphasisProductsCategoryOptions } from '../../../../api/emphasis_products_category'
import SaveCategorySub from '@/pages/category_module/save-category-sub.vue'
import SaveCategory from '@/pages/category_module/save-category.vue'
import { mapGetters } from 'vuex'

const { id: sale_id, name: login_name, nickname: login_nickname } = JSON.parse(localStorage.getItem('user_info'))
const sale_name = login_name || login_nickname

const genKey = function() {
  return (new Date()).getTime()
}
const initKey = function() {
  return {
    SelectProduct: genKey(),
    SelectProductBrand: genKey(),
    SelectCategorySub: genKey(),
    SelectProductSale: genKey()
  }
}
const Model = {
  id: null,
  product_id: '',
  product_name: null,
  brand_id: null,
  brand_name: null,
  category: null,
  category_sub: null,
  sale_id: null,
  sale_name: null,
  budget: null,
  commission: null,
  febi: null
}
const rules = {
  product_name: [
    { required: true, message: '请填写商品', trigger: ['blur', 'change'] },
    { min: 1, max: 64, message: '长度在 1 到 64 个字符', trigger: ['blur', 'change'] }
  ],
  category: [{ required: true, message: '请填写品类', trigger: ['blur', 'change'] }],
  // category_sub: [{ required: false, message: '请填写类目', trigger: ['blur', 'change'] }],
  brand_id: [{ required: true, message: '请填写品牌', trigger: ['blur', 'change'] }],
  sale_id: [{ required: true, message: '请填写销售', trigger: ['blur', 'change'] }],
  budget: [{ required: true, message: '请填写预算', trigger: ['blur', 'change'] }],
  commission: [{ required: true, message: '请填写佣金', trigger: ['blur', 'change'] }],
  febi: [{ required: true, message: '请填写费比', trigger: ['blur', 'change'] }]
}


export default {
  name: 'EmphasisProductsStore',
  components: {
    SaveCategorySub,
    SaveCategory, MlButton, SelectSale, SelectProductBrand, SelectProductCategory, SelectProduct
  },
  props: {
    buttonType: {
      type: String,
      default() {
        return 'primary'
      }
    }
  },
  data() {
    return {
      rules,
      show: false,
      loading: false,
      is_just_edit: false,
      key: initKey(),
      form: { ...{}, ...Model },
      options: { category: [], category_sub: [] }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    onlySelf() {
      return this.userPermissions.indexOf('emphasis_products_manage__save_all') === -1
    }
  },
  watch: {
    'form.category'() {
      this.getOptionsCategorySub()
    },
    form: {
      deep: true,
      handler() {
        const { product_name, brand_name, sale_name } = this.form
        if (product_name && brand_name && sale_name) {
          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      }
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    callbackAddCate: async function(name) {
      const { category } = await emphasisProductOptions({ fields: ['category'] })
      this.options.category = category || []
      this.form.category = name
    },
    handleAddCateSub: function() {
      this.$refs.SaveSub.interfaceAdd(this.form.category_id)
    },
    handleAddCate: function() {
      this.$refs.Save.interfaceAdd()
    },
    eventChangeProduct: function(product) {
      if (this.is_just_edit) {
        this.is_just_edit = false
        return
      }
      if (product) {
        const { brand_id } = product
        this.form.brand_id = brand_id || null
        this.key.SelectProductBrand = genKey()
        this.key.SelectProductSale = genKey()
      }
    },
    handleAdd: async function() {
      this.is_just_edit = false
      this.form = { ...{}, ...Model }
      if (this.onlySelf) {
        this.form.sale_id = sale_id
        this.form.sale_name = sale_name
      }
      this.show = true
      await this.$nextTick()
      this.$refs.form && this.$refs.form.clearValidate()
    },
    handleEdit: async function(obj) {
      this.show = true
      await this.getOptions()
      this.is_just_edit = true
      this.loading = true
      this.form = { ...{}, ...obj }
      this.key = initKey()
    },
    handleSubmit: function() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await emphasisProductSave(this.form)
          this.show = false
          this.$message.success('操作成功')
          this.$emit('success', !!this.form.id)
        } else {
          return false
        }
      })
    },
    getOptions: async function() {
      this.options = await emphasisProductOptions()
    },
    getOptionsCategorySub: async function() {
      const obj = this.options.category.find((row) => {
        return row.value === this.form.category
      })
      if (obj) {
        this.form.category_id = obj.id || -1
      }
      if (!this.form.category_id) {
        return
      }

      this.options.category_sub = await emphasisProductsCategoryOptions({ parent_id: this.form.category_id })
      this.key.SelectCategorySub = genKey()
    }
  }
}

</script>
<style lang="scss">
.emphasis-products-store-container {
  border-radius: 20px;
  width: 800px;

  .el-form {
    width: 80%;
    margin: 0 auto;
  }

  .el-select, .el-cascader {
    width: 100%;
  }

  .select-category {
    width: calc(100% - 46px);
    margin-right: 10px;
  }
}
</style>
