<template>
  <el-table v-loading="loading" :data="table.list" show-summary :summary-method="getSummaries" :cell-class-name="cellClass"
            highlight-current-row
            highlight-current-column
            style="width: 100%" height="100%">
    <el-table-column prop="leader_name" label="负责人" min-width="120" align="center" fixed />
    <el-table-column prop="category_all" label="全品类" min-width="120" align="center" fixed="right" />
    <el-table-column v-for="(head,head_i) in table.head" :label="head.category_name" :key="head_i" align="center">
      <el-table-column
        v-for="(child,child_i) in head.children" :key="child_i"
        :prop="child.field"
        :label="child.category_sub_name"
        custom-class="aaa"
        min-width="120" align="right" />
    </el-table-column>
  </el-table>
</template>
<script>
import { lbStatCategory } from '../../../../api/lb_stat'
import { numberFormat } from '../../../../utils/utils'

export default {
  name: 'LbStatTab3',
  data() {
    return {
      loading: false,
      table: {
        head: [],
        list: []
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData: async function() {
      this.loading = true
      this.table = await lbStatCategory()
      this.loading = false
    },
    cellClass: function({ column }) {
      if (column.property.indexOf('bg_1') > -1) {
        return 'cell-bg_1'
      } else if (column.property.indexOf('bg_2') > -1) {
        return 'cell-bg_2'
      }
      // else if (column.property.indexOf('sum') > -1) {
      //   return 'cell-total'
      // }
    },
    getSummaries: function(param) {
      const { columns, data } = param
      const sums = []
      const obj = {}
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '全员'
          return
        }
        const { property } = column
        if (!property || ['artist_nickname', 'duration', 'month'].includes(property)) {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          obj[property] = sums[index]
          sums[index] = numberFormat(sums[index], 0)
        } else {
          obj[property] = 0
          sums[index] = ''
        }
      })

      return sums
    }
  }
}

</script>
<style lang="scss">
.cell-total {
  background-color: #fff8fb;
}

.cell-bg_1 {
  background-color: #fff8fb;
}

.cell-bg_2 {
  background-color: #f7fffc;
}
</style>
