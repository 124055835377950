import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：】
 * 生成日期：2025/01/09 11:46:04
 * 生成路径: src/api/employee.js
 * 生成人：超级管理员
 */

/**
 * @description 获取列表
 * @param {*} data
 * @returns
 */
export function getEmployeeList(data) {
  return request.post('/employee/list', data)
}
/**
 * @description 保存
 * @param {*} data
 * @returns
 */
export function saveEmployee(data) {
  return request.post('/employee/store', data)
}

/**
 * @description 获取详情
 * @param id
 * @returns
 */
export function getEmployeeInfo(id) {
  let data = { id: id }
  return request.post('/employee/get_info', data)
}

/**
 * @description 获取详情
 * @param where
 * @returns
 */
export function queryEmployeeInfo(where) {
  return request.post('/employee/query', where)
}

/**
 * @description 根据id删除单条信息
 * @param id
 * @returns
 */
export function deleteEmployee(id) {
  let data = { id: id }
  return request.post('/employee/delete', data)
}

/**
 * 导入
 * @param data
 * @returns {Promise}
 */
export function importEmployees(data) {
  return request.post('/employee/import', data)
}

/**
 * 导出
 * @param data
 * @returns {Promise}
 */
export function exportEmployees(data) {
  return request.postBlob('/employee/export', data)
}
