//自动检查更新
//获取html页面内容： fetch('/').then(resp => resp.text()).then(resp => console.log(resp))
// 检测比对 js引入是否已不同，若不同则提示需要更新

// 上一次获取的script地址
let lastSrcArr

const scriptReg = /<script.*src=["'](?<src>[^"']+)/gm

/**
 * 获取最新页面中script链接
 * @returns {Promise<*[]>}
 */
async function extractNewScripts() {
  const html = await fetch('/?_t=' + Date.now()).then(resp => resp.text())
  scriptReg.lastIndex = 0
  let result = []
  let match
  while ((match = scriptReg.exec(html))) {
    result.push(match.groups.src)
  }

  return result
}


async function needUpdate() {
  const newScripts = await extractNewScripts()
  if (!lastSrcArr) {
    lastSrcArr = newScripts
    return false
  }
  let result = false
  const srcLen = lastSrcArr.length
  for (let i = 0; i < srcLen; i++) {
    if (lastSrcArr[i] !== newScripts[i]) {
      result = true
      break
    }
  }
  lastSrcArr = newScripts
  return result

}

// 轮询时间:每5秒检测一次
const DURATION = 5000

function autoRefresh() {
  // 生产环境才检测更新
  if (process.env.NODE_ENV === 'production') {
    setInterval(async () => {
      // const result = confirm('点击确认刷新页面')
      // console.log('autoRefresh',result)
      // if (result) {
      //   location.reload()
      // }else {
      //   console.log("放弃更新")
      // }
      const willUpdate = await needUpdate()
      if (willUpdate) {
        const result = confirm('页面有更新，点击确认刷新页面')
        if (result) {
          location.reload()
        } else {
          console.log('放弃更新')
        }
      }
    }, DURATION)
  } else {
    console.log('node_env', process.env.NODE_ENV)
  }

}

autoRefresh()
