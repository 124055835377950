<!--/**-->
<!--* 自动生成 vue列表代码【模块名：】-->
<!--* 生成日期：2025/01/09 11:46:04-->
<!--* 生成路径: src/pages/employee/EmployeeList.vue-->
<!--* 生成人：管理员-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='UID'>
              <el-input v-model='searchCondition.uid' placeholder='UID' clearable></el-input>
            </el-form-item>
            <el-form-item label='姓名'>
              <el-input v-model='searchCondition.name' placeholder='姓名' clearable></el-input>
            </el-form-item>
            <el-form-item label='性别'>
              <el-input v-model='searchCondition.gender' placeholder='性别：M男|F女' clearable></el-input>
            </el-form-item>
            <el-form-item label='手机号码'>
              <el-input v-model='searchCondition.phone_no' placeholder='手机号码' clearable></el-input>
            </el-form-item>
            <el-form-item label='邮箱'>
              <el-input v-model='searchCondition.email' placeholder='邮箱' clearable></el-input>
            </el-form-item>
            <el-form-item label='身份证'>
              <el-input v-model='searchCondition.id_card' placeholder='身份证' clearable></el-input>
            </el-form-item>
            <el-form-item label='出生日期'>
              <el-input v-model='searchCondition.birth_date' placeholder='出生日期' clearable></el-input>
            </el-form-item>
            <el-form-item label='员工工号'>
              <el-input v-model='searchCondition.job_no' placeholder='员工工号' clearable></el-input>
            </el-form-item>
            <el-form-item label='岗位'>
              <el-input v-model='searchCondition.position' placeholder='岗位' clearable></el-input>
            </el-form-item>
            <!--            <el-form-item label='薪资'>-->
            <!--              <el-input v-model='searchCondition.salary' placeholder='薪资' clearable></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label='‌所属部门'>-->
            <!--              <el-input v-model='searchCondition.department' placeholder='‌所属部门' clearable></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label='入职日期'>-->
            <!--              <el-input v-model='searchCondition.hired_date' placeholder='入职日期' clearable></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label='离职日期'>-->
            <!--              <el-input v-model='searchCondition.leave_date' placeholder='离职日期' clearable></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label='住址'>-->
            <!--              <el-input v-model='searchCondition.address' placeholder='住址' clearable></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label='状态'>
              <el-input v-model='searchCondition.status' placeholder='状态Y:在职 N离职' clearable></el-input>
            </el-form-item>

            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
        <el-row class='mb8' :gutter='10'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-plus' v-if='hasAuthority("employee_create")' @click='handleAdd'>新增
            </el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button icon='el-icon-upload' v-if='hasAuthority("employee_import")' @click='openImport=true'>导入
            </el-button>
          </el-col>
          <el-col :span='1.5'>
            <export-btn ref='refLogisticSubscribeExport' :can-export='hasAuthority("employee_export")'
                        @export='handleExport'></export-btn>
          </el-col>
        </el-row>
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column slot='gender' prop='gender' align='center' width='120' label='性别'>
              <template slot-scope='{row}'>
                <span>{{ fmtGender(row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column slot='status' prop='status' align='center' width='120' label='状态'>
              <template slot-scope='{row}'>
                <span>{{ fmtStatus(row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column align='center' width='120' label='操作'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-edit' v-if='hasAuthority("employee_edit")'
                           @click='handleEdit(row)'>修改
                </el-button>
                <el-button type='text' icon='el-icon-delete' v-if='hasAuthority("employee_delete")'
                           @click='handleDelete(row,$index)'>删除
                </el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>

      <!-- 添加或修改对话框 -->
      <el-dialog :title='title' :visible.sync='open' width='600px' append-to-body :close-on-click-modal='false'>
        <el-form ref='form' :model='form' :rules='rules' label-width='100px'>
          <el-form-item label='唯一ID' prop='uid'>
            <el-input v-model='form.uid' placeholder='请输入钉钉唯一标识（多次入职不变）' clearable />
          </el-form-item>
          <el-form-item label='员工ID' prop='staff_id'>
            <el-input v-model='form.staff_id' placeholder='请输入钉钉员工ID（多次入职会变）' clearable />
          </el-form-item>
          <el-form-item label='姓名' prop='name'>
            <el-input v-model='form.name' placeholder='请输入姓名' clearable />
          </el-form-item>
          <el-form-item label='性别' prop='gender'>
            <!--            <el-input v-model='form.gender' placeholder='请输入性别：M男|F女' clearable />-->
            <el-select v-model='form.gender' placeholder='性别：M男|F女' clearable>
              <el-option v-for='(option,index) in genderMap' :key='`gender_${index}`' :label='option.label'
                         :value='option.value'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='手机号码' prop='phone_no'>
            <el-input v-model='form.phone_no' placeholder='请输入手机号码' clearable />
          </el-form-item>
          <el-form-item label='邮箱' prop='email'>
            <el-input v-model='form.email' placeholder='请输入邮箱' clearable />
          </el-form-item>
          <el-form-item label='身份证' prop='id_card'>
            <el-input v-model='form.id_card' placeholder='请输入身份证' clearable />
          </el-form-item>
          <el-form-item label='出生日期' prop='birth_date'>
            <el-input v-model='form.birth_date' placeholder='请输入出生日期' clearable />
          </el-form-item>
          <el-form-item label='员工工号' prop='job_no'>
            <el-input v-model='form.job_no' placeholder='请输入员工工号' clearable />
          </el-form-item>
          <el-form-item label='岗位' prop='position'>
            <el-input v-model='form.position' placeholder='请输入岗位' clearable />
          </el-form-item>
          <el-form-item label='薪资' prop='salary'>
            <el-input v-model='form.salary' placeholder='请输入薪资' clearable />
          </el-form-item>
          <el-form-item label='‌所属部门' prop='department'>
            <el-input v-model='form.department' placeholder='请输入‌所属部门' clearable />
          </el-form-item>
          <el-form-item label='入职日期' prop='hired_date'>
            <el-input v-model='form.hired_date' placeholder='请输入入职日期' clearable />
          </el-form-item>
          <el-form-item label='离职日期' prop='leave_date'>
            <el-input v-model='form.leave_date' placeholder='请输入离职日期' clearable />
          </el-form-item>
          <el-form-item label='住址' prop='address'>
            <el-input v-model='form.address' placeholder='请输入住址' clearable />
          </el-form-item>
          <el-form-item label='状态' prop='status'>
            <!--            <el-input v-model='form.status' placeholder='请输入状态Y:在职 N离职' clearable />-->
            <el-select v-model='form.status' placeholder='状态:Y在职 N离职' clearable>
              <el-option v-for='(option,index) in statusMap' :key='`gender_${index}`' :label='option.label'
                         :value='option.value'></el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button type='primary' @click='submitForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定
          </el-button>
          <el-button @click='cancel'>取 消</el-button>
        </div>
      </el-dialog>
      <!--      导入-->
      <ImportEmployee :visible.sync='openImport' />
      <!--      <ImportEmployee :visible.sync='openImport' v-if='hasAuthority("employee_import") @imported="handleQuery" />-->
    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ImportEmployee from './ImportEmployee'
import { deleteEmployee, exportEmployees, getEmployeeInfo, getEmployeeList, saveEmployee } from '@/api/employee'

export default {
  name: 'EmployeeList',
  components: { ImportEmployee, ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'employee',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {
        date_range: [],
        uid: null,
        name: null,
        gender: null,
        phone_no: null,
        email: null,
        id_card: null,
        birth_date: null,
        job_no: null,
        position: null,
        salary: null,
        department: null,
        hired_date: null,
        leave_date: null,
        address: null,
        status: null
      },
      pageData: { current_page: 1, page_size: 10 },
      orderBy: { id: 'asc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [{ hide: false, prop: 'id', label: '序号', width: 120, sortable: true, fixed: false },
        { hide: true, prop: 'uid', label: '唯一ID', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'staff_id', label: '员工ID', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'name', label: '姓名', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'gender', label: '性别', width: 120, sortable: false, fixed: false, slot: true },
        { hide: true, prop: 'birth_date', label: '出生日期', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'id_card', label: '身份证', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'phone_no', label: '手机号码', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'email', label: '邮箱', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'job_no', label: '员工工号', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'position', label: '岗位', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'salary', label: '薪资', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'department', label: '‌所属部门', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'hired_date', label: '入职日期', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'leave_date', label: '离职日期', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'address', label: '住址', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'status', label: '状态', width: 120, sortable: false, fixed: false, slot: true },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 120, sortable: true, fixed: false }],
      // 弹出层标题
      title: '添加/修改',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {
        // uid: [{ required: true, message: 'UID不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        // gender: [{ required: true, message: '性别：M男|F女不能为空', trigger: 'blur' }],
        // phone_no: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }],
        // email: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }],
        // id_card: [{ required: true, message: '身份证不能为空', trigger: 'blur' }],
        // birth_date: [{ required: true, message: '出生日期不能为空', trigger: 'blur' }],
        // job_no: [{ required: true, message: '员工工号不能为空', trigger: 'blur' }],
        // position: [{ required: true, message: '岗位不能为空', trigger: 'blur' }],
        // salary: [{ required: true, message: '薪资不能为空', trigger: 'blur' }],
        // department: [{ required: true, message: '‌所属部门不能为空', trigger: 'blur' }],
        hired_date: [{ required: true, message: '入职日期不能为空', trigger: 'blur' }],
        // leave_date: [{ required: true, message: '离职日期不能为空', trigger: 'blur' }],
        // address: [{ required: true, message: '住址不能为空', trigger: 'blur' }],
        status: [{ required: true, message: '状态Y:在职 N离职不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      statusMap: [{ label: '在职', value: 'Y' }, { label: '离职', value: 'N' }],
      genderMap: [{ label: '男', value: 'M' }, { label: '女', value: 'F' }],
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:employee'
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },

    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await getEmployeeList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    async delRow(id) {
      await deleteEmployee(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加'
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改'
      let { info } = await getEmployeeInfo(row.id)
      this.form = { ...info }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          saveEmployee(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await exportEmployees(searchCondition)
        let name = ``

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    },
    fmtGender(val) {
      return this.genderMap.find(value => value.value === val)?.label || '未知'
    },
    fmtStatus(val) {
      return this.statusMap.find(value => value.value === val)?.label || val
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>
