import * as request from '@/utils/request'

/**
 * 品牌（产品）
 * @param data
 * @returns {Promise}
 */
export function optionsBrandHubBrands(data) {
  return request.post('/options/brand_hub_brands', data)
}

/**
 * 产品
 * @param data
 * @returns {Promise}
 */
export function optionsProducts(data) {
  return request.post('/options/products', data)
}

/**
 * 销售
 * @param data
 * @returns {Promise}
 */
export function optionsUsers(data) {
  return request.post('/options/users', data)
}
