<template>
  <div style="display: inline-block">
    <el-button @click='handleSetKolSelectNum' icon='el-icon-folder-opened'>红人选品</el-button>
    <el-dialog
        title="红人选品限制"
        :visible.sync="kolSelectVisible"
        width="50%">
      <!--      <div> 设置限制额度：-->
      <!--        <el-input style="width:120px"-->
      <!--                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"-->
      <!--                  v-model="dataInput" placeholder="请输入限制的额度"></el-input>-->
      <!--        <el-button @click="configSelect" type="primary" size="mini" style="margin-left: 10px">确定</el-button>-->
      <!--      </div>-->
      <!--      <div>-->
      <!--        已选红人：-->
      <!--        <el-tag-->
      <!--            v-for="tag in selectRows"-->
      <!--            :key="tag.id"-->
      <!--            style="margin: 0 4px"-->
      <!--        >-->
      <!--          {{ tag.nickname }}-->
      <!--        </el-tag>-->
      <!--      </div>-->
      <hr/>

      <div class='main-page-content'>
        <div style='display: flex;flex-direction: row;gap: 5px;justify-content: space-between'>
          <div style='display: inline'>
            <el-form :model='searchCondition' :inline='true' label-position='right' label-width=''>
              <el-form-item label=''>
                <el-input @change="init" v-model='searchCondition.nickname' placeholder='红人昵称模糊匹配'
                          clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' @click='searchForm'><i class='el-icon-search'></i>搜索</el-button>
                <el-button @click='clearCondition'>重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--   table列表     -->
        <div class='default-table'>
          <ApeTable
              ref='apeTable'
              :data='dataList'
              :loading='loadingStatus'
              :pagingData='pagingData'
              @switchPaging='switchPaging'
              row-key="id"
              @selectList="selectList"
              highlight-current-row
              border>
            <el-table-column
                slot='second-column'
                width='64'
                label='序号' align='center' fixed='left'>
              <template slot-scope='scope'>
                <span>{{ offset + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column slot='second-column' prop='nickname' label='红人昵称' min-width='120' align='center'
                             show-overflow-tooltip
            >
              <template slot-scope='{row}'>
                <artist-nickname :info='{artist_id:row.id,nickname:row.nickname}'/>
              </template>
            </el-table-column>
            <el-table-column slot='second-column' prop='group_id' label='项目组' min-width='120' align='center'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.group_name || row.group_id }}</span>
              </template>
            </el-table-column>
            <el-table-column slot='second-column' prop='group_id' label='限制额度' min-width='120' align='center'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.selection_qty || '无限额' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="{ row }">
                <el-popover placement="right"
                            trigger="click" :title="`修改【${row.nickname}】限额`">
                  <el-input style="width:120px" oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                            v-model="dataInput" placeholder="请输入限制的额度"></el-input>
                  <el-button size="mini" @click="configSelect(row)" type="primary"
                             style="display: block;margin-top: 10px">确认
                  </el-button>

                  <template #reference>
                    <el-button size="default" type="text" class="el-icon-s-claim">修改限额</el-button>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
          </ApeTable>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import GroupSelect from "@/pages/groups/components/GroupSelect.vue";
import ApeTable from "@/components/ApeTable.vue";

export default {
  name: 'kolSelectCount',
  components: {ApeTable, GroupSelect},
  data() {
    return {
      kolSelectVisible: false,
      loadingStatus: true,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 10
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      platforms: [],
      searchCondition: {
        nickname: null,
        group_id: null,
        platforms: []
      },
      dataInput: 0,
      selectRows: []
    }
  },
  methods: {
    init() {
      this.getColumns()
      setTimeout(() => {
        this.initList()
      }, 800)

    },

    async getColumns() {
      let {columns} = await this.$api.getColumnsArtist()
      this.columns = columns
    },
    // 初始化列表
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo,)
      this.dataList = []
      let {list, pages} = await this.$api.getArtistLimitConfigList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStatus = false
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.platforms) {
        condition.platforms = this.searchCondition.platforms
      }
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }
      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }
      if (this.searchCondition.group_range?.length != 0) {
        condition.group_range = this.searchCondition.group_range
      }

      return condition
    },
    // 搜索
    searchForm() {
      this.offset = 0
      this.initList()
      // this.$refs['apeTable'].handleCurrentChange(1)
    },
    clearCondition() {
      this.searchCondition = {
        nickname: null,
        group_id: null,
        platforms: [],
        group_range: []
      }
      this.searchForm()
    },
    async selectDept(val, type = null) {
      // let group_id = null
      this.searchCondition.group_range = val
      this.searchCondition.group_id = null
      if (!type) {
        this.searchForm()
      }
    },
    handleSetKolSelectNum() {
      this.kolSelectVisible = true
      this.init()
    },
    configSelect(row) {
      this.$confirm(`确定给以下${row.nickname}设置限额吗`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = {
          artist_id: row.id,
          selection_qty: this.dataInput
        }
        if (row.limit_config_id && row.limit_config_id !== '') {
          data = {...data, id: row.limit_config_id}
          let id = await this.$api.setEditArtistLimitConfig(data)
          if (id) {
            this.$message.success('设置成功')
          }
        } else {
          let id = await this.$api.setArtistLimitConfig(data)
          if (id) {
            this.$message.success('设置成功')
          }
        }
        this.init()

      })
    },
    selectList(row) {
      this.selectRows = row
    },
  }
}
</script>