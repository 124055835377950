// 首字母大小
import EditableCell from '@/components/EditableCell'
import { numberFormat } from '@/utils/utils'
////
// columns 数据结构-单级
//   {
//     value: 'live_time',
//     title: '直播时间',
//     width: 240,
//     align: 'center',
//     component: 'el-date-picker',  //时间选择器  //el-input ：输入框
//     data_type: 'string',
//     required: true  //是否必填
//   }
// columns 数据结构-多级
// {
//   value: '', title: '非星图价格', width: 100, children: [
//   { value: '1231', title: '单推', width: 100, component: 'el-input', data_type: 'integer' },
//   { value: '2111', title: '合集', width: 100, component: 'el-input', data_type: 'integer' }
// ]
// }
export const editTable = {
  components: {
    EditableCell
  },
  data() {
    return {
      num: 1
    }
  },
  computed: {
    // ---- 分页相关配置 -------
    // 每页条数，切换
    pageSizes() {
      if (!(typeof (this.pagingData.page_size) == 'undefined') && this.pagingData.page_size < 10) {
        return [this.pagingData.page_size]
      }
      return [10, 15, 20, 50, 100, 200, 300]
    },
    // 每一页大小，默认
    pageSize() {
      if (this.pagingData && !(typeof (this.pagingData.page_size) == 'undefined')) {
        return this.pagingData.page_size
      }
      return 10
    },
    // 初始化分页
    initPaging() {
      if (!(typeof (this.pagingData) == 'undefined')) {
        if (this.pagingData.is_show && this.pagingData.total > this.pageSize) {
          return true
        }
      }
      return false
    },
    // 数组总数
    dataTotal() {
      return this.pagingData.total
    },
    // 数据偏移量，分页序号使用
    dataOffset() {
      return typeof (this.pagingData.offset) == 'undefined' ? 0 : this.pagingData.offset
    },
    // 默认分页结构
    defaultLayout() {
      if (!(typeof (this.pagingData) == 'undefined')) {
        if (this.pagingData.layout) {
          return this.pagingData.layout
        }
      }
      return 'total, sizes, prev, pager, next, jumper'
    }
  },
  created() {
    const tblHeight = window.document.body.clientHeight
    // console.log('tbl-h', tblHeight)
    this.tblHeight = tblHeight - 200
  },
  methods: {
    // pageSize 改变时处理
    handleSizeChange(val) {
      // this.pageSize = val
      this.apePageSize = val
      this.currentPage = 1
      this.switchPaging()
      // this.$emit('switchPaging')
    },
    // currentPage 改变时处理
    handleCurrentChange(val) {
      this.currentPage = val
      this.switchPaging()
      // this.$emit('switchPaging')
    },

    switchPaging() {
      this.initList()
    },
    // ------ 分页相关 ---------
    // 获取当前分页相关信息,type类型主要处理删除删除后的情况处理
    // 解决当前页数据全部删除完空白作用
    getPagingInfo(type = 'no_del') {
      if (type == 'del' && (this.pagingData.total - 1) <= (this.currentPage - 1) * this.pageSize) {
        this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1
      }
      let pagingInfo = {
        page_size: this.apePageSize ? this.apePageSize : this.pageSize,
        current_page: this.currentPage
      }
      return pagingInfo
    },

    moneyFormat(value) { // 金额 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },

    async initData(searchCondition) {
      this.searchCondition = searchCondition
      this.tableData = []
      if (searchCondition.artist_id && searchCondition.platform_code) {
        await this.initList()
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }
      if (this.searchCondition.rangYm) {
        condition.start_time = this.searchCondition.rangYm[0]
        condition.end_time = this.searchCondition.rangYm[1]
      }

      return condition
    },



    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
      if (!row.id) {
        return 'add_line'
      } else {
        return 'update_line'
      }
    },
    //
    // selectEditRow(row, column, e) {
    //   e.preventDefault()//阻止元素发生默认的行为
    //   if (column && column.property) {
    //     // console.log(`${ e.type } ${ column.property }`)
    //     this.tableDataAppend = [row]
    //     this.currentRow = { ...row }
    //   }
    // },

    async saveRow(fields, arrText) {
      let row = {}
      for (let i = 0; i < fields.length; i++) {
        let f = fields[i]
        row[f] = arrText[i] ? arrText[i].trim() : ''
      }
      await this.addInfoData(row)
    },

    //保存当前选中的行（若无选中，则新增）
    async saveCurrentRow(row) {
      let hasrequired = false
      let requiredvalue = ''

      this.columns.forEach((item) => {
        if (item.children) {
          item.children.forEach((item) => {
            if (item.required) {
              requiredvalue = String(item.value)
              if (!row[requiredvalue]) {
                this.$notify.warning(`${item.title}必填！！！`)
                hasrequired = true
              }
            }
          })
        } else {
          if (item.required) {
            requiredvalue = String(item.value)
            if (!row[requiredvalue] && !(Number(row[requiredvalue]) === 0) ) {
              this.$notify.warning(`${item.title}必填！！！`)
              hasrequired = true
            }
          }
        }
      })
      this.loadingAdd = true
      if (!row.id && !hasrequired) {
        ///自己的页面写--判断有id并且是必填都写了！！！这是新增！！！！
        await this.addInfoData(row)
      } else if (!hasrequired) {
        ///这是修改！！！！
        if (row.id) {
          //
          await this.saveCell(row)

          this.$nextTick(() => {
            // this.$refs.refTblLiveDataRecord.doLayout()
          })
        }
        //
        // if (row.id === this.currentRow.id) {
        //   console.log('else')
        //   //
        //   let len = this.fields.length
        //   for (let i = 0; i < len; i++) {
        //     let field = this.fields[i]
        //     let oldVal = this.currentRow[field]
        //     let val = row[field]
        //     if (val !== oldVal) {
        //       //
        //       await this.saveCell(row, field)
        //     } else {
        //       //调试模式打印，生产环境不打印
        //       this.debugLogger(`${val}=== ${oldVal},无需修改`)
        //     }
        //   }
        //   //保存成功后
        //   let index = row.index - 1
        //   this.tableData[index] = { ...row }
        //   this.tableDataAppend = [{}]
        //   this.$nextTick(() => {
        //     // this.$refs.refTblLiveDataRecord.doLayout()
        //   })
        // }
      else {
          this.$notify.warning('保存异常')
        }

      }

      this.loadingAdd = false
    },
    clearRow() {
      this.tableDataAppend = [{}]
      this.currentRow = {}
      this.currentColumn = {}
    }
  }
}
