import * as request from '@/utils/request'

/**
 * 列表
 * @param data
 * @returns {Promise}
 */
export function lbStatList(data) {
  return request.post('/lb_stat/list', data)
}


export function lbStatCategory(data) {
  return request.post('/lb_stat/category', data)
}

export function lbStatsInit(data) {
  return request.post('/lb_stat/init_stat', data)
}
