export default {
  douyin: {
    platform_name: '抖音',
    options: ['flow_source__not_in', 'shop_id__not_in'],
    fields: {
      '流量来源': 'flow_source__not_in',
      '店铺名称': 'shop_id__not_in',
      '成交金额': 'amount__max'
    }
  },
  xiaohongshu: {
    platform_name: '小红书',
    options: ['order_status__not_in', 'shop_id__not_in'],
    fields: {
      '订单状态': 'order_status__not_in',
      '店铺名称': 'shop_id__not_in',
      '成交金额': 'amount__max'
    }
  },
  kuaishou: {
    platform_name: '快手',
    options: ['shop_id__not_in'],
    fields: {
      '店铺名称': 'shop_id__not_in',
      '成交金额': 'amount__max'
    }
  }
}
