<template>
  <div>
    <div class='flex-sb' style='margin-bottom: 8px'>
      <div class='flex-sb'>
        <span style='font-size: 16px;font-weight: bold'> 刊例表格(导出Excel)列配置</span>
      </div>
      <div class='flex-sb' style='gap: 10px'>
        <el-link type='warning' icon='el-icon-refresh-left' @click='handleCleanExcel'>清空</el-link>
      </div>
    </div>
    <div class='drag-container'>
      <!--    工作簿表格配置-->
      <div class='drag-source' data-drop='move'>
        <template v-for='(item,index) in excelColsArr'>
          <div class='drag-item plain ellipsis'
               :key='`drag_${item.field}_${index}`'
               draggable='true'
               data-effect='move'
               data-drop='move'
               :data-field='`${item.field}`'
               :data-item='`${JSON.stringify(item)}`'
               style='margin-right: 5px;margin-bottom: 5px'
          >
            <span>{{ index + 1 }}、{{ item.title }}</span>
          </div>
        </template>

      </div>

      <div class='drag-target'>
        <template v-for='(item,index) in dataList'>
          <div class='drag-item plain ellipsis flex-sb'
               :key='`dragged_${item.field}_${index}`'
               style='margin-right: 5px;margin-bottom: 5px'
               :data-field='`${item.field}`'
               :data-item='`${JSON.stringify(item)}`'
          >
            <span>{{ index + 1 }}、{{ item.title }}</span>
            <el-link type='danger' icon='el-icon-close' @click='delTblRow(item)'></el-link>
          </div>
        </template>
      </div>
    </div>
    <div class='default-table sort-container'>
      <el-table class='dragTable' :data='dataList' row-class-name="drag-row"  @row-contextmenu.prevent>
        <el-table-column type='expand'>
          <template slot-scope='{row,$index}'>
            <PlcChildColumns :data-list.sync='row.children'
                             :key='`childColumns_${$index}`'></PlcChildColumns>
          </template>
        </el-table-column>
        <el-table-column label='序号' :align='`center`' width='66'>
          <template slot-scope='{$index}'>
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label='字段' prop='field' :align='`center`' width='120'></el-table-column>
        <el-table-column label='列名' prop='title' :align='`left`' header-align='center' min-width='180'>
          <editable-cell
            slot-scope='{row,column}'
            :show-input='row.editMode'
            v-model='row[column.property]'
            type='textarea'>
            <div slot='content'>
                <span :title='`${row.field}`' data-effect='copy'
                      data-drop='copy'>{{ row[column.property] }}</span>
              <el-button type='text' icon='el-icon-edit' class='edit-btn'></el-button>
            </div>
          </editable-cell>
        </el-table-column>
        <el-table-column label='描述' prop='placeholder' :align='`center`' min-width='200'>
          <editable-cell
            slot-scope='{row,column}'
            :show-input='row.editMode'
            v-model='row[column.property]'
            type='textarea'>
            <div slot='content'>
              <span>{{ row[column.property] }}</span>
              <el-button type='text' icon='el-icon-edit' class='edit-btn'></el-button>
            </div>
          </editable-cell>
        </el-table-column>
        <el-table-column label='行高(pt)' prop='height' :align='`center`' width='100'>
          <editable-cell
            slot-scope='{row,column}'
            :show-input='row.editMode'
            v-model='row[column.property]'
            :is-input-number='true'>
            <div slot='content'>
              <span>{{ row[column.property] }}</span>
              <el-button type='text' icon='el-icon-edit' class='edit-btn'></el-button>
            </div>
          </editable-cell>
        </el-table-column>
        <el-table-column label='列宽(pt)' prop='width' :align='`center`' width='120'>
          <editable-cell
            slot-scope='{row,column}'
            :show-input='row.editMode'
            v-model='row[column.property]'
            :is-input-number='true'>
            <div slot='content'>
              <span>{{ row[column.property] }}</span>
              <el-button type='text' icon='el-icon-edit' class='edit-btn'></el-button>
            </div>
          </editable-cell>
        </el-table-column>
        <el-table-column label='对齐方式' prop='horizontal' align='center' width='120'>
          <template slot-scope='{row,column}'>
            <el-select v-model='row[column.property]'>
              <el-option label='左对齐' value='left'>左对齐</el-option>
              <el-option label='居中' value='center'>居中</el-option>
              <el-option label='右对齐' value='right'>右对齐</el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label='合并列名' prop='merge_col' align='center' width='120'>
          <template slot-scope='{row,column}'>
            <el-select v-model='row[column.property]'>
              <el-option label='是' value='Y'>是</el-option>
              <el-option label='否' value='N'>否</el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label='合并行' prop='merge_row' align='center' width='120'>
          <template slot-scope='{row,column}'>
            <el-select v-model='row[column.property]'>
              <el-option label='是' value='Y'>是</el-option>
              <el-option label='否' value='N'>否</el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <div style='padding: 10px;display: flex;flex-direction: row;gap: 5px;justify-content: center'>
        <el-button size='medium' type='primary' @click='handleSave'>保存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import Sortable from 'sortablejs'
// eslint-disable-next-line no-unused-vars
import { dragContainerBind } from './drag'
import EditableCell from '@/components/EditableCell'
import plcMap from '@/json/plc.json'
import PlcChildColumns from '@/pages/plc/setting/PlcChildColumns'
// import draggable from 'vuedraggable';

export default {
  name: 'PlcColumnsTable',
  components: { PlcChildColumns, EditableCell },
  data() {
    return {
      loading: false,
      dataList: [],
      expandRowKey: null,
      common_cols: plcMap.common_cols,
      quoteInfo: plcMap.quoteInfo,
      excelColsArr: [],
      draggingIndex: null,// 当前正在拖拽的行索引
    }
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    quoteMulti() {
      return this.quoteInfo.multi[this.formData.type] || []
    },
    selectedFields() {
      return this.dataList.map((item) => {
        if (item)
          return item.field

        return null
      })
    }
  },
  methods: {
    init(columns_data) {
      this.dataList = []
      columns_data.forEach((item) => {
        if (item)
          this.dataList.push({ ...item })
      })

      this.excelColsArr = this.initExcelColsArr()
      // this.dataList = JSON.parse(JSON.stringify(this.formData.columns_data))
      this.$nextTick(() => {
        this.dragEvent()
      })
    },
    initExcelColsArr() {
      let arr = []

      const selectedFields = this.dataList.map((item) => {
        if (item)
          return item.field
        return null
      })

      // console.log(this.formData.platform_quote)
      try {
        if (this.formData.platform_quote && this.formData.platform_quote.length > 0) {
          this.formData.platform_quote.forEach((item) => {
            if (item && item.field && selectedFields.indexOf(item.field) === -1)
              arr.push({ title: item.title, field: item.field, placeholder: `报价-${item.title}` })
          })
        }
      } catch (e) {
        console.log(e)
      }

      plcMap.common_cols.forEach((item) => {
        if (item && item.field && selectedFields.indexOf(item.field) === -1)
          arr.push({ title: item.title, field: item.field, placeholder: `报价-${item.title}` })
      })
      return arr
    },
    delTblRow(item) {
      this.$confirm(`确认移除【${item.title}】`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const pIndex = this.dataList.indexOf(item)
        if (pIndex > -1) {
          this.dataList.splice(pIndex, 1)
          // this.excelColsArr = this.initExcelColsArr()
          this.excelColsArr.push({ title: item.title, field: item.field, placeholder: `${item.title}` })
        } else {
          this.$notify.warning('未匹配到该数据项，无法移除')
        }
      })

    },
    dragEvent() {
      this.$nextTick(() => {
        // 表格行拖拽
        // this.rowDrop()
        // 列配置项拖拽
        dragContainerBind(document.querySelector('.drag-source'), this.dragMoved)
      })
    },
    dragMoved(target) {
      try {
        let item = target.dataset['item']
        if (item) {
          const obj = JSON.parse(item)
          if (obj && typeof obj === 'object') {
            if (obj.field) {
              this.handleAddToExcelCol(obj)
              //表格重新渲染

            }

          }
        }
      } catch (e) {
        console.log('dragMoved err:', e)
      }


    },

    //行拖拽
    rowDrop() {

      const tbody = document.querySelector('.dragTable > .el-table__body-wrapper tbody')
      console.log(tbody)
      // eslint-disable-next-line no-unused-vars
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          console.log(newIndex, oldIndex)
          _this.handleMoveRow(newIndex, oldIndex)
          // const currRow = _this.dataList.splice(oldIndex, 1)[0]
          // _this.dataList.splice(newIndex, 0, currRow)
        }
      })
    },
    handleMoveRow(newIndex, oldIndex) {
      const currRow = this.dataList.splice(oldIndex, 1)[0]
      this.dataList.splice(newIndex, 0, currRow)
    },
    handleClear() {
      this.$confirm('确认清空列设置？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.tableData = []

        this.$emit('handleSave')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    handleSave() {
      this.$emit('handleSave', this.dataList)
    },
    getMultiChildren() {
      // 平台报价字段(multi)
      return this.quoteMulti.cols
    },
    handleAddToExcelCol(item) {
      if (item) {
        let itemChildren = []
        if (item.field === 'multi') {
          // 计算 children
          itemChildren = this.getMultiChildren()
        }
        const cell = {
          field: item.field,
          title: item.title,
          height: '80.1',
          width: '15',
          placeholder: item.placeholder || '暂无描述',
          horizontal: 'center',
          merge_col: 'Y',
          merge_row: 'Y',
          showTool: true,
          children: itemChildren
        }
        this.dataList.push(cell)
        const pIndex = this.excelColsArr.findIndex(value => value.field === cell.field)
        this.excelColsArr.splice(pIndex, 1)
        // this.formData.columns_data.push(cell)
      }

    },
    //清空
    handleCleanExcel() {
      this.dataList = []
      this.init([])
    },
    /**
     * 记录当前展开页面
     * @param row
     */
    expandChange(row) {
      this.expandRowKey = row.field
    },
    // ============================ <<start 表格行拖拽 事件绑定和处理=============================
    // 添加拖拽事件
    addDragEvents() {
      // 获取所有带有类名 'drag-row' 的表格行
      const rows = document.querySelectorAll('.drag-row');
      // 遍历每一行，添加拖拽事件
      rows.forEach((row, index) => {
        row.draggable = true; // 设置行元素为可拖拽
        // 开始拖拽时的事件处理
        row.ondragstart = (event) => this.handleDragStart(event, index);
        // 拖拽经过时的事件处理
        row.ondragover = (event) => this.handleDragOver(event);
        // 拖拽放下时的事件处理
        row.ondrop = (event) => this.handleDrop(event, index);
      });
    },
    // 处理拖拽开始事件
    handleDragStart(event, index) {
      this.draggingIndex = index; // 记录当前拖拽行的索引
      event.dataTransfer.effectAllowed = 'move'; // 设置拖拽效果为移动
      event.dataTransfer.setData('text/plain', index); // 将索引存储到拖拽数据中
    },
    // 处理拖拽经过事件
    handleDragOver(event) {
      event.preventDefault(); // 阻止默认事件，允许放置
      event.dataTransfer.dropEffect = 'move'; // 设置拖拽效果为移动
    },
    // 处理拖拽放下事件
    handleDrop(event, targetIndex) {
      const sourceIndex = this.draggingIndex; // 获取开始拖拽时记录的索引

      // 如果源索引和目标索引相同，直接返回
      if (sourceIndex === targetIndex) return;

      // 创建项目列表的副本
      const projects = [...this.dataList];
      // 删除源位置的项目，并存储被拖拽的项目
      const [movedItem] = projects.splice(sourceIndex, 1);
      // 在目标位置插入被拖拽的项目
      projects.splice(targetIndex, 0, movedItem);

      // 更新项目列表数据
      this.dataList = projects;
      this.draggingIndex = null; // 重置拖拽索引
    }
    // ============================ 表格行拖拽  end>> =============================
  },
  mounted() {
    // 组件挂载后添加拖拽事件
    this.addDragEvents();
  },
  updated() {
    // 数据更新后重新添加拖拽事件
    // 使用 this.$nextTick 获取表格行的 DOM 元素
    this.$nextTick(() => {
      this.addDragEvents();
    });
  }
}
</script>

<style scoped>
.edit-btn {
  float: right;
}

.drag-source, .drag-target {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
  border: #f2f2f2 1px dashed;
  margin-bottom: 5px
}

.drag-item {
  padding: 5px;
  width: auto;
  line-height: 18px;
  border: #fe346e 1px solid;
  text-align: left;
  border-radius: 2px;
  color: #fe346e;
  overflow-x: hidden;
}


.drag-over {
  background-color: #fe346e;
}

.dragged {
  opacity: 50%;
}

</style>
