<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2'>
      <div class='main-page-content'>
        <el-row class='table-search'>
          <el-form :inline='true' :model='searchCondition'>
            <el-form-item label=''>
              <el-date-picker
                v-model='searchCondition.year'
                type='year'
                format='yyyy 年'
                value-format='yyyy'
                style='width: 140px'
                placeholder='请选择年份' @change='searchButton' clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button icon='el-icon-search' type='primary' @click='searchButton'>查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain type='primary' size='mini' v-if="userPermissions.indexOf('plc_edit')>-1"
                         @click='handleAdd'>
                <i class='el-icon-plus'></i> 新建
              </el-button>

              <!--              <el-button style="float: right" type="danger" size="mini" icon="el-icon-delete" :disabled="multiple" @click="handleDelete">删除-->
              <!--              </el-button>-->
            </el-form-item>
            <el-form-item style='display: inline-block;float: right'>
              <el-button type='warning' size='mini' v-if="userPermissions.indexOf('journal')>-1"
                         @click='handleSetUp'>
                <i class='el-icon-plus'></i> 刊例类型配置
              </el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <!--        刊例列表-->
        <div class='default-table'
             v-loading='loadingStatus'
             element-loading-text='玩命加载中……'
             element-loading-spinner='el-icon-loading'>
          <el-table :data='dataList'
                    :expand-row-keys='[expandRowKey]'
                    @expand-change='expandChange'
                    row-key='id'
                    border>
            <el-table-column type='expand'>
              <template slot-scope='{row,$index}'>
                <div  style='padding: 10px;width:clamp(900px, 1280px, 100%);margin: auto;'>
                  <ChildPanel :base-info='row'></ChildPanel>

                  <div class='opt-row flex-sb'>
                    <export-btn size='mini' type='warning' :ref='`refExportLink_${$index}`'
                                :can-export="userPermissions.indexOf('plc_export')>-1"
                                :lint-title='`导出`' :title='`${row.year}年${row.month}月刊例`'
                                @export='exportData(row,$index)'
                                class-name='export-btn'
                                style='display: inline' />

                    <el-button size='mini' type='danger' icon='el-icon-delete' @click='handleDelete(row)'
                               v-if="userPermissions.indexOf('plc_delete')>-1">删除
                    </el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label='序号' align='center' width='80'>
              <template slot-scope='{$index}'>
                {{ offset + $index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label='刊例标题' align='center' min-width='200'
                             :show-overflow-tooltip='true'>
              <template slot-scope='scope'>
                <el-link type='primary' @click='jumpTo(scope.row)'>{{ scope.row.title || '/' }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label='有效期' align='center' min-width='200'
                             :show-overflow-tooltip='true'>
              <template slot-scope='scope'>
                <span>{{ scope.row.validity_date || '/' }} ~ {{ scope.row.expiry_date || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='提报截止时间' prop='deadline' align='center'></el-table-column>
            <el-table-column label='创建时间' prop='created_at' align='center'></el-table-column>
            <el-table-column label='修改时间' prop='updated_at' align='center'></el-table-column>
<!--            <el-table-column label='操作' width='180' align='center'>-->
<!--              <template slot-scope='{row,$index}'>-->
<!--                <div>-->
<!--                  <el-button size='mini' type='text' icon='el-icon-edit' @click='handleUpdate(row)'-->
<!--                             v-if="userPermissions.indexOf('plc_edit')>-1">-->
<!--                    修改-->
<!--                  </el-button>-->
<!--                  <el-button size='mini' type='text' icon='el-icon-delete' @click='handleDelete(row)'-->
<!--                             v-if="userPermissions.indexOf('plc_delete')>-1">删除-->
<!--                  </el-button>-->

<!--                  <export-btn size='mini' type='text' :ref='`refExportLink_${$index}`'-->
<!--                              :can-export="userPermissions.indexOf('plc_export')>-1"-->
<!--                              :lint-title='`导出`' :title='`${row.year}年${row.month}月刊例`'-->
<!--                              @export='exportData(row,$index)'-->
<!--                              class-name='export-btn'-->
<!--                              style='display: inline' />-->
<!--                </div>-->

<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
          <div>
            <m-pagination :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                          :total.sync='pagingData.total' @pagination='getList'></m-pagination>
          </div>
        </div>
      </div>

      <el-dialog
        title='刊例类型配置'
        :visible.sync='dialogVisible'
        width='800px'
      >
        <Platforms-set-up></Platforms-set-up>
      </el-dialog>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import PlatformsSetUp from '@/pages/publication/PlatformsSetUp'
import ChildPanel from '@/pages/plc/detail/ChildPanel'

export default {
  name: 'PlcIndex',
  components: { ChildPanel, PlatformsSetUp, ExportBtn, ApeTable, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      loadingStatus: false,
      expandRowKey: null,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      searchCondition: { year: null },
      activeName: 'first',
      version: 2025,
      dataList: [],
      columns: [
        // {title: '提报时间', value: 'date_alias', width: 120},
        // {title: '刊例标题', value: 'title', width: 200},
        // {title: '提报数量', value: 'items_count', width: 100},
        // {title: '状态', value: 'status_alias', width: 100},
        // {title: '有效期', value: 'period_validity', width: 120},
        { title: '提报截止时间', value: 'deadline', width: 120 },
        { title: '创建时间', value: 'created_at', width: 120 },
        { title: '最后修改时间', value: 'updated_at', width: 120 }
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 10,
        current_page: 1
        // offset: 0,
      },
      // 分页的offset,序号列使用
      offset: 0,
      dialogVisible: false
    }
  },
  watch: {
    '$route.matched': function(n) {
      if (n.length === 2) {
        this.getList()
      }
    }
  },
  methods: {
    /**
     * 记录当前展开页面
     * @param row
     */
    expandChange(row) {
      this.expandRowKey = row.id
    },
    searchButton() {
      //查询
      // this.$notify.info('查询')
      this.pagingData.current_page = 1
      this.getList()
    },
    handleAdd() {
      // this.$notify.info('新建刊例')
      this.$router.push({ name: 'plc_create' })
    },
    handleUpdate(row) {
      // this.$notify.info('新建刊例')
      this.$router.push({ name: 'plc_edit', params: { id: row.id } })
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该月刊例, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.delPublicationMonthInfo(row.id)
        await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async getList() {
      this.loadingStatus = true
      let pagingInfo = { current_page: this.pagingData.current_page, page_size: this.pagingData.page_size }
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, pagingInfo)
      let { list, pages } = await this.$api.getPublicationMonthList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
        let firstRowId = list[0] ? list[0]['id'] : ''
        let row = list.find((value) => value.id === this.expandRowKey)
        if (!row) {
          if (firstRowId) {
            this.expandRowKey = firstRowId
          }
        }
      })
      this.pagingData.total = pages.total
      setTimeout(() => {
        this.loadingStatus = false
      }, 100)
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      return condition
    },
    jumpTo(row) {
      //调整
      this.$router.push({ name: 'plc_list', params: { year: row.year, month: row.month } })
    },
    //导出表格
    // eslint-disable-next-line no-unused-vars
    async exportData(row, index) {
      try {
        // eslint-disable-next-line no-unused-vars
        let date = new Date()
        //获取数据
        let data = await this.$api.getExportPublicationAllData(row.year, row.month)
        // // 生成excel
        let response = await this.$api.downloadPublicationExcelByData(data)
        let name = `【缇苏】${row.year}年${row.month}月刊例（含税）-${date.getMonth() + 1}.${date.getDate()}`
        if (this.$refs[`refExportLink_${index}`])
          this.$refs[`refExportLink_${index}`].exportSaveXlsx(response, name)

      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    handleSetUp() {
      this.dialogVisible = true
    }


  },
  mounted() {
    // console.log(this.$route.matched.length)
    if (this.$route.matched.length === 2)
      this.getList()
  },
  created() {
    // let date = new Date()
    // this.searchCondition.year = date.getFullYear() + ''
  }
}
</script>

<style scoped>
.opt-row {
  padding: 10px;
}
</style>

